import { ForwardedRef, RefObject } from "react";

import { showDevelopmentError } from "src/utils";

// Inner imports
import { GetElementParamsReturnType } from "./types";
// import { MENU_DROPDOWN_EXTRA_SPACE } from "./constants";

export const getElementParams = (
  elementRef: RefObject<HTMLElement> | ForwardedRef<HTMLElement>,
): GetElementParamsReturnType => {
  const params: GetElementParamsReturnType = {
    element: null,
    height: 0,
    width: 0,
    bottom: 0,
    right: 0,
  };

  if (!elementRef || !("current" in elementRef)) return params;

  try {
    const { current } = elementRef || {};
    const { clientHeight = 0, clientWidth = 0 } = current || {};
    const { bottom = 0, right = 0 } = current?.getBoundingClientRect() || {};

    params.element = current;
    params.height = clientHeight;
    params.width = clientWidth;
    params.bottom = bottom;
    params.right = right;
  } catch (error) {
    const errorTitle = "MENU DROPDOWN REF ERROR";

    showDevelopmentError({ additionalTexts: [errorTitle], error });
  }

  return params;
};

export const getClientParams = () => {
  const { clientHeight, clientWidth } = document.documentElement;

  return {
    height: clientHeight,
    width: clientWidth,
  };
};

export const updateFixedDropdownPosition = (
  buttonElement: HTMLButtonElement,
  element: HTMLDivElement,
): void => {
  const buttonRect = buttonElement.getBoundingClientRect();
  const dropdownRect = element.getBoundingClientRect();
  const windowWidth = window.innerWidth;
  const windowHeight = window.innerHeight;

  const showBelow = buttonRect.bottom + dropdownRect.height <= windowHeight;
  const showRight = buttonRect.right + dropdownRect.width <= windowWidth;

  const top = showBelow
    ? buttonRect.bottom
    : buttonRect.top - dropdownRect.height;

  const left = showRight
    ? buttonRect.right
    : buttonRect.left - dropdownRect.width;

  element.style.setProperty("position", "fixed");
  element.style.setProperty("top", `${top}px`);
  element.style.setProperty("left", `${left}px`);
};
