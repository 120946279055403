import isAfter from "date-fns/isAfter";
import axios from "src/services/axios";
import isValidDate from "date-fns/isValid";
import firestore from "src/services/firestore";

import {
  composeWidgetSyncId,
  getResponseFileName,
  showDevelopmentError,
} from "src/utils";
import { getSearchesByIds } from "../searches/searchesApi";
import { API_ENDPOINTS, COLLECTION_IDS } from "../constants";

// Inner imports
import type { WidgetData } from "./types";
import * as schemas from "./widgetsSchema";
import * as dataCheck from "./widgetDataCheck";
import * as dataFormat from "./widgetsDataFormaters";

//#region SHARE OF VOLUME WIDGET
export const getVolumeShareWidget = async <T extends Widget.IdType>({
  widgetId,
  dashboardDateRangeId,
  trackersCollectionId,
}: {
  widgetId: T;
  dashboardDateRangeId: DashboardDateRange.Data["id"];
  trackersCollectionId: TrackersCollection.Data["id"];
}): Promise<Widgets.DataSources[T]> => {
  const { doc, status } = await getWidgetDocument({
    widgetId,
    dashboardDateRangeId,
    trackersCollectionId,
  });

  if (!doc?.exists || status === "fetch_failed" || status === "not_calculated")
    return <WidgetData<T>>{ updatedAt: "", status };

  try {
    const { updatedAt, ...validatedData } =
      schemas.volumeShareWidgetSchema.validateSync(doc.data());

    const formattedData = dataFormat.formatVolumeShareData(validatedData);

    const isDataComplete = dataCheck.checkVolumeShareWidgetData(formattedData);

    return <WidgetData<T>>{
      updatedAt,
      data: formattedData,
      status: isDataComplete ? status : "data_empty",
    };
  } catch (error) {
    showDevelopmentError({
      error,
      additionalTexts: [
        `Error in ${widgetId} fetching`,
        `DashboardDateRangeId: ${dashboardDateRangeId}`,
        `TrackersCollectionId: ${trackersCollectionId}`,
      ],
    });

    return <WidgetData<T>>{ updatedAt: "", status: "schema_failed" };
  }
};

export const getVolumeShareWidgetExport = async (
  dashboardDateRangeId: DashboardDateRange.Data["id"],
): Promise<Widget.Export> =>
  getWidgetExport(API_ENDPOINTS.exportVolumeShare, dashboardDateRangeId);
//#endregion SHARE OF VOLUME WIDGET

//#region SHARE OF SEARCH WIDGET
export const getSearchShareWidget = async <T extends Widget.IdType>({
  widgetId,
  dashboardDateRangeId,
  trackersCollectionId,
}: {
  widgetId: T;
  dashboardDateRangeId: DashboardDateRange.Data["id"];
  trackersCollectionId: TrackersCollection.Data["id"];
}): Promise<Widgets.DataSources[T]> => {
  const { doc, status } = await getWidgetDocument({
    widgetId,
    dashboardDateRangeId,
    trackersCollectionId,
  });

  if (!doc?.exists || status === "fetch_failed" || status === "not_calculated")
    return <WidgetData<T>>{ updatedAt: "", status };

  try {
    const { updatedAt, ...validatedData } =
      schemas.searchShareWidgetSchema.validateSync(doc.data());

    const formattedData = dataFormat.formatSearchShareData(validatedData);

    const isDataComplete = dataCheck.checkSearchShareWidgetData(formattedData);

    return <WidgetData<T>>{
      updatedAt,
      data: formattedData,
      status: isDataComplete ? status : "data_empty",
    };
  } catch (error) {
    showDevelopmentError({
      error,
      additionalTexts: [
        `Error in ${widgetId} fetching`,
        `DashboardDateRangeId: ${dashboardDateRangeId}`,
        `TrackersCollectionId: ${trackersCollectionId}`,
      ],
    });

    return <WidgetData<T>>{ updatedAt: "", status: "schema_failed" };
  }
};

export const getSearchShareWidgetExport = async (
  dashboardDateRangeId: DashboardDateRange.Data["id"],
): Promise<Widget.Export> =>
  getWidgetExport(API_ENDPOINTS.exportSearchShare, dashboardDateRangeId);
//#endregion SHARE OF SEARCH WIDGET

//#region ARTICLES WIDGET
export const getArticlesWidget = async <T extends Widget.IdType>({
  widgetId,
  paginationProps,
  dashboardDateRangeId,
  trackersCollectionId,
}: {
  widgetId: T;
  paginationProps: Widgets.Articles.PaginationProps;
  dashboardDateRangeId: DashboardDateRange.Data["id"];
  trackersCollectionId: TrackersCollection.Data["id"];
}): Promise<Widgets.DataSources[T]> => {
  const { doc, status } = await getWidgetDocument({
    widgetId,
    dashboardDateRangeId,
    trackersCollectionId,
    collection: COLLECTION_IDS.widgetArticles,
  });

  if (!doc?.exists || status === "fetch_failed" || status === "not_calculated")
    return <WidgetData<T>>{ updatedAt: "", status };

  try {
    const data = await getArticles(paginationProps);

    const { updatedAt, ...validatedData } =
      schemas.articlesWidgetSchema.validateSync(data);

    return <WidgetData<T>>{
      status,
      updatedAt,
      data: dataFormat.formatArticlesData(validatedData),
    };
  } catch (error) {
    showDevelopmentError({
      error,
      additionalTexts: [
        `Error in ${widgetId} fetching`,
        `DashboardDateRangeId: ${dashboardDateRangeId}`,
        `TrackersCollectionId: ${trackersCollectionId}`,
      ],
    });

    return <WidgetData<T>>{ updatedAt: "", status: "schema_failed" };
  }
};

export async function getArticles({
  limit = 25,
  trackers = [],
  searchQuery = "",
}: Widgets.Articles.PaginationProps): Promise<object> {
  const defaultFilters = await getArticlesDefaultFilters(trackers);

  return axios
    .post(API_ENDPOINTS.getArticles, {
      limit,
      searchQuery,
      filters: defaultFilters,
    })
    .then(({ data: articles }) => ({ data: { articles, filters: [] } }));
}

async function getArticlesDefaultFilters(
  trackers: Tracker.Data[],
): Promise<string[]> {
  const [searchIds, categories, descriptions] = [
    new Set<Search.Data["id"]>(),
    new Set<Tracker.Data["category"]>(),
    new Set<Tracker.Data["description"]>(),
  ];

  for (const tracker of trackers) {
    for (const searchId of tracker.searchIds) searchIds.add(searchId);

    if (tracker.category) categories.add(tracker.category);

    if (tracker.description) descriptions.add(tracker.description);
  }

  const searches = await getSearchesByIds([...searchIds]);

  const searchSubjects = searches.map(({ subject }) => subject);

  return [...categories, ...descriptions, ...searchSubjects].filter(Boolean);
}
//#endregion ARTICLES WIDGET

//#region TOP KEYWORDS WIDGET
export const getTopKeywordsWidget = async <T extends Widget.IdType>({
  widgetId,
  dashboardDateRangeId,
  trackersCollectionId,
}: {
  widgetId: T;
  dashboardDateRangeId: DashboardDateRange.Data["id"];
  trackersCollectionId: TrackersCollection.Data["id"];
}): Promise<Widgets.DataSources[T]> => {
  const { doc, status } = await getWidgetDocument({
    widgetId,
    dashboardDateRangeId,
    trackersCollectionId,
  });

  if (!doc?.exists || status === "fetch_failed" || status === "not_calculated")
    return <WidgetData<T>>{ updatedAt: "", status };

  try {
    const { updatedAt, ...validatedData } =
      schemas.topKeywordsWidgetSchema.validateSync(doc.data());

    return <WidgetData<T>>{
      status,
      updatedAt,
      data: dataFormat.formatTopKeywordsData(validatedData),
    };
  } catch (error) {
    showDevelopmentError({
      error,
      additionalTexts: [
        `Error in ${widgetId} fetching`,
        `DashboardDateRangeId: ${dashboardDateRangeId}`,
        `TrackersCollectionId: ${trackersCollectionId}`,
      ],
    });

    return <WidgetData<T>>{ updatedAt: "", status: "schema_failed" };
  }
};

export const getTopKeywordsWidgetExport = async (
  dashboardDateRangeId: DashboardDateRange.Data["id"],
): Promise<Widget.Export> =>
  getWidgetExport(API_ENDPOINTS.exportTopKeywords, dashboardDateRangeId);
//#endregion TOP KEYWORDS WIDGET

//#region TRENDING KEYWORDS WIDGET
export const getTrendingKeywordsWidget = async <T extends Widget.IdType>({
  widgetId,
  dashboardDateRangeId,
  trackersCollectionId,
}: {
  widgetId: T;
  dashboardDateRangeId: DashboardDateRange.Data["id"];
  trackersCollectionId: TrackersCollection.Data["id"];
}): Promise<Widgets.DataSources[T]> => {
  const { doc, status } = await getWidgetDocument({
    widgetId,
    dashboardDateRangeId,
    trackersCollectionId,
  });

  if (!doc?.exists || status === "fetch_failed" || status === "not_calculated")
    return <WidgetData<T>>{ updatedAt: "", status };

  try {
    const { updatedAt, ...validatedData } =
      schemas.trendingKeywordsWidgetSchema.validateSync(doc.data());

    return <WidgetData<T>>{
      status,
      updatedAt,
      data: dataFormat.formatTrendingKeywordsData(validatedData),
    };
  } catch (error) {
    showDevelopmentError({
      error,
      additionalTexts: [
        `Error in ${widgetId} fetching`,
        `DashboardDateRangeId: ${dashboardDateRangeId}`,
        `TrackersCollectionId: ${trackersCollectionId}`,
      ],
    });

    return <WidgetData<T>>{ updatedAt: "", status: "schema_failed" };
  }
};

export const getTrendingKeywordsWidgetExport = async (
  dashboardDateRangeId: DashboardDateRange.Data["id"],
): Promise<Widget.Export> =>
  getWidgetExport(API_ENDPOINTS.exportTrendingKeywords, dashboardDateRangeId);
//#endregion TRENDING KEYWORDS WIDGET

//#region INSIGHTS WIDGET
export const getInsightsWidget = async <T extends Widget.IdType>({
  widgetId,
  dashboardDateRangeId,
  trackersCollectionId,
}: {
  widgetId: T;
  dashboardDateRangeId: DashboardDateRange.Data["id"];
  trackersCollectionId: TrackersCollection.Data["id"];
}): Promise<Widgets.DataSources[T]> => {
  const { doc, status } = await getWidgetDocument({
    widgetId,
    dashboardDateRangeId,
    trackersCollectionId,
  });

  if (!doc?.exists || status === "fetch_failed" || status === "not_calculated")
    return <WidgetData<T>>{ updatedAt: "", status };

  try {
    const { updatedAt, ...validatedData } =
      schemas.insightsWidgetSchema.validateSync(doc.data());

    return <WidgetData<T>>{
      status,
      updatedAt,
      data: dataFormat.formatInsightsData(validatedData),
    };
  } catch (error) {
    showDevelopmentError({
      error,
      additionalTexts: [
        `Error in ${widgetId} fetching`,
        `DashboardDateRangeId: ${dashboardDateRangeId}`,
        `TrackersCollectionId: ${trackersCollectionId}`,
      ],
    });

    return <WidgetData<T>>{ updatedAt: "", status: "schema_failed" };
  }
};

export const updateInsightsWidget = ({
  id,
  changes,
}: Store.UpdateEntity<Widgets.Insights.Data>): Promise<void> =>
  firestore()
    .collection(COLLECTION_IDS.widgetInsights)
    .doc(id)
    .set(changes, { merge: true });
//#endregion INSIGHTS WIDGET

export const createWidgetSyncs = ({
  widgetIds,
  dependentWidgetIds,
  isScheduled = false,
  forceUpdate = false,
  dashboardDateRangeId,
}: {
  isScheduled?: boolean;
  forceUpdate?: boolean;
  widgetIds: Widget.IdType[];
  dependentWidgetIds: Widget.IdType[];
  dashboardDateRangeId: DashboardDateRange.Data["id"];
}): Promise<void> =>
  axios.post(API_ENDPOINTS.createWidgetSyncs, {
    isScheduled,
    forceUpdate,
    dashboardDateRangeId,
    widgetIds: [...widgetIds, ...dependentWidgetIds],
  });

async function getWidgetExport(
  url: string,
  dashboardDateRangeId: DashboardDateRange.Data["id"],
): Promise<Widget.Export> {
  const response = await axios.post(
    url,
    { dashboardDateRangeId },
    { responseType: "blob" },
  );

  return schemas.widgetExportSchema.validateSync({
    data: response.data,
    name: getResponseFileName(response),
  });
}

async function getWidgetDocument<T extends Widget.IdType>({
  widgetId,
  collection,
  trackersCollectionId,
  dashboardDateRangeId,
}: {
  widgetId: T;
  collection?: string;
  dashboardDateRangeId: DashboardDateRange.Data["id"];
  trackersCollectionId: TrackersCollection.Data["id"];
}): Promise<{
  doc: firestore.DocumentSnapshot<firestore.DocumentData> | null;
  status: Widget.Status;
}> {
  const widgetSyncId = composeWidgetSyncId({
    widgetId,
    trackersCollectionId,
    dashboardDateRangeId,
  });

  try {
    const [widgetDoc, widgetSyncDoc] = await Promise.all([
      firestore()
        .collection(collection || widgetId)
        .doc(dashboardDateRangeId)
        .get(),
      firestore()
        .collection(COLLECTION_IDS.widgetsSyncs)
        .doc(widgetSyncId)
        .get(),
    ]);

    if (!widgetDoc.exists && !widgetSyncDoc.exists)
      return { doc: widgetDoc, status: "not_calculated" };

    if (!widgetDoc.exists && widgetSyncDoc.exists)
      return { doc: widgetDoc, status: "calculating" };

    if (widgetDoc.exists && !widgetSyncDoc.exists)
      return { doc: widgetDoc, status: "success" };

    const trackersCollectionDoc = await firestore()
      .collection(COLLECTION_IDS.trackersCollections)
      .doc(trackersCollectionId)
      .get();

    const isTrackersCollectionUpdated = checkIsTrackersCollectionUpdated(
      widgetDoc,
      trackersCollectionDoc,
    );

    if (isTrackersCollectionUpdated)
      return { doc: widgetDoc, status: "calculating" };

    return { doc: widgetDoc, status: "updating" };
  } catch (error) {
    showDevelopmentError({
      error,
      additionalTexts: [
        `Error in ${widgetId} fetching`,
        `DashboardDateRangeId: ${dashboardDateRangeId}`,
        `TrackersCollectionId: ${trackersCollectionId}`,
      ],
    });

    return { doc: null, status: "fetch_failed" };
  }
}

function checkIsTrackersCollectionUpdated(
  widgetDoc: firestore.DocumentSnapshot<firestore.DocumentData>,
  trackersCollectionDoc: firestore.DocumentSnapshot<firestore.DocumentData>,
): boolean {
  const [widgetDocData = {}, trackersCollectionDocData = {}] = [
    widgetDoc.data(),
    trackersCollectionDoc.data(),
  ];

  if (
    !("updatedAt" in widgetDocData) ||
    !("updatedAt" in trackersCollectionDocData) ||
    !isValidDate(new Date(widgetDocData.updatedAt)) ||
    !isValidDate(new Date(trackersCollectionDocData.updatedAt))
  )
    return false;

  const [formattedWidgetUpdatedAt, formattedTrackersCollectionUpdatedAt] = [
    new Date(widgetDocData.updatedAt),
    new Date(trackersCollectionDocData.updatedAt),
  ];

  return isAfter(
    formattedTrackersCollectionUpdatedAt,
    formattedWidgetUpdatedAt,
  );
}
