import { FC, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { useModal } from "src/hooks";
import { useSelector } from "react-redux";
import { useAppDispatch } from "src/store";
import { Translation } from "src/components";
import { showToastNotification } from "src/utils";
import { selectWidgetData } from "src/store/selectors";
import { ConfirmModal } from "../ConfirmModal/ConfirmModal";
import { updateInsightsWidgetData } from "src/store/actions";
import { WIDGET_ID } from "../EditInsightsWidgetDataModal/constants";

type Props = {
  trackersCollectionId: TrackersCollection.Data["id"];
  dashboardDateRangeId: DashboardDateRange.Data["id"];
};

export const ConfirmResetInsightsWidgetDataModal: FC<Props> = ({
  dashboardDateRangeId,
}) => {
  const { t } = useTranslation();

  const { closeModal } = useModal();

  const dispatch = useAppDispatch();

  const widgetData = useSelector((state: Store.RootState) =>
    selectWidgetData(state, dashboardDateRangeId, WIDGET_ID),
  );

  const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>("idle");

  const isLoading = useMemo<boolean>(
    () => loadingStatus === "loading",
    [loadingStatus],
  );

  const isDisabled = useMemo<boolean>(() => isLoading, [isLoading]);

  const onSubmit = async (): Promise<void> => {
    if (isDisabled || !widgetData) return;

    try {
      setLoadingStatus("loading");

      await dispatch(
        updateInsightsWidgetData({
          dashboardDateRangeId,
          changes: {
            ...widgetData,
            data: {
              ...widgetData.data,
              customPrompt: { value: "", authorId: "", updatedAt: "" },
            },
          },
        }),
      );

      showToastNotification({
        id: "insights-reset-success",
        type: "success",
        text: t("component.modal.reset_insights.status.success.data_updated"),
      });

      setLoadingStatus("succeeded");

      closeModal("confirm-reset-insights-widget-data");
    } catch (error) {
      console.error(error);

      showToastNotification({
        id: "insights-reset-fail",
        type: "error",
        text: t("server_error"),
      });

      setLoadingStatus("failed");
    }
  };

  return (
    <ConfirmModal
      id="confirm-reset-insights-widget-data"
      type="warning"
      title={t("component.modal.reset_insights.title")}
      acceptButton={{
        text: t("component.modal.reset_insights.button.submit"),
        onClick: onSubmit,
      }}
      cancelButton={{
        text: t("component.modal.reset_insights.button.cancel"),
        onClick: () => closeModal("confirm-reset-insights-widget-data"),
      }}
      isLoading={isLoading}
    >
      <span>
        <Translation i18nKey="component.modal.reset_insights.body" />
      </span>
    </ConfirmModal>
  );
};
