import { DEFAULT_LANGUAGE_ID } from "src/constants";

export type LocationType = Extract<
  keyof Location.Data,
  "city" | "region" | "country"
>;

export const getLocationDefaultLanguageId = (
  locationId: Location.Data["id"],
  locations: Location.Data[],
  languages: Language.Data[],
): Language.Data["id"] => {
  if (!locationId) return DEFAULT_LANGUAGE_ID;

  const location = locations.find(({ id }) => locationId === id);

  if (!location) return DEFAULT_LANGUAGE_ID;

  const locationDefaultLanguageId = location.languageIds[0];

  if (!locationDefaultLanguageId) return DEFAULT_LANGUAGE_ID;

  const language = languages.find(({ id }) => locationDefaultLanguageId === id);

  if (!language) return DEFAULT_LANGUAGE_ID;

  return language.id;
};

export const getLocationType = (location: Location.Data): LocationType => {
  if (location.city !== null) return "city";

  if (location.region !== null) return "region";

  return "country";
};

export const getLocationName = (location: Location.Data): string => {
  const locationType = getLocationType(location);

  if (locationType === "country" && location.country) {
    return location.country;
  }

  if (locationType === "region" && location.region) {
    let locationName = location.region;

    if (location.type) locationName = `${locationName} (${location.type})`;

    return locationName;
  }

  if (locationType === "city" && location.city) {
    let locationName = location.city;

    if (location.type) locationName = `${locationName} (${location.type})`;

    return locationName;
  }

  return location.name;
};
