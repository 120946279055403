import { TableColumn } from "src/components/Table/types";

// Inner imports
import { FormattedMember } from "./types";

export const SETTINGS_MEMBERS_TABLE_COLUMNS: TableColumn<
  Partial<FormattedMember>
>[] = [
  {
    key: "name",
    labelKey: "page.settings.company_members.table.label.name",
    isSortable: true,
    style: { fontWeight: 500, width: "30%" },
  },
  {
    key: "email",
    labelKey: "page.settings.company_members.table.label.email",
    isSortable: true,
    style: { width: "35%" },
  },
  {
    key: "companyRoleValue",
    dataKey: "companyRoleLabel",
    labelKey: "page.settings.company_members.table.label.role",
    isSortable: true,
    style: { width: "15%" },
  },
  {
    key: "lastActiveAt",
    dataKey: "lastActiveAtLabel",
    labelKey: "page.settings.company_members.table.label.last_active",
    isSortable: true,
    style: { width: "20%" },
  },
];
