import { MutableRefObject, useEffect } from "react";

import { useScrollTo } from "src/hooks";

export const useScrollToExtendedWidget = (
  expandedWidgetId: Widget.IdType | null,
  customRef?: MutableRefObject<HTMLDivElement | null>,
): MutableRefObject<HTMLDivElement | null> => {
  const [ref, setShouldScrollTo] = useScrollTo<HTMLDivElement | null>(
    { block: "start" },
    customRef,
  );

  useEffect(() => {
    if (expandedWidgetId && ref) setShouldScrollTo(true);
  }, [ref, expandedWidgetId, setShouldScrollTo]);

  return ref;
};
