import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";

import * as api from "./languagesApi";
import { onStateFulfilled, onStatePending, onStateRejected } from "../utils";

export const languagesAdapter = createEntityAdapter<Language.Data>({
  sortComparer: (a, b) => a.name.localeCompare(b.name),
});

const initialState = languagesAdapter.getInitialState<Store.InitialState>({
  status: "idle",
  error: null,
});

export const fetchAllLanguages = createAsyncThunk<Language.Data[], void>(
  "languages/fetch-all",
  api.getLanguages,
);

const languages = createSlice({
  name: "languages",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAllLanguages.pending, onStatePending);
    builder.addCase(fetchAllLanguages.rejected, onStateRejected);
    builder.addCase(fetchAllLanguages.fulfilled, (...args) => {
      languagesAdapter.addMany(...args);
      onStateFulfilled(...args);
    });
  },
});

export default languages.reducer;
