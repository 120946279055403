import { FC, FormEvent, useCallback, useContext, useState } from "react";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";

import styles from "./LoginForm.module.scss";
import context from "src/context";
import { withError } from "src/hocs";
import { useAppDispatch } from "src/store";
import { useTemporaryErrors } from "src/hooks";
import { logInWithEmailAndPassword } from "src/store/actions";
import { Input, InputPassword, Label, Button } from "src/components";
import {
  validateEmail,
  isErrorTypeGuard,
  showToastNotification,
} from "src/utils";

const InputWithError = withError(Input);

const LoginForm: FC = () => {
  const history = useHistory();

  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const { setIsGlobalPreloaderShown } = useContext(context);

  const { errors, setErrors } = useTemporaryErrors(3000);

  const [email, setEmail] = useState<string>("");

  const [password, setPassword] = useState<string>("");

  const redirectToVerification = useCallback(
    (userId: User.Data["id"]): void => history.push(`/verify/${userId}`),
    [history],
  );

  async function handleSubmitForm(
    e: FormEvent<HTMLFormElement>,
  ): Promise<void> {
    e.preventDefault();

    const errors = validate();
    if (Object.keys(errors).length) {
      setErrors(errors);
      return;
    }

    setIsGlobalPreloaderShown(true);

    try {
      await dispatch(
        logInWithEmailAndPassword({
          email,
          password,
          unverifiedUserCallback: redirectToVerification,
        }),
      ).unwrap();
    } catch (error) {
      let message = "";

      if (isErrorTypeGuard(error)) message = error.message;

      showToastNotification({
        id: "login-error-notification",
        type: "error",
        text: message || t("common.error.server_error"),
      });
    } finally {
      setIsGlobalPreloaderShown(false);
    }
  }

  function validate() {
    const validationErrors: typeof errors = {};

    if (!email.trim().length)
      validationErrors.email = t(
        "authentication.login.form.validation.email_required",
      );

    if (!validateEmail(email))
      validationErrors.email = t(
        "authentication.login.form.validation.invalid_email",
      );

    if (!password.trim().length)
      validationErrors.password = t(
        "authentication.login.form.validation.password_required",
      );

    return validationErrors;
  }

  return (
    <form
      className={styles.formWrapper}
      name="logIn"
      autoComplete="off"
      onSubmit={handleSubmitForm}
    >
      <div className={styles.inputsWrapper}>
        <div className={styles.inputWrapper}>
          <Label
            leftText={t("authentication.login.form.label.email")}
            className={styles.label}
          />
          <InputWithError
            className={styles.input}
            value={email}
            changeHandler={(value) => setEmail(value.trim())}
            placeholder={t("authentication.login.form.placeholder.email")}
            error={errors.email}
            tabIndex={1}
            autoFocus
          />
        </div>
        <div className={styles.inputWrapper}>
          <Label
            leftText={t("authentication.login.form.label.password")}
            className={styles.label}
          />
          <InputPassword
            className={cx(styles.input, styles.passwordInput)}
            value={password}
            onChangeHandler={(value) => setPassword(value.trim())}
            placeholder={t("authentication.login.form.placeholder.password")}
            error={errors.password}
            tabIndex={2}
          />
        </div>
      </div>
      <div className={styles.resetPassword}>
        <span>{t("authentication.login.label.forgotten_password")}</span>
        <Link className={styles.link} to="/password-reset">
          {t("authentication.login.button.reset_password")}
        </Link>
      </div>
      <Button className={styles.button} type="submit" tabIndex={3}>
        {t("authentication.login.form.button.submit")}
      </Button>
    </form>
  );
};

export { LoginForm };
