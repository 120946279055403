import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import styles from "../InsightsPreview.module.scss";
import { Tooltip, Translation } from "src/components";
import {
  formatToMonthDayFullYearDate,
  formatToDayMonthFullYearTimeDate,
} from "src/utils";
import {
  selectUserId,
  selectCompany,
  selectWidgetData,
  selectIsCompanyDashboard,
} from "src/store/selectors";

// Inner imports
import { WIDGET_ID } from "../constants";

export const useInsightsFooter = ({
  trackersCollectionId,
  dashboardDateRangeId,
}: Widget.ChartProps): JSX.Element | null => {
  const { t } = useTranslation();

  const userId = useSelector(selectUserId);

  const { members } = useSelector(selectCompany);

  const widgetData = useSelector((state: Store.RootState) =>
    selectWidgetData(state, dashboardDateRangeId, WIDGET_ID),
  );

  const isCompanyDashboard = useSelector((state: Store.RootState) =>
    selectIsCompanyDashboard(state, trackersCollectionId),
  );

  const authorName = useMemo<string>(() => {
    if (!widgetData) return "";

    const { customPrompt } = widgetData.data;

    const authorId = customPrompt.authorId;

    if (!authorId) return t("widget.insights.footer.label.ai_name");

    if (authorId === userId)
      return t("widget.insights.footer.label.current_user_name");

    const member = members[authorId];

    if (!member) return "";

    return t("w_insights_footer_author_name", member);
  }, [members, t, userId, widgetData]);

  const formattedUpdatedAt = useMemo<string>(() => {
    if (!widgetData) return "";

    const { customPrompt, aiPrompt } = widgetData.data;

    if (customPrompt.updatedAt)
      return formatToMonthDayFullYearDate(customPrompt.updatedAt);

    return formatToMonthDayFullYearDate(aiPrompt.updatedAt);
  }, [widgetData]);

  const tooltipFormattedUpdatedAt = useMemo<string>(() => {
    if (!widgetData) return "";

    const { customPrompt, aiPrompt } = widgetData.data;

    if (customPrompt.updatedAt)
      return formatToDayMonthFullYearTimeDate(customPrompt.updatedAt);

    return formatToDayMonthFullYearTimeDate(aiPrompt.updatedAt);
  }, [widgetData]);

  if (!isCompanyDashboard || !authorName) return null;

  return (
    <div className={styles.footerWrapper}>
      <Tooltip
        content={t("widget.insights.footer.tooltip.updated_at", {
          date: tooltipFormattedUpdatedAt,
        })}
      >
        <span>
          <Translation
            i18nKey="widget.insights.footer.label.updated_at"
            values={{ name: authorName, date: formattedUpdatedAt }}
          />
        </span>
      </Tooltip>
    </div>
  );
};
