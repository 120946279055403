import { useImmer } from "use-immer";

type Props = {
  defaultSelectedSearches: Record<Tracker.Data["id"], Search.CreationData[]>;
};

export const useSelectedSearches = ({ defaultSelectedSearches }: Props) => {
  const [selectedSearches, setSelectedSearches] = useImmer<
    Record<string, Search.CreationData[]>
  >(defaultSelectedSearches);

  const selectSearch = (
    { id }: Tracker.CreationData,
    search: Search.CreationData,
  ): void =>
    setSelectedSearches((draft) => {
      const selectedSearches = draft[id] || [];

      draft[id] = [...selectedSearches, search];
    });

  const selectSearches = (
    { id }: Tracker.CreationData,
    searches: Search.CreationData[],
  ): void =>
    setSelectedSearches((draft) => {
      draft[id] = [...searches];
    });

  const resetSearches = ({ id }: Tracker.CreationData): void =>
    setSelectedSearches((draft) => {
      draft[id] = [];
    });

  return { selectedSearches, selectSearch, selectSearches, resetSearches };
};
