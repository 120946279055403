import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import styles from "./PasswordReset.module.scss";
import { PasswordsResetForm } from "src/features";

const PasswordReset: FC = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.passwordReset}>
      <span className={styles.title}>
        {t("authentication.password_reset.title")}
      </span>
      <PasswordsResetForm />
      <Link className={styles.link} to="/login">
        {t("authentication.password_reset.button.redirect_to_login")}
      </Link>
    </div>
  );
};

export default PasswordReset;
