import { memo, FC } from "react";
import cx from "classnames";

import styles from "./Tabs.module.scss";
import { Button } from "src/components";
import { compareTwoStrings } from "src/utils";

// Inner imports
import type { TabsProps } from "./types";

export const Tabs: FC<TabsProps> = memo(
  ({ className = "", activeTab = "", tabs }) => (
    <div className={cx(styles.tabs, className)}>
      {tabs.map(({ value, label, onSelect }) => {
        const isTabActive = compareTwoStrings(activeTab, value);

        return (
          <Button
            key={value}
            className={cx(styles.tab, styles[isTabActive ? "tabActive" : ""])}
            buttonSize="medium"
            buttonStyle="transparent"
            onClick={() => onSelect(value)}
          >
            {label}
          </Button>
        );
      })}
    </div>
  ),
);
