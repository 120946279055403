import { FC, useEffect, useMemo, useState } from "react";
import { useImmer } from "use-immer";
import addDays from "date-fns/addDays";
import isAfter from "date-fns/isAfter";
import isBefore from "date-fns/isBefore";
import { useSelector } from "react-redux";
import isSameDay from "date-fns/isSameDay";
import { useTranslation } from "react-i18next";

import styles from "./EditEventModal.module.scss";
import { withError } from "src/hocs";
import { useAppDispatch } from "src/store";
import { ConfirmModal } from "src/features";
import { EVENT_INPUT_LIMIT, EVENT_TYPE_OPTIONS } from "src/constants";
import { DateSelector, Form, Input, Label, Select } from "src/components";
import { useElementFocus, useModal, useTemporaryErrors } from "src/hooks";
import {
  updateEvent,
  createWidgetSyncs,
  createDashboardDateRange,
  removeDashboardDateRange,
} from "src/store/actions";
import {
  selectEventById,
  selectActiveWidgetIds,
  selectEventsByDashboardId,
  selectKeywordsDataSourcesLatestDataDate,
  selectTrackersCollectionKeywordsDataSources,
  selectDashboardDataRangeByDashboardDateRangeId,
  selectDashboardDateRangesByTrackersCollectionId,
  selectDefaultDashboardDateRangeByTrackersCollectionId,
} from "src/store/selectors";
import {
  checkIsSameMonth,
  removeExtraSpaces,
  formatToYearMonthDay,
  showToastNotification,
  isEventTypeTypeGuard,
  showDevelopmentError,
  getDifferenceInObjects,
  generateDashboardDateRangeFromEvent,
  getExistingDashboardDateRangeByEvent,
} from "src/utils";

const InputWithError = withError(Input);

const DateSelectorWithError = withError(DateSelector);

type Props = {
  eventId: Event.Data["id"];
  dashboardId: Dashboard.Data["id"];
};

export const EditEventModal: FC<Props> = ({ dashboardId, eventId }) => {
  const { t } = useTranslation();

  const { closeModal } = useModal();

  const dispatch = useAppDispatch();

  const [ref, setFocus] = useElementFocus();

  const activeWidgetIds = useSelector(selectActiveWidgetIds);

  const dashboardDateRanges = useSelector((state: Store.RootState) =>
    selectDashboardDateRangesByTrackersCollectionId(state, dashboardId),
  );

  const keywordsDataSources = useSelector((state: Store.RootState) =>
    selectTrackersCollectionKeywordsDataSources(state, dashboardId),
  );

  const keywordsDataSourcesLatestDate = useSelector((state: Store.RootState) =>
    selectKeywordsDataSourcesLatestDataDate(state, keywordsDataSources),
  );

  const dashboardDataRange = useSelector((state: Store.RootState) =>
    selectDefaultDashboardDateRangeByTrackersCollectionId(state, dashboardId),
  );

  const { startDate: dataStartDate } = useSelector((state: Store.RootState) =>
    selectDashboardDataRangeByDashboardDateRangeId(
      state,
      dashboardDataRange?.id || "",
    ),
  );

  const selectedEvent = useSelector((state: Store.RootState) =>
    selectEventById(state, eventId),
  );

  const defaultEvent = useMemo<Event.Data>(() => {
    if (selectedEvent) return selectedEvent;

    const defaultDate = formatToYearMonthDay(new Date());

    return {
      id: "",
      name: "",
      authorId: "",
      createdAt: "",
      updatedAt: "",
      companyId: "",
      isActive: true,
      dashboardId: "",
      description: "",
      endDate: defaultDate,
      startDate: defaultDate,
      dashboardDateRangeId: "",
    };
  }, [selectedEvent]);

  const defaultEventType = useMemo<Event.Type>(() => {
    const { startDate, endDate } = defaultEvent;

    const [formattedStartDate, formattedEndDate] = [
      new Date(startDate),
      new Date(endDate),
    ];

    return isSameDay(formattedStartDate, formattedEndDate) ? "single" : "range";
  }, [defaultEvent]);

  const events = useSelector((state: Store.RootState) =>
    selectEventsByDashboardId(state, defaultEvent.dashboardId),
  );

  const [event, setEvent] = useImmer<Event.Data>(defaultEvent);

  const [eventType, setEventType] = useState<Event.Type>(defaultEventType);

  const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>("idle");

  const { errors, setErrors } = useTemporaryErrors(3000);

  const isLoading = useMemo<boolean>(
    () => loadingStatus === "loading",
    [loadingStatus],
  );

  const isSingleDateSelected = useMemo<boolean>(
    () => eventType === "single",
    [eventType],
  );

  const minEndDate = useMemo<string>(() => {
    try {
      const formattedStartDate = new Date(event.startDate);

      return formatToYearMonthDay(addDays(formattedStartDate, 1));
    } catch (error) {
      console.error(error);

      return event.startDate;
    }
  }, [event.startDate]);

  const isEventChanged = useMemo<boolean>(
    () => Boolean(getDifferenceInObjects(event, defaultEvent)),
    [event, defaultEvent],
  );

  const isEventDateMonthChanged = useMemo<boolean>(() => {
    const [isStartDateMonthChanged, isEndDateMonthChanged] = [
      !checkIsSameMonth(defaultEvent.startDate, event.startDate),
      !checkIsSameMonth(defaultEvent.endDate, event.endDate),
    ];

    return isStartDateMonthChanged || isEndDateMonthChanged;
  }, [
    event.endDate,
    event.startDate,
    defaultEvent.endDate,
    defaultEvent.startDate,
  ]);

  const isDisabled = useMemo<boolean>(
    () => isLoading || !isEventChanged,
    [isLoading, isEventChanged],
  );

  useEffect(() => setFocus(), [setFocus]);

  const onNameChange = (value: string): void =>
    setEvent((draft) => {
      draft.name = value;
    });

  const onDescriptionChange = (value: string): void =>
    setEvent((draft) => {
      draft.description = value;
    });

  const onTypeChange = (value: string): void => {
    if (!isEventTypeTypeGuard(value)) return;

    if (value === eventType) return;

    setEventType(value);

    if (value === "single")
      setEvent((draft) => {
        draft.endDate = draft.startDate;
      });

    if (value === "range")
      setEvent((draft) => {
        draft.endDate = minEndDate;
      });
  };

  const onDateChange = (value: string): void =>
    setEvent((draft) => {
      draft.startDate = value;
      draft.endDate = value;
    });

  const onStartDateChange = (value: string): void => {
    setEvent((draft) => {
      draft.startDate = value;
    });

    const [formattedStartDate, formattedEndDate] = [
      new Date(value),
      new Date(event.endDate),
    ];

    if (!isBefore(formattedStartDate, formattedEndDate))
      setEvent((draft) => {
        draft.endDate = value;
      });
  };

  const onEndDateChange = (value: string): void =>
    setEvent((draft) => {
      draft.endDate = value;
    });

  const onSubmit = async (): Promise<void> => {
    const errors = validate();

    if (Object.keys(errors).length) return setErrors(errors);

    const formattedEvent = {
      ...event,
      name: removeExtraSpaces(event.name),
      description: removeExtraSpaces(event.description),
      endDate: eventType === "single" ? event.startDate : event.endDate,
    };

    try {
      setLoadingStatus("loading");

      if (isEventDateMonthChanged) {
        const existingDashboardDateRangeByEvent =
          getExistingDashboardDateRangeByEvent(event, dashboardDateRanges);

        if (existingDashboardDateRangeByEvent) {
          formattedEvent.dashboardDateRangeId =
            existingDashboardDateRangeByEvent.id;
        } else {
          const createdDashboardDateRange = await dispatch(
            createDashboardDateRange(
              generateDashboardDateRangeFromEvent(event, dataStartDate),
            ),
          ).unwrap();

          formattedEvent.dashboardDateRangeId = createdDashboardDateRange.id;

          dispatch(
            createWidgetSyncs({
              forceUpdate: true,
              isScheduled: false,
              dependentWidgetIds: [],
              widgetIds: activeWidgetIds,
              dashboardDateRangeId: createdDashboardDateRange.id,
            }),
          ).catch((error) =>
            showDevelopmentError({
              error,
              additionalTexts: [
                "Error on widget calculation",
                `DashboardDateRangeId: ${createdDashboardDateRange.id}`,
              ],
            }),
          );
        }

        await dispatch(removeDashboardDateRange(event.dashboardDateRangeId));
      }

      await dispatch(
        updateEvent({ id: event.id, changes: formattedEvent }),
      ).unwrap();

      setLoadingStatus("succeeded");

      showToastNotification({
        id: "edit_event_success",
        type: "success",
        text: t("component.modal.edit_event.form.status.success.event_created"),
      });

      closeModal("edit-event");
    } catch (error) {
      showToastNotification({
        type: "error",
        text: t("common.error.server_error"),
      });

      setLoadingStatus("failed");
    }
  };

  function validate() {
    const validationErrors: typeof errors = {};

    const { id, name, startDate, endDate } = event;

    if (name.trim().length) {
      const isExistingEventName = events.some(
        (event) => event.id !== id && event.name === name,
      );

      if (isExistingEventName)
        validationErrors.name = t(
          "component.modal.edit_event.form.validation.name_exists",
        );
    } else {
      validationErrors.name = t(
        "component.modal.edit_event.form.validation.name_required",
      );
    }

    const [formattedStartDate, formattedEndDate] = [
      new Date(startDate),
      new Date(endDate),
    ];

    const isExistingDateRange = events.some(
      (event) =>
        event.id !== id &&
        isSameDay(new Date(event.startDate), formattedStartDate) &&
        isSameDay(new Date(event.endDate), formattedEndDate),
    );

    if (isExistingDateRange) {
      validationErrors.startDate = t(
        "component.modal.edit_event.form.validation.date_exists",
      );
      validationErrors.date = t(
        "component.modal.edit_event.form.validation.date_exists",
      );
    }

    if (isAfter(formattedStartDate, formattedEndDate))
      validationErrors.startDate = t(
        "component.modal.edit_event.form.validation.incorrect_date",
      );

    return validationErrors;
  }

  return (
    <ConfirmModal
      id="edit-event"
      type="success"
      acceptButton={{
        onClick: onSubmit,
        text: t("component.modal.edit_event.button.submit"),
        disabled: isDisabled,
      }}
      cancelButton={{
        onClick: () => closeModal("edit-event"),
        text: t("component.modal.edit_event.button.cancel"),
      }}
      title={t("component.modal.edit_event.title", { name: defaultEvent.name })}
      isLoading={isLoading}
    >
      <Form
        className={styles.formWrapper}
        onSubmit={onSubmit}
        disabled={isDisabled}
      >
        <div className={styles.inputWrapper}>
          <Label leftText={t("component.modal.edit_event.form.label.name")} />
          <InputWithError
            ref={ref}
            value={event.name}
            error={errors.name}
            changeHandler={onNameChange}
            characterLimit={EVENT_INPUT_LIMIT}
            placeholder={t("component.modal.edit_event.form.placeholder.name")}
          />
        </div>
        <div className={styles.inputWrapper}>
          <Label
            leftText={t("component.modal.edit_event.form.label.description")}
          />
          <InputWithError
            value={event.description}
            error={errors.description}
            characterLimit={EVENT_INPUT_LIMIT}
            changeHandler={onDescriptionChange}
            placeholder={t(
              "component.modal.edit_event.form.placeholder.description",
            )}
          />
        </div>
        <div className={styles.inputWrapper}>
          <Label
            leftText={t("component.modal.edit_event.form.label.event_type")}
          />
          <Select
            value={eventType}
            options={EVENT_TYPE_OPTIONS}
            changeHandler={onTypeChange}
            openingDirection="bottom-end"
            dropdownClassName={styles.selectDropdownWrapper}
            placeholder={t(
              "component.modal.edit_event.form.placeholder.event_type",
            )}
          />
        </div>
        {isSingleDateSelected && (
          <div className={styles.inputWrapper}>
            <Label leftText={t("component.modal.edit_event.form.label.date")} />
            <DateSelectorWithError
              error={errors.date}
              value={event.startDate}
              onChange={onDateChange}
              maxValue={keywordsDataSourcesLatestDate}
              placeholder={t(
                "component.modal.edit_event.form.placeholder.date",
              )}
            />
          </div>
        )}
        {!isSingleDateSelected && (
          <div className={styles.groupWrapper}>
            <div className={styles.inputWrapper}>
              <Label
                leftText={t("component.modal.edit_event.form.label.start_date")}
              />
              <DateSelectorWithError
                value={event.startDate}
                error={errors.startDate}
                onChange={onStartDateChange}
                maxValue={keywordsDataSourcesLatestDate}
                placeholder={t(
                  "component.modal.edit_event.form.placeholder.start_date",
                )}
              />
            </div>
            <div className={styles.inputWrapper}>
              <Label
                leftText={t("component.modal.edit_event.form.label.end_date")}
              />
              <DateSelectorWithError
                value={event.endDate}
                minValue={minEndDate}
                error={errors.endDate}
                onChange={onEndDateChange}
                maxValue={keywordsDataSourcesLatestDate}
                placeholder={t(
                  "component.modal.edit_event.form.placeholder.endDate",
                )}
              />
            </div>
          </div>
        )}
      </Form>
    </ConfirmModal>
  );
};
