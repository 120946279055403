import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { useAbortController } from "src/hooks";
import { selectLocationsEntities } from "src/store/selectors";
import { getSuggestedCategories } from "src/store/trackers/trackersApi";
import {
  DEFAULT_TRACKER_CATEGORY,
  DEFAULT_TRACKER_DESCRIPTION,
} from "src/constants";
import {
  showDevelopmentError,
  showToastNotification,
  isAbortedRequestErrorTypeGuard,
} from "src/utils";

type Props = {
  status: LoadingStatus;
  updateStatusHandler: (status: LoadingStatus) => void;
};

export const useGetSuggestedCategories = ({
  status,
  updateStatusHandler,
}: Props) => {
  const { t } = useTranslation();

  const locations = useSelector(selectLocationsEntities);

  const abortController = useAbortController();

  const getDefaultCategory = useCallback(
    (query: string): Tracker.Category => ({
      subject: query,
      category: DEFAULT_TRACKER_CATEGORY,
      description: DEFAULT_TRACKER_DESCRIPTION,
    }),
    [],
  );

  const getLocation = useCallback(
    (locationId: Location.Data["id"]): Location.Data | undefined =>
      locations[locationId],
    [locations],
  );

  const _getSuggestedCategories = async ({
    query,
    callback,
    locationId,
  }: {
    query: string;
    locationId: Location.Data["id"];
    callback?: (categories: Tracker.Category[]) => void;
  }): Promise<Tracker.Category[]> => {
    const location = getLocation(locationId);

    if (!location) return [];

    try {
      updateStatusHandler("loading");

      const result = await getSuggestedCategories(
        { query, location },
        { signal: abortController?.signal },
      );

      if (!result.length && query) result.push(getDefaultCategory(query));

      updateStatusHandler("succeeded");

      callback?.(result);

      return result;
    } catch (error) {
      if (isAbortedRequestErrorTypeGuard(error)) return [];

      showDevelopmentError({ error });

      showToastNotification({
        type: "error",
        text: t("common.error.server_error"),
      });

      updateStatusHandler("failed");

      const result: Tracker.Category[] = [];

      if (!result.length && query) result.push(getDefaultCategory(query));

      callback?.(result);

      return result;
    }
  };

  const cancelGetSuggestedCategories = (): void => {
    if (abortController.signal.aborted) return;

    return abortController.abort();
  };

  return {
    loadingStatus: status,
    cancelGetSuggestedCategories,
    getSuggestedCategories: _getSuggestedCategories,
  };
};
