import { useMemo, useState } from "react";

// Inner imports
import * as utils from "../utils";
import { ChartItem } from "../types";

type Props = {
  data: ChartItem[];
  hasForecast: boolean;
  hasTrendLine: boolean;
};

type ReturnProps = {
  isTrendLineShown: boolean;
  trendLineData: Record<number, number>;
  forecastTrendLineData: Record<number, number>;
  setIsTrendLineShown: (value: boolean) => void;
};

export const useTrendLine = ({
  data,
  hasForecast,
  hasTrendLine,
}: Props): ReturnProps => {
  const [isTrendLineShown, setIsTrendLineShown] =
    useState<boolean>(hasTrendLine);

  const trendLineData = useMemo<Record<number, number>>(() => {
    if (!hasTrendLine) return {};

    const actualData = data.filter(({ isForecasted }) => !isForecasted);

    return utils.getTrendLineValues(actualData);
  }, [hasTrendLine, data]);

  const forecastTrendLineData = useMemo<Record<number, number>>(() => {
    if (!hasTrendLine || !hasForecast) return {};

    return utils.getTrendLineValues(data);
  }, [data, hasForecast, hasTrendLine]);

  return {
    trendLineData,
    isTrendLineShown,
    setIsTrendLineShown,
    forecastTrendLineData,
  };
};
