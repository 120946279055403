import { useEffect, useMemo, useRef } from "react";
import debounce from "lodash/debounce";

export const useDebounce = <T extends Function>(
  callback: T,
  delay: number = 1000,
) => {
  const ref = useRef<T>();

  useEffect(() => {
    ref.current = callback;
  }, [callback]);

  return useMemo(() => {
    function func(...args: any) {
      ref.current?.(...args);
    }

    return debounce(func, delay);
  }, [delay]);
};
