import Profile from "src/pages/Settings/Profile/Profile";
import Company from "src/pages/Settings/Company/Company";
import Members from "src/pages/Settings/Members/Members";
import Billing from "src/pages/Settings/Billing/Billing";

// Inner imports
import { SettingsTab, SettingsTabMetaData } from "./types";
import {
  checkBillingTabPermission,
  checkCompanyTabPermission,
  checkCompanyMembersTabPermission,
} from "./utils";

export const SETTINGS_TAB_IDS = [
  "profile",
  "company",
  "members",
  "billing",
] as const;

export const SETTINGS_TABS: Record<SettingsTab, SettingsTabMetaData> = {
  profile: {
    label: "page.settings.label.profile",
    Component: Profile,
  },
  company: {
    label: "page.settings.label.company",
    Component: Company,
    checkIsAvailable: checkCompanyTabPermission,
  },
  members: {
    label: "page.settings.label.company_members",
    Component: Members,
    checkIsAvailable: checkCompanyMembersTabPermission,
  },
  billing: {
    label: "page.settings.label.subscription_plan",
    Component: Billing,
    checkIsAvailable: checkBillingTabPermission,
  },
};

export const SETTINGS_TABS_DEFAULT_TAB: SettingsTab = "profile";
