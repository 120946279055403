import firestore from "src/services/firestore";
import { showDevelopmentError } from "src/utils";
import { COLLECTION_IDS } from "../constants";
import locationSchema from "./locationsSchema";

export const getLocations = async (): Promise<Location.Data[]> => {
  const result = await firestore().collection(COLLECTION_IDS.locations).get();

  if (result.empty) throw Error("Locations not found");

  return result.docs.reduce<Location.Data[]>((acc, doc) => {
    try {
      const docData = doc.data();
      const { isActive } = docData;

      if (!Boolean(isActive)) return acc;

      const location = locationSchema.validateSync(docData);

      acc.push({
        ...location,
        id: doc.id,
      });
    } catch (error) {
      const errorTitle = "LOCATION VALIDATION ERROR";

      showDevelopmentError({
        error,
        additionalTexts: [errorTitle],
        reportMetadata: { message: errorTitle },
      });
    }

    return acc;
  }, []);
};
