import { createDraftSafeSelector } from "@reduxjs/toolkit";

import { RootState } from "..";
import { WIDGET_DEFAULT_STATUS } from "src/constants";

// Inner imports
import { widgetsAdapter } from "./widgetsSlice";

export const {
  selectAll: selectWidgets,
  selectEntities: selectWidgetsEntities,
  selectById: selectWidgetsByDashboardDateRangeId,
} = widgetsAdapter.getSelectors<Store.RootState>(({ widgets }) => widgets);

export const selectWidgetStatus = createDraftSafeSelector(
  (
    state: RootState,
    dashboardDateRangeId: DashboardDateRange.Data["id"],
    widgetId: Widget.IdType,
  ): [
    ReturnType<typeof selectWidgetsByDashboardDateRangeId>,
    Widget.IdType,
  ] => [
    selectWidgetsByDashboardDateRangeId(state, dashboardDateRangeId),
    widgetId,
  ],
  ([widgets, widgetId]): Widget.Status => {
    if (!widgets) return WIDGET_DEFAULT_STATUS;

    const widgetData = widgets[widgetId];

    if (!widgetData) return WIDGET_DEFAULT_STATUS;

    return widgetData.status;
  },
);

export const selectWidgetLastUpdatedDate = createDraftSafeSelector(
  (
    state: RootState,
    dashboardDateRangeId: DashboardDateRange.Data["id"],
    widgetId: Widget.IdType,
  ): [
    ReturnType<typeof selectWidgetsByDashboardDateRangeId>,
    Widget.IdType,
  ] => [
    selectWidgetsByDashboardDateRangeId(state, dashboardDateRangeId),
    widgetId,
  ],
  ([widgets, widgetId]) => widgets?.[widgetId]?.updatedAt || "",
);

export const selectWidgetData = <T extends Widget.IdType>(
  state: RootState,
  dashboardDateRangeId: DashboardDateRange.Data["id"],
  widgetId: T,
): Exclude<Widgets.DataSources[T], undefined>["data"] => {
  const widget = selectWidgetsByDashboardDateRangeId(
    state,
    dashboardDateRangeId,
  );

  return widget?.[widgetId]?.data;
};
