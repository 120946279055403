import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useImmer } from "use-immer";
import { useSelector } from "react-redux";
import isUndefined from "lodash/isUndefined";
import { useTranslation } from "react-i18next";

import styles from "./SuggestedTrackers.module.scss";
import { Button, Preloader } from "src/components";
import { getLocationDefaultLanguageId } from "src/utils";
import { Stars, ExpandAlt, CollapseAlt, Sync } from "src/assets/icons";
import { useQueryParams, useUnmountEffect, useTrackerBlocker } from "src/hooks";
import {
  useGetSuggestedTrackers,
  useGetSuggestedPerspectives,
} from "src/pages/Trackers/CreateTrackers/hooks";
import {
  LocationsDropdown,
  LanguagesDropdown,
  KeywordsDataSourcesDropdown,
} from "src/features";
import {
  DEFAULT_LANGUAGE_ID,
  DEFAULT_LOCATION_ID,
  TRACKER_KEYWORD_CATEGORY,
  DEFAULT_TRACKER_PERSPECTIVE,
  SEARCH_KEYWORDS_DATA_SOURCE_MAP,
} from "src/constants";
import {
  selectLanguageById,
  selectLocationById,
  selectTrackersById,
  selectLocationsByKeywordsDataSource,
  selectLanguagesByKeywordsDataSource,
} from "src/store/selectors";
import { SuggestedPerspectives } from "../../components";

// Inner imports
import { SuggestedTracker } from "./components";

type Props = {
  isExpanded?: boolean;
  category: Tracker.Category;
  languageId: Language.Data["id"];
  locationId: Location.Data["id"];
  perspective: Tracker.Perspective;
  selectedTrackers: Tracker.CreationData[];
  suggestedTrackers: Record<Tracker.Perspective, Tracker.CreationData[]>;
  keywordsDataSource: Search.KeywordsDataSource;
  selectTrackerHandler: (value: Tracker.CreationData) => void;
  suggestedPerspectives: Tracker.Perspective[];
  unselectTrackerHandler: (value: Tracker.CreationData) => void;
  selectLocationIdHandler: (value: Location.Data["id"]) => void;
  selectLanguageIdHandler: (value: Language.Data["id"]) => void;
  selectPerspectiveHandler: (value: Tracker.Perspective) => void;
  updateSuggestedTrackersHandler: (
    perspective: Tracker.Perspective,
    value: Tracker.CreationData[],
  ) => void;
  selectKeywordsDataSourceHandler: (value: Search.KeywordsDataSource) => void;
  updateSuggestedPerspectivesHandler: (value: Tracker.Perspective[]) => void;
};

export const SuggestedTrackers: FC<Props> = ({
  category,
  locationId,
  languageId,
  perspective,
  selectedTrackers,
  keywordsDataSource,
  selectTrackerHandler,
  unselectTrackerHandler,
  selectLocationIdHandler,
  selectLanguageIdHandler,
  selectPerspectiveHandler,
  updateSuggestedTrackersHandler,
  selectKeywordsDataSourceHandler,
  updateSuggestedPerspectivesHandler,
  isExpanded: defaultIsExpanded = false,
  suggestedTrackers: defaultSuggestedTrackers,
  suggestedPerspectives: defaultSuggestedPerspectives,
}) => {
  const { t } = useTranslation();

  const { topics: trackerIdsString = "" } = useQueryParams();

  const usedTrackerIds = useMemo<Tracker.Data["id"][]>(
    () => trackerIdsString.split(","),
    [trackerIdsString],
  );

  const trackers = useSelector((state: Store.RootState) =>
    selectTrackersById(state, usedTrackerIds),
  );

  const { isTrackerBlocked } = useTrackerBlocker();

  const locations = useSelector((state: Store.RootState) =>
    selectLocationsByKeywordsDataSource(state, keywordsDataSource),
  );

  const languages = useSelector((state: Store.RootState) =>
    selectLanguagesByKeywordsDataSource(state, keywordsDataSource),
  );

  const location = useSelector((state: Store.RootState) =>
    selectLocationById(state, locationId),
  );

  const language = useSelector((state: Store.RootState) =>
    selectLanguageById(state, languageId),
  );

  const isKeywordCategory = useMemo<boolean>(
    () => category.category === TRACKER_KEYWORD_CATEGORY,
    [category.category],
  );

  const [suggestedTrackersMap, setSuggestedTrackersMap] = useImmer<
    Record<Tracker.Perspective, Tracker.CreationData[]>
  >(defaultSuggestedTrackers);

  const [excludedTrackersMap, setExcludedTrackersMap] = useImmer<
    Record<Tracker.Perspective, Tracker.CreationData[]>
  >({});

  const updateSuggestedTrackersMap = useCallback(
    (
      selectedPerspective: Optional<Tracker.Perspective> = perspective,
      trackers: Tracker.CreationData[],
    ): void =>
      setSuggestedTrackersMap((draft) => {
        draft[selectedPerspective] = [...trackers];
      }),
    [perspective, setSuggestedTrackersMap],
  );

  const resetSuggestedTrackersMap = useCallback((): void => {
    setSuggestedTrackersMap((draft) => {
      for (const perspective in draft) delete draft[perspective];
    });
  }, [setSuggestedTrackersMap]);

  const updateExcludedTrackersMap = useCallback(
    (
      trackers: Tracker.CreationData[],
      selectedPerspective: Optional<Tracker.Perspective> = perspective,
    ): void =>
      setExcludedTrackersMap((draft) => {
        draft[selectedPerspective] = [...trackers];
      }),
    [perspective, setExcludedTrackersMap],
  );

  const [suggestedPerspectives, setSuggestedPerspectives] = useState<
    Tracker.Perspective[]
  >(defaultSuggestedPerspectives);

  const [isExpanded, setIsExpanded] = useState<boolean>(
    defaultIsExpanded || !isKeywordCategory,
  );

  const [suggestedTrackersLoadingStatus, setSuggestedTrackersLoadingStatus] =
    useState<LoadingStatus>("idle");

  const [
    suggestedPerspectivesLoadingStatus,
    setSuggestedPerspectivesLoadingStatus,
  ] = useState<LoadingStatus>("idle");

  const { getSuggestedTrackers, cancelGetSuggestedTrackers } =
    useGetSuggestedTrackers({
      updateStatusHandler: setSuggestedTrackersLoadingStatus,
      updateExcludedTrackersHandler: updateExcludedTrackersMap,
    });

  const { getSuggestedPerspectives, cancelGetSuggestedPerspectives } =
    useGetSuggestedPerspectives({
      updateStatusHandler: setSuggestedPerspectivesLoadingStatus,
    });

  const isSuggestedTrackersLoading = useMemo<boolean>(
    () => suggestedTrackersLoadingStatus === "loading",
    [suggestedTrackersLoadingStatus],
  );

  const isSuggestedPerspectivesLoading = useMemo<boolean>(
    () => suggestedPerspectivesLoadingStatus === "loading",
    [suggestedPerspectivesLoadingStatus],
  );

  const isLoading = useMemo<boolean>(
    () => isSuggestedPerspectivesLoading || isSuggestedTrackersLoading,
    [isSuggestedPerspectivesLoading, isSuggestedTrackersLoading],
  );

  const keywordsDataSourceName = useMemo<string>(
    () => t(SEARCH_KEYWORDS_DATA_SOURCE_MAP[keywordsDataSource].labelKey),
    [t, keywordsDataSource],
  );

  const locationName = useMemo<string>(
    () => location?.name || "",
    [location?.name],
  );

  const languageName = useMemo<string>(
    () => language?.name || "",
    [language?.name],
  );

  const suggestedTrackers = useMemo<Tracker.CreationData[]>(() => {
    if (!perspective)
      return suggestedTrackersMap[DEFAULT_TRACKER_PERSPECTIVE] || [];

    return suggestedTrackersMap[perspective] || [];
  }, [perspective, suggestedTrackersMap]);

  const excludedTrackers = useMemo<Tracker.CreationData[]>(() => {
    if (!perspective)
      return excludedTrackersMap[DEFAULT_TRACKER_PERSPECTIVE] || [];

    return excludedTrackersMap[perspective] || [];
  }, [excludedTrackersMap, perspective]);

  const filteredTrackers = useMemo<Tracker.CreationData[]>(() => {
    const trackers = new Set<Tracker.CreationData>();

    for (const tracker of suggestedTrackers) {
      const isTrackerSelected = selectedTrackers.some(
        ({ id }) => id === tracker.id,
      );

      if (!isTrackerSelected) trackers.add(tracker);
    }

    return Array.from(trackers);
  }, [selectedTrackers, suggestedTrackers]);

  const hasSuggestedTrackersAutoFetch = useMemo<boolean>(
    () =>
      isExpanded &&
      Boolean(category.subject) &&
      !isTrackerBlocked &&
      !filteredTrackers.length &&
      suggestedTrackersLoadingStatus === "idle",
    [
      isExpanded,
      isTrackerBlocked,
      category.subject,
      filteredTrackers.length,
      suggestedTrackersLoadingStatus,
    ],
  );

  const hasSuggestedPerspectivesAutoFetch = useMemo<boolean>(
    () =>
      isExpanded &&
      Boolean(category.subject) &&
      !isTrackerBlocked &&
      !suggestedPerspectives.length &&
      suggestedPerspectivesLoadingStatus === "idle",
    [
      isExpanded,
      category.subject,
      isTrackerBlocked,
      suggestedPerspectives.length,
      suggestedPerspectivesLoadingStatus,
    ],
  );

  const existingTrackers = useMemo<Tracker.CreationData[]>(() => {
    const formattedTrackers = new Set<Tracker.CreationData>();

    for (const tracker of trackers) {
      formattedTrackers.add({
        id: tracker.id,
        name: tracker.name,
        category: tracker.category,
        locationId: "",
        languageId: "",
        description: tracker.description,
        keywordsDataSources: [],
      });
    }

    return Array.from(formattedTrackers);
  }, [trackers]);

  const updateSuggestedTrackers = useCallback(
    ({
      category: newCategory,
      locationId: newLocationId,
      languageId: newLanguageId,
      perspective: newPerspective,
      keywordsDataSource: newKeywordsDataSource,
    }: {
      category?: Tracker.Category;
      locationId?: Location.Data["id"];
      languageId?: Language.Data["id"];
      perspective?: Tracker.Perspective;
      keywordsDataSource?: Search.KeywordsDataSource;
    }): Promise<Tracker.CreationData[]> => {
      const [
        isLocationUpdated,
        isLanguageUpdated,
        isKeywordsDataSourceUpdated,
        isPerspectiveUpdated,
      ] = [
        isUndefined(newLocationId) ? false : newLocationId !== locationId,
        isUndefined(newLanguageId) ? false : newLanguageId !== languageId,
        isUndefined(newKeywordsDataSource)
          ? false
          : newKeywordsDataSource !== keywordsDataSource,
        isUndefined(newPerspective) ? false : newPerspective !== perspective,
      ];

      const isConfigurationUpdated =
        isLocationUpdated ||
        isLanguageUpdated ||
        isKeywordsDataSourceUpdated ||
        isPerspectiveUpdated;

      const newExcludedTrackers = isConfigurationUpdated
        ? [...selectedTrackers]
        : [
            ...existingTrackers,
            ...excludedTrackers,
            ...selectedTrackers,
            ...suggestedTrackers,
          ];

      return getSuggestedTrackers({
        category: newCategory || category,
        locationId: newLocationId || locationId,
        languageId: newLanguageId || languageId,
        perspective: newPerspective || perspective,
        excludedTrackers: newExcludedTrackers,
        keywordsDataSource: newKeywordsDataSource || keywordsDataSource,
        callback: (value): void => {
          updateSuggestedTrackersMap(newPerspective || perspective, value);

          updateSuggestedTrackersHandler(newPerspective || perspective, value);
        },
      });
    },
    [
      category,
      locationId,
      languageId,
      perspective,
      existingTrackers,
      excludedTrackers,
      selectedTrackers,
      suggestedTrackers,
      keywordsDataSource,
      getSuggestedTrackers,
      updateSuggestedTrackersMap,
      updateSuggestedTrackersHandler,
    ],
  );

  const selectTracker = useCallback(
    (tracker: Tracker.CreationData): void => {
      selectTrackerHandler(tracker);

      const updatedFilteredTrackers = filteredTrackers.filter(
        ({ id }) => id !== tracker.id,
      );

      if (!updatedFilteredTrackers.length) updateSuggestedTrackers({}).catch();
    },
    [filteredTrackers, selectTrackerHandler, updateSuggestedTrackers],
  );

  const SuggestedTrackers = useMemo<JSX.Element>(() => {
    switch (true) {
      case Boolean(filteredTrackers.length):
        return (
          <>
            {isSuggestedTrackersLoading && (
              <div className={styles.loaderWrapper}>
                <Preloader
                  type="bar"
                  text={t(
                    "page.create_tracker.select_trackers.loader.suggest_trackers",
                  )}
                />
              </div>
            )}
            {filteredTrackers.map((tracker) => (
              <SuggestedTracker
                key={tracker.id}
                tracker={tracker}
                selectedTrackers={selectedTrackers}
                selectTrackerHandler={selectTracker}
                unselectTrackerHandler={unselectTrackerHandler}
              />
            ))}
          </>
        );
      case isSuggestedTrackersLoading:
        return (
          <Preloader
            type="bar"
            className={styles.loaderWrapper}
            text={t(
              "page.create_tracker.select_trackers.loader.suggest_trackers",
            )}
          />
        );
      default:
        return (
          <div className={styles.placeholder}>
            {t(
              "page.create_tracker.select_trackers.placeholder.suggest_trackers",
            )}
          </div>
        );
    }
  }, [
    t,
    selectTracker,
    filteredTrackers,
    selectedTrackers,
    unselectTrackerHandler,
    isSuggestedTrackersLoading,
  ]);

  const updateSuggestedPerspectives = useCallback(
    ({
      category: newCategory,
      locationId: newLocationId,
      languageId: newLanguageId,
    }: {
      category?: Tracker.Category;
      locationId?: Location.Data["id"];
      languageId?: Language.Data["id"];
    }): Promise<Tracker.Perspective[]> =>
      getSuggestedPerspectives({
        category: newCategory || category,
        locationId: newLocationId || locationId,
        languageId: newLanguageId || languageId,
        callback: (value): void => {
          setSuggestedPerspectives(value);

          updateSuggestedPerspectivesHandler(value);
        },
      }),
    [
      category,
      locationId,
      languageId,
      getSuggestedPerspectives,
      updateSuggestedPerspectivesHandler,
    ],
  );

  useUnmountEffect(() => {
    cancelGetSuggestedTrackers();
    cancelGetSuggestedPerspectives();
  });

  useEffect(() => {
    if (hasSuggestedTrackersAutoFetch) updateSuggestedTrackers({}).catch();
  }, [hasSuggestedTrackersAutoFetch, updateSuggestedTrackers]);

  useEffect(() => {
    if (hasSuggestedPerspectivesAutoFetch)
      updateSuggestedPerspectives({}).catch();
  }, [hasSuggestedPerspectivesAutoFetch, updateSuggestedPerspectives]);

  const onLocationIdChange = (value: Location.Data["id"]): void => {
    if (value === locationId) return;

    selectLocationIdHandler(value);

    const languageId = getLocationDefaultLanguageId(
      value,
      locations,
      languages,
    );

    resetSuggestedTrackersMap();

    updateSuggestedTrackers({ locationId: value, languageId }).catch();
  };

  const onLanguageIdChange = (value: Language.Data["id"]): void => {
    if (value === languageId) return;

    selectLanguageIdHandler(value);

    resetSuggestedTrackersMap();

    updateSuggestedTrackers({ languageId: value }).catch();
  };

  const onKeywordsDataSourceChange = (
    value: Search.KeywordsDataSource,
  ): void => {
    if (value === keywordsDataSource) return;

    selectKeywordsDataSourceHandler(value);

    const isCurrentLocationAvailable =
      location?.keywordsDataSources.includes(value);

    resetSuggestedTrackersMap();

    if (isCurrentLocationAvailable) {
      updateSuggestedTrackers({
        languageId,
        locationId,
        keywordsDataSource: value,
      }).catch();
    } else {
      updateSuggestedTrackers({
        keywordsDataSource: value,
        languageId: DEFAULT_LANGUAGE_ID,
        locationId: DEFAULT_LOCATION_ID,
      }).catch();
    }
  };

  const onPerspectiveSelect = (value: Tracker.Perspective): void => {
    if (value === perspective) return;

    selectPerspectiveHandler(value);

    const perspectiveSuggestedTrackers = suggestedTrackersMap[value];

    if (!perspectiveSuggestedTrackers)
      updateSuggestedTrackers({ perspective: value }).catch();
  };

  const onPerspectiveUnselect = (value: Tracker.Perspective): void => {
    if (value === DEFAULT_TRACKER_PERSPECTIVE) return;

    const updatedValue = DEFAULT_TRACKER_PERSPECTIVE;

    selectPerspectiveHandler(updatedValue);

    const perspectiveSuggestedTrackers = suggestedTrackersMap[updatedValue];

    if (!perspectiveSuggestedTrackers)
      updateSuggestedTrackers({ perspective: updatedValue }).catch();
  };

  const onExpandClick = (): void => setIsExpanded((state) => !state);

  return (
    <div className={styles.wrapper}>
      <div className={styles.section}>
        <div className={styles.title}>
          <Stars />
          <span className={styles.heading}>
            {t("page.create_tracker.select_trackers.label.suggested_trackers")}
          </span>
        </div>
        {isExpanded && (
          <div className={styles.settings}>
            <div title={keywordsDataSourceName}>
              <KeywordsDataSourcesDropdown
                dataSource={keywordsDataSource}
                isDisabled={isLoading}
                setDataSource={onKeywordsDataSourceChange}
              />
            </div>
            <div title={locationName}>
              <LocationsDropdown
                isDisabled={isLoading}
                locationId={locationId}
                setLocationId={onLocationIdChange}
                keywordsDataSource={keywordsDataSource}
              />
            </div>
            <div title={languageName}>
              <LanguagesDropdown
                isDisabled={isLoading}
                languageId={languageId}
                setLanguageId={onLanguageIdChange}
                keywordsDataSource={keywordsDataSource}
              />
            </div>
          </div>
        )}
        <Button
          onClick={onExpandClick}
          buttonSize="medium"
          buttonStyle="transparent"
          className={styles.expandButton}
        >
          {isExpanded ? <CollapseAlt /> : <ExpandAlt />}
        </Button>
      </div>
      {isExpanded && (
        <div className={styles.section}>
          <SuggestedPerspectives
            selectedPerspective={perspective}
            suggestedPerspectives={suggestedPerspectives}
            selectPerspectiveHandler={onPerspectiveSelect}
            unselectPerspectiveHandler={onPerspectiveUnselect}
            isSuggestedTrackersLoading={isSuggestedTrackersLoading}
            isSuggestedPerspectivesLoading={isSuggestedPerspectivesLoading}
          />
          <div className={styles.trackers}>{SuggestedTrackers}</div>
          <div className={styles.update}>
            <Button
              onClick={() => updateSuggestedTrackers({})}
              disabled={isLoading}
              buttonSize="small"
              buttonStyle="transparent"
            >
              <Sync
                className={isSuggestedTrackersLoading ? styles.loader : ""}
              />
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
