import isString from "lodash/isString";

import { sortValues } from "src/utils";

// Inner imports
import { CustomTooltipProps, Payload, PreparedPayload } from "./types";
import { CATEGORY_TREND_LINE } from "../../constants";

export const getCleanDataKey = (key: string | number): string => {
  if (!isString(key)) return String(key);

  return key.split("_")[0] || "";
};

export const formatPayload = (
  payload: CustomTooltipProps["payload"],
): PreparedPayload => {
  if (!payload) return [];

  const formattedPayload = new Set<PreparedPayload[number]>();

  for (const [index, data] of payload.entries()) {
    const { dataKey = "", payload: innerPayload, value, color } = data;

    const isValidPayloadData = checkInnerPayloadData(data, index, payload);

    if (!isValidPayloadData) continue;

    const additionalValue = innerPayload?.additionalValues?.[dataKey];

    const formattedData = { dataKey, value, additionalValue, color };

    formattedPayload.add(formattedData);
  }

  return [...formattedPayload].sort((a, b) =>
    sortValues(a.value, b.value, "DESC"),
  );
};

function checkInnerPayloadData(
  data: Payload[number],
  index: number,
  payload: Payload,
): boolean {
  const dataKey = String(data.dataKey);

  if (!dataKey) return false;

  const [isCategoryTrend, isDuplicatedData] = [
    dataKey.includes(CATEGORY_TREND_LINE.value),
    payload
      .filter((_, k) => k !== index)
      .some((row) => dataKey.includes(String(row.dataKey || ""))),
  ];

  return !isCategoryTrend && !isDuplicatedData;
}
