export const LOCALIZATION_DEFAULT_LANGUAGE_ID: Localization.Id = "en";

export const LOCALIZATION_DEFAULT_NAMESPACE: string = "translation";

export const LOCALIZATION_COMPONENT: Record<
  "bold" | "break" | "div" | "span",
  JSX.Element
> = {
  bold: <span style={{ fontWeight: 500 }} />,
  break: <br />,
  div: <div />,
  span: <span />,
};
