import { FC, useMemo, useState } from "react";
import cx from "classnames";
import { useSelector } from "react-redux";
import { showNewMessage } from "@intercom/messenger-js-sdk";

import styles from "./UpdatePlanButton.module.scss";
import { Loader } from "src/assets/icons";
import { showToastNotification } from "src/utils";
import { Button, Translation } from "src/components";
import { SubscriptionPlansSidebar } from "src/features";
import { ButtonProps } from "src/components/buttons/Button/types";
import { createStripePortalToken } from "src/store/subscriptionPlans/subscriptionPlansApi";
import {
  selectUser,
  selectCompany,
  selectCompanyOwnerEmail,
  selectCompanySubscriptionPlan,
} from "src/store/selectors";

type Props = ButtonProps & {
  preselectedComponent?: "sidebar" | "redirect";
};

export const UpdatePlanButton: FC<Props> = ({
  className,
  children,
  preselectedComponent,
  ...props
}) => {
  const { companyRole } = useSelector(selectUser);

  const company = useSelector(selectCompany);

  const companyOwnerEmail = useSelector(selectCompanyOwnerEmail);

  const subscriptionPlan = useSelector(selectCompanySubscriptionPlan);

  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(false);

  const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>("idle");

  const isInvoiceCustomer = useMemo<boolean>(
    () => Boolean(company.fortnoxCustomerId),
    [company.fortnoxCustomerId],
  );

  const isLoading = useMemo<boolean>(
    () => loadingStatus === "loading",
    [loadingStatus],
  );

  const onUpgradeSubscriptionPlan = (): void => {
    if (isInvoiceCustomer) return showNewMessage("");

    if (companyRole !== "owner" && companyRole !== "admin") {
      const localizationKey = companyOwnerEmail
        ? "component.header.status.info.contact_company_owner"
        : "component.header.status.info.contact_support";

      return showToastNotification({
        id: "update-plan",
        type: "info",
        text: (
          <Translation
            i18nKey={localizationKey}
            values={{ email: companyOwnerEmail }}
          />
        ),
      });
    }

    if (preselectedComponent === "sidebar") return setIsSidebarOpen(true);

    if (
      !subscriptionPlan ||
      subscriptionPlan.category === "free" ||
      !company.stripeCustomerId ||
      !company.stripeSubscriptionId
    )
      return setIsSidebarOpen(true);

    setLoadingStatus("loading");

    createStripePortalToken(
      company.id,
      window.location.href,
      "subscription_update",
    )
      .then((token) => {
        if (!token) return setLoadingStatus("succeeded");

        window.open(token, "_self")?.focus();
      })
      .catch((error) => {
        console.error(error);

        setLoadingStatus("failed");
      });
  };

  return (
    <div className={styles.wrapper}>
      <Button
        {...props}
        buttonType="success"
        disabled={isLoading}
        onClick={onUpgradeSubscriptionPlan}
        className={cx(styles.button, className)}
      >
        {children}
        {isLoading && <Loader className={styles.loader} />}
      </Button>
      <SubscriptionPlansSidebar
        isOpened={isSidebarOpen}
        setIsOpened={setIsSidebarOpen}
      />
    </div>
  );
};
