import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";

import styles from "./SelectSearchesSection.module.scss";
import { useAppDispatch } from "src/store";
import { Button, Form } from "src/components";
import { generateDocId } from "src/store/utils";
import { removeSearches } from "src/store/actions";
import { isSearchCreatedTypeGuard } from "src/utils";
import {
  SEARCH_DEFAULT_STATUS,
  SEARCH_DEFAULT_DESCRIPTION,
} from "src/constants";
import {
  useLanguageId,
  useLocationId,
  useResetScrollPosition,
} from "src/hooks";

// Inner imports
import { useSelectedTrackerSearches } from "./hooks";
import {
  SelectedSearches,
  SelectedTracker,
  SuggestedSearches,
} from "./components";

type Props = {
  tracker: Tracker.CreationData;
  locationId: Location.Data["id"];
  languageId: Language.Data["id"];
  submitHandler: (
    tracker: Tracker.CreationData,
    searches: Search.CreationData[],
  ) => void;
  selectedTrackers: Tracker.CreationData[];
  selectedSearches: Search.CreationData[];
  keywordsDataSource: Search.KeywordsDataSource;
};

export const SelectSearchesSection: FC<Props> = ({
  tracker,
  submitHandler,
  keywordsDataSource,
  selectedSearches: defaultSelectedSearches,
}) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const {
    selectSearch,
    updateSearch,
    unselectSearch,
    searchKeywords,
    selectedSearches,
    isSearchesChanged,
    unselectedSearches,
    updateSearchKeywords,
  } = useSelectedTrackerSearches({
    trackerId: tracker.id,
    selectedSearches: defaultSelectedSearches,
  });

  const [locationId, setLocationId] = useLocationId({
    keywordsDataSource,
    locationId: tracker.locationId,
  });

  const [languageId, setLanguageId] = useLanguageId({
    locationId,
    keywordsDataSource,
    languageId: tracker.languageId,
  });

  const isSubmitShown = useMemo<boolean>(
    () => Boolean(selectedSearches.length) && isSearchesChanged,
    [isSearchesChanged, selectedSearches.length],
  );

  const isEverySearchCreated = useMemo<boolean>(
    () => selectedSearches.every(isSearchCreatedTypeGuard),
    [selectedSearches],
  );

  const isSubmitAvailable = useMemo<boolean>(
    () => isSubmitShown && isEverySearchCreated,
    [isEverySearchCreated, isSubmitShown],
  );

  useResetScrollPosition();

  const selectCustomSearch = (value: Search.Data["subject"]): void => {
    if (!languageId || !locationId) return;

    selectSearch({
      languageId,
      locationId,
      subject: value,
      keywordsDataSource,
      id: generateDocId(),
      status: SEARCH_DEFAULT_STATUS,
      description: SEARCH_DEFAULT_DESCRIPTION,
    });
  };

  const onSubmit = (): void => {
    if (unselectedSearches.length) {
      const unselectedSearchIds = unselectedSearches.map(({ id }) => id);

      dispatch(removeSearches(unselectedSearchIds)).unwrap().catch();
    }

    if (!selectedSearches.length) return;

    submitHandler(tracker, selectedSearches);
  };

  if (!tracker || !locationId || !languageId) return null;

  return (
    <Form onSubmit={onSubmit} className={styles.wrapper}>
      <div className={styles.section}>
        <SelectedTracker tracker={tracker} />
      </div>
      <SuggestedSearches
        tracker={tracker}
        locationId={locationId}
        languageId={languageId}
        selectedSearches={selectedSearches}
        keywordsDataSource={keywordsDataSource}
        selectSearchHandler={selectSearch}
        unselectSearchHandler={unselectSearch}
        selectLanguageIdHandler={setLanguageId}
        selectLocationIdHandler={setLocationId}
      />
      <SelectedSearches
        keywords={searchKeywords}
        selectedSearches={selectedSearches}
        selectSearchHandler={selectSearch}
        updateSearchHandler={updateSearch}
        unselectSearchHandler={unselectSearch}
        selectCustomSearchHandler={selectCustomSearch}
        updateSearchKeywordsHandler={updateSearchKeywords}
      />
      {isSubmitShown && (
        <div className={styles.submit}>
          <Button
            onClick={onSubmit}
            autoFocus
            buttonSize="medium"
            className={styles.submitButton}
            disabled={!isSubmitAvailable}
          >
            <span>
              {t("page.create_tracker.select_searches.form.button.submit")}
            </span>
          </Button>
        </div>
      )}
    </Form>
  );
};
