import { FC, FormEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { AxiosError } from "axios";

import styles from "./PasswordsResetForm.module.scss";
import { withError } from "src/hocs";
import { Loader } from "src/assets/icons";
import { Input, Button } from "src/components";
import { useTemporaryErrors } from "src/hooks";
import { selectWhiteLabel } from "src/store/selectors";
import { resetUserPassword } from "src/store/user/userApi";
import {
  validateEmail,
  isErrorTypeGuard,
  showToastNotification,
} from "src/utils";

const InputWithError = withError(Input);

export const PasswordsResetForm: FC = () => {
  const { t } = useTranslation();

  const whiteLabel = useSelector(selectWhiteLabel);

  const { errors, setErrors } = useTemporaryErrors(3000);

  const [email, setEmail] = useState<string>("");

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [isLinkSent, setIsLinkSent] = useState<boolean>(false);

  const handleSubmitForm = async (
    e: FormEvent<HTMLFormElement>,
  ): Promise<void> => {
    e.preventDefault();

    const errors = validate();

    if (Object.keys(errors).length) return setErrors(errors);

    try {
      setIsLoading(true);

      await resetUserPassword(email, whiteLabel);

      setIsLinkSent(true);
    } catch (error) {
      let message = "";

      if (error instanceof AxiosError) message = error.response?.data?.error;

      if (isErrorTypeGuard(error)) message = error.message;

      showToastNotification({
        type: "error",
        text: message || t("common.error.server_error"),
      });
    } finally {
      setIsLoading(false);
    }
  };

  function validate() {
    const validationErrors: typeof errors = {};

    const formattedEmail = email.trim();

    if (!formattedEmail)
      validationErrors.email = t(
        "authentication.password_reset.form.validation.email_required",
      );

    if (formattedEmail && !validateEmail(formattedEmail)) {
      validationErrors.email = t(
        "authentication.password_reset.form.validation.invalid_email",
      );
    }

    return validationErrors;
  }

  return (
    <form
      name="resetPassword"
      autoComplete="off"
      onSubmit={handleSubmitForm}
      className={styles.passwordForm}
    >
      {isLinkSent ? (
        <>
          <span className={styles.subTitle}>
            {t("authentication.password_reset.label.email_sent")}
          </span>
          <Button className={styles.resendButton} disabled={isLoading}>
            {isLoading ? (
              <Loader className={styles.loader} />
            ) : (
              t("authentication.password_reset.form.button.resend_email")
            )}
          </Button>
        </>
      ) : (
        <>
          <InputWithError
            className={styles.input}
            type="email"
            value={email}
            changeHandler={setEmail}
            placeholder={t(
              "authentication.password_reset.form.placeholder.email",
            )}
            error={errors.email}
            autoFocus
            tabIndex={1}
            disabled={isLoading}
          />
          <Button
            className={styles.submitButton}
            type="submit"
            tabIndex={2}
            disabled={isLoading}
          >
            {isLoading ? (
              <Loader className={styles.loader} />
            ) : (
              t("authentication.password_reset.form.button.submit")
            )}
          </Button>
        </>
      )}
    </form>
  );
};
