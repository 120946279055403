import { useMemo } from "react";
import cx from "classnames";

import styles from "./TableCardBody.module.scss";
import { genericMemo } from "src/utils";

// Inner imports
import type {
  TableColumn,
  TableItemGenericProps,
  TableProps,
} from "../../../../types";
import { TableCardCell } from "../../..";
import { TableCard } from "../";

type Props<T> = {
  item: T;
  cardHeaderProp?: keyof T;
  isSubTable?: boolean;
} & Pick<TableProps<T>, "tableColumns">;

export const TableCardBody = genericMemo(
  <T extends TableItemGenericProps>({
    item,
    tableColumns,
    cardHeaderProp,
    isSubTable,
  }: Props<T>) => {
    const hasSubTable = useMemo<boolean>(
      () => Boolean(item.subTableProps?.items.length),
      [item.subTableProps?.items],
    );

    const filteredColumns = useMemo<TableColumn<T>[]>(
      () =>
        tableColumns.filter(
          ({ isAction, labelKey, key }) =>
            !isAction && labelKey && key !== cardHeaderProp,
        ),
      [cardHeaderProp, tableColumns],
    );

    return (
      <div
        className={cx(
          styles.tableCardBody,
          isSubTable ? styles.subTableCardBody : "",
        )}
      >
        <div className={styles.tableCardContent}>
          {filteredColumns.map((tableColumn) => (
            <TableCardCell
              key={String(tableColumn.key)}
              item={item}
              tableColumn={tableColumn}
            />
          ))}
        </div>
        {hasSubTable &&
          item.subTableProps?.items.map((subItem) => (
            <TableCard
              key={subItem.id}
              item={subItem}
              tableColumns={item.subTableProps?.tableColumns || []}
              getActionMenuOptions={item.subTableProps?.getActionMenuOptions}
              isSubTable
            />
          ))}
      </div>
    );
  },
);
