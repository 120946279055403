import type { ArticlesSortKey } from "../../types";

export const ARTICLES_SORT_OPTIONS: {
  value: ArticlesSortKey;
  label: string;
}[] = [
  { value: "date", label: "chart.articles.sort.date" },
  { value: "title", label: "chart.articles.sort.title" },
  { value: "score", label: "chart.articles.sort.score" },
];

export const ARTICLES_SORT: Record<ArticlesSortKey, SortDirection> = {
  date: "DESC",
  title: "ASC",
  score: "DESC",
};
