import { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { useAppDispatch } from "src/store";
import { ArticlesSortKey } from "src/components";
import { showToastNotification } from "src/utils";
import { useDebounce, usePagination, useSortedItems } from "src/hooks";
import { articlesWidgetSchema } from "src/store/widgets/widgetsSchema";
import { updateArticlesWidgetData } from "src/store/widgets/widgetsSlice";
import { selectTrackersById, selectWidgetData } from "src/store/selectors";
import { getArticles as fetchArticles } from "src/store/widgets/widgetsApi";
import {
  WIDGET_ID,
  WIDGET_ARTICLES_ITEMS_PER_PAGE,
  WIDGET_ARTICLES_PAGINATION_DEFAULT_PROPS,
} from "../../constants";

// Inner imports
import { UseArticlesPaginationProps } from "./types";

const {
  pageNumber: defaultPageNumber,
  searchQuery: defaultSearchQuery,
  sortBy: defaultSortBy,
  sortDirection: defaultSortDirection,
} = WIDGET_ARTICLES_PAGINATION_DEFAULT_PROPS;

export const useArticlesPagination = ({
  trackerIds,
  dashboardDateRangeId,
}: UseArticlesPaginationProps) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const widgetData = useSelector((state: Store.RootState) =>
    selectWidgetData(state, dashboardDateRangeId, WIDGET_ID),
  );

  const trackers = useSelector((state: Store.RootState) =>
    selectTrackersById(state, trackerIds),
  );

  const [searchQuery, setSearchQuery] = useState<string>(defaultSearchQuery);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { articles } = useMemo<Widgets.Articles.Data["data"]>(
    () => widgetData?.data || { articles: [], filters: [] },
    [widgetData],
  );

  const totalArticlesCount = useMemo<number>(
    () => articles.length,
    [articles.length],
  );

  const hasArticles = useMemo<boolean>(
    () => articles.length > 0,
    [articles.length],
  );

  const { sortedItems, sort, setSort } = useSortedItems({
    items: articles,
    defaultSort: { [defaultSortBy]: defaultSortDirection },
  });

  const { slicedItems, pagesCount, pageNumber, setPageNumber } = usePagination({
    items: sortedItems,
    itemsPerPage: WIDGET_ARTICLES_ITEMS_PER_PAGE,
    initialPageNumber: defaultPageNumber,
  });

  const getArticles = useCallback(
    async (
      paginationProps: Widgets.Articles.PaginationProps,
    ): Promise<void> => {
      setIsLoading(true);

      try {
        const { articles, filters } = await fetchArticles({
          ...paginationProps,
          trackers,
        }).then(
          (response) =>
            articlesWidgetSchema.validateSync({ ...response, updatedAt: "" })
              .data,
        );

        await dispatch(
          updateArticlesWidgetData({
            dashboardDateRangeId,
            changes: {
              data: { articles, filters },
              endDate: widgetData?.endDate || "",
              startDate: widgetData?.startDate || "",
            },
          }),
        );
      } catch (error) {
        showToastNotification({
          type: "error",
          text: t("common.error.server_error"),
        });
      } finally {
        setIsLoading(false);
      }
    },
    [
      t,
      trackers,
      dispatch,
      widgetData?.endDate,
      dashboardDateRangeId,
      widgetData?.startDate,
    ],
  );

  const debouncedGetArticles = useDebounce(getArticles, 750);

  const onPageChange = (value: number): void => setPageNumber(value);

  const onSortChange = (
    key: ArticlesSortKey,
    direction: SortDirection,
  ): void => {
    const currentSortKey = Object.keys(sort || {})[0];

    if (currentSortKey === key) return;

    setPageNumber(defaultPageNumber);
    setSort(key, direction);
  };

  const onSearchQueryChange = async (value: string): Promise<void> => {
    setSearchQuery(value);
    setPageNumber(defaultPageNumber);

    const _getTrackerArticles = value ? debouncedGetArticles : getArticles;

    await _getTrackerArticles({ searchQuery: value });
  };

  return {
    pageNumber,
    searchQuery,
    pagesCount,
    sort,
    hasArticles,
    slicedArticles: slicedItems,
    totalArticlesCount,
    isLoading,
    onPageChange,
    onSortChange,
    onSearchQueryChange,
  };
};
