import * as yup from "yup";

import { COMPANY_ROLES } from "src/constants";

export const userSchema = yup
  .object({
    firstName: yup.string().default(""),
    lastName: yup.string().default(""),

    companyId: yup.string().required(),
    companyRole: yup
      .string<Company.Role>()
      .oneOf([...COMPANY_ROLES])
      .required(),
    isWhiteLabelAdmin: yup.boolean().default(false),

    role: yup.string().default(""),
    phone: yup.string().default(""),
    department: yup.string().default(""),

    verifyEmailAttemptsNumber: yup.number().default(0),

    lastViewedTrackerIds: yup.array(yup.string().required()).default([]),
    lastViewedDashboardIds: yup.array(yup.string().required()).default([]),

    lastActiveAt: yup.string().default(""),
    createdAt: yup.string().default(""),
    updatedAt: yup.string().default(""),
  })
  .noUnknown()
  .required();

export const userInfoSchema = yup
  .object({
    id: yup.string().required(),
    emailVerified: yup.boolean().default(false),
  })
  .noUnknown()
  .required();

export const usersEmailSchema = yup.array(yup.string().default("")).default([]);

export const isWhiteLabelUserSchema = yup.boolean().default(false);

export const verifyEmailSchema = yup.string().required();

export const createUserSchema = yup.string().required();

export type UserInfoSchemaType = yup.InferType<typeof userInfoSchema>;
