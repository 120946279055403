import { CSSProperties } from "react";
import kebabCase from "lodash/kebabCase";
import { renderToStaticMarkup } from "react-dom/server";

import { isCSSPropertyTypeGuard } from "./typeGuards";

export const getElementDimensions = (
  element: JSX.Element,
  style?: CSSProperties,
): Dimensions => {
  const dimensions = { height: 0, width: 0 };

  const container = document.createElement("div");

  for (const property in style) {
    if (!isCSSPropertyTypeGuard(property, style)) continue;

    const [formattedProperty, value] = [
      kebabCase(property),
      style[property]?.toString(),
    ];

    if (value) container.style.setProperty(formattedProperty, value);
  }

  container.innerHTML = renderToStaticMarkup(element);

  document.body.appendChild(container);

  dimensions.height = container.clientHeight;
  dimensions.width = container.clientWidth;

  container.parentNode?.removeChild(container);

  return dimensions;
};
