import { useCallback, useEffect, useMemo, useState } from "react";
import { useImmer } from "use-immer";

import { isSearchCreatedTypeGuard } from "src/utils";
import { subscribeOnSearchesStatus } from "src/store/searches/searchesApi";

export const useSearchesStatusObserver = (searches?: Search.CreationData[]) => {
  const [isObserverSet, setIsObserverSet] = useState<boolean>(false);

  const [searchesStatus, setSearchesStatus] = useImmer<
    Record<Search.Data["id"], Search.Status | null>
  >({});

  const callback = useCallback(
    (data: Search.Data): void => {
      setSearchesStatus((draft) => {
        draft[data.id] = data.status;
      });

      setIsObserverSet(true);
    },
    [setSearchesStatus],
  );

  const searchIds = useMemo<Search.Data["id"][]>(() => {
    if (!searches) return [];

    const result: Search.Data["id"][] = [];

    for (const search of searches) {
      if (isSearchCreatedTypeGuard(search)) result.push(search.id);
    }

    return result;
  }, [searches]);

  useEffect(() => {
    if (!searchIds.length) return;

    let observer = () => {};

    try {
      observer = subscribeOnSearchesStatus(searchIds, callback);
    } catch (err) {
      console.error(err);
    }

    return () => {
      observer();
    };
  }, [callback, searchIds, searches?.length]);

  return { isObserverSet, searchesStatus };
};
