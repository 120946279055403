import { TFunction } from "i18next";
import isEqual from "lodash/isEqual";
import { Dictionary } from "@reduxjs/toolkit";

import { AppDispatch } from "src/store";
import { createSearch } from "src/store/actions";
import { isSearchCreatedTypeGuard } from "src/utils";
import { SEARCH_DEFAULT_KEYWORDS_DATA_SOURCE } from "src/constants";
import { formatNewTrackerName, getDefaultSuggestedSearch } from "../../utils";

export const formatSelectedTrackers = async ({
  t,
  trackers,
  dispatch,
  locations,
  selectedTrackers,
  selectedSearches,
}: {
  t: TFunction;
  dispatch: AppDispatch;
  trackers: Tracker.Data[];
  selectedTrackers: Tracker.CreationData[];
  locations: Dictionary<Location.Data>;
  selectedSearches: Record<string, Search.CreationData[]>;
}): Promise<Array<Tracker.CreationData & Pick<Tracker.Data, "searchIds">>> => {
  const formattedTrackers = new Set<
    Tracker.CreationData & Pick<Tracker.Data, "searchIds">
  >();

  for (const tracker of selectedTrackers) {
    const trackerSearches = selectedSearches[tracker.id] || [];

    const searchIds = new Set<Search.Data["id"]>();

    for (const search of trackerSearches) {
      if (!isSearchCreatedTypeGuard(search)) continue;

      searchIds.add(search.id);
    }

    if (!searchIds.size) {
      const defaultSuggestedSearch = getDefaultSuggestedSearch({
        subject: tracker.name,
        locationId: tracker.locationId,
        languageId: tracker.languageId,
        description: tracker.description,
        keywordsDataSource:
          tracker.keywordsDataSources[0] || SEARCH_DEFAULT_KEYWORDS_DATA_SOURCE,
      });

      const { id: searchId } = await dispatch(
        createSearch(defaultSuggestedSearch),
      ).unwrap();

      searchIds.add(searchId);
    }

    const trackerName = getTrackerNameWithLocation({
      t,
      tracker,
      trackers,
      locations,
      trackerSearches,
      selectedTrackers: Array.from(formattedTrackers),
    });

    formattedTrackers.add({
      ...tracker,
      name: trackerName,
      searchIds: [...searchIds],
    });
  }

  return [...formattedTrackers].reverse();
};

function getTrackerNameWithLocation({
  t,
  tracker,
  trackers,
  locations,
  trackerSearches,
  selectedTrackers,
}: {
  t: TFunction;
  trackers: Tracker.Data[];
  tracker: Tracker.CreationData;
  locations: Dictionary<Location.Data>;
  trackerSearches: Search.CreationData[];
  selectedTrackers: Tracker.CreationData[];
}): string {
  const locationName = getTrackerLocationName(t, trackerSearches, locations);

  const initialTrackerName = formatNewTrackerName(tracker.name, locationName);

  const includedTrackers = [...trackers, ...selectedTrackers];

  const sameTrackerNames: Tracker.Data["name"][] = [];

  for (const { name, keywordsDataSources } of includedTrackers) {
    const [sortedKeywordsDataSources, sortedTrackerKeywordsDataSources] = [
      [...keywordsDataSources].sort(),
      [...tracker.keywordsDataSources].sort(),
    ];

    const [isSameName, isSameKeywordsDataSources] = [
      compareTrackerNames(name, initialTrackerName),
      isEqual(sortedKeywordsDataSources, sortedTrackerKeywordsDataSources),
    ];

    if (!isSameName || !isSameKeywordsDataSources) continue;

    sameTrackerNames.push(name);
  }

  if (!sameTrackerNames.length) return initialTrackerName;

  const maxNumber = findMaxNumberInParentheses(sameTrackerNames);

  return `${initialTrackerName} (${maxNumber + 1})`;
}

function getTrackerLocationName(
  t: TFunction,
  searches: Search.CreationData[],
  locations: Dictionary<Location.Data>,
): string {
  const locationIds = new Set<Location.Data["id"]>();

  for (const { locationId } of searches) {
    const location = locations[locationId];

    if (location) locationIds.add(locationId);
  }

  if (locationIds.size > 1) return t("tracker.label.location_mixed");

  const locationId = [...locationIds][0];

  if (!locationId) return "";

  const location = locations[locationId];

  if (!location) return "";

  return location.city || location.region || location.country || location.name;
}

function compareTrackerNames(name1: string, name2: string): boolean {
  return cleanTrackerName(name1) === cleanTrackerName(name2);
}

function cleanTrackerName(name: string): string {
  return name.replace(/\s*\(([1-9]\d*)\)$/, "").trim();
}

function findMaxNumberInParentheses(names: Tracker.Data["name"][]): number {
  const numbers: number[] = [];

  for (const name of names) {
    const match = name.match(/\(([1-9]\d*)\)$/);

    if (!match || !match[1]) continue;

    const formattedMatch = parseInt(match[1]);

    if (formattedMatch) numbers.push(formattedMatch);
  }

  return numbers.length ? Math.max(...numbers) : 0;
}
