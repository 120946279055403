import { FC, useState, useRef, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import cx from "classnames";

import styles from "./MultiSelect.module.scss";
import * as icons from "src/assets/icons";
import { ChevronDown } from "src/assets/icons";
import { Checkbox, Input, InputWithIcon, Tooltip } from "src/components";
import {
  useOutsideClickHandler,
  useElementMaxHeightAdaptiveToWindow,
} from "src/hooks";

// Inner imports
import {
  MULTI_SELECT_FILTER_MIN_ITEMS_COUNT,
  MULTI_SELECT_DROPDOWN_DEFAULT_MAX_HEIGHT,
} from "./constants";
import type {
  MultiSelectProps,
  OpeningDirection,
  MultiSelectOption,
} from "./types";

export const MultiSelect: FC<MultiSelectProps> = ({
  multiSelectClassName = "",
  dropdownClassName = "",
  optionsClassName = "",
  inputClassName = "",
  style,
  options = [],
  selectedOptions = [],
  hasFilter = false,
  customInputText,
  selectAllButtonText = "Select all",
  placeholder = "Select",
  tabIndex = -1,
  openingDirection: _openingDirection,
  isDisabled = false,
  onSelectAllHandler,
  onCheckHandler,
  icon,
  hasSelectAll,
}) => {
  const { t } = useTranslation();

  const selectRef = useRef<HTMLDivElement>(null);

  const [openingDirection, setOpeningDirection] = useState<OpeningDirection>(
    _openingDirection || "bottom-end",
  );

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const [filter, setFilter] = useState<string>("");

  const dropdownMaxHeight = useElementMaxHeightAdaptiveToWindow(
    selectRef.current,
    openingDirection,
    MULTI_SELECT_DROPDOWN_DEFAULT_MAX_HEIGHT,
  );

  const filteredOptions: MultiSelectOption[] = options.filter((option) =>
    option.label.toLowerCase().includes(filter.trim().toLowerCase()),
  );

  const isAllOptionSelected = useMemo(() => {
    const filteredOptions = options.filter(
      ({ isDisabled }) => !Boolean(isDisabled),
    );

    // Selected options;
    const selectedValuesCollection: Set<string> = new Set();
    for (const { value } of selectedOptions) {
      selectedValuesCollection.add(value);
    }

    return filteredOptions.every(({ value }) =>
      selectedValuesCollection.has(value),
    );
  }, [options, selectedOptions]);

  const isFilterPresent = useMemo<boolean>(
    () => hasFilter && options.length > MULTI_SELECT_FILTER_MIN_ITEMS_COUNT,
    [hasFilter, options.length],
  );

  useOutsideClickHandler(selectRef, () => setIsOpen(false));

  useEffect(() => {
    if (!isOpen) setFilter("");
  }, [isOpen]);

  useEffect(() => {
    if (_openingDirection) return;

    const _setOpeningDirection = () => {
      const clientHeight = document.documentElement.clientHeight;
      const selectTop = selectRef.current?.getBoundingClientRect().top!;
      setOpeningDirection(selectTop > clientHeight / 2 ? "top" : "bottom");
    };
    _setOpeningDirection();

    window.addEventListener("resize", _setOpeningDirection);

    return () => {
      window.removeEventListener("resize", _setOpeningDirection);
    };
  }, [_openingDirection]);

  const inputValue = useMemo<string>(
    () =>
      customInputText ||
      (isAllOptionSelected
        ? t("component.multiselect.label.selected_all")
        : selectedOptions.map((el) => el.label).join(", ")),
    [isAllOptionSelected, selectedOptions, customInputText, t],
  );

  const onDropdownShowToggle = (): void => {
    if (isDisabled) return;

    setIsOpen((prevIsOpen) => !prevIsOpen);
  };

  const renderOption = (option: MultiSelectOption, i: number): JSX.Element => {
    const {
      label,
      value,
      isDisabled = false,
      tooltip = "",
      icon,
      renderLabel,
      renderCheckbox,
    } = option;

    const isSelected = selectedOptions.some(
      (selectedOption) => selectedOption.value === value,
    );

    const {
      name: iconName,
      className: iconClassName = "",
      tooltip: iconTooltip = "",
    } = icon || {};

    const Icon = iconName && icons[iconName];

    return (
      <li key={i} className={styles.option}>
        <button
          type="button"
          onClick={() => onCheckHandler(option)}
          disabled={isDisabled && !isSelected}
        >
          {renderCheckbox?.() || (
            <Checkbox isChecked={isSelected} isDisabled={isDisabled} />
          )}
          {renderLabel?.() || (
            <span title={tooltip || label} className={styles.optionLabel}>
              {label}
            </span>
          )}
          {Icon && (
            <Tooltip content={iconTooltip}>
              <Icon className={cx(styles.optionIcon, iconClassName)} />
            </Tooltip>
          )}
        </button>
      </li>
    );
  };

  return (
    <div
      className={cx(styles.wrapper, multiSelectClassName)}
      style={style}
      ref={selectRef}
    >
      <div
        title={inputValue}
        className={cx(styles.inputWrapper, inputClassName)}
        onClick={onDropdownShowToggle}
      >
        {icon ? (
          <InputWithIcon
            readOnly
            placeholder={t(placeholder)}
            inputClassName={styles.input}
            value={inputValue}
            tabIndex={tabIndex}
            disabled={isDisabled}
            icon={icon}
          />
        ) : (
          <Input
            readOnly
            placeholder={t(placeholder)}
            inputClassName={styles.input}
            value={inputValue}
            tabIndex={tabIndex}
            disabled={isDisabled}
          />
        )}
        <ChevronDown
          tabIndex={-1}
          className={cx(styles.triangle, styles[isOpen ? "Open" : ""])}
        />
      </div>
      {isOpen && (
        <div
          style={{ maxHeight: dropdownMaxHeight }}
          className={cx(
            styles.popup,
            styles[openingDirection],
            dropdownClassName,
          )}
        >
          {isFilterPresent && (
            <>
              <InputWithIcon
                className={styles.optionsFilterInput}
                value={filter}
                changeHandler={setFilter}
                placeholder={t(
                  "component.multiselect.placeholder.search_query",
                )}
                icon="Magnifier"
                hasClearButton
                autoFocus
              />
              <div className={styles.divider} />
            </>
          )}
          {Boolean(onSelectAllHandler) &&
            hasSelectAll !== false &&
            !filter.length && (
              <>
                <button
                  type="button"
                  className={styles.selectAllOption}
                  title={t("component.multiselect.label.selected_all")}
                  onClick={onSelectAllHandler}
                >
                  <Checkbox isChecked={isAllOptionSelected} />
                  <span>{selectAllButtonText}</span>
                </button>
                <div className={styles.divider} />
              </>
            )}
          {filteredOptions.length ? (
            <ul className={cx(styles.options, optionsClassName)}>
              {filteredOptions.map(renderOption)}
            </ul>
          ) : (
            <div className={styles.noOptions}>
              <span>{t("component.multiselect.placeholder.no_data")}</span>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
