import { FC, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import styles from "./CreateDashboardModal.module.scss";
import { withError } from "src/hocs";
import { useAppDispatch } from "src/store";
import { TrackerSelectOption } from "src/features";
import { createTrackersCollection, createDashboard } from "src/store/actions";
import { MultiSelectOption } from "src/components/selectors/MultiSelect/types";
import {
  ROUTES,
  DASHBOARD_INPUT_LIMIT,
  DASHBOARD_VISIBILITY_OPTIONS,
} from "src/constants";
import {
  removeExtraSpaces,
  showToastNotification,
  isDashboardVisibilityTypeGuard,
} from "src/utils";
import {
  useModal,
  useElementFocus,
  useTrackerBlocker,
  useTemporaryErrors,
} from "src/hooks";
import {
  Form,
  Input,
  Label,
  Select,
  MultiSelect,
  Translation,
} from "src/components";
import {
  selectUser,
  selectCompanyDashboards,
  selectCompanyTrackersEntities,
  selectTrackersCollectionsTrackersLimit,
} from "src/store/selectors";
import { ConfirmModal } from "../ConfirmModal/ConfirmModal";

// Inner imports

const InputWithError = withError(Input);
const SelectWithError = withError(Select);
const MultiSelectWithError = withError(MultiSelect);

type Props = {
  onSubmitHandler?: () => void;
};

export const CreateDashboardModal: FC<Props> = ({ onSubmitHandler }) => {
  const { t } = useTranslation();

  const history = useHistory();

  const dispatch = useAppDispatch();

  const { closeModal } = useModal();

  const [ref, setFocus] = useElementFocus();

  const { errors, setErrors } = useTemporaryErrors(3000);

  const trackers = useSelector(selectCompanyTrackersEntities);

  const dashboards = useSelector(selectCompanyDashboards);

  const user = useSelector(selectUser);

  const { trackersCollectionsTrackersLimit } = useSelector(
    selectTrackersCollectionsTrackersLimit,
  );

  const { hasTrackerAccess, showTrackerBlockerNotification } =
    useTrackerBlocker();

  const [name, setName] = useState<Dashboard.Data["name"]>("");

  const [trackerIds, setTrackerIds] = useState<Tracker.Data["id"][]>([]);

  const [visibility, setVisibility] = useState<Dashboard.Visibility>("private");

  const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>("idle");

  const trackerOptions = useMemo<MultiSelectOption[]>(() => {
    const trackerOptions = new Set<MultiSelectOption>();

    for (const id in trackers) {
      const tracker = trackers[id];

      if (!tracker) continue;

      trackerOptions.add({
        value: id,
        label: tracker.name,
        renderLabel: () => <TrackerSelectOption tracker={tracker} />,
      });
    }

    return [...trackerOptions].sort((a, b) => a.label.localeCompare(b.label));
  }, [trackers]);

  const selectedTrackerOptions = useMemo<MultiSelectOption[]>(() => {
    const selectedTrackerOptions = new Set<MultiSelectOption>();

    for (const id of trackerIds) {
      const tracker = trackers[id];

      if (!tracker) continue;

      selectedTrackerOptions.add({ value: id, label: tracker.name });
    }

    return [...selectedTrackerOptions];
  }, [trackerIds, trackers]);

  const visibilityOptions = useMemo<Option[]>(() => {
    const options: Option[] = [];

    for (const option of DASHBOARD_VISIBILITY_OPTIONS) {
      const { checkIsAvailable } = option;

      if (checkIsAvailable && !checkIsAvailable(user)) continue;

      options.push(option);
    }

    return options;
  }, [user]);

  const isLoading = useMemo<boolean>(
    () => loadingStatus === "loading",
    [loadingStatus],
  );

  const isDashboardChanged = useMemo<boolean>(
    () => Boolean(name || visibility !== "private" || trackerIds.length),
    [name, visibility, trackerIds.length],
  );

  const isDisabled = useMemo<boolean>(
    () => isLoading || !isDashboardChanged,
    [isLoading, isDashboardChanged],
  );

  useEffect(() => setFocus(), [setFocus]);

  const onNameChange = (value: TrackersCollection.Data["name"]): void =>
    setName(value);

  const onVisibilityChange = (value: string): void => {
    if (!isDashboardVisibilityTypeGuard(value)) return;

    setVisibility(value);
  };

  const onTrackerIdsChange = (option: Option): void => {
    setTrackerIds((state) => {
      const { value } = option;

      if (state.includes(value))
        return state.filter((trackerId) => trackerId !== value);

      if (state.length >= trackersCollectionsTrackersLimit) {
        showToastNotification({
          id: "trackers-collection-tracker-limit",
          type: "warning",
          text: (
            <Translation
              i18nKey="trackers_collection.status.warning.trackers_collection_limit"
              values={{ count: trackersCollectionsTrackersLimit }}
            />
          ),
        });

        return state;
      } else {
        return [...state, option.value];
      }
    });
  };

  const onSubmit = async (): Promise<void> => {
    const errors = validate();

    if (Object.keys(errors).length) return setErrors(errors);

    if (!hasTrackerAccess) return showTrackerBlockerNotification();

    try {
      setLoadingStatus("loading");

      // Create trackers collection ---->
      const { id: trackersCollectionId } = await dispatch(
        createTrackersCollection({
          trackerIds,
          name: `${removeExtraSpaces(name)} Collection`,
        }),
      ).unwrap();

      // Create dashboard based on tracker collection ---->
      const { id: dashboardId } = await dispatch(
        createDashboard({
          visibility,
          trackersCollectionId,
          originalDashboardId: null,
          name: removeExtraSpaces(name),
        }),
      ).unwrap();

      setLoadingStatus("succeeded");

      history.push(`${ROUTES.dashboardsHomePage}/${dashboardId}`);

      onSubmitHandler?.();

      closeModal("create-dashboard");
    } catch (error) {
      console.error(error);

      showToastNotification({
        type: "error",
        text: t("common.error.server_error"),
      });

      setLoadingStatus("failed");
    }
  };

  function validate() {
    const validationErrors: typeof errors = {};

    if (!name.trim().length)
      validationErrors.name = t(
        "component.modal.create_dashboard.form.validation.name_required",
      );

    if (name.trim().length) {
      const dashboardByName = dashboards.find(
        (dashboard) => dashboard.name.trim() === name.trim(),
      );

      if (dashboardByName)
        validationErrors.name = t(
          "component.modal.create_dashboard.form.validation.name_exists",
        );
    }

    if (!trackerIds.length)
      validationErrors.trackerIds = t(
        "component.modal.create_dashboard.form.validation.trackers_required",
      );

    return validationErrors;
  }

  return (
    <ConfirmModal
      id="create-dashboard"
      type="success"
      acceptButton={{
        onClick: onSubmit,
        text: t("component.modal.create_dashboard.button.submit"),
        disabled: isDisabled,
      }}
      cancelButton={{
        onClick: () => closeModal("create-dashboard"),
        text: t("component.modal.create_dashboard.button.cancel"),
      }}
      title={t("component.modal.create_dashboard.title")}
      isLoading={isLoading}
    >
      <Form
        onSubmit={onSubmit}
        disabled={isDisabled}
        className={styles.formWrapper}
      >
        <div className={styles.inputWrapper}>
          <Label
            leftText={t("component.modal.create_dashboard.form.label.name")}
          />
          <InputWithError
            ref={ref}
            value={name}
            error={errors.name}
            changeHandler={onNameChange}
            characterLimit={DASHBOARD_INPUT_LIMIT}
            placeholder={t(
              "component.modal.create_dashboard.form.placeholder.name",
            )}
          />
        </div>
        <div className={styles.inputWrapper}>
          <Label
            leftText={t("component.modal.create_dashboard.form.label.trackers")}
          />
          <MultiSelectWithError
            hasFilter
            options={trackerOptions}
            error={errors.trackerIds}
            openingDirection="bottom-end"
            onCheckHandler={onTrackerIdsChange}
            selectedOptions={selectedTrackerOptions}
            placeholder={t(
              "component.modal.create_dashboard.form.placeholder.trackers",
            )}
          />
        </div>
        <div className={styles.inputWrapper}>
          <Label
            leftText={t(
              "component.modal.create_dashboard.form.label.visibility",
            )}
          />
          <SelectWithError
            value={visibility}
            placeholder={t(
              "component.modal.create_dashboard.form.placeholder.visibility",
            )}
            changeHandler={onVisibilityChange}
            options={visibilityOptions}
            error={errors.visibility}
            openingDirection="bottom-end"
          />
        </div>
      </Form>
    </ConfirmModal>
  );
};
