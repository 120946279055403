import { FC, MouseEventHandler } from "react";
import { toast } from "react-toastify";
import isFunction from "lodash/isFunction";

import { Loader } from "src/components";
import { getMessageIcon } from "src/utils";

type NotificationType = "success" | "warning" | "error" | "info";

type CloseButtonProps = {
  text: string;
  buttonType?: NotificationType;
  onClick?: MouseEventHandler<HTMLButtonElement>;
};

type Props = {
  id?: string;
  type: NotificationType;
  text: string | JSX.Element;
  autoClose?: false | number;
  closeButton?: CloseButtonProps;
};

const CloseButton: FC<CloseButtonProps> = ({ text, buttonType, onClick }) => (
  <button
    type="button"
    onClick={onClick}
    className={`toast-button--${buttonType}`}
  >
    {text}
  </button>
);

export const showToastNotification = ({
  id,
  type,
  text,
  closeButton,
  autoClose = 5000,
}: Props): void => {
  const CustomCloseButton = closeButton ? (
    <CloseButton
      text={closeButton.text}
      onClick={closeButton.onClick}
      buttonType={closeButton.buttonType || type}
    />
  ) : undefined;

  if (!isFunction(toast[type])) return;

  toast[type](text, {
    autoClose,
    toastId: id,
    icon: getMessageIcon(type),
    closeButton: CustomCloseButton,
  });
};

export const showPromiseToastNotification = ({
  text,
}: Pick<Props, "text">): ((value: Pick<Props, "text" | "type">) => void) => {
  const toastId = toast(text, {
    type: "default",
    icon: <Loader />,
    autoClose: false,
    closeOnClick: false,
  });

  return ({ text, type }: Pick<Props, "text" | "type">): void => {
    if (type === "success")
      return toast.update(toastId, {
        type,
        icon: getMessageIcon(type),
        render: text,
        isLoading: false,
        autoClose: 5000,
        closeOnClick: true,
      });

    if (type === "error")
      return toast.update(toastId, {
        type,
        icon: getMessageIcon(type),
        render: text,
        isLoading: false,
        autoClose: 5000,
        closeOnClick: true,
      });
  };
};
