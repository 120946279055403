import { FC, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import styles from "./ProfileButton.module.scss";
import { ROUTES } from "src/constants";
import { Button } from "src/components";
import { Profile } from "src/assets/icons";
import { removeExtraSpaces } from "src/utils";
import { useOutsideClickHandler } from "src/hooks";
import {
  selectUser,
  selectCompany,
  selectCompanySubscriptionPlan,
} from "src/store/selectors";
import {
  checkBillingTabPermission,
  checkCompanyTabPermission,
  checkCompanyMembersTabPermission,
} from "src/containers/Settings/utils";

// Inner imports
import { ProfileButtonOption } from "./types";

export const ProfileButton: FC = () => {
  const { t } = useTranslation();

  const history = useHistory();

  const user = useSelector(selectUser);

  const company = useSelector(selectCompany);

  const subscriptionPlan = useSelector(selectCompanySubscriptionPlan);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const UserIcon = useMemo<JSX.Element>(() => {
    const [firstNameLetter, lastNameLetter] = [
      user.firstName[0] || "",
      user.lastName[0] || "",
    ];

    const userSignature = `${firstNameLetter}${lastNameLetter}`.toUpperCase();

    if (!userSignature) return <Profile />;

    return <div className={styles.signature}>{userSignature}</div>;
  }, [user.firstName, user.lastName]);

  const userFullName = useMemo<string>(
    () => removeExtraSpaces(`${user.firstName} ${user.lastName}`),
    [user],
  );

  const settingsOptions = useMemo<ProfileButtonOption[]>(() => {
    const options = [
      {
        id: "Profile",
        label: t("component.header.button.profile"),
        onClick: () => history.push(`${ROUTES.settingsPage}#profile`),
      },
    ];

    if (checkCompanyTabPermission(user))
      options.push({
        id: "Company",
        label: t("component.header.button.company"),
        onClick: () => history.push(`${ROUTES.settingsPage}#company`),
      });

    if (checkCompanyMembersTabPermission(user))
      options.push({
        id: "Members",
        label: t("component.header.button.company_members"),
        onClick: () => history.push(`${ROUTES.settingsPage}#members`),
      });

    if (checkBillingTabPermission(user, company, subscriptionPlan))
      options.push({
        id: "Billing",
        label: t("component.header.button.subscription_plan"),
        onClick: () => history.push(`${ROUTES.settingsPage}#billing`),
      });

    return options;
  }, [company, history, subscriptionPlan, t, user]);

  const authOptions = useMemo<ProfileButtonOption[]>(
    () => [
      {
        id: "SignOut",
        label: t("component.header.button.log_out"),
        onClick: () => history.push(ROUTES.logout),
      },
    ],
    [history, t],
  );

  const ref = useRef(null);

  useOutsideClickHandler(ref, () => setIsOpen(false));

  return (
    <div className={styles.profile} ref={ref}>
      <Button
        className={styles.profileButton}
        buttonSize="small"
        buttonType="dark"
        onClick={() => setIsOpen((state) => !state)}
      >
        {UserIcon}
      </Button>

      {isOpen && (
        <div className={styles.dropDown}>
          <div className={styles.userInfo}>
            {UserIcon}
            <div className={styles.userInfoName}>
              <span className={styles.userFullName} title={userFullName}>
                {userFullName}
              </span>
              <span className={styles.userEmail} title={user.email}>
                {user.email}
              </span>
            </div>
          </div>
          <hr className={styles.divider} />
          <div className={styles.options}>
            {settingsOptions.map(({ id, label, onClick }) => (
              <Button
                key={id}
                onClick={() => {
                  onClick();

                  setIsOpen(false);
                }}
                buttonSize="small"
                buttonStyle="transparent"
                className={styles.option}
              >
                <span>{label}</span>
              </Button>
            ))}
          </div>
          <hr className={styles.divider} />
          <div className={styles.options}>
            {authOptions.map(({ id, label, onClick }) => (
              <Button
                key={id}
                onClick={() => {
                  onClick();

                  setIsOpen(false);
                }}
                buttonSize="small"
                buttonStyle="transparent"
                className={styles.option}
              >
                <span>{label}</span>
              </Button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
