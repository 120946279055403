import { FC, memo } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { ConfirmModal } from "src/features";
import { useModal } from "src/hooks";

const DiscardModal: FC = memo(() => {
  const { t } = useTranslation();
  const history = useHistory();
  const { closeModal } = useModal();

  const handleOK = () => {
    history.block(() => {});
    history.goBack();
    closeModal("discard-modal");
  };

  const handleCancel = () => {
    closeModal("discard-modal");
  };

  return (
    <ConfirmModal
      id="discard-modal"
      type="error"
      acceptButton={{
        text: t("dcmw_ok_btn"),
        onClick: () => handleOK(),
      }}
      cancelButton={{
        text: t("dcmw_cancel_btn"),
        onClick: () => handleCancel(),
      }}
      title={t("dcmw_title")}
    >
      <p>{t("dcmw_text")}</p>
    </ConfirmModal>
  );
});

export { DiscardModal };
