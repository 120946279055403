import * as yup from "yup";

import { WIDGET_IDS, WIDGET_CHARTS } from "src/constants";

const widgetViewSchema = yup.object({
  isAvailableForOneTracker: yup.boolean().required(),
  isDefault: yup.boolean().required(),
  order: yup.number().required(),
  type: yup.string().required(),
  subType: yup
    .string()
    .notRequired()
    .nullable()
    .transform((value) => value ?? ""),
});

const widgetDependentOnWidgetsSchema = yup
  .array(
    yup
      .string<Widget.IdType>()
      .oneOf([...WIDGET_IDS])
      .required(),
  )
  .required();

export const widgetsInfoSchema = yup
  .object({
    name: yup.string().default(""),
    order: yup.number().required(),
    resultCollection: yup
      .string<Widget.IdType>()
      .oneOf([...WIDGET_IDS])
      .required(),
    views: yup.array(widgetViewSchema).required(),
    preReleaseLabel: yup.string().default(""),
    placeHolderOnUi: yup.string().default(""),
    dependentOnWidgets: widgetDependentOnWidgetsSchema,
    isActive: yup.boolean().default(false),
    isAvailableForOneTracker: yup.boolean().required(),
    isImmediatelyAvailable: yup.boolean().default(false),
  })
  .noUnknown();

export const widgetsInfoViewsSchema = yup
  .object({
    isAvailableForOneTracker: yup.boolean().required(),
    isDefault: yup.boolean().required(),
    order: yup.number().required(),
    type: yup
      .string()
      .oneOf([...WIDGET_CHARTS])
      .required(),
    subType: yup.string().default(""),
  })
  .noUnknown();
