import firestore from "src/services/firestore";
import { COLLECTION_IDS } from "../constants";

// Inner imports
import * as schemas from "./localizationSchema";

export const getLocalization = async (
  languageId: Localization.Id,
): Promise<Record<string, string>> => {
  const res = await firestore()
    .collection(COLLECTION_IDS.localization)
    .doc(languageId)
    .get();

  return schemas.localizationSchema.validateSync(res.data());
};
