import { FC, useMemo } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import styles from "./DashboardTrackers.module.scss";
import { Checkbox, MultiSelect } from "src/components";
import { selectTrackersById } from "src/store/selectors";
import { MultiSelectOption } from "src/components/selectors/MultiSelect/types";
import { TrackerChartIcon, TrackerKeywordsDataSourcesIcon } from "src/features";

type Props = {
  trackerIds: Tracker.Data["id"][];
  activeTrackerIds: Tracker.Data["id"][];
  setActiveTrackerIdsHandler: (value: Tracker.Data["id"][]) => void;
};

export const DashboardTrackers: FC<Props> = ({
  trackerIds,
  activeTrackerIds,
  setActiveTrackerIdsHandler,
}) => {
  const { t } = useTranslation();

  const trackers = useSelector((state: Store.RootState) =>
    selectTrackersById(state, trackerIds),
  );

  const trackerOptions = useMemo<MultiSelectOption[]>(
    () =>
      trackers.map(({ name, id }) => ({
        value: id,
        label: name,
        renderLabel: () => (
          <div className={styles.trackerLabel}>
            <span>{name}</span>
            <TrackerKeywordsDataSourcesIcon trackerId={id} />
          </div>
        ),
        renderCheckbox: () => (
          <Checkbox
            isChecked={activeTrackerIds.includes(id)}
            elementChecked={
              <TrackerChartIcon
                trackerId={id}
                trackerIds={trackerIds}
                className={styles.trackerChartIcon}
              />
            }
            elementUnchecked={
              <TrackerChartIcon
                trackerId={id}
                trackerIds={trackerIds}
                className={styles.trackerChartIconInactive}
              />
            }
          />
        ),
      })),
    [activeTrackerIds, trackerIds, trackers],
  );

  const selectedTrackerOptions = useMemo<MultiSelectOption[]>(() => {
    const selectedTrackerOptions = new Set<MultiSelectOption>();

    for (const id of activeTrackerIds) {
      const tracker = trackers.find((value) => value.id === id);

      if (!tracker) continue;

      selectedTrackerOptions.add({ value: id, label: tracker.name });
    }

    return [...selectedTrackerOptions];
  }, [activeTrackerIds, trackers]);

  const hasSingleTracker = useMemo<boolean>(
    () => trackerIds.length === 1,
    [trackerIds.length],
  );

  const customMultiselectInputText = useMemo(() => {
    const [activeTrackerIdsLength, trackerIdsLength] = [
      activeTrackerIds.length,
      trackerIds.length,
    ];

    if (activeTrackerIdsLength === 1) {
      const tracker = trackers.find(
        (value) => value.id === activeTrackerIds[0],
      );

      if (!tracker) return t("page.dashboard.label.options_no_trackers");

      return tracker.name;
    }

    if (!trackerIdsLength) return t("page.dashboard.label.options_no_trackers");

    if (!activeTrackerIdsLength)
      return t("page.dashboard.label.options_select_trackers");

    if (activeTrackerIdsLength === trackerIdsLength)
      return t("page.dashboard.label.options_all_trackers_selected");

    return t("page.dashboard.label.options_trackers_selected", {
      count: activeTrackerIdsLength,
    });
  }, [activeTrackerIds, t, trackerIds.length, trackers]);

  const onSelectTrackerId = ({ value }: Option): void => {
    const isTrackerIdIncluded = activeTrackerIds.includes(value);

    const updatedActiveTrackerIds = isTrackerIdIncluded
      ? activeTrackerIds.filter((id) => id !== value)
      : [...activeTrackerIds, value];

    return setActiveTrackerIdsHandler(updatedActiveTrackerIds);
  };

  const onSelectAllTrackerIds = (): void => {
    const [activeTrackerIdsLength, trackerIdsLength] = [
      activeTrackerIds.length,
      trackerIds.length,
    ];

    const firstTrackerId = trackerIds[0];

    const updatedActiveTrackerIds =
      activeTrackerIdsLength === trackerIdsLength && firstTrackerId
        ? [firstTrackerId]
        : trackerIds;

    return setActiveTrackerIdsHandler(updatedActiveTrackerIds);
  };

  return (
    <div className={styles.wrapper}>
      <MultiSelect
        icon="Filter"
        hasFilter={false}
        options={trackerOptions}
        inputClassName={styles.input}
        openingDirection="bottom-start"
        hasSelectAll={!hasSingleTracker}
        onCheckHandler={onSelectTrackerId}
        dropdownClassName={styles.dropdown}
        multiSelectClassName={styles.select}
        selectedOptions={selectedTrackerOptions}
        onSelectAllHandler={onSelectAllTrackerIds}
        customInputText={customMultiselectInputText}
      />
    </div>
  );
};
