import { checkIsLocalEnvironment } from "./checkIsLocalEnvironment";

const { REACT_APP_DEFAULT_WHITE_LABEL_DOMAIN } = process.env;

export const getHostDomain = (): string => {
  if (checkIsLocalEnvironment()) return getDefaultWhiteLabelDomain();

  const formattedDomain = window.location.hostname.replace("www.", "");

  return formattedDomain || getDefaultWhiteLabelDomain();
};

function getDefaultWhiteLabelDomain(): string {
  return REACT_APP_DEFAULT_WHITE_LABEL_DOMAIN || "";
}
