import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import styles from "./SubscriptionPlansSidebar.module.scss";
import { useModal } from "src/hooks";
import { isNumber, triggerGtmEvent } from "src/utils";
import { useSubscriptionPlansLoader } from "src/containers/Settings/hooks";
import {
  Button,
  Switch,
  Sidebar,
  Preloader,
  Translation,
} from "src/components";
import {
  ConfirmSubscriptionPlanRequest,
  SubscriptionPlanPaymentSidebar,
} from "src/features";
import {
  selectUserId,
  selectSubscriptionPlans,
  selectCompanySubscriptionPlan,
} from "src/store/selectors";

// Inner imports
import { SUBSCRIPTION_PLAN_MONTHS_IN_YEAR } from "./constants";
import {
  SubscriptionPlanDescription,
  EnterpriseSubscriptionPlanDescription,
} from "./components";

type Props = {
  isOpened: boolean;
  setIsOpened: (value: boolean) => void;
};

export const SubscriptionPlansSidebar: FC<Props> = ({
  isOpened,
  setIsOpened,
}) => {
  const { t } = useTranslation();

  const { setModal } = useModal();

  const isSubscriptionPlansLoaded = useSubscriptionPlansLoader();

  const userId = useSelector(selectUserId);

  const subscriptionPlans = useSelector(selectSubscriptionPlans);

  const companySubscriptionPlan = useSelector(selectCompanySubscriptionPlan);

  const [billingPeriod, setBillingPeriod] =
    useState<SubscriptionPlan.BillingPeriod>("monthly");

  const [isPaymentSidebarOpen, setIsPaymentSidebarOpen] =
    useState<boolean>(false);

  const [selectedSubscriptionPlanId, setSelectedSubscriptionPlanId] =
    useState<SubscriptionPlan.Data["id"]>("");

  const formattedSubscriptionPlans = useMemo<SubscriptionPlan.Data[]>(() => {
    const formattedSubscriptionPlans = new Set<SubscriptionPlan.Data>();

    if (companySubscriptionPlan)
      formattedSubscriptionPlans.add(companySubscriptionPlan);

    for (const subPlan of subscriptionPlans) {
      if (subPlan.id === companySubscriptionPlan?.id) continue;

      formattedSubscriptionPlans.add(subPlan);
    }

    return [...formattedSubscriptionPlans];
  }, [companySubscriptionPlan, subscriptionPlans]);

  const isPaymentPeriodTypeChecked = useMemo<boolean>(
    () => billingPeriod === "yearly",
    [billingPeriod],
  );

  const getSubscriptionPlanPrice = useCallback(
    (
      subscriptionPlan: SubscriptionPlan.Data,
      billingPeriod: SubscriptionPlan.BillingPeriod,
    ): number => {
      if (billingPeriod === "monthly") return subscriptionPlan.price.monthly;

      if (billingPeriod === "yearly") {
        const number = Number(
          subscriptionPlan.price.yearly / SUBSCRIPTION_PLAN_MONTHS_IN_YEAR,
        ).toFixed(2);

        const formattedNumber = Number(number);

        if (!isNumber(formattedNumber)) return 0;

        return formattedNumber;
      }

      return 0;
    },
    [],
  );

  useEffect(() => {
    triggerGtmEvent("SubscriptionPlanView", { userId });
  }, [userId]);

  const onPaymentPeriodTypeChange = (value: boolean): void => {
    if (value) setBillingPeriod("yearly");
    else setBillingPeriod("monthly");
  };

  const onSubscriptionPlanSelect = (
    value: SubscriptionPlan.Data["id"],
  ): void => {
    setIsPaymentSidebarOpen(true);

    setSelectedSubscriptionPlanId(value);

    triggerGtmEvent("SubscriptionPlanSelect", {
      userId,
      subscriptionPlanId: value,
    });
  };

  const onEnterpriseSubscriptionPlanSelect = (): void =>
    setModal(
      "confirm-subscription-plan-request",
      <ConfirmSubscriptionPlanRequest />,
    );

  if (!isSubscriptionPlansLoaded)
    return (
      <Sidebar isSidebarOpen={isOpened} setIsSidebarOpen={setIsOpened}>
        <div className={styles.wrapper}>
          <Preloader />
        </div>
      </Sidebar>
    );

  return (
    <Sidebar isSidebarOpen={isOpened} setIsSidebarOpen={setIsOpened}>
      <div className={styles.wrapper}>
        <div className={styles.titleWrapper}>
          <span className={styles.customSubscriptionPlanFeature}>
            {t("page.settings.subscription_plan.label.payment_monthly")}
          </span>
          <Switch
            checked={isPaymentPeriodTypeChecked}
            onChange={onPaymentPeriodTypeChange}
          />
          <span className={styles.customSubscriptionPlanFeature}>
            {t("page.settings.subscription_plan.label.payment_yearly")}
          </span>
        </div>
        <div className={styles.subscriptionPlansWrapper}>
          {formattedSubscriptionPlans.map((subscriptionPlan) => (
            <div className={styles.subscriptionPlan} key={subscriptionPlan.id}>
              <div className={styles.subscriptionPlanTitle}>
                <span className={styles.subscriptionPlanName}>
                  {subscriptionPlan.name}
                </span>
                <div className={styles.subscriptionPlanPriceWrapper}>
                  <span className={styles.subscriptionPlanPrice}>
                    {t("page.settings.subscription_plan.label.price", {
                      count: getSubscriptionPlanPrice(
                        subscriptionPlan,
                        billingPeriod,
                      ),
                    })}
                  </span>
                  <span className={styles.subscriptionPlanPriceSubtitle}>
                    {t(
                      "page.settings.subscription_plan.label.suffix_payment_monthly",
                    )}
                  </span>
                </div>
              </div>
              <SubscriptionPlanDescription
                billingPeriod={billingPeriod}
                subscriptionPlanId={subscriptionPlan.id}
              />
              {subscriptionPlan.id === companySubscriptionPlan?.id ? (
                <Button
                  className={styles.button}
                  buttonStyle="outlined"
                  disabled
                >
                  {t(
                    "page.settings.subscription_plan.button.current_subscription_plan",
                  )}
                </Button>
              ) : (
                <Button
                  className={styles.button}
                  onClick={() => onSubscriptionPlanSelect(subscriptionPlan.id)}
                >
                  {t(
                    "page.settings.subscription_plan.button.select_subscription_plan",
                  )}
                </Button>
              )}
            </div>
          ))}
          <div className={styles.subscriptionPlan}>
            <div className={styles.subscriptionPlanTitle}>
              <span className={styles.subscriptionPlanName}>
                {t(
                  "page.settings.subscription_plan.label.custom_subscription_plan",
                )}
              </span>
              <div className={styles.subscriptionPlanPriceWrapper}>
                <span className={styles.subscriptionPlanPriceCustom}>
                  <Translation i18nKey="page.settings.subscription_plan.label.request_custom_subscription_plan" />
                </span>
              </div>
            </div>
            <EnterpriseSubscriptionPlanDescription />
            <Button
              className={styles.button}
              onClick={onEnterpriseSubscriptionPlanSelect}
            >
              {t(
                "page.settings.subscription_plan.button.request_custom_subscription_plan",
              )}
            </Button>
          </div>
        </div>
      </div>
      {isPaymentSidebarOpen && (
        <SubscriptionPlanPaymentSidebar
          billingPeriod={billingPeriod}
          isOpened={isPaymentSidebarOpen}
          setIsOpened={setIsPaymentSidebarOpen}
          subscriptionPlanId={selectedSubscriptionPlanId}
          setSubscriptionPlanId={setSelectedSubscriptionPlanId}
        />
      )}
    </Sidebar>
  );
};
