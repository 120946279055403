import { useCallback, useEffect, useRef, useState } from "react";
import { RouteComponentProps } from "react-router-dom";

import { useModal } from "src/hooks";
import { ConfirmRedirectionModal } from "src/features";

export const useBlockRedirection = (
  history: RouteComponentProps["history"],
  isRedirectionBlocked: boolean = false,
  setRedirectionState?: (path: string) => Object,
) => {
  const { setModal } = useModal();

  const unblockRef = useRef<Function | null>(null);

  const [redirectionPathName, setRedirectionPathName] = useState<string>("");

  const unblockRedirection = useCallback(
    () => history.block(() => {}),
    [history],
  );

  useEffect(() => {
    const beforeUnload = () =>
      window.addEventListener("beforeunload", beforeUnloadHandler, true);

    const cleanBeforeUnload = () =>
      window.removeEventListener("beforeunload", beforeUnloadHandler, true);

    isRedirectionBlocked ? beforeUnload() : cleanBeforeUnload();

    return () => cleanBeforeUnload();
  }, [isRedirectionBlocked]);

  useEffect(() => {
    unblockRef.current = history.block(({ pathname, hash }) => {
      if (!isRedirectionBlocked) return;

      setRedirectionPathName(pathname);

      const state = setRedirectionState?.(pathname) || {};

      const queryParams = new URLSearchParams(location.search).toString();

      setModal(
        "confirm-redirection",
        <ConfirmRedirectionModal
          hash={hash}
          state={state}
          path={pathname}
          history={history}
          query={queryParams}
        />,
      );

      return "true";
    });

    return () => unblockRef.current?.();
  }, [
    history,
    setModal,
    unblockRedirection,
    setRedirectionState,
    isRedirectionBlocked,
  ]);

  return { redirectionPathName, unblockRedirection };
};

function beforeUnloadHandler(evt: BeforeUnloadEvent): null {
  evt.preventDefault();

  evt.returnValue = "";

  return null;
}
