import { LineProps } from "recharts";

import { SLATE_BLUE } from "src/constants/colors";

export const CATEGORY_TREND_LINE = {
  color: SLATE_BLUE,
  label: "Trend",
  value: "Trend",
} as const;

export const LINE_CHART_TICK_STYLES = {
  fill: "currentColor",
  fontSize: 10,
  fontWeight: 400,
  opacity: 1,
} as const;

export const LINE_CHART_TICK_MIN_STEP = 10;

export const LINE_CHART_Y_AXIS_DEFAULT_WIDTH = 30;

export const LINE_CHART_LINE_PROPS: Omit<LineProps, "ref"> = {
  type: "monotone",
  dot: false,
  connectNulls: true,
  animationEasing: "ease",
  animationDuration: 1500,
};

export const LINE_CHART_EVENT_ACTIVE_PADDING_TOP = 15;

export const LINE_CHART_EVENT_PADDING_TOP = 20;

export const LINE_CHART_FORECAST_PADDING_TOP = 15;

export const LINE_CHART_FORECAST_LINE_ANIMATION_DELAY = 1300;
