import { KeywordsTableKeywordData } from "../../types";

export const writeKeywordsRecursively = (
  accumulator: Set<string>,
  children: KeywordsTableKeywordData[],
): void => {
  for (const child of children) {
    const { children: childChildren = [], value = "" } = child;

    if (childChildren.length) {
      writeKeywordsRecursively(accumulator, childChildren);
    } else {
      accumulator.add(value);
    }
  }
};
