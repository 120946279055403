import { useState } from "react";

import styles from "./TableCard.module.scss";
import { genericMemo } from "src/utils";

// Inner imports
import type { TableItemGenericProps, TableProps } from "../../../../types";
import { TableCardBody, TableCardHead } from "../";

type Props<T> = {
  item: T;
  cardHeaderProp?: keyof T;
  isSubTable?: boolean;
} & Pick<TableProps<T>, "tableColumns" | "getActionMenuOptions">;

export const TableCard = genericMemo(
  <T extends TableItemGenericProps>({
    item,
    tableColumns,
    getActionMenuOptions,
    cardHeaderProp = "name",
    isSubTable,
  }: Props<T>) => {
    const [isCollapsed, setIsCollapsed] = useState<boolean>(false);

    const toggleCollapse = (): void => setIsCollapsed((state) => !state);

    return (
      <div className={styles.tableCard}>
        <TableCardHead
          item={item}
          tableColumns={tableColumns}
          cardHeaderProp={cardHeaderProp}
          getActionMenuOptions={getActionMenuOptions}
          isCollapsed={isCollapsed}
          isSubTable={isSubTable}
          toggleCollapse={toggleCollapse}
        />
        {isCollapsed && (
          <TableCardBody
            item={item}
            tableColumns={tableColumns}
            isSubTable={isSubTable}
            cardHeaderProp={cardHeaderProp}
          />
        )}
      </div>
    );
  },
);
