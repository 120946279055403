import { FC } from "react";

import styles from "./EmptyTablePlaceholder.module.scss";

type Props = {
  text: string;
};

export const EmptyTablePlaceholder: FC<Props> = ({ text }) => (
  <div className={styles.wrapper}>
    <p className={styles.text}>{text}</p>
  </div>
);
