import { TFunction } from "i18next";
import { Label, ReferenceLine } from "recharts";

// Inner imports
import {
  FORECAST_DIVIDER_LINE_PROPS,
  FORECAST_DIVIDER_LABEL_PROPS,
} from "./constants";

type Props = {
  x: number;
  t: TFunction;
};

export const ForecastDivider = ({ x, t }: Props): JSX.Element | null => (
  <ReferenceLine {...FORECAST_DIVIDER_LINE_PROPS} x={x}>
    <Label
      {...FORECAST_DIVIDER_LABEL_PROPS}
      value={t("chart.line.data.divider.actual")}
      position="insideTopRight"
    />
    <Label
      {...FORECAST_DIVIDER_LABEL_PROPS}
      value={t("chart.line.data.divider.forecast")}
      position="insideTopLeft"
    />
  </ReferenceLine>
);
