import { FC, memo, useMemo } from "react";
import { useSelector } from "react-redux";

import styles from "./PreReleaseComponent.module.scss";
import { selectWidgetInfoById } from "src/store/selectors";
import { RootState } from "src/store";

// Inner imports
import { PreReleaseLabel } from "./components/PreReleaseLabel/PreReleaseLabel";
import { VIEW_TYPES } from "./constants";

type Props = {
  widgetId: string;
  viewType?: "default" | "compact";
};

export const PreReleaseComponent: FC<Props> = memo(
  ({ widgetId, viewType = VIEW_TYPES.default }) => {
    const widgetInfo = useSelector((state: RootState) =>
      selectWidgetInfoById(state, widgetId),
    );

    const preReleaseLabelContent = useMemo(
      () => widgetInfo?.preReleaseLabel,
      [widgetInfo],
    );

    if (!preReleaseLabelContent || !widgetId) return null;

    return (
      <div className={styles.preReleaseWrapper}>
        <PreReleaseLabel content={preReleaseLabelContent} viewType={viewType} />
      </div>
    );
  },
);
