import { useMemo } from "react";
import { useSelector } from "react-redux";

import {
  selectAvailableDashboardById,
  selectAvailableWidgetIdsByCompanyId,
} from "src/store/selectors";

export const useIsWidgetLocked = (
  dashboardId: Dashboard.Data["id"],
  widgetId: Widget.IdType,
  isReadOnly: boolean,
): boolean => {
  const dashboard = useSelector((state: Store.RootState) =>
    selectAvailableDashboardById(state, dashboardId, isReadOnly),
  );

  const dashboardCompanyId = useMemo<Dashboard.Data["companyId"]>(
    () => dashboard?.companyId || "",
    [dashboard?.companyId],
  );

  const availableWidgetIds = useSelector((state: Store.RootState) =>
    selectAvailableWidgetIdsByCompanyId(state, dashboardCompanyId),
  );

  return useMemo<boolean>(
    () => !availableWidgetIds.has(widgetId),
    [availableWidgetIds, widgetId],
  );
};
