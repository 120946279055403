import { isNumber } from "./isNumber";

export const formatToLocaleNumber = (
  number: number | string | boolean,
): string => {
  const _number = Number(number);

  if (!isNumber(_number)) return "Wrong format";

  const formatter = Intl.NumberFormat("en-US", {
    notation: "compact",
    maximumFractionDigits: 2,
  });

  return formatter.format(_number);
};
