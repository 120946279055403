import * as yup from "yup";

import { WIDGET_IDS } from "src/constants";

export const widgetSyncSchema = yup
  .object({
    syncPriority: yup.number().default(0),
    trackersCollectionId: yup.string().required(),
    dashboardDateRangeId: yup.string().required(),
    widgetId: yup
      .string<Widget.IdType>()
      .oneOf([...WIDGET_IDS])
      .required(),
    startedAt: yup.string().required(),
    finishedAt: yup.string().default(""),
  })
  .noUnknown()
  .required();
