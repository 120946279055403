import { useHistory } from "react-router-dom";

import { updateQueryParams } from "src/utils";
import { useQueryParams } from "src/hooks/useQueryParams";

export const useQueryParamsState = (): [
  Record<string, string>,
  (value: Record<string, string>) => void,
] => {
  const history = useHistory();

  const queryParams = useQueryParams();

  const updateQuery = (value: Record<string, string>): void =>
    updateQueryParams(value, history);

  return [queryParams, updateQuery];
};
