import { useIsVolumeShareForecastCalculated } from "../../Widgets/Components/VolumeShare/hooks";

export const WIDGET_IS_FORECAST_CALCULATED_HOOKS: Record<
  Widget.IdType,
  ({
    widgetId,
    dashboardDateRangeId,
  }: Pick<Widget.ChartProps, "widgetId" | "dashboardDateRangeId">) => boolean
> = {
  "widget-volume-share": useIsVolumeShareForecastCalculated,
  "widget-insights": () => false,
  "widget-articles": () => false,
  "widget-search-share": () => false,
  "widget-top-keywords": () => false,
  "widget-trending-keywords": () => false,
};

export const useIsWidgetForecastCalculated = (
  props: Pick<Widget.ChartProps, "widgetId" | "dashboardDateRangeId">,
): boolean => WIDGET_IS_FORECAST_CALCULATED_HOOKS[props.widgetId](props);
