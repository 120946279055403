import { FC, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import styles from "./DuplicateTrackerModal.module.scss";
import { useAppDispatch } from "src/store";
import { GRAY, GREEN } from "src/constants";
import { duplicateTracker } from "src/store/actions";
import { Button, Form, Label, Loader, Switch } from "src/components";
import { showPromiseToastNotification, showToastNotification } from "src/utils";
import { SelectedTracker } from "src/pages/Trackers/CreateTrackers/components/SelectSearchesSection/components";
import {
  useModal,
  useScrollTo,
  useLanguageId,
  useLocationId,
  useTrackerBlocker,
  useTrackerLocation,
  useTrackerLanguage,
} from "src/hooks";
import {
  selectTrackerById,
  selectTrackersLimit,
  selectSearchesByTrackerId,
} from "src/store/selectors";
import {
  ConfirmModal,
  LocationsDropdown,
  LanguagesDropdown,
} from "src/features";

// Inner imports
import { DuplicateTrackerConfiguration } from "./components";

type Props = {
  trackerId: Tracker.Data["id"];
};

export const DuplicateTrackerModal: FC<Props> = ({ trackerId }) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const { closeModal } = useModal();

  const [configurationRef, setShouldScrollTo] = useScrollTo<HTMLDivElement>({
    behavior: "smooth",
  });

  const { showTrackerLimitNotification } = useTrackerBlocker();

  const { trackersLimit, trackersCount } = useSelector(selectTrackersLimit);

  const tracker = useSelector((state: Store.RootState) =>
    selectTrackerById(state, trackerId),
  );

  const searches = useSelector((state: Store.RootState) =>
    selectSearchesByTrackerId(state, trackerId),
  );

  const trackerLocation = useTrackerLocation(searches);

  const trackerLanguage = useTrackerLanguage(searches);

  const [configurations, setConfigurations] = useState<
    Tracker.DuplicateConfig[]
  >([]);

  const [shouldCopyKeywords, setShouldCopyKeywords] = useState<boolean>(false);

  const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>("idle");

  const keywordsDataSource = useMemo<
    Nullable<Tracker.Data["keywordsDataSources"][number]>
  >(() => {
    if (!tracker?.keywordsDataSources.length) return null;

    return tracker.keywordsDataSources[0] || null;
  }, [tracker?.keywordsDataSources]);

  const [locationId, setLocationId] = useLocationId({ keywordsDataSource });

  const [languageId, setLanguageId] = useLanguageId({
    locationId,
    keywordsDataSource,
  });

  const formattedTracker = useMemo<Nullable<Tracker.CreationData>>(() => {
    if (!tracker) return null;

    return {
      ...tracker,
      locationId: trackerLocation.id,
      languageId: trackerLanguage.id,
    };
  }, [tracker, trackerLanguage.id, trackerLocation.id]);

  const isAddAvailable = useMemo<boolean>(
    () => Boolean(locationId) && Boolean(languageId),
    [locationId, languageId],
  );

  const hasConfigurations = useMemo<boolean>(
    () => Boolean(configurations.length),
    [configurations.length],
  );

  const isLoading = useMemo<boolean>(
    () => loadingStatus === "loading",
    [loadingStatus],
  );

  const isSubmitAvailable = useMemo<boolean>(
    () => !isLoading && hasConfigurations,
    [hasConfigurations, isLoading],
  );

  const updateShouldCopyKeywords = (): void =>
    setShouldCopyKeywords((state) => !state);

  const addConfiguration = (
    locationId: Nullable<Location.Data["id"]>,
    languageId: Nullable<Language.Data["id"]>,
  ): void => {
    if (!locationId || !languageId) return;

    const isTrackersLimitExceeded =
      trackersCount + configurations.length >= trackersLimit;

    if (isTrackersLimitExceeded) return showTrackerLimitNotification();

    const newConfiguration = { locationId, languageId };

    const hasConfiguration = configurations.some(
      (configuration) =>
        configuration.languageId === languageId &&
        configuration.locationId === locationId,
    );

    if (hasConfiguration)
      return showToastNotification({
        id: "duplicate-tracker-duplicate-tracker-configuration",
        type: "info",
        text: t(
          "component.modal.duplicate_tracker.status.info.configuration_exists",
        ),
      });

    setConfigurations((state) => [...state, newConfiguration]);

    setLocationId(null);

    setLanguageId(null);

    setShouldScrollTo(true);
  };

  const removeConfiguration = ({
    locationId,
    languageId,
  }: Tracker.DuplicateConfig): void => {
    setConfigurations((state) =>
      state.filter(
        (configuration) =>
          configuration.locationId !== locationId ||
          configuration.languageId !== languageId,
      ),
    );
  };

  const onSubmit = async (): Promise<void> => {
    if (isLoading) return;

    const updateNotification = showPromiseToastNotification({
      text: t(
        "component.modal.duplicate_tracker.status.loading.duplicate_tracker",
      ),
    });

    try {
      setLoadingStatus("loading");

      await dispatch(
        duplicateTracker({ trackerId, shouldCopyKeywords, configurations }),
      ).unwrap();

      updateNotification({
        type: "success",
        text: t(
          "component.modal.duplicate_tracker.status.success.duplicate_tracker",
        ),
      });

      setLoadingStatus("succeeded");

      closeModal("duplicate-tracker");
    } catch (error) {
      console.error(error);

      setLoadingStatus("failed");

      updateNotification({
        type: "error",
        text: t("Failed to create topic duplicates"),
      });
    }
  };

  if (!formattedTracker) return null;

  return (
    <ConfirmModal
      id="duplicate-tracker"
      type="success"
      title={t("component.modal.duplicate_tracker.title")}
      className={styles.wrapper}
    >
      <Form onSubmit={onSubmit} className={styles.form}>
        <SelectedTracker tracker={formattedTracker} />
        <div className={styles.settingsWrapper}>
          <span className={styles.settingsTitle}>
            {t("component.modal.duplicate_tracker.label.duplicate_settings")}
          </span>
          <div className={styles.settings}>
            <div className={styles.setting}>
              <Label leftText="Location" className={styles.label} />
              <LocationsDropdown
                keywordsDataSource={keywordsDataSource}
                locationId={locationId}
                setLocationId={setLocationId}
              />
            </div>
            <div className={styles.setting}>
              <Label leftText="Language" className={styles.label} />
              <LanguagesDropdown
                languageId={languageId}
                keywordsDataSource={keywordsDataSource}
                setLanguageId={setLanguageId}
              />
            </div>
            <div>
              <Button
                onClick={() => addConfiguration(locationId, languageId)}
                disabled={!isAddAvailable}
                className={styles.button}
              >
                {t(
                  "component.modal.duplicate_tracker.button.add_configuration",
                )}
              </Button>
            </div>
          </div>
        </div>
        {hasConfigurations && (
          <div className={styles.configurations}>
            {configurations.map((configuration, index) => (
              <DuplicateTrackerConfiguration
                ref={configurationRef}
                key={`${locationId}-${languageId}-${index}`}
                configuration={configuration}
                removeConfigurationHandler={removeConfiguration}
              />
            ))}
          </div>
        )}
        <div className={styles.keywordsSettings}>
          <Switch
            onColor={GREEN}
            checked={shouldCopyKeywords}
            offColor={GRAY}
            disabled={!isSubmitAvailable}
            onChange={updateShouldCopyKeywords}
          />
          <span>
            {t("component.modal.duplicate_tracker.label.should_copy_keywords")}
          </span>
        </div>
        <div className={styles.submit}>
          <Button type="submit" disabled={!isSubmitAvailable}>
            {isLoading && <Loader />}
            {t("component.modal.duplicate_tracker.button.submit")}
          </Button>
        </div>
      </Form>
    </ConfirmModal>
  );
};
