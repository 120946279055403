import { FC, useMemo } from "react";
import cx from "classnames";
import capitalize from "lodash/capitalize";
import { useTranslation } from "react-i18next";

import styles from "./ConfirmModal.module.scss";
import { Button } from "src/components";
import * as icons from "src/assets/icons";
import {
  useModal,
  useKeyboardListener,
  useElementOutsideClick,
} from "src/hooks";

// Inner imports
import { ConfirmModalProps } from "./types";

export const ConfirmModal: FC<ConfirmModalProps> = ({
  id,
  title,
  children,
  isLoading,
  cancelButton,
  acceptButton,
  extraButtons,
  size = "large",
  className = "",
  buttonsWrapperClassName = "",
}) => {
  const { t } = useTranslation();

  const { closeModal } = useModal();

  const ref = useElementOutsideClick(() => closeModal(id));

  useKeyboardListener({
    key: "Escape",
    type: "keydown",
    callback: () => !isLoading && closeModal(id),
  });

  const hasModalButtons = useMemo<boolean>(
    () => Boolean(cancelButton || acceptButton || extraButtons?.length),
    [acceptButton, cancelButton, extraButtons],
  );

  const Title = useMemo<JSX.Element | null | undefined>(() => {
    if (!title) return null;

    if (typeof title === "string")
      return (
        <div className={cx(styles.title, styles[`title${capitalize(size)}`])}>
          <span>{title}</span>
        </div>
      );

    return title;
  }, [size, title]);

  return (
    <div className={cx(styles.wrapper, className)} ref={ref}>
      <div className={styles.header}>
        {Title}
        <Button
          onClick={() => closeModal(id)}
          disabled={isLoading}
          className={styles.closeButton}
          buttonSize="small"
          buttonStyle="outlined"
        >
          <icons.Cross />
        </Button>
      </div>
      <div className={styles.content}>{children}</div>
      {hasModalButtons && (
        <div className={cx(styles.buttonsWrapper, buttonsWrapperClassName)}>
          {cancelButton && (
            <Button
              {...cancelButton}
              type="button"
              className={cx(styles.cancelButton, cancelButton.className || "")}
              buttonSize={cancelButton.buttonSize}
              buttonStyle={cancelButton.buttonStyle || "outlined"}
              disabled={isLoading || cancelButton.disabled}
            >
              {cancelButton.text ?? t("tp_modal_wrapper_cancel_button")}
            </Button>
          )}
          {acceptButton && (
            <Button
              {...acceptButton}
              autoFocus
              type="button"
              className={cx(styles.acceptButton, acceptButton?.className || "")}
              onClick={acceptButton.onClick}
              buttonSize={acceptButton.buttonSize}
              buttonStyle={acceptButton.buttonStyle}
              disabled={isLoading || acceptButton.disabled}
            >
              {isLoading ? (
                <icons.Loader className={styles.loader} />
              ) : (
                acceptButton.text ?? t("tp_modal_wrapper_accept_button")
              )}
            </Button>
          )}
          {Boolean(extraButtons?.length) && (
            <div className={styles.extraButtonsWrapper}>
              {extraButtons?.map((button, index) => {
                const Icon = button.icon && icons[button.icon];

                return (
                  <Button
                    {...button}
                    type="button"
                    key={`${button.text}-${index}`}
                    className={cx(styles.extraButton, button.className)}
                    onClick={button.onClick}
                    style={{
                      ...button.style,
                      backgroundColor: button.color ?? "",
                    }}
                    disabled={isLoading || button.disabled}
                  >
                    {!!Icon && <Icon />}
                    {button.text ?? ""}
                  </Button>
                );
              })}
            </div>
          )}
        </div>
      )}
    </div>
  );
};
