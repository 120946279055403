import { useMemo, useState } from "react";
import { scaleLinear, scaleSymlog } from "d3-scale";

import { getPercentageValue } from "src/utils";

// Inner imports
import * as utils from "../utils";
import * as constants from "../constants";
import {
  ChartItem,
  ChartLabel,
  LineChartEvent,
  LineChartScale,
  FormattedChartItem,
} from "../types";

type Props = {
  data: ChartItem[];
  labels: ChartLabel[];
  isTrendLineShown: boolean;
  formattedData: FormattedChartItem[];
  trendLineData: Record<number, number>;
  increaseYAxisWidthInPercentage: number;
  selectedEvent: LineChartEvent | undefined;
};

type ReturnProps = {
  xDomain: [number, number];
  yDomain: [number, number];
  yAxisScale: LineChartScale;
  setYAxisScale: (scale: LineChartScale) => void;
  yAxisScaleFunction: Function;
};

export const useLineChartAxis = ({
  data,
  labels,
  trendLineData,
  formattedData,
  selectedEvent,
  isTrendLineShown,
  increaseYAxisWidthInPercentage,
}: Props): ReturnProps => {
  const [yAxisScale, setYAxisScale] = useState<LineChartScale>("linear");

  const [minTrendLineValue, maxTrendLineValue] = useMemo<[number, number]>(
    () => utils.getTrendLineMinMaxValues(trendLineData),
    [trendLineData],
  );

  const [minDataValue, maxDataValue] = useMemo<[number, number]>(() => {
    const dataValues = new Set<number>();

    for (const dataItem of data) {
      const values = Object.entries(dataItem.values);

      for (const [trackerId, value] of values) {
        const isLabelIncluded = labels.some(({ value }) => value === trackerId);

        if (isLabelIncluded) dataValues.add(value);
      }
    }

    const [minValue, maxValue] = [
      Math.min(...dataValues, minTrendLineValue),
      Math.max(...dataValues, maxTrendLineValue),
    ];

    return [minValue, maxValue];
  }, [data, labels, minTrendLineValue, maxTrendLineValue]);

  const yAxisTicks = useMemo<number[]>(() => {
    let [minValue, maxValue] = [0, 0];

    if (isTrendLineShown) {
      if (minTrendLineValue < minValue) minValue = minTrendLineValue;
      if (maxTrendLineValue > maxValue) maxValue = maxTrendLineValue;
    }

    if (minDataValue < minValue) minValue = minDataValue;
    if (maxDataValue > maxValue) maxValue = maxDataValue;

    const [minValueRemainder, maxValueRemainder] = [
      minValue % 20,
      maxValue % 20,
    ];

    const [minTick, maxTick] = [
      minValueRemainder
        ? minValue > 0
          ? minValue - minValueRemainder
          : minValue - (20 + minValueRemainder)
        : minValue,
      maxValueRemainder
        ? maxValue > 0
          ? maxValue - maxValueRemainder + 20
          : maxValue - maxValueRemainder
        : maxValue,
    ];

    const ticks: number[] = [];

    const tickStep = utils.getTickStep(minTick, maxTick);

    for (let i = minTick; i <= maxTick; i += tickStep) ticks.push(i);

    return ticks;
  }, [
    minDataValue,
    maxDataValue,
    isTrendLineShown,
    minTrendLineValue,
    maxTrendLineValue,
  ]);

  const xDomain = useMemo<[number, number]>(() => {
    if (!formattedData.length) return [0, 0];

    const { minValue, maxValue } = formattedData.reduce(
      (acc, { date }) => {
        if (date < acc?.minValue) acc.minValue = date;
        if (date > acc?.maxValue) acc.maxValue = date;
        return acc;
      },
      { minValue: formattedData[0]?.date || 0, maxValue: 0 },
    );

    return [minValue, maxValue];
  }, [formattedData]);

  const yDomain = useMemo<[number, number]>(() => {
    if (yAxisScale === "log" && minDataValue < maxDataValue)
      return [minDataValue, maxDataValue];

    const [minValue, maxValue] = [
      yAxisTicks[0] || 0,
      yAxisTicks[yAxisTicks.length - 1] || 0,
    ];

    if (!minValue && !maxValue && selectedEvent)
      return [
        -constants.LINE_CHART_TICK_MIN_STEP,
        constants.LINE_CHART_TICK_MIN_STEP,
      ];

    const [domainMinValue, domainMaxValue] = [
      minValue,
      maxValue + getPercentageValue(maxValue, increaseYAxisWidthInPercentage),
    ];

    return [domainMinValue, domainMaxValue];
  }, [
    yAxisTicks,
    yAxisScale,
    minDataValue,
    maxDataValue,
    selectedEvent,
    increaseYAxisWidthInPercentage,
  ]);

  const yAxisScaleFunction = useMemo<Function>(() => {
    switch (yAxisScale) {
      case "log":
        return scaleSymlog;
      case "linear":
      default:
        return scaleLinear;
    }
  }, [yAxisScale]);

  return {
    xDomain,
    yDomain,
    yAxisScale,
    setYAxisScale,
    yAxisScaleFunction,
  };
};
