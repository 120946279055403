import { FC, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useParams, Link } from "react-router-dom";

import styles from "./VerificationInfo.module.scss";
import { Button } from "src/components";
import { Loader } from "src/assets/icons";
import { showToastNotification } from "src/utils";
import { selectWhiteLabel } from "src/store/selectors";
import { sendVerifyEmail } from "src/store/user/userApi";

const VerificationInfo: FC = () => {
  const { t } = useTranslation();

  const whiteLabel = useSelector(selectWhiteLabel);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const params = useParams<UrlParams>();

  const { userId } = params || {};

  const onResendConfirmationLink = async () => {
    setIsLoading(true);

    if (userId) {
      try {
        await sendVerifyEmail(userId, whiteLabel);

        showToastNotification({
          type: "success",
          text: t(
            "authentication.email_confirmation.status.success.email_sent",
          ),
        });
      } catch (err) {
        console.error(err);
      }
    } else {
      showToastNotification({
        type: "error",
        text: t("common.error.server_error"),
      });
    }

    setIsLoading(false);
  };

  return (
    <div className={styles.verificationInfo}>
      <span className={styles.title}>
        {t("authentication.email_confirmation.title")}
      </span>
      <span className={styles.subTitle}>
        {t("authentication.email_confirmation.subtitle")}
      </span>
      <Button
        className={styles.button}
        onClick={() => onResendConfirmationLink()}
        tabIndex={1}
        disabled={isLoading}
      >
        {isLoading ? (
          <Loader className={styles.loader} />
        ) : (
          t("authentication.email_confirmation.button.resend_email")
        )}
      </Button>
      <Link to="/login" className={styles.link}>
        {t("authentication.email_confirmation.button.redirect_to_login")}
      </Link>
    </div>
  );
};

export default VerificationInfo;
