import { FC, FormHTMLAttributes, FormEvent } from "react";

import styles from "./Form.module.scss";

type Props = FormHTMLAttributes<HTMLFormElement> & {
  onSubmit: () => void;
  disabled?: boolean;
};

export const Form: FC<Props> = ({
  onSubmit: submitHandler,
  children,
  disabled = false,
  ...props
}) => {
  const onSubmit = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    event.stopPropagation();

    if (disabled) return;

    submitHandler();
  };

  return (
    <form onSubmit={onSubmit} {...props}>
      {children}
      <button className={styles.submit} type="submit" />
    </form>
  );
};
