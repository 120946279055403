const DASHBOARD_GRID_DEFAULT_WIDGET_POSITION = {
  large: {
    xAxis: 0,
    yAxis: 0,
    height: 1,
    width: 2,
  },
  medium: {
    xAxis: 0,
    yAxis: 0,
    height: 1,
    width: 1,
  },
  small: {
    xAxis: 0,
    yAxis: 0,
    height: 1,
    width: 1,
  },
};

const DASHBOARD_GRID_CELLS_PER_ROW = {
  large: 4,
  medium: 2,
  small: 1,
};

export const calculateWidgetLayoutPosition = ({
  widgetId,
  layout,
  layoutSize,
}: {
  widgetId: string;
  layout?: Dashboard.Data["layouts"][LayoutSize];
  layoutSize?: LayoutSize;
}) => {
  if (!layout || !layoutSize)
    return { ...DASHBOARD_GRID_DEFAULT_WIDGET_POSITION, widgetId };

  const existingWidgetPositions = layout.find(
    ({ widgetId: id }) => id === widgetId,
  );

  if (existingWidgetPositions) return existingWidgetPositions;

  const {
    yAxis: maxY,
    height: maxHeight,
    width: maxWidth,
  } = layout.reduce(
    (acc, gridItem) => {
      if (acc.xAxis < gridItem.xAxis) acc.xAxis = gridItem.xAxis;

      if (acc.yAxis < gridItem.yAxis) acc.yAxis = gridItem.yAxis;

      if (acc.width < gridItem.width) acc.width = gridItem.width;

      return acc;
    },
    { ...DASHBOARD_GRID_DEFAULT_WIDGET_POSITION[layoutSize] },
  );

  const calculateXAndY = (): { xAxis: number; yAxis: number } => {
    const sortedLayoutItems = [...layout].sort(
      (itemA, itemB) => itemB.yAxis - itemA.yAxis || itemB.xAxis - itemA.xAxis,
    );

    const lastItem = sortedLayoutItems[0];

    if (!lastItem) return { xAxis: 0, yAxis: 0 };

    if (
      lastItem.xAxis + 1 + maxWidth >
      DASHBOARD_GRID_CELLS_PER_ROW[layoutSize]
    )
      return {
        xAxis: 0,
        yAxis: maxY + 1,
      };

    return {
      xAxis: lastItem.xAxis + lastItem.width,
      yAxis: maxY,
    };
  };

  const { xAxis: calculatedX, yAxis: calculatedY } = calculateXAndY();

  return {
    widgetId,
    height: maxHeight,
    width: maxWidth,
    xAxis: calculatedX,
    yAxis: calculatedY,
  };
};
