import omit from "lodash/omit";
import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";

import { selectUserId } from "../user/userSelector";
import { selectCompanyId } from "../company/companySelector";
import { selectLocationById } from "../locations/locationsSelector";
import { onStateFulfilled, onStatePending, onStateRejected } from "../utils";

// Inner imports
import * as api from "./searchesApi";

export const searchesAdapter = createEntityAdapter<Search.Data>({
  sortComparer: (a, b) => a.createdAt.localeCompare(b.createdAt),
});

const initialState = searchesAdapter.getInitialState<Store.InitialState>({
  status: "idle",
  error: null,
});

export const fetchAllSearches = createAsyncThunk<
  Search.Data[],
  Company.Data["id"]
>("searches/fetch-by-company-id", api.getAllSearches);

export const fetchTrackerSearches = createAsyncThunk<
  Search.Data[],
  Array<Search.Data["id"]>
>("searches/fetch-by-ids", api.getTrackerSearches);

export const fetchSearchById = createAsyncThunk<Search.Data, Search.Data["id"]>(
  "searches/fetch-by-id",
  (searchId) => api.getSearchById(searchId),
);

export const createSearch = createAsyncThunk<
  Search.Data,
  Search.CreationData,
  { state: Store.RootState }
>("searches/create-one", async (payload, { getState }) => {
  const state = getState();
  const companyId = selectCompanyId(state);
  const userId = selectUserId(state);
  const location = selectLocationById(state, payload.locationId);

  const _payload = omit(
    { ...payload, authorId: userId, companyId },
    "id",
    "status",
  );

  const newSearchId = await api.createSearch(_payload, location);

  return api.getSearchById(newSearchId);
});

export const createSearches = createAsyncThunk<
  Search.Data[],
  Search.CreationData[],
  { state: Store.RootState }
>("searches/create-many", async (payload, { getState }) => {
  const state = getState();
  const companyId = selectCompanyId(state);
  const userId = selectUserId(state);

  const updatedPayload: Omit<
    Search.Data,
    "id" | "createdAt" | "updatedAt" | "status"
  >[] = [];

  for (const search of payload) {
    const formattedSearch = omit(
      { ...search, authorId: userId, companyId },
      "id",
      "status",
    );

    updatedPayload.push(formattedSearch);
  }

  const newSearchIds = await api.createSearches(updatedPayload);

  return api.getSearchesByIds(newSearchIds);
});

export const updateSearch = createAsyncThunk<
  Store.UpdateEntity<Search.Data>,
  Store.UpdateEntity<Search.Data>
>("searches/update-one", api.updateSearch);

export const updateSearches = createAsyncThunk<
  Store.UpdateEntity<Search.Data>[],
  Store.UpdateEntity<Search.Data>[]
>("searches/update-many", api.updateSearches);

export const updateSearchesByAuthorId = createAsyncThunk<
  Store.UpdateEntity<Search.Data>[],
  {
    changes: Store.UpdateEntity<Search.Data>["changes"];
    authorId: Search.Data["authorId"];
  },
  { state: Store.RootState }
>("searches/update-by-author-id", (payload, { getState }) => {
  const state = getState();

  const companyId = selectCompanyId(state);

  return api.updateSearchesByAuthorId(payload, companyId);
});

export const removeSearch = createAsyncThunk<
  Search.Data["id"],
  Search.Data["id"]
>("searches/remove-one", async (payload) => {
  await api.deleteSearch(payload);

  return payload;
});

export const removeSearches = createAsyncThunk<
  Search.Data["id"][],
  Search.Data["id"][]
>("searches/remove-many", async (payload) => {
  await api.deleteSearches(payload);

  return payload;
});

const searchesSlice = createSlice({
  name: "searches",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAllSearches.pending, onStatePending);
    builder.addCase(fetchAllSearches.rejected, onStateRejected);
    builder.addCase(fetchAllSearches.fulfilled, (...args) => {
      searchesAdapter.addMany(...args);
      onStateFulfilled(...args);
    });

    builder.addCase(fetchTrackerSearches.fulfilled, searchesAdapter.addMany);

    builder.addCase(createSearch.fulfilled, (...args) => {
      searchesAdapter.addOne(...args);
      onStateFulfilled(...args);
    });

    builder.addCase(createSearches.fulfilled, searchesAdapter.addMany);

    builder.addCase(updateSearch.pending, onStatePending);
    builder.addCase(updateSearch.rejected, onStateRejected);
    builder.addCase(updateSearch.fulfilled, (...args) => {
      searchesAdapter.updateOne(...args);
      onStateFulfilled(...args);
    });

    builder.addCase(updateSearches.pending, onStatePending);
    builder.addCase(updateSearches.rejected, onStateRejected);
    builder.addCase(updateSearches.fulfilled, (...args) => {
      searchesAdapter.updateMany(...args);
      onStateFulfilled(...args);
    });

    builder.addCase(
      updateSearchesByAuthorId.fulfilled,
      searchesAdapter.updateMany,
    );

    builder.addCase(removeSearch.fulfilled, searchesAdapter.removeOne);

    builder.addCase(removeSearches.fulfilled, searchesAdapter.removeMany);
  },
});

export default searchesSlice.reducer;
