import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";

import styles from "./TableLoader.module.scss";
import { Preloader } from "src/components";

type Props = {
  isKeywordsLoading: boolean;
  isKeywordsPending: boolean;
};

export const TableLoader: FC<Props> = ({
  isKeywordsLoading,
  isKeywordsPending,
}) => {
  const { t } = useTranslation();

  const text = useMemo<string>(() => {
    switch (true) {
      case isKeywordsPending:
        return t("component.keywords_table.loader.process_keywords");
      case isKeywordsLoading:
        return t("component.keywords_table.loader.download_keywords");
      default:
        return "";
    }
  }, [isKeywordsLoading, isKeywordsPending, t]);

  return (
    <div className={styles.wrapper}>
      <Preloader type="bar" text={text} />
    </div>
  );
};
