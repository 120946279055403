import { FC, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { loadStripe } from "@stripe/stripe-js";
import {
  EmbeddedCheckout,
  EmbeddedCheckoutProvider,
} from "@stripe/react-stripe-js";

import styles from "./SubscriptionPlanPaymentSidebar.module.scss";
import { Preloader, Sidebar } from "src/components";
import { roundToNearest, showToastNotification } from "src/utils";
import { createStripeCheckoutToken } from "src/store/subscriptionPlans/subscriptionPlansApi";
import {
  selectCompany,
  selectUserId,
  selectTrackersLimit,
  selectCompanyTrackers,
  selectCompanySubscriptionPlan,
} from "src/store/selectors";
import {
  SUBSCRIPTION_PLAN_QUANTITY_MARGIN,
  SUBSCRIPTION_PLAN_MIN_TRACKERS_COUNT,
} from "../SubscriptionPlansSidebar/constants";

const { REACT_APP_STRIPE_KEY = "" } = process.env;

const stripePromise = loadStripe(REACT_APP_STRIPE_KEY);

type Props = {
  isOpened: boolean;
  setIsOpened: (value: boolean) => void;
  billingPeriod: SubscriptionPlan.BillingPeriod;
  subscriptionPlanId: SubscriptionPlan.Data["id"];
  setSubscriptionPlanId?: (value: SubscriptionPlan.Data["id"]) => void;
};

export const SubscriptionPlanPaymentSidebar: FC<Props> = ({
  isOpened,
  setIsOpened,
  billingPeriod,
  subscriptionPlanId,
  setSubscriptionPlanId,
}) => {
  const { t } = useTranslation();

  const userId = useSelector(selectUserId);

  const company = useSelector(selectCompany);

  const trackers = useSelector(selectCompanyTrackers);

  const { trackersLimit } = useSelector(selectTrackersLimit);

  const companySubscriptionPlan = useSelector(selectCompanySubscriptionPlan);

  const [checkoutToken, setCheckoutToken] = useState<string>("");

  const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>("idle");

  const quantity = useMemo<number>(() => {
    let maxQuantity = Math.max(
      SUBSCRIPTION_PLAN_MIN_TRACKERS_COUNT,
      trackers.length,
    );

    if (
      !companySubscriptionPlan ||
      (companySubscriptionPlan.category !== "trial" &&
        companySubscriptionPlan.category !== "free")
    ) {
      maxQuantity =
        Math.max(
          trackersLimit,
          trackers.length,
          SUBSCRIPTION_PLAN_MIN_TRACKERS_COUNT,
        ) + SUBSCRIPTION_PLAN_QUANTITY_MARGIN;
    }

    return roundToNearest(maxQuantity, SUBSCRIPTION_PLAN_MIN_TRACKERS_COUNT);
  }, [companySubscriptionPlan, trackers.length, trackersLimit]);

  useEffect(() => {
    if (!userId || loadingStatus !== "idle" || !subscriptionPlanId) return;

    setLoadingStatus("loading");

    createStripeCheckoutToken({
      userId,
      quantity,
      billingPeriod,
      subscriptionPlanId,
    })
      .then((value) => {
        setCheckoutToken(value);

        setLoadingStatus("succeeded");
      })
      .catch((error) => {
        console.error(error);

        showToastNotification({
          type: "error",
          text: t("common.error.server_error"),
        });

        setLoadingStatus("failed");

        setIsOpened(false);

        setSubscriptionPlanId?.("");
      });
  }, [
    t,
    userId,
    quantity,
    company.id,
    setIsOpened,
    billingPeriod,
    loadingStatus,
    subscriptionPlanId,
    setSubscriptionPlanId,
  ]);

  if (loadingStatus === "loading")
    return (
      <Sidebar isSidebarOpen={isOpened} setIsSidebarOpen={setIsOpened}>
        <div className={styles.wrapper}>
          <Preloader />
        </div>
      </Sidebar>
    );

  if (!checkoutToken) return null;

  return (
    <Sidebar isSidebarOpen={isOpened} setIsSidebarOpen={setIsOpened}>
      <div className={styles.wrapper}>
        <EmbeddedCheckoutProvider
          stripe={stripePromise}
          options={{ clientSecret: checkoutToken }}
        >
          <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>
      </div>
    </Sidebar>
  );
};
