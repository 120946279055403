import * as yup from "yup";

import {
  WIDGET_IDS,
  SUBSCRIPTION_PLAN_CATEGORIES,
  SEARCH_KEYWORDS_DATA_SOURCES,
} from "src/constants";

type YupBooleanType = yup.BooleanSchema<
  boolean,
  Record<string, any>,
  boolean,
  "d"
>;

const availableWidgetsMap = WIDGET_IDS.reduce<
  Widget.WidgetsMap<YupBooleanType>
>((acc, item) => {
  acc[item] = yup.boolean().default(false).required();

  return acc;
}, {} as Widget.WidgetsMap<YupBooleanType>);

const priceSchema = yup
  .object({
    monthly: yup.number().min(0).default(0),
    yearly: yup.number().min(0).default(0),
  })
  .required()
  .noUnknown();

const limitsSchema = yup
  .object({
    team: yup.number().min(0).default(0),
    admins: yup.number().min(0).default(0),
    events: yup.number().min(0).default(0),
    searches: yup.number().min(0).default(0),
    trackers: yup.number().min(0).default(0),
    dashboards: yup.number().min(0).default(0),
    trackerSearches: yup.number().min(0).default(0),
    trackersCollections: yup.number().min(0).default(0),
    keywordsDataInMonths: yup.number().min(0).default(0),
    autoUpdatedDashboards: yup.number().min(0).default(0),
    trackersCollectionTrackers: yup.number().min(0).default(0),
  })
  .required()
  .noUnknown();

const subscriptionPlanKeywordsDataSourcesSchema = yup
  .string<Search.KeywordsDataSource>()
  .oneOf([...SEARCH_KEYWORDS_DATA_SOURCES])
  .required();

export const subscriptionPlanSchema = yup
  .object({
    name: yup.string().default(""),
    category: yup
      .string<SubscriptionPlan.Data["category"]>()
      .oneOf([...SUBSCRIPTION_PLAN_CATEGORIES])
      .required(),
    isSelectable: yup.boolean().default(false),
    isReadOnly: yup.boolean().default(true),
    isEnterprise: yup.boolean().default(false),

    price: priceSchema,

    limits: limitsSchema,

    isDataUpdateAllowed: yup.boolean().default(false),
    keywordsDataSources: yup
      .array(subscriptionPlanKeywordsDataSourcesSchema)
      .required(),

    hideUnavailableWidgets: yup.boolean().default(true),
    availableWidgetsMap: yup.object(availableWidgetsMap),
  })
  .noUnknown()
  .required();

export const stripeCheckoutTokenSchema = yup.string().required();

export const stripePortalTokenSchema = yup.string().required();
