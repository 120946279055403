export const DASHBOARD_DATE_RANGE_CATEGORIES = ["default", "custom"] as const;

export const DASHBOARD_DATE_RANGE_DEFAULT_CATEGORY: DashboardDateRange.Category =
  "default";

export const DASHBOARD_DATE_RANGE_TYPES = [
  "all",
  "12m",
  "6m",
  "3m",
  "1m",
] as const;

export const DASHBOARD_DATE_RANGE_LABEL: Record<
  Exclude<DashboardDateRange.Data["type"], null>,
  string
> = {
  all: "dashboard_date_range.label.all",
  "12m": "dashboard_date_range.label.last_twelve_month",
  "6m": "dashboard_date_range.label.last_six_month",
  "3m": "dashboard_date_range.label.last_three_month",
  "1m": "dashboard_date_range.label.last_one_month",
};
