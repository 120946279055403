import {
  FC,
  useMemo,
  useEffect,
  useCallback,
  ChangeEvent,
  KeyboardEvent,
} from "react";
import cx from "classnames";
import isString from "lodash/isString";
import { useTranslation } from "react-i18next";

import styles from "./Textarea.module.scss";
import { limitString } from "src/utils";
import { useElementFocus } from "src/hooks";

// Inner imports
import { TextareaProps } from "./types";
import { adjustTextareaHeightAndFontSize } from "./utils";

export const Textarea: FC<TextareaProps> = ({
  title,
  value,
  flexible,
  wordLimit,
  className,
  changeHandler,
  characterLimit,
  textareaClassName,
  isLimitShown = true,
  ...rest
}: TextareaProps) => {
  const { t } = useTranslation();

  const [ref, setFocus] = useElementFocus<HTMLTextAreaElement>();

  const valueWords = useMemo<string[]>(() => {
    if (!value || !isString(value)) return [];

    return value.split(" ").filter(Boolean);
  }, [value]);

  const hasCharacterLimit = useMemo<boolean>(
    () => Boolean(isLimitShown && characterLimit && isString(value)),
    [isLimitShown, characterLimit, value],
  );

  const hasWordLimit = useMemo<boolean>(
    () => Boolean(isLimitShown && wordLimit && isString(value)),
    [isLimitShown, wordLimit, value],
  );

  const isCharacterLimitReached = useMemo<boolean>(() => {
    if (!characterLimit || !isString(value)) return false;

    return value.length >= characterLimit;
  }, [characterLimit, value]);

  const isWordLimitReached = useMemo<boolean>(() => {
    if (!wordLimit || !isString(value)) return false;

    return valueWords.length >= wordLimit;
  }, [value, valueWords.length, wordLimit]);

  const characterLimitText = useMemo<string>(() => {
    if (!characterLimit || !isString(value)) return "";

    return t("component.textarea.label.character_limit", {
      length: value.length,
      limit: characterLimit,
    });
  }, [characterLimit, value, t]);

  const wordLimitText = useMemo<string>(() => {
    if (!wordLimit || !isString(value)) return "";

    return t("component.textarea.label.word_limit", {
      limit: wordLimit,
      length: valueWords.length,
    });
  }, [wordLimit, value, t, valueWords.length]);

  useEffect(() => setFocus(), [setFocus]);

  const calculateFontSize = useCallback(
    (element: HTMLTextAreaElement | null) => {
      if (!element || !isString(value) || !flexible) return;

      const { defaultFontSize, minFontSize, defaultHeight } = flexible;

      adjustTextareaHeightAndFontSize({
        value,
        element,
        minFontSize,
        defaultHeight,
        defaultFontSize,
      });
    },
    [value, flexible],
  );

  const refCallback = useCallback(
    (element: HTMLTextAreaElement | null): void => {
      if (!element) return;

      ref.current = element;

      calculateFontSize(element);
    },
    [calculateFontSize, ref],
  );

  const onKeyDown = (event: KeyboardEvent<HTMLTextAreaElement>): void => {
    if (event.key === "Enter" && !event.shiftKey) event.preventDefault();
  };

  const onKeyUp = (event: KeyboardEvent<HTMLTextAreaElement>): void => {
    if (event.key !== "Enter" || event.shiftKey) return;

    const element = event.target as HTMLTextAreaElement;

    const formElement = element.closest("form");

    if (formElement) formElement.requestSubmit();
  };

  const onChange = (event: ChangeEvent<HTMLTextAreaElement>): void => {
    const formattedValue = limitString(event.target.value, {
      wordLimit,
      characterLimit,
    });

    changeHandler?.(formattedValue);
  };

  return (
    <div className={cx(styles.wrapper, className)}>
      <textarea
        ref={refCallback}
        value={value}
        title={title}
        onKeyUp={onKeyUp}
        onChange={onChange}
        onKeyDown={onKeyDown}
        className={cx(styles.textarea, textareaClassName)}
        {...rest}
      />
      {hasCharacterLimit && isCharacterLimitReached && (
        <div className={styles.limit}>
          <span>{characterLimitText}</span>
        </div>
      )}
      {hasWordLimit && isWordLimitReached && (
        <div className={styles.limit}>
          <span>{wordLimitText}</span>
        </div>
      )}
    </div>
  );
};
