import { FC, useMemo } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import styles from "./DashboardTrackersAlert.module.scss";
import { MenuOption } from "src/components/MenuDropdown/types";
import { Alert, Button, MenuDropdown, Translation } from "src/components";
import {
  selectTrackers,
  selectApplicationInfo,
  selectTrackersCollectionById,
} from "src/store/selectors";

type Props = {
  isReadOnly: boolean;
  trackersCollectionId: TrackersCollection.Data["id"];
  editTrackerHandler: (value: Tracker.Data) => void;
};

export const DashboardTrackersAlert: FC<Props> = ({
  isReadOnly,
  editTrackerHandler,
  trackersCollectionId,
}) => {
  const { t } = useTranslation();

  const trackers = useSelector(selectTrackers);

  const trackersCollection = useSelector((state: Store.RootState) =>
    selectTrackersCollectionById(state, trackersCollectionId),
  );

  const { trackerIdsWithPartialData } = useSelector(selectApplicationInfo);

  const editTrackerOptions = useMemo<MenuOption[]>(() => {
    const trackerOptions = new Set<MenuOption>();

    if (!trackersCollection) return [...trackerOptions];

    for (const trackerId of trackersCollection.trackerIds) {
      const isTrackerHasBrokenSearch =
        trackerIdsWithPartialData.includes(trackerId);

      if (!isTrackerHasBrokenSearch) continue;

      const tracker = trackers.find(({ id }) => id === trackerId);

      if (!tracker) continue;

      trackerOptions.add({
        label: tracker.name,
        onClick: () => editTrackerHandler(tracker),
      });
    }

    return [...trackerOptions];
  }, [
    trackers,
    editTrackerHandler,
    trackersCollection,
    trackerIdsWithPartialData,
  ]);

  const isTrackersWithPartialDataWarningShown = useMemo<boolean>(() => {
    if (isReadOnly) return false;

    return Boolean(editTrackerOptions.length);
  }, [isReadOnly, editTrackerOptions.length]);

  const TrackersPartialDataButton = useMemo<JSX.Element>(() => {
    if (!editTrackerOptions.length) return <span />;

    if (editTrackerOptions.length === 1) {
      const trackerOption = editTrackerOptions[0];

      if (!trackerOption) return <span />;

      return (
        <Button
          buttonSize="small"
          buttonStyle="transparent"
          className={styles.button}
          onClick={trackerOption.onClick}
        >
          <span>{trackerOption.label}</span>
        </Button>
      );
    }

    return (
      <MenuDropdown
        options={editTrackerOptions}
        buttonClassName={styles.trackersButton}
        buttonContent={t("page.dashboard.button.edit_incomplete_tracker")}
        dropdownDirection={{ y: "down", x: "left" }}
      />
    );
  }, [editTrackerOptions, t]);

  if (!isTrackersWithPartialDataWarningShown) return null;

  return (
    <div className={styles.wrapper}>
      <Alert
        type="caution"
        message={
          <div className={styles.alertContent}>
            <Translation
              i18nKey="page.dashboard.status.warning.incomplete_trackers"
              values={{ count: editTrackerOptions.length }}
              components={{ button: TrackersPartialDataButton }}
            />
          </div>
        }
      />
    </div>
  );
};
