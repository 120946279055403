import { useEffect, useMemo, useState } from "react";

const WIDTH_BREAKPOINTS = {
  sm: 768,
  md: 991,
  lg: 1200,
  xl: 1366,
};

type ReturnType = {
  windowWidth: number;
  windowHeight: number;
  isMobileView: boolean;
  isTabletView: boolean;
  isDesktopView: boolean;
};

export const useWindowSize = (): ReturnType => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  useEffect(() => {
    function handleResize(): void {
      setWindowWidth(window.innerWidth);

      setWindowHeight(window.innerHeight);
    }

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const breakpoints = useMemo(
    () => ({
      isMobileView: windowWidth <= WIDTH_BREAKPOINTS.sm,
      isTabletView: windowWidth <= WIDTH_BREAKPOINTS.md,
      isDesktopView: windowWidth > WIDTH_BREAKPOINTS.md,
    }),
    [windowWidth],
  );

  return {
    ...breakpoints,
    windowHeight,
    windowWidth,
  };
};
