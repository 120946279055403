import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";

import { useAppDispatch } from "src/store";
import { showDevelopmentError } from "src/utils";
import {
  fetchAllLocations,
  fetchAllSubscriptionPlans,
} from "src/store/actions";
import {
  selectLocationsStatus,
  selectSubscriptionPlansStatus,
} from "src/store/selectors";

export const useLocationsLoader = (): boolean => {
  const dispatch = useAppDispatch();

  const locationsStatus = useSelector(selectLocationsStatus);

  useEffect(() => {
    if (locationsStatus !== "idle") return;

    dispatch(fetchAllLocations())
      .unwrap()
      .catch((error) => {
        const errorTitle = "ERROR IN ALL LOCATIONS FETCHING";
        showDevelopmentError({ additionalTexts: [errorTitle], error });
      });
  }, [locationsStatus, dispatch]);

  return useMemo<boolean>(
    () => locationsStatus === "succeeded" || locationsStatus === "failed",
    [locationsStatus],
  );
};

export const useSubscriptionPlansLoader = (): boolean => {
  const dispatch = useAppDispatch();

  const subscriptionPlansStatus = useSelector(selectSubscriptionPlansStatus);

  useEffect(() => {
    if (subscriptionPlansStatus !== "idle") return;

    dispatch(fetchAllSubscriptionPlans())
      .unwrap()
      .catch((error) => {
        const errorTitle = "ERROR IN ALL SUBSCRIPTION PLANS FETCHING";
        showDevelopmentError({ additionalTexts: [errorTitle], error });
      });
  }, [subscriptionPlansStatus, dispatch]);

  return useMemo<boolean>(
    () =>
      subscriptionPlansStatus === "succeeded" ||
      subscriptionPlansStatus === "failed",
    [subscriptionPlansStatus],
  );
};
