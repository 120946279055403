import { FC, memo, useCallback } from "react";
import { useTranslation } from "react-i18next";

import styles from "./WidgetViewButtons.module.scss";
import { Tooltip } from "src/components";

// Inner imports
import { WidgetViewButton } from "./components";
import { WIDGET_VIEW_BUTTONS_TOOLTIPS } from "./constants";

type Props = {
  widgetViews: Widget.ChartView[];
  viewIndex: number;
  setViewIndex: (value: number) => void;
};

export const WidgetViewButtons: FC<Props> = memo(
  ({ widgetViews, viewIndex, setViewIndex }) => {
    const { t } = useTranslation();

    const getChartTooltip = useCallback(
      ({ type, subType = "" }: Widget.ChartView): string => {
        const translationKey =
          WIDGET_VIEW_BUTTONS_TOOLTIPS[type][subType] ||
          WIDGET_VIEW_BUTTONS_TOOLTIPS[type].default ||
          "";

        return t(translationKey);
      },
      [t],
    );

    if (widgetViews.length < 2) return null;

    return (
      <div className={styles.widgetViewButtons}>
        {widgetViews.map((chart, index) =>
          index !== viewIndex ? (
            <Tooltip key={chart.id} content={getChartTooltip(chart)}>
              <WidgetViewButton
                key={chart.id}
                className={styles.button}
                icon={chart.buttonIcon}
                number={chart.buttonNumber}
                onButtonClick={() => setViewIndex(index)}
              />
            </Tooltip>
          ) : null,
        )}
      </div>
    );
  },
);
