import DOMPurify from "dompurify";

export const limitString = (
  value: string,
  options: { wordLimit?: number; characterLimit?: number },
): string => {
  const { characterLimit, wordLimit } = options;

  const valueWords = value.split(" ").filter(Boolean);

  const [isCharacterLimitReached, isWordLimitReached] = [
    characterLimit ? value.length >= characterLimit : false,
    wordLimit ? valueWords.length >= wordLimit : false,
  ];

  let formattedValue = value;

  if (isCharacterLimitReached)
    formattedValue = formattedValue.slice(0, characterLimit);

  if (isWordLimitReached)
    formattedValue = formattedValue
      .split(" ")
      .filter(Boolean)
      .slice(0, wordLimit)
      .join(" ");

  return formattedValue;
};

export const stringFilter = (value: string, filter: string) => {
  const [formattedValue, formattedFilter] = [
    removeExtraSpaces(value.toLowerCase()),
    removeExtraSpaces(filter.toLowerCase()),
  ];

  return formattedValue.includes(formattedFilter);
};

export const sanitizeValue = <T extends unknown>(value: T): T => {
  if (typeof value === "string") return DOMPurify.sanitize(value) as T;

  if (typeof value === "object") {
    for (const key in value)
      if (value.hasOwnProperty(key)) value[key] = sanitizeValue(value[key]);

    return value;
  }

  return value;
};

export const removeExtraSpaces = (str: string): string =>
  str.replace(/\s+/g, " ").trim();

export const deepRemoveExtraSpaces = <T extends unknown>(value: T): T => {
  if (typeof value === "string") return removeExtraSpaces(value) as T;

  if (typeof value === "object") {
    for (const key in value)
      if (value.hasOwnProperty(key))
        value[key] = deepRemoveExtraSpaces(value[key]);

    return value;
  }

  return value;
};
