import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";

import type { RootState } from "src/store";
import { onStateFulfilled, onStatePending, onStateRejected } from "../utils";
import { readWidgetsInfo } from "./widgetsInfoApi";

export const widgetsInfoAdapter = createEntityAdapter<WidgetsInfo.Data>({
  selectId: (widget) => widget.id,
  sortComparer: (a, b) => a.order - b.order,
});

const initialState = widgetsInfoAdapter.getInitialState<Store.InitialState>({
  status: "idle",
  error: null,
});

export const fetchAllWidgetsInfo = createAsyncThunk<
  WidgetsInfo.Data[],
  void,
  { state: RootState }
>("widgets-info/fetch-all", readWidgetsInfo);

const widgetsInfoSlice = createSlice({
  name: "widgets-info",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAllWidgetsInfo.pending, onStatePending);
    builder.addCase(fetchAllWidgetsInfo.fulfilled, (...args) => {
      widgetsInfoAdapter.setAll(...args);
      onStateFulfilled(...args);
    });
    builder.addCase(fetchAllWidgetsInfo.rejected, onStateRejected);
  },
});

export default widgetsInfoSlice.reducer;
