import { FC, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import styles from "./BillingDetailsForm.module.scss";
import { Button } from "src/components";
import { Loader } from "src/assets/icons";
import { showToastNotification } from "src/utils";
import { selectCompany } from "src/store/selectors";
import { createStripePortalToken } from "src/store/subscriptionPlans/subscriptionPlansApi";

export const BillingDetailsForm: FC = () => {
  const { t } = useTranslation();

  const { id: companyId } = useSelector(selectCompany);

  const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>("idle");

  const isLoading = useMemo<boolean>(
    () => loadingStatus === "loading",
    [loadingStatus],
  );

  const onSubmit = (): void => {
    setLoadingStatus("loading");

    createStripePortalToken(companyId, window.location.href)
      .then((token) => {
        if (!token) return setLoadingStatus("succeeded");

        window.open(token, "_self")?.focus();
      })
      .catch((error) => {
        console.error(error);

        showToastNotification({
          type: "error",
          text: t("network_error"),
        });

        setLoadingStatus("failed");
      });
  };

  return (
    <div className={styles.wrapper}>
      <span>
        {t("page.settings.subscription_plan.label.manage_billing_details")}
      </span>
      <div>
        <Button
          className={styles.button}
          buttonStyle="outlined"
          onClick={onSubmit}
          disabled={isLoading}
        >
          <span>
            {t("page.settings.subscription_plan.button.billing_details")}
          </span>
          {isLoading && <Loader className={styles.loader} />}
        </Button>
      </div>
    </div>
  );
};
