import { createDraftSafeSelector } from "@reduxjs/toolkit";

import { WHITE_LABEL_INITIAL_DATA } from "./whiteLabelSlice";

export const selectWhiteLabel = createDraftSafeSelector(
  ({ whiteLabel }: Store.RootState) => whiteLabel.entities,
  (whiteLabel) => Object.values(whiteLabel)[0] || WHITE_LABEL_INITIAL_DATA,
);

export const selectWhiteLabelStatus = createDraftSafeSelector(
  ({ whiteLabel }: Store.RootState) => whiteLabel,
  (whiteLabel) => whiteLabel.status,
);
