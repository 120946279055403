import { FC, memo, useMemo } from "react";
import useResizeObserver from "use-resize-observer";
import { TagCloud } from "react-tagcloud";
import cx from "classnames";

import styles from "./TagCloudChart.module.scss";
import { calculateAverage } from "src/utils";

// Inner imports
import { Tag } from "./types";
import { TagComponent } from "./components";
import { calculateTagFontSize, calculateWrapperSize } from "./utils";

type Props = {
  tags: Tag[];
  icon: AppIcon;
  title?: string;
  tagStyle?: "outlined" | "filled" | "transparent";
  className?: string;
  tagClassName?: string;
  tagCloudClassName?: string;
  viewType?: Widget.ViewType;
  hasHoverValue?: boolean;
  hasRelatedWordsTooltip?: boolean;
  valueFormatter?: (value: number) => string;
};

export const TagCloudChart: FC<Props> = memo(
  ({
    tags,
    icon,
    title,
    tagStyle = "transparent",
    className,
    tagClassName,
    tagCloudClassName,
    viewType = "preview",
    hasHoverValue = false,
    hasRelatedWordsTooltip = false,
    valueFormatter,
  }) => {
    const {
      ref: chartWrapperRef,
      width: chartWrapperWidth = 0,
      height: chartWrapperHeight = 0,
    } = useResizeObserver<HTMLDivElement>();

    const wrapperSize = useMemo<number>(
      () =>
        calculateWrapperSize(viewType, chartWrapperWidth, chartWrapperHeight),
      [chartWrapperHeight, chartWrapperWidth, viewType],
    );

    const averageTagsCount = useMemo<number>(() => {
      const allTagsCountValue = tags.map(({ value }) => value);

      return calculateAverage(allTagsCountValue);
    }, [tags]);

    const tagCloudKey = useMemo<string>(
      () => tags.map(({ id }) => id).join("_"),
      [tags],
    );

    return (
      <div ref={chartWrapperRef} className={cx(styles.wrapper, className)}>
        {Boolean(title) && <div className={styles.title}>{title}</div>}
        <TagCloud
          key={tagCloudKey}
          className={cx(tagCloudClassName, styles.tagCloud)}
          tags={tags}
          minSize={-Infinity} // required minSize placeholder. Has no side effects, because custom renderer is used
          maxSize={Infinity} // required maxSize placeholder. Has no side effects, because custom renderer is used
          renderer={(tag: Tag) => (
            <TagComponent
              key={tag.id}
              className={tagClassName}
              tagStyle={tagStyle}
              tag={tag}
              icon={icon}
              fontSize={calculateTagFontSize(
                wrapperSize,
                tag.value,
                averageTagsCount,
              )}
              hasHoverValue={hasHoverValue}
              hasRelatedWordsTooltip={hasRelatedWordsTooltip}
              valueFormatter={valueFormatter}
            />
          )}
          shuffle={false}
        />
      </div>
    );
  },
);
