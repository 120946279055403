import { FC } from "react";
import { useSelector } from "react-redux";
import ReactSwitch, { ReactSwitchProps } from "react-switch";

import { selectWhiteLabel } from "src/store/selectors";

// Inner imports
import { SWITCH_STYLES } from "./constants";

type Props = ReactSwitchProps;

export const Switch: FC<Props> = ({ ...props }) => {
  const {
    styles: { primaryColor },
  } = useSelector(selectWhiteLabel);

  return (
    <ReactSwitch
      activeBoxShadow={`0 0 2px 3px ${primaryColor}`}
      {...SWITCH_STYLES}
      {...props}
    />
  );
};
