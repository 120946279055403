import firestore from "src/services/firestore";
import { composeWidgetSyncId } from "src/utils";
import { COLLECTION_IDS } from "../constants";

// Inner imports
import * as schemas from "./widgetSyncsSchema";

export const subscribeOnWidgetSync = (
  trackersCollectionId: TrackersCollection.Data["id"],
  widgetId: Widget.IdType,
  dashboardDateRangeId: DashboardDateRange.Data["id"],
  callback: (sync: WidgetSync.Data | null) => void,
): (() => void) => {
  const widgetSyncId = composeWidgetSyncId({
    widgetId,
    trackersCollectionId,
    dashboardDateRangeId,
  });

  const docsRef = firestore()
    .collection(COLLECTION_IDS.widgetsSyncs)
    .doc(widgetSyncId);

  return docsRef.onSnapshot((doc) => {
    try {
      if (doc.exists) {
        const sync = schemas.widgetSyncSchema.validateSync(doc.data());

        callback({ ...sync, id: doc.id });
      } else {
        callback(null);
      }
    } catch (error) {
      console.error(error);

      callback(null);
    }
  });
};

export const subscribeOnSyncsByDashboardDateRangeId = ({
  callback,
  addCallback,
  deleteCallback,
  updateCallback,
  trackersCollectionId,
}: {
  callback: (value: number) => void;
  addCallback: (value: WidgetSync.Data) => void;
  deleteCallback: (value: WidgetSync.Data) => void;
  updateCallback: (value: WidgetSync.Data) => void;
  trackersCollectionId: TrackersCollection.Data["id"];
}): (() => void) => {
  const docsRef = firestore()
    .collection(COLLECTION_IDS.widgetsSyncs)
    .where("trackersCollectionId", "==", trackersCollectionId);

  return docsRef.onSnapshot((snap) => {
    for (const { type, doc } of snap.docChanges()) {
      if (!doc.exists) continue;

      try {
        const validatedData = schemas.widgetSyncSchema.validateSync(doc.data());

        const widgetSync = { ...validatedData, id: doc.id };

        switch (type) {
          case "added": {
            addCallback(widgetSync);
            break;
          }
          case "modified": {
            updateCallback(widgetSync);
            break;
          }
          case "removed": {
            deleteCallback(widgetSync);
            break;
          }
          default:
            break;
        }
      } catch (error) {
        console.error(error);
      }
    }

    callback(snap.docs.length);
  });
};
