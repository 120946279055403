import { useState } from "react";
import uniq from "lodash/uniq";
import { useSelector } from "react-redux";

import { Translation } from "src/components";
import { useTrackerBlocker } from "src/hooks";
import { showToastNotification } from "src/utils";
import {
  selectTrackersLimit,
  selectTrackersCollectionsTrackersLimit,
} from "src/store/selectors";

type Props = {
  defaultSelectedTrackers: Tracker.CreationData[];
};

export const useSelectedTrackers = ({
  defaultSelectedTrackers = [],
}: Props) => {
  const { showTrackerLimitNotification } = useTrackerBlocker();

  const { trackersCollectionsTrackersLimit } = useSelector(
    selectTrackersCollectionsTrackersLimit,
  );

  const { trackersCount, trackersLimit } = useSelector(selectTrackersLimit);

  const [selectedTrackers, setSelectedTrackers] = useState<
    Tracker.CreationData[]
  >(defaultSelectedTrackers);

  const selectTracker = (
    tracker: Tracker.CreationData,
    callback?: () => void,
  ): void => {
    const trackersTotalCount = trackersCount + selectedTrackers.length;

    if (selectedTrackers.length >= trackersCollectionsTrackersLimit)
      return showToastNotification({
        type: "warning",
        id: "trackers-collection-tracker-limit",
        text: (
          <Translation
            i18nKey="trackers_collection.status.warning.tracker_limit"
            values={{ count: trackersCollectionsTrackersLimit }}
          />
        ),
      });

    if (trackersTotalCount >= trackersLimit)
      return showTrackerLimitNotification();

    setSelectedTrackers((state) => uniq([...state, tracker]));

    callback?.();
  };

  const updateTracker = ({
    id,
    changes,
    callback,
  }: {
    id: Tracker.CreationData["id"];
    changes: Tracker.CreationData;
    callback?: () => void;
  }): void => {
    setSelectedTrackers((state) =>
      state.map((tracker) => {
        if (tracker.id !== id) return tracker;

        return { ...changes };
      }),
    );

    callback?.();
  };

  const unselectTracker = (tracker: Tracker.CreationData): void =>
    setSelectedTrackers((state) => state.filter(({ id }) => id !== tracker.id));

  return {
    updateTracker,
    selectTracker,
    unselectTracker,
    setSelectedTrackers,
    selectedTrackers,
  };
};
