import { useEffect, useMemo, useState } from "react";
import useResizeObserver from "use-resize-observer";
import cx from "classnames";

import styles from "./TableChart.module.scss";
import { genericMemo } from "src/utils";
import { usePagination, useSortedItems } from "src/hooks";
import { TablePagination } from "../../TablePagination/TablePagination";

// Inner imports
import { TableChartSortIcon } from "./components";
import { TABLE_CHART_PAGINATION_PROPS } from "./constants";
import { TableChartFormattedData, TableChartColumn } from "./types";

type Props<T> = {
  data: T[];
  columns: TableChartColumn<T>[];
  emptyDataPlaceholder?: string;
  hasHead?: boolean;
  totalLabel?: JSX.Element | string;
  hasNumeration?: boolean;
  hasResponsivePagination?: boolean;
  itemsPerPage?: number;
  defaultSort?: Partial<Record<keyof T, SortDirection>> | null;
  className?: string;
};

export const TableChart = genericMemo(
  <T extends {}>({
    data,
    columns,
    totalLabel,
    hasHead = true,
    hasNumeration = true,
    hasResponsivePagination = true,
    emptyDataPlaceholder = "",
    itemsPerPage: _itemsPerPage = 10,
    defaultSort,
    className,
  }: Props<T>) => {
    const { ref: tableBodyResizeRef } =
      useResizeObserver<HTMLTableSectionElement>();

    const [itemsPerPage, setItemsPerPage] = useState<number>(_itemsPerPage);

    const {
      sortedItems: sortedData,
      sort,
      setSort,
    } = useSortedItems({
      defaultSort,
      items: data,
    });

    const formattedSortedItems = useMemo<TableChartFormattedData<T>>(
      () => sortedData.map((item, index) => ({ ...item, order: index + 1 })),
      [sortedData],
    );

    const tableBodyRefCallback = (element: HTMLTableSectionElement): void => {
      if (!element || !hasResponsivePagination) return;

      const [elementClientHeight, elementChildHeight] = [
        element.clientHeight,
        element.children?.[0]?.clientHeight,
      ];

      if (!elementClientHeight || !elementChildHeight) return;

      const calculatedItemsPerPage = Math.floor(
        elementClientHeight / (Math.round(elementChildHeight / 10) * 10),
      );

      if (calculatedItemsPerPage) setItemsPerPage(calculatedItemsPerPage);

      tableBodyResizeRef(element);
    };

    const {
      slicedItems: slicedData,
      pageNumber,
      setPageNumber,
      pagesCount,
    } = usePagination({
      items: formattedSortedItems,
      itemsPerPage,
    });

    useEffect(() => {
      if (sort) setPageNumber(0);
    }, [setPageNumber, sort]);

    return (
      <div className={cx(styles.wrapper, className)}>
        <table className={styles.table}>
          {hasHead && (
            <thead className={styles.tableHead}>
              <tr className={styles.tableHeadRow}>
                {hasNumeration && (
                  <th
                    className={cx(
                      styles.tableHeadRowCell,
                      styles.tableHeadNumerationCell,
                    )}
                  />
                )}
                {columns.map(({ key, label, isSortable, style, width }) => {
                  const formattedStyle =
                    style instanceof Function ? style() : style;

                  return (
                    <th
                      key={label}
                      title={label}
                      style={{ width }}
                      className={styles.tableHeadRowCell}
                      onClick={() => isSortable && setSort(key)}
                    >
                      <div
                        style={formattedStyle}
                        className={styles.tableHeadRowCellContent}
                      >
                        <span>{label}</span>
                        {isSortable && (
                          <TableChartSortIcon sortDirection={sort?.[key]} />
                        )}
                      </div>
                    </th>
                  );
                })}
              </tr>
            </thead>
          )}
          <tbody className={styles.tableBody} ref={tableBodyRefCallback}>
            {slicedData.map((item) => (
              <tr key={item.order} className={styles.tableBodyRow}>
                {hasNumeration && (
                  <td
                    className={cx(
                      styles.tableBodyRowCell,
                      styles.tableBodyNumerationCell,
                    )}
                  >
                    <span>{item.order}</span>
                  </td>
                )}
                {columns.map(
                  ({ key, label, style, width, className, valueFormatter }) => {
                    const value = item[key];

                    const content =
                      valueFormatter?.(item) || value || emptyDataPlaceholder;

                    const [formattedStyle, formattedClassName] = [
                      style instanceof Function ? style(item) : style,
                      className instanceof Function
                        ? className(item)
                        : className,
                    ];

                    return (
                      <td
                        key={label}
                        style={{ width }}
                        className={styles.tableBodyRowCell}
                      >
                        <div
                          className={cx(
                            styles.tableBodyRowCellContent,
                            formattedClassName,
                          )}
                          style={formattedStyle}
                        >
                          <span>{content}</span>
                        </div>
                      </td>
                    );
                  },
                )}
              </tr>
            ))}
          </tbody>
        </table>
        <TablePagination
          pageCount={pagesCount}
          forcePage={pageNumber}
          totalLabel={totalLabel}
          onPageChange={({ selected }) => setPageNumber(selected)}
          {...TABLE_CHART_PAGINATION_PROPS}
        />
      </div>
    );
  },
);
