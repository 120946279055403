import { FC, useMemo, useState } from "react";
import cx from "classnames";

import styles from "./Alert.module.scss";
import { Button } from "../buttons/Button/Button";
import {
  Cross,
  Warning,
  InfoCircle,
  CheckCircle,
  CloseCircle,
  ExclamationCircle,
} from "src/assets/icons";

type Props = {
  type: "success" | "info" | "warning" | "caution" | "error";
  icon?: JSX.Element;
  message: string | JSX.Element;
  description?: string | JSX.Element;
  isIconShown?: boolean;
  isCloseIconShown?: boolean;
};

export const Alert: FC<Props> = ({
  type,
  icon,
  message,
  description,
  isIconShown = true,
  isCloseIconShown = false,
}) => {
  const [isShown, setIsShown] = useState<boolean>(true);

  const isDescriptionShown = useMemo<boolean>(
    () => Boolean(description),
    [description],
  );

  const isExtendedAlert = useMemo<boolean>(
    () => Boolean(message && description),
    [description, message],
  );

  const AlertIcon = useMemo<JSX.Element | null>(() => {
    if (icon) return icon;

    switch (type) {
      case "success":
        return <CheckCircle />;
      case "info":
        return <InfoCircle />;
      case "warning":
        return <ExclamationCircle />;
      case "caution":
        return <Warning />;
      case "error":
        return <CloseCircle />;
      default:
        return null;
    }
  }, [icon, type]);

  const onCloseClick = (): void => setIsShown(false);

  if (!isShown) return null;

  return (
    <div
      className={cx(
        styles.wrapper,
        styles[`wrapper_${type}`],
        isExtendedAlert ? styles.wrapperExtended : "",
      )}
    >
      {isIconShown && (
        <div className={cx(styles.iconWrapper, styles[`iconWrapper_${type}`])}>
          {AlertIcon}
        </div>
      )}
      <div className={styles.contentWrapper}>
        <span className={styles.message}>{message}</span>
        {isDescriptionShown && (
          <div className={styles.description}>{description}</div>
        )}
      </div>
      {isCloseIconShown && (
        <div className={styles.closeButtonWrapper}>
          <Button
            type="button"
            buttonSize="small"
            buttonStyle="transparent"
            onClick={onCloseClick}
          >
            <Cross />
          </Button>
        </div>
      )}
    </div>
  );
};
