import * as yup from "yup";

import { WIDGET_IDS } from "src/constants";

import {
  dashboardLayoutsSchema,
  dashboardTilesSchema,
} from "../dashboards/dashboardsSchema";

type YupStringType = yup.StringSchema<
  string | undefined | null,
  Record<string, any>,
  string,
  "d"
>;

const alternativeWidgetNamesMap = WIDGET_IDS.reduce<
  Record<Widget.IdType, YupStringType>
>((acc, item) => {
  acc[item] = yup.string().default("").nullable();

  return acc;
}, {} as Record<Widget.IdType, YupStringType>);

const whiteLabelDefaultDashboardSchema = yup
  .object({
    layouts: dashboardLayoutsSchema,
    tiles: dashboardTilesSchema,
  })
  .noUnknown()
  .required();

const whiteLabelFeaturesSchema = yup
  .object({
    signupEnabled: yup.boolean().required(),
  })
  .noUnknown()
  .required();

const whiteLabelLogosSchema = yup
  .object({
    big: yup.string().required(),
    medium: yup.string().required(),
    small: yup.string().required(),
    favicon: yup.string().required(),
  })
  .noUnknown()
  .required();

const whiteLabelStylesSchema = yup
  .object({
    fontFamily: yup.string().default(""),
    primaryColor: yup.string().required(),
    secondaryColor: yup.string().required(),
  })
  .noUnknown()
  .required();

export const whiteLabelSchema = yup
  .object({
    alternativeWidgetNamesMap: yup.object(alternativeWidgetNamesMap),
    subDomainName: yup.string().required(),
    name: yup.string().required(),
    countryCode: yup.string().required(),
    explorePageTopics: yup.array(yup.string().default("")).default([]),
    promptSuggestions: yup.array(yup.string().default("")).default([]),
    features: whiteLabelFeaturesSchema,
    defaultDashboard: whiteLabelDefaultDashboardSchema,
    logos: whiteLabelLogosSchema,
    styles: whiteLabelStylesSchema,
  })
  .noUnknown()
  .required();

export const whiteLabelDomainSchema = yup
  .object({
    whiteLabelId: yup.string().required(),
  })
  .noUnknown()
  .required();
