export const customRequestIdleCallback = (
  callback: () => void,
  timeout: number = 0,
): void => {
  try {
    requestIdleCallback(() => callback());
  } catch (error) {
    setTimeout(() => callback(), timeout);
  }
};
