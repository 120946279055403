import { AnyAction } from "@reduxjs/toolkit";
import chunk from "lodash/chunk";

import firestore from "src/services/firestore";

// Inner imports
import { COLLECTION_IDS } from "./constants";

export const onStateIdle = (
  state: Store.RootState[keyof Store.RootState],
  action: AnyAction,
): void => setState(state, action, "idle");

export const onStatePending = (
  state: Store.RootState[keyof Store.RootState],
  action: AnyAction,
): void => setState(state, action, "loading");

export const onStateRejected = (
  state: Store.RootState[keyof Store.RootState],
  action: AnyAction,
): void => setState(state, action, "failed");

export const onStateFulfilled = (
  state: Store.RootState[keyof Store.RootState],
  action: AnyAction,
): void => setState(state, action, "succeeded");

export const getTimestamps = (): Record<keyof Timestamps, ISODateString> => {
  const dateNow = new Date().toISOString();

  return {
    createdAt: dateNow,
    updatedAt: dateNow,
  };
};

export const getFirestoreEntitiesById = async (
  ids: DocId[],
  collection: ValueOf<typeof COLLECTION_IDS>,
): Promise<firestore.QueryDocumentSnapshot<firestore.DocumentData>[]> => {
  const chunks = chunk(ids, 10);

  const queries = new Set<Promise<firestore.QuerySnapshot>>();

  for (const chunk of chunks) {
    const query = firestore()
      .collection(collection)
      .where(firestore.FieldPath.documentId(), "in", chunk)
      .get();

    queries.add(query);
  }

  const result = await Promise.all([...queries]);

  const documents = new Set<
    firestore.QueryDocumentSnapshot<firestore.DocumentData>
  >();

  for (const { docs } of result) {
    for (const doc of docs) {
      documents.add(doc);
    }
  }

  return [...documents].sort((a, b) => {
    const [aIdIndex, bIdIndex] = [
      ids.findIndex((id) => id === a.id),
      ids.findIndex((id) => id === b.id),
    ];

    return aIdIndex - bIdIndex;
  });
};

export const generateDocId = (): string =>
  firestore().collection(COLLECTION_IDS.app).doc().id;

function setState(
  state: Store.RootState[keyof Store.RootState],
  action: AnyAction,
  status: LoadingStatus,
): void {
  if (!state) return;

  if ("status" in state) state.status = status;

  if ("error" in state) state.error = action.error || null;
}
