import { FC, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import styles from "./ConfirmDeleteTrackerModal.module.scss";
import { ConfirmModal } from "src/features";
import { useAppDispatch } from "src/store";
import { showToastNotification } from "src/utils";
import { useGlobalPreloader, useModal } from "src/hooks";
import {
  selectDashboardsRelatedToTracker,
  selectTrackersCollectionsWithTrackerId,
} from "src/store/selectors";
import {
  removeTracker,
  removeDashboards,
  removeTrackersCollections,
  updateTrackersCollections,
} from "src/store/actions";

type Props = {
  trackerId: Tracker.Data["id"];
};

export const ConfirmDeleteTrackerModal: FC<Props> = ({ trackerId }) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const { closeModal } = useModal();

  const { showGlobalPreloader, hideGlobalPreloader } = useGlobalPreloader();

  const dashboardsWithTrackerId = useSelector((state: Store.RootState) =>
    selectDashboardsRelatedToTracker(state, trackerId),
  );

  const trackersCollectionsWithTrackerId = useSelector(
    (state: Store.RootState) =>
      selectTrackersCollectionsWithTrackerId(state, trackerId),
  );

  const dashboardsWithTrackerIdRef = useRef<Dashboard.Data[]>(
    dashboardsWithTrackerId,
  );

  const onSubmit = async (): Promise<void> => {
    if (!trackerId) return;

    try {
      showGlobalPreloader();

      const [
        dashboardIdsRemove,
        trackersCollectionIdsRemove,
        trackersCollectionsUpdate,
      ] = [
        new Set<Dashboard.Data["id"]>(),
        new Set<TrackersCollection.Data["id"]>(),
        new Set<Store.UpdateEntity<TrackersCollection.Data>>(),
      ];

      for (const trackersCollection of trackersCollectionsWithTrackerId) {
        const { id, trackerIds } = trackersCollection;

        const updatedTrackerIds = trackerIds.filter((id) => id !== trackerId);

        if (!updatedTrackerIds.length) {
          trackersCollectionIdsRemove.add(id);

          continue;
        }

        trackersCollectionsUpdate.add({
          id,
          changes: {
            trackerIds: updatedTrackerIds,
            updatedAt: new Date().toISOString(),
          },
        });
      }

      for (const dashboard of dashboardsWithTrackerId) {
        const shouldDashboardBeRemoved = trackersCollectionIdsRemove.has(
          dashboard.trackersCollectionId,
        );

        if (shouldDashboardBeRemoved) dashboardIdsRemove.add(dashboard.id);
      }

      await dispatch(removeDashboards(Array.from(dashboardIdsRemove))).unwrap();

      await dispatch(
        removeTrackersCollections(Array.from(trackersCollectionIdsRemove)),
      ).unwrap();

      await dispatch(
        updateTrackersCollections(Array.from(trackersCollectionsUpdate)),
      ).unwrap();

      await dispatch(removeTracker(trackerId)).unwrap();

      showToastNotification({
        type: "success",
        text: t("page.edit_tracker.status.success.tracker_deleted"),
      });
    } catch (error) {
      console.error(error);

      showToastNotification({
        type: "error",
        text: t("common.error.server_error_reload"),
      });
    } finally {
      closeModal("confirm-delete-tracker");
      hideGlobalPreloader();
    }
  };

  return (
    <ConfirmModal
      id="confirm-delete-tracker"
      type="warning"
      acceptButton={{
        text: t("component.modal.delete_tracker.button.submit"),
        buttonType: "error",
        onClick: onSubmit,
      }}
      cancelButton={{
        text: t("component.modal.delete_tracker.button.cancel"),
        onClick: () => closeModal("confirm-delete-tracker"),
      }}
      title={t("component.modal.delete_tracker.title")}
    >
      <div className={styles.wrapper}>
        <span>{t("component.modal.delete_tracker.body")}</span>
        {Boolean(dashboardsWithTrackerIdRef.current.length) && (
          <>
            <span>
              {t(
                "component.modal.delete_tracker.status.warning.dashboard_update",
              )}
            </span>
            <ul>
              {dashboardsWithTrackerIdRef.current.map(({ id, name }) => (
                <li key={id}>{name}</li>
              ))}
            </ul>
          </>
        )}
      </div>
    </ConfirmModal>
  );
};
