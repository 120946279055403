const TABLE_PAGINATION_ITEMS_PER_PAGE_OPTIONS = [
  { label: "10", value: "10" },
  { label: "20", value: "20" },
  { label: "50", value: "50" },
  { label: "100", value: "100" },
] as const;

const TABLE_PAGINATION_NAVIGATION_ICON_SIZE = 20 as const;

const TABLE_PAGINATION_BREAK_LABEL = "..." as const;

const TABLE_PAGINATION_CONFIG = {
  marginPagesDisplayed: 1,
  pageRangeDisplayed: 2,
} as const;

export {
  TABLE_PAGINATION_ITEMS_PER_PAGE_OPTIONS,
  TABLE_PAGINATION_NAVIGATION_ICON_SIZE,
  TABLE_PAGINATION_BREAK_LABEL,
  TABLE_PAGINATION_CONFIG,
};
