import { useCallback, useEffect, useMemo, useState } from "react";
import isAfter from "date-fns/isAfter";
import { useSelector } from "react-redux";

import { useAppDispatch } from "src/store";
import { updateHardReload } from "src/store/actions";
import { selectApplicationInfo } from "src/store/selectors";
import { subscribeOnHardReloadInfo } from "src/store/application/applicationApi";

export const useHardReloadInfoObserver = () => {
  const dispatch = useAppDispatch();

  const {
    sessionDate,
    hardReloadInfo: { updatedAt: hardReloadUpdatedAt },
  } = useSelector(selectApplicationInfo);

  const [isObserverSet, setIsObserverSet] = useState<boolean>(false);

  const [formattedHardReloadUpdatedAt, formattedSessionDate] = useMemo<
    [Date, Date]
  >(
    () => [new Date(hardReloadUpdatedAt), new Date(sessionDate)],
    [sessionDate, hardReloadUpdatedAt],
  );

  const shouldHardReload = useMemo<boolean>(() => {
    const currentDate = new Date();

    if (isAfter(formattedHardReloadUpdatedAt, currentDate)) return false;

    return isAfter(formattedHardReloadUpdatedAt, formattedSessionDate);
  }, [formattedSessionDate, formattedHardReloadUpdatedAt]);

  const hardReloadInfoCallback = useCallback(
    (data: Partial<Application.HardReloadInfo>): void => {
      dispatch(updateHardReload(data));

      setIsObserverSet(true);
    },
    [dispatch],
  );

  useEffect(() => {
    let observer = () => {};

    try {
      observer = subscribeOnHardReloadInfo(hardReloadInfoCallback);
    } catch (err) {
      console.error(err);
    }

    return () => {
      observer();
    };
  }, [hardReloadInfoCallback]);

  useEffect(() => {
    if (!shouldHardReload) return;

    window.location.reload();
  }, [shouldHardReload]);

  return { isObserverSet };
};
