import { FC } from "react";

import styles from "./WidgetFooter.module.scss";
import { useWidgetFooter } from "src/features/Widgets/hooks/useWidgetFooter";

type Props = Widget.ChartProps;

export const WidgetFooter: FC<Props> = (props) => {
  const WidgetFooter = useWidgetFooter(props);

  if (!WidgetFooter) return null;

  return <div className={styles.wrapper}>{WidgetFooter}</div>;
};
