import { useContext } from "react";
import { createPortal } from "react-dom";

import styles from "./PreloaderPortal.module.scss";
import context from "src/context";
import { Preloader } from "src/components";

const PORTAL = document.getElementById("preloader");

export const PreloaderPortal = () => {
  const { isGlobalPreloaderShown, globalPreloaderText } = useContext(context);

  if (!isGlobalPreloaderShown || !PORTAL) return null;

  return createPortal(
    <div className={styles.wrapper}>
      <Preloader modifier="global" text={globalPreloaderText} />
    </div>,
    PORTAL,
  );
};
