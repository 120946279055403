import isAfter from "date-fns/isAfter";
import { createDraftSafeSelector } from "@reduxjs/toolkit";

import {
  removeExtraSpaces,
  formatStringToDate,
  isWidgetIdTypeGuard,
} from "src/utils";

import {
  selectUser,
  selectCompany,
  selectCompanyId,
  selectTrackersCollections,
  selectTrackersCollectionsEntities,
  selectAvailableWidgetIdsByCompanyId,
  selectWidgetsByDashboardDateRangeId,
  selectTrackersCollectionsWithTrackerId,
} from "../selectors";
import { selectUserId, selectUserPermissions } from "../user/userSelector";

// Inner imports
import { dashboardsAdapter } from "./dashboardsSlice";

export const {
  selectAll: selectDashboards,
  selectEntities: selectDashboardsEntities,
  selectTotal: selectDashboardsCount,
  selectById: selectDashboardById,
} = dashboardsAdapter.getSelectors<Store.RootState>(
  ({ dashboards }) => dashboards,
);

export const selectDashboardsStatus = createDraftSafeSelector(
  ({ dashboards }: Store.RootState) => dashboards,
  (dashboards) => dashboards.status,
);

export const selectIsUserDashboardOwner = createDraftSafeSelector(
  selectDashboardById,
  selectUserId,
  (dashboard, userId) => {
    if (!dashboard) return false;

    return dashboard.authorId === userId;
  },
);

export const selectIsUserCanEditDashboard = createDraftSafeSelector(
  selectUserPermissions,
  selectUser,
  selectDashboardById,
  ({ isUserCompanyOwnerOrAdmin }, user, dashboard) => {
    if (!user.id || !dashboard) return false;

    if (user.companyId !== dashboard.companyId) return false;

    if (isUserCompanyOwnerOrAdmin) return true;

    return dashboard.authorId === user.id;
  },
);

export const selectCompanyDashboards = createDraftSafeSelector(
  selectDashboards,
  selectUser,
  (dashboards, user) => {
    const filteredDashboards = new Set<Dashboard.Data>();

    for (const dashboard of dashboards) {
      const isCompanyDashboard = dashboard.companyId === user.companyId;

      if (isCompanyDashboard) filteredDashboards.add(dashboard);
    }

    return [...filteredDashboards];
  },
);

export const selectAvailableDashboards = createDraftSafeSelector(
  (
    state: Store.RootState,
    isReadOnly: boolean = false,
  ): [
    ReturnType<typeof selectDashboards>,
    ReturnType<typeof selectUserId>,
    boolean,
  ] => [selectDashboards(state), selectUserId(state), isReadOnly],
  ([dashboards, userId, isReadOnly]) => {
    const filteredDashboards = new Set<Dashboard.Data>();

    if (isReadOnly)
      for (const dashboard of dashboards) filteredDashboards.add(dashboard);
    else
      for (const dashboard of dashboards) {
        const [isUserDashboard, isPrivateDashboard] = [
          dashboard.authorId === userId,
          dashboard.visibility === "private",
        ];

        if (!isPrivateDashboard || (isPrivateDashboard && isUserDashboard))
          filteredDashboards.add(dashboard);
      }

    return [...filteredDashboards];
  },
);

export const selectAvailableDashboardById = createDraftSafeSelector(
  (
    state: Store.RootState,
    dashboardId: Dashboard.Data["id"],
    isReadOnly: boolean = false,
  ): [ReturnType<typeof selectAvailableDashboards>, Dashboard.Data["id"]] => [
    selectAvailableDashboards(state, isReadOnly),
    dashboardId,
  ],
  ([availableDashboards, dashboardId]) =>
    availableDashboards.find(({ id }) => id === dashboardId),
);

export const selectVisibleDashboards = createDraftSafeSelector(
  selectDashboards,
  selectCompanyId,
  selectUserId,
  (dashboards, companyId, userId) => {
    const filteredDashboards = new Set<Dashboard.Data>();

    for (const dashboard of dashboards) {
      const [isCompanyDashboard, isUserDashboard, isPrivateDashboard] = [
        dashboard.companyId === companyId,
        dashboard.authorId === userId,
        dashboard.visibility === "private",
      ];

      if (!isCompanyDashboard) continue;

      if (!isPrivateDashboard || (isPrivateDashboard && isUserDashboard))
        filteredDashboards.add(dashboard);
    }

    return [...filteredDashboards];
  },
);

export const selectDashboardOwnerName = createDraftSafeSelector(
  selectDashboardById,
  selectCompany,
  (dashboard, company) => {
    const { authorId = "" } = dashboard || {};

    const member = company.members[authorId];

    if (!member) return "";

    return removeExtraSpaces(`${member.firstName} ${member.lastName}`);
  },
);

export const selectDashboardWidgetSettings = createDraftSafeSelector(
  (
    state: Store.RootState,
    dashboardId: Dashboard.Data["id"],
    widgetId: Widget.IdType,
  ): [ReturnType<typeof selectDashboardById>, Widget.IdType] => [
    selectDashboardById(state, dashboardId),
    widgetId,
  ],
  ([dashboard, widgetId]) => dashboard?.settings?.widgets?.[widgetId],
);

export const selectDashboardsRelatedToTracker = createDraftSafeSelector(
  (
    state: Store.RootState,
    trackerId: Tracker.Data["id"],
  ): [TrackersCollection.Data[], Dashboard.Data[], Tracker.Data["id"]] => [
    selectTrackersCollections(state),
    selectDashboards(state),
    trackerId,
  ],
  ([trackersCollections, dashboards, trackerId]) => {
    const dashboardsRelatedToTracker = new Set<Dashboard.Data>();

    for (const trackersCollection of trackersCollections) {
      if (!trackersCollection.trackerIds.includes(trackerId)) continue;

      const dashboard = dashboards.find(
        ({ trackersCollectionId }) =>
          trackersCollectionId === trackersCollection.id,
      );

      if (dashboard) dashboardsRelatedToTracker.add(dashboard);
    }

    return Array.from(dashboardsRelatedToTracker);
  },
);

export const selectDashboardDataUpdatedAt = createDraftSafeSelector(
  (
    state: Store.RootState,
    trackersCollectionId: TrackersCollection.Data["id"],
    dashboardDateRangeId: DashboardDateRange.Data["id"],
    companyId: Company.Data["id"],
  ): [
    Store.RootState,
    ReturnType<typeof selectDashboardById>,
    Set<Widget.IdType>,
    DashboardDateRange.Data["id"],
  ] => [
    state,
    selectDashboardById(state, trackersCollectionId),
    selectAvailableWidgetIdsByCompanyId(state, companyId),
    dashboardDateRangeId,
  ],
  ([state, dashboard, availableWidgetIds, dashboardDateRangeId]) => {
    let updatedAt = "";

    const widgets = selectWidgetsByDashboardDateRangeId(
      state,
      dashboardDateRangeId,
    );

    if (!widgets || !dashboard) return updatedAt;

    for (const widgetId in widgets) {
      if (!isWidgetIdTypeGuard(widgetId)) continue;

      if (!availableWidgetIds.has(widgetId)) continue;

      const { updatedAt: widgetUpdatedAt } = widgets[widgetId] || {};

      if (!widgetUpdatedAt) continue;

      if (!updatedAt) {
        updatedAt = widgetUpdatedAt;

        continue;
      }

      const isWidgetOnDashboard = dashboard.tiles[widgetId];

      const [widgetUpdateAtDate, updatedAtDate] = [
        formatStringToDate(widgetUpdatedAt),
        formatStringToDate(updatedAt),
      ];

      if (
        isWidgetOnDashboard &&
        widgetUpdateAtDate &&
        updatedAtDate &&
        isAfter(widgetUpdateAtDate, updatedAtDate)
      )
        updatedAt = widgetUpdatedAt;
    }

    return updatedAt;
  },
);

export const selectDashboardsByAuthorId = createDraftSafeSelector(
  (
    state: Store.RootState,
    authorId: Dashboard.Data["authorId"],
  ): [Store.RootState, Dashboard.Data["authorId"]] => [state, authorId],
  ([state, authorId]) => {
    const dashboards = selectDashboards(state);

    const filteredDashboards = new Set<Dashboard.Data>();

    for (const dashboard of dashboards) {
      const isUserDashboardOwner = dashboard.authorId === authorId;

      if (isUserDashboardOwner) filteredDashboards.add(dashboard);
    }

    return [...filteredDashboards];
  },
);

export const selectIsCompanyDashboard = createDraftSafeSelector(
  (
    state: Store.RootState,
    dashboardId: Dashboard.Data["id"],
  ): [
    ReturnType<typeof selectUser>,
    ReturnType<typeof selectDashboardById>,
  ] => [selectUser(state), selectDashboardById(state, dashboardId)],
  ([user, dashboard]) => {
    if (!user || !dashboard) return false;

    return user.companyId === dashboard.companyId;
  },
);

export const selectDashboardsByTrackerId = createDraftSafeSelector(
  (
    state: Store.RootState,
    trackerId: Tracker.Data["id"],
  ): [
    ReturnType<typeof selectDashboardsEntities>,
    ReturnType<typeof selectTrackersCollections>,
  ] => [
    selectDashboardsEntities(state),
    selectTrackersCollectionsWithTrackerId(state, trackerId),
  ],
  ([dashboards, trackersCollections]) => {
    const filteredDashboards = new Set<Dashboard.Data>();

    if (!trackersCollections.length) return [...filteredDashboards];

    for (const { id: trackersCollectionId } of trackersCollections) {
      const dashboard = dashboards[trackersCollectionId];

      if (dashboard) filteredDashboards.add(dashboard);
    }

    return [...filteredDashboards];
  },
);

export const selectDashboardsWithTrackerIds = createDraftSafeSelector(
  (
    state: Store.RootState,
  ): [
    ReturnType<typeof selectDashboardsEntities>,
    ReturnType<typeof selectTrackersCollectionsEntities>,
  ] => [
    selectDashboardsEntities(state),
    selectTrackersCollectionsEntities(state),
  ],
  ([dashboards, trackersCollections]) => {
    const formattedDashboards = new Set<
      Dashboard.Data & Pick<TrackersCollection.Data, "trackerIds">
    >();

    for (const dashboardId in dashboards) {
      const [dashboard, trackersCollection] = [
        dashboards[dashboardId],
        trackersCollections[dashboardId],
      ];

      if (trackersCollection && dashboard)
        formattedDashboards.add({
          ...dashboard,
          trackerIds: trackersCollection.trackerIds,
        });
    }

    return [...formattedDashboards];
  },
);

export const selectAvailableDashboardsByTrackerId = createDraftSafeSelector(
  (
    state: Store.RootState,
    trackerId: Tracker.Data["id"],
  ): [
    ReturnType<typeof selectAvailableDashboards>,
    ReturnType<typeof selectTrackersCollections>,
  ] => [
    selectAvailableDashboards(state),
    selectTrackersCollectionsWithTrackerId(state, trackerId),
  ],
  ([dashboards, trackersCollections]) => {
    const filteredDashboards = new Set<Dashboard.Data>();

    if (!trackersCollections.length) return [...filteredDashboards];

    for (const { id: trackersCollectionId } of trackersCollections) {
      const dashboard = dashboards.find(
        ({ id }) => id === trackersCollectionId,
      );

      if (dashboard) filteredDashboards.add(dashboard);
    }

    return [...filteredDashboards];
  },
);
