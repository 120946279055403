import { Options } from "dom-to-image";
import convertHTMLtoImage from "dom-to-image-more";

export type DownloadHTMLImageFormat = "svg" | "png" | "jpeg";

type DownloadHTMLImageProps = {
  name: string;
  element: HTMLElement;
  options?: Options;
  format: DownloadHTMLImageFormat;
};

const DOWNLOAD_HTML_IMAGE_FORMAT_FUNCTION: Record<
  DownloadHTMLImageFormat,
  (node: Node, options?: Options) => Promise<string>
> = {
  svg: convertHTMLtoImage.toSvg,
  png: convertHTMLtoImage.toPng,
  jpeg: convertHTMLtoImage.toJpeg,
};

export const downloadHTMLImage = async ({
  name,
  element,
  options,
  format,
}: DownloadHTMLImageProps): Promise<void> =>
  DOWNLOAD_HTML_IMAGE_FORMAT_FUNCTION[format](element, options).then(
    (dataUrl) => {
      const link = document.createElement("a");

      link.download = `${name}.${format}`;

      link.href = dataUrl;

      link.click();

      link.remove();
    },
  );
