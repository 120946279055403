import { createDraftSafeSelector } from "@reduxjs/toolkit";

import { selectWidgetData } from "../widgets/widgetsSelectors";
import { selectEventsByDashboardId } from "../events/eventsSelector";

// Inner imports
import { dashboardDateRangesAdapter } from "./dashboardDateRangesSlice";

export const {
  selectAll: selectDashboardDateRanges,
  selectEntities: selectDashboardDateRangesEntities,
  selectTotal: selectDashboardDateRangesCount,
  selectById: selectDashboardDateRangeById,
} = dashboardDateRangesAdapter.getSelectors<Store.RootState>(
  ({ dashboardDateRanges }) => dashboardDateRanges,
);

export const selectDashboardDateRangesByTrackersCollectionId =
  createDraftSafeSelector(
    (
      state: Store.RootState,
      trackersCollectionId: TrackersCollection.Data["id"],
    ): [
      ReturnType<typeof selectDashboardDateRanges>,
      TrackersCollection.Data["id"],
    ] => [selectDashboardDateRanges(state), trackersCollectionId],
    ([dashboardDateRanges, trackersCollectionId]) => {
      const filteredDashboardDateRanges = new Set<DashboardDateRange.Data>();

      for (const dashboardDateRange of dashboardDateRanges) {
        const isTrackersCollectionDashboardDateRange =
          dashboardDateRange.trackersCollectionId === trackersCollectionId;

        if (isTrackersCollectionDashboardDateRange)
          filteredDashboardDateRanges.add(dashboardDateRange);
      }

      return [...filteredDashboardDateRanges];
    },
  );

export const selectDefaultDashboardDateRangeByTrackersCollectionId =
  createDraftSafeSelector(
    (
      state: Store.RootState,
      trackersCollectionId: TrackersCollection.Data["id"],
    ): [ReturnType<typeof selectDashboardDateRangesByTrackersCollectionId>] => [
      selectDashboardDateRangesByTrackersCollectionId(
        state,
        trackersCollectionId,
      ),
    ],
    ([dashboardDateRanges]) => {
      const dateRangeTypeAll = dashboardDateRanges.find(
        ({ type }) => type === "all",
      );

      return dateRangeTypeAll || dashboardDateRanges[0];
    },
  );

export const selectDefaultDashboardDateRangeIdByTrackersCollectionId =
  createDraftSafeSelector(
    (
      state: Store.RootState,
      trackersCollectionId: TrackersCollection.Data["id"],
    ): [ReturnType<typeof selectDashboardDateRangesByTrackersCollectionId>] => [
      selectDashboardDateRangesByTrackersCollectionId(
        state,
        trackersCollectionId,
      ),
    ],
    ([dashboardDateRanges]) => {
      const dateRangeTypeAll = dashboardDateRanges.find(
        ({ type }) => type === "all",
      );

      if (!dateRangeTypeAll) return dashboardDateRanges[0]?.id || "";

      return dateRangeTypeAll.id;
    },
  );

export const selectActiveDashboardDateRangesByTrackersCollectionId =
  createDraftSafeSelector(
    (
      state: Store.RootState,
      trackersCollectionId: TrackersCollection.Data["id"],
    ): [
      ReturnType<typeof selectDashboardDateRangesByTrackersCollectionId>,
      ReturnType<typeof selectEventsByDashboardId>,
    ] => [
      selectDashboardDateRangesByTrackersCollectionId(
        state,
        trackersCollectionId,
      ),
      selectEventsByDashboardId(state, trackersCollectionId),
    ],
    ([dashboardDateRanges, events]) => {
      const activeDashboardDateRanges = new Set<DashboardDateRange.Data>();

      for (const dashboardDateRange of dashboardDateRanges) {
        const isDefaultDashboardDateRange =
          dashboardDateRange.category === "default";

        if (isDefaultDashboardDateRange) {
          activeDashboardDateRanges.add(dashboardDateRange);

          continue;
        }

        const isEventDashboardDateRange = events.some(
          ({ dashboardDateRangeId }) =>
            dashboardDateRangeId === dashboardDateRange.id,
        );

        if (!isEventDashboardDateRange) {
          activeDashboardDateRanges.add(dashboardDateRange);

          continue;
        }

        const isActiveEventDashboardDateRange = events.some(
          ({ dashboardDateRangeId, isActive }) =>
            dashboardDateRangeId === dashboardDateRange.id && isActive,
        );

        if (isActiveEventDashboardDateRange)
          activeDashboardDateRanges.add(dashboardDateRange);
      }

      return [...activeDashboardDateRanges];
    },
  );

export const selectDashboardDataRangeByDashboardDateRangeId =
  createDraftSafeSelector(
    (
      state: Store.RootState,
      dashboardDateRangeId: DashboardDateRange.Data["id"],
    ): [ReturnType<typeof selectWidgetData>] => [
      selectWidgetData(state, dashboardDateRangeId, "widget-volume-share"),
    ],
    ([widgetData]) => {
      if (!widgetData) return { startDate: "", endDate: "" };

      return { startDate: widgetData.startDate, endDate: widgetData.endDate };
    },
  );
