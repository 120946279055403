import { FC, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import capitalize from "lodash/capitalize";

import { useModal } from "src/hooks";
import { useAppDispatch } from "src/store";
import { Table, Translation } from "src/components";
import { ConfirmRemoveMemberModal } from "src/features";
import { updateCompanyMember } from "src/store/actions";
import { ActionMenuOption } from "src/components/Table/types";
import {
  showToastNotification,
  formatToUniversalTime,
  formatToMonthDayFullYearDate,
} from "src/utils";
import {
  selectUser,
  selectCompanyMembers,
  selectUserPermissions,
  selectCompanyTeamLimit,
} from "src/store/selectors";
import { getMemberName } from "../../utils";

// Inner imports
import { FormattedMember } from "./types";
import { SETTINGS_MEMBERS_TABLE_COLUMNS } from "./constants";
import { calculateCompanyRoleValue, membersAfterSort } from "./utils";

export const MembersTable: FC = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const { setModal } = useModal();

  const user = useSelector(selectUser);

  const members = useSelector(selectCompanyMembers);

  const { isUserCompanyOwnerOrAdmin } = useSelector(selectUserPermissions);

  const { adminsLimit, isAdminsLimitExceeded } = useSelector(
    selectCompanyTeamLimit,
  );

  const formattedMembers = useMemo<FormattedMember[]>(() => {
    const _formattedMembers: FormattedMember[] = [];

    for (const memberId in members) {
      const member = members[memberId];

      if (!member) continue;

      const { companyRole, lastActiveAt } = member;

      _formattedMembers.push({
        ...member,
        id: memberId,
        name: getMemberName(member),
        companyRoleLabel: capitalize(companyRole),
        companyRoleValue: calculateCompanyRoleValue(member),
        lastActiveAt: formatToUniversalTime(lastActiveAt),
        lastActiveAtLabel: formatToMonthDayFullYearDate(lastActiveAt),
      });
    }

    return _formattedMembers;
  }, [members]);

  const updateMemberRole = useCallback(
    async (
      member: FormattedMember,
      newRole: Company.Member["companyRole"],
    ): Promise<void> => {
      if (newRole === "admin" && isAdminsLimitExceeded)
        return showToastNotification({
          id: "company_team_admins_limit",
          type: "warning",
          text: (
            <Translation
              i18nKey="company.team.status.warning.admin_limit"
              values={{ count: adminsLimit }}
            />
          ),
        });

      try {
        await dispatch(
          updateCompanyMember({
            id: member.id,
            changes: { companyRole: newRole },
          }),
        ).unwrap();
      } catch (error) {
        showToastNotification({
          type: "error",
          text: t("common.error.server_error"),
        });
      }
    },
    [isAdminsLimitExceeded, t, adminsLimit, dispatch],
  );

  const onRemoveMemberClick = useCallback(
    (member: FormattedMember): void =>
      setModal(
        "confirm-remove-member",
        <ConfirmRemoveMemberModal member={member} />,
      ),
    [setModal],
  );

  const getMemberActionMenuOptions = useCallback(
    (member: FormattedMember): ActionMenuOption[] => {
      const options: ActionMenuOption[] = [];

      const isMemberCurrentUser = member.id === user.id;

      const [
        isMemberCompanyOwner,
        isMemberCompanyAdmin,
        isMemberCompanyMember,
      ] = [
        member.companyRole === "owner",
        member.companyRole === "admin",
        member.companyRole === "member",
      ];

      if (
        isMemberCurrentUser ||
        isMemberCompanyOwner ||
        !isUserCompanyOwnerOrAdmin
      )
        return options;

      if (isMemberCompanyMember) {
        options.push({
          icon: "UserPromote",
          label: t("page.settings.company_members.table.button.upgrade_user"),
          onClick: () => updateMemberRole(member, "admin"),
        });
      }

      if (isMemberCompanyAdmin) {
        options.push({
          icon: "UserDemote",
          label: t("page.settings.company_members.table.button.downgrade_user"),
          onClick: () => updateMemberRole(member, "member"),
        });
      }

      options.push({
        icon: "UserRemove",
        label: t("page.settings.company_members.table.button.remove_user"),
        onClick: () => onRemoveMemberClick(member),
      });

      return options;
    },
    [
      t,
      user.id,
      updateMemberRole,
      onRemoveMemberClick,
      isUserCompanyOwnerOrAdmin,
    ],
  );

  return (
    <Table
      items={formattedMembers}
      tableColumns={SETTINGS_MEMBERS_TABLE_COLUMNS}
      customAfterSort={membersAfterSort}
      defaultSort={{ companyRoleValue: "DESC" }}
      emptyTableLabel={t(
        "page.settings.company_members.table.label.no_members",
      )}
      getActionMenuOptions={getMemberActionMenuOptions}
    />
  );
};
