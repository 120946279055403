import * as yup from "yup";

const searchConfigurationSchema = yup
  .object({
    keywords: yup.array(yup.string().required()).required(),
  })
  .noUnknown()
  .required();

export default searchConfigurationSchema;
