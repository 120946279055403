import { isProductionEnv } from "src/utils";
import { reportError } from "src/store/user/userApi";

const STYLES: string = "color: #f5de4c; font-size: 14px";
const DIVIDER: string = "------------------------------";

type Props = {
  error?: unknown;
  additionalTexts?: string[];
  reportMetadata?: Parameters<typeof reportError>[0];
};

export const showDevelopmentError = ({
  error,
  reportMetadata,
  additionalTexts = [],
}: Props) => {
  console.group("%cDEVELOPMENT WARNING", STYLES);

  if (Boolean(additionalTexts.length))
    for (const customError of additionalTexts)
      console.log(`${customError}\n${DIVIDER}`);

  if (error) {
    console.log("%c↓↓↓ ERROR ↓↓↓", "color: red");
    console.log(error);
  }

  console.groupEnd();

  if (reportMetadata && isProductionEnv()) reportError(reportMetadata).catch();
};
