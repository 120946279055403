import { FC, useMemo } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import styles from "./SelectedSearch.module.scss";
import { TrackerKeywordsDataSourcesIcon } from "src/features";
import { selectLocationById, selectLanguageById } from "src/store/selectors";

type Props = {
  search: Search.CreationData;
};

export const SelectedSearch: FC<Props> = ({ search }) => {
  const { t } = useTranslation();

  const location = useSelector((state: Store.RootState) =>
    selectLocationById(state, search.locationId),
  );

  const language = useSelector((state: Store.RootState) =>
    selectLanguageById(state, search.languageId),
  );

  const locationName = useMemo<string>(
    () => location?.name || "",
    [location?.name],
  );

  const languageName = useMemo<string>(
    () => language?.name || "",
    [language?.name],
  );

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <span className={styles.heading}>
          {t("component.selected_search.label.search")}
        </span>
        <span className={styles.title}>{search.subject}</span>
      </div>
      <span className={styles.description}>{search.description}</span>
      <div className={styles.settings}>
        <div className={styles.keywordsDataSource}>
          <TrackerKeywordsDataSourcesIcon
            keywordsDataSources={[search.keywordsDataSource]}
          />
        </div>
        <div className={styles.location} title={locationName}>
          <span>{locationName}</span>
        </div>
        <div className={styles.language} title={languageName}>
          <span>{search.languageId.toUpperCase()}</span>
        </div>
      </div>
    </div>
  );
};
