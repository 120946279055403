import { omit } from "lodash";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { useAppDispatch } from "src/store";
import { showToastNotification } from "src/utils";
import { selectAvailableDashboardById } from "src/store/selectors";
import { updateDashboard, removeWidgetStructure } from "src/store/actions";

export const useRemoveWidgetFromDashboard = (
  dashboardId: Dashboard.Data["id"],
  dashboardDateRangeId: DashboardDateRange.Data["id"],
) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const dashboard = useSelector((state: Store.RootState) =>
    selectAvailableDashboardById(state, dashboardId),
  );

  return async (widgetId: Widget.IdType): Promise<void> => {
    if (!dashboard) {
      showToastNotification({
        type: "error",
        text: t("common.error.server_error_reload"),
      });
      return;
    }

    const updatedTiles = omit(dashboard.tiles, [widgetId]);

    await dispatch(
      updateDashboard({ id: dashboard.id, changes: { tiles: updatedTiles } }),
    ).unwrap();

    dispatch(removeWidgetStructure({ widgetId, dashboardDateRangeId }));
  };
};
