import compareAsc from "date-fns/compareAsc";

export const sortDashboardDateRanges = (
  aValue: DashboardDateRange.Data,
  bValue: DashboardDateRange.Data,
): number => {
  const [aTypePriority, bTypePriority] = [
    getDashboardDateRangeTypePriority(aValue),
    getDashboardDateRangeTypePriority(bValue),
  ];

  if (aTypePriority !== bTypePriority) return aTypePriority - bTypePriority;

  const [aFormattedCreatedAt, bFormattedCreatedAt] = [
    new Date(aValue.createdAt),
    new Date(bValue.createdAt),
  ];

  return compareAsc(aFormattedCreatedAt, bFormattedCreatedAt);
};

export const getDashboardDateRangeTypePriority = ({
  type,
}: DashboardDateRange.Data): number => {
  switch (type) {
    case "all":
      return 0;
    case "12m":
      return 1;
    case "6m":
      return 2;
    case "3m":
      return 3;
    case "1m":
      return 4;
    case null:
    default:
      return 5;
  }
};
