import { createDraftSafeSelector } from "@reduxjs/toolkit";

import { locationsAdapter } from "./locationsSlice";
import { SEARCH_DEFAULT_KEYWORDS_DATA_SOURCE } from "../../constants";

export const {
  selectAll: selectLocations,
  selectEntities: selectLocationsEntities,
  selectById: selectLocationById,
} = locationsAdapter.getSelectors<Store.RootState>(
  ({ locations }) => locations,
);

export const selectLocationsStatus = createDraftSafeSelector(
  ({ locations }: Store.RootState) => locations,
  (locations) => locations.status,
);

export const selectActiveLocations = createDraftSafeSelector(
  selectLocations,
  (locations) => locations.filter(({ isActive }) => isActive),
);

export const selectLocationsByKeywordsDataSource = createDraftSafeSelector(
  (
    state: Store.RootState,
    keywordsDataSource: Nullable<Search.KeywordsDataSource>,
  ): [
    ReturnType<typeof selectActiveLocations>,
    Nullable<Search.KeywordsDataSource>,
  ] => [selectActiveLocations(state), keywordsDataSource],
  ([locations, keywordsDataSource]) =>
    locations.filter(({ keywordsDataSources }) =>
      keywordsDataSources.includes(
        keywordsDataSource || SEARCH_DEFAULT_KEYWORDS_DATA_SOURCE,
      ),
    ),
);

export const selectLocationLanguageId = createDraftSafeSelector(
  (
    state: Store.RootState,
    locationId: Location.Data["id"],
  ): [ReturnType<typeof selectLocationById>] => [
    selectLocationById(state, locationId),
  ],
  ([location]) => location?.languageIds[0] || "",
);
