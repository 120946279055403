import { useEffect, useRef } from "react";

export const useInterval = (
  callback: () => void,
  delay: number | null,
): void => {
  const callbackRef = useRef<() => void>(callback);

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    if (!delay && delay !== 0) return;

    const intervalId = setInterval(() => callbackRef.current(), delay);

    return () => clearInterval(intervalId);
  }, [delay]);
};
