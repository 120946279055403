import { FC, useMemo } from "react";
import DatePicker from "react-datepicker";

import styles from "./DateSelector.module.scss";
import { formatStringToDate, formatToYearMonthDay } from "src/utils";

// Inner imports
import { DateSelectorInput } from "./components";

type Props = {
  value: string;
  minValue?: string | null;
  maxValue?: string | null;
  placeholder?: string;
  onChange: (value: string) => void;
};

export const DateSelector: FC<Props> = ({
  value,
  minValue,
  maxValue,
  placeholder,
  onChange,
}) => {
  const minDate = useMemo<Date | null>(
    () => formatStringToDate(minValue),
    [minValue],
  );

  const maxDate = useMemo<Date | null>(
    () => formatStringToDate(maxValue),
    [maxValue],
  );

  const selectedDate = useMemo<Date>(() => {
    const formattedDate = formatStringToDate(value, new Date());

    if (!formattedDate) return new Date();

    return formattedDate;
  }, [value]);

  const handleChange = (value: Date | [Date, Date] | null): void => {
    if (!(value instanceof Date)) return;

    const formattedValue = formatToYearMonthDay(value);

    onChange(formattedValue);
  };

  return (
    <div className={styles.wrapper}>
      <DatePicker
        onChange={handleChange}
        selected={selectedDate}
        minDate={minDate}
        maxDate={maxDate}
        wrapperClassName={styles.datePickerWrapper}
        calendarClassName={styles.datePickerCalendar}
        customInput={<DateSelectorInput placeholder={placeholder} />}
        popperPlacement="bottom-end"
        popperClassName={styles.datePickerPopper}
        showMonthDropdown
        showYearDropdown
      />
    </div>
  );
};
