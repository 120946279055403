import * as yup from "yup";
import mapValues from "lodash/mapValues";

export const widgetExportSchema = yup
  .object({
    name: yup.string().required(),
    data: yup
      .mixed<Blob>()
      .test((value: unknown) => value instanceof Blob)
      .required(),
  })
  .noUnknown()
  .required();

//#region VOLUME SHARE SCHEMA
const volumeSharePieChartItemSchema = yup
  .object({
    volume: yup.number().required(),
  })
  .required();

const volumeSharePieChartSchema = yup.lazy((obj) =>
  yup.object(mapValues(obj, () => volumeSharePieChartItemSchema)).required(),
);

const volumeShareLineChartItemSchema = yup
  .object({
    date: yup.string().required(),
    volume: yup.number().required(),
    isForecasted: yup.boolean().default(false),
  })
  .required();

const volumeShareLineChartSchema = yup.lazy((obj) =>
  yup
    .object(
      mapValues(obj, () =>
        yup.array(volumeShareLineChartItemSchema).required(),
      ),
    )
    .required(),
);

const volumeShareLineTableItemSchema = yup
  .object({
    volume: yup.number().required(),
    trendChange: yup.number().required(),
  })
  .required();

const volumeShareTableChartSchema = yup.lazy((obj) =>
  yup.object(mapValues(obj, () => volumeShareLineTableItemSchema)).required(),
);

const volumeShareDataSchema = yup
  .object({
    pieChart: volumeSharePieChartSchema,
    lineChart: volumeShareLineChartSchema,
    tableChart: volumeShareTableChartSchema,
  })
  .required();

export const volumeShareWidgetSchema = yup
  .object({
    data: volumeShareDataSchema,
    dateFrom: yup.string().required(),
    dateTo: yup.string().required(),
    updatedAt: yup.string().required(),
  })
  .noUnknown();
//#endregion VOLUME SHARE SCHEMA

//#region SEARCH SHARE SCHEMA
const searchShareTableChartItemSchema = yup
  .object({
    volume: yup.number().required(),
    percentage: yup.number().required(),
    trendChange: yup.number().required(),
  })
  .required();

const searchShareTableChartSchema = yup.lazy((obj) =>
  yup.object(mapValues(obj, () => searchShareTableChartItemSchema)).required(),
);

const searchSharePieChartItemSchema = yup
  .object({
    volume: yup.number().required(),
  })
  .required();

const searchSharePieChartSchema = yup.lazy((obj) =>
  yup.object(mapValues(obj, () => searchSharePieChartItemSchema)).required(),
);

const searchShareLineChartItemSchema = yup
  .object({
    date: yup.string().required(),
    volume: yup.number().required(),
  })
  .required();

const searchShareLineChartSchema = yup.lazy((obj) =>
  yup
    .object(
      mapValues(obj, () =>
        yup.array(searchShareLineChartItemSchema).required(),
      ),
    )
    .required(),
);

const searchShareDataSchema = yup
  .object({
    pieChart: searchSharePieChartSchema,
    lineChart: searchShareLineChartSchema,
    tableChart: searchShareTableChartSchema,
  })
  .required();

export const searchShareWidgetSchema = yup
  .object({
    data: searchShareDataSchema,
    dateFrom: yup.string().required(),
    dateTo: yup.string().required(),
    updatedAt: yup.string().required(),
  })
  .noUnknown();
//#endregion SEARCH SHARE SCHEMA

//#region ARTICLES SCHEMA
const articlesFilterSchema = yup
  .object({
    subject: yup.string().required(),
  })
  .noUnknown();

const articleSchema = yup
  .object({
    url: yup.string().required(),
    title: yup.string().required(),
    description: yup.string().default(""),
    date: yup.string().nullable().default(""),
    score: yup.number().default(0),
  })
  .noUnknown();

const articlesDataSchema = yup
  .object({
    articles: yup.array(articleSchema).required(),
    filters: yup.array(articlesFilterSchema).required(),
  })
  .noUnknown()
  .required();

export const articlesWidgetSchema = yup
  .object({
    data: articlesDataSchema,
    updatedAt: yup.string().required(),
  })
  .noUnknown();
//#endregion ARTICLES SCHEMA

//#region TOP KEYWORDS SCHEMA
const topKeywordsKeywordVolumeSchema = yup
  .object({
    keyword: yup.string().required(),
    volume: yup.number().required(),
  })
  .noUnknown()
  .required();

const topKeywordsListChartSchema = yup.lazy((obj) =>
  yup
    .object(
      mapValues(obj, () =>
        yup.array(topKeywordsKeywordVolumeSchema).required(),
      ),
    )
    .noUnknown()
    .required(),
);

export const topKeywordsWidgetSchema = yup
  .object({
    data: yup.object({ listChart: topKeywordsListChartSchema }).required(),
    dateFrom: yup.string().required(),
    dateTo: yup.string().required(),
    updatedAt: yup.string().required(),
  })
  .noUnknown()
  .required();
//#endregion TOP KEYWORDS SCHEMA

//#region TRENDING KEYWORDS SCHEMA
const trendingKeywordsKeywordVolumeSchema = yup
  .object({
    keyword: yup.string().required(),
    volume: yup.number().required(),
    trend: yup.number().required(),
  })
  .noUnknown()
  .required();

const trendingKeywordsListChartSchema = yup.lazy((obj) =>
  yup
    .object(
      mapValues(obj, () =>
        yup.array(trendingKeywordsKeywordVolumeSchema).required(),
      ),
    )
    .noUnknown()
    .required(),
);

export const trendingKeywordsWidgetSchema = yup
  .object({
    data: yup.object({ listChart: trendingKeywordsListChartSchema }).required(),
    dateFrom: yup.string().required(),
    dateTo: yup.string().required(),
    updatedAt: yup.string().required(),
  })
  .noUnknown()
  .required();
//#endregion TOP KEYWORDS SCHEMA

//#region INSIGHTS SCHEMA
const insightsCustomPromptSchema = yup
  .object({
    value: yup.string().default(""),
    updatedAt: yup.string().default(""),
    authorId: yup.string().nullable().default(null),
  })
  .noUnknown()
  .nullable()
  .default({
    value: "",
    updatedAt: "",
    authorId: null,
  });

const insightsAiPromptSchema = yup
  .object({
    value: yup.string().default(""),
    updatedAt: yup.string().default(""),
    authorId: yup.string().nullable().default(null),
  })
  .noUnknown()
  .required();

const insightsDataSchema = yup
  .object({
    aiPrompt: insightsAiPromptSchema,
    customPrompt: insightsCustomPromptSchema,
  })
  .noUnknown()
  .required();

export const insightsWidgetSchema = yup
  .object({
    data: insightsDataSchema,
    dateFrom: yup.string().required(),
    dateTo: yup.string().required(),
    updatedAt: yup.string().required(),
  })
  .noUnknown()
  .required();
//#endregion INSIGHTS SCHEMA
