import firestore from "src/services/firestore";
import { showDevelopmentError } from "src/utils";
import { COLLECTION_IDS } from "../constants";

// Inner imports
import * as schemas from "./companySchema";
import { COMPANY_INITIAL_DATA } from "./companySlice";

export const getCompanyById = async (
  id: Company.Data["id"],
): Promise<Company.Data> => {
  const [companyResult, membersResult] = await Promise.all([
    firestore().collection(COLLECTION_IDS.companies).doc(id).get(),
    firestore()
      .collection(COLLECTION_IDS.users)
      .where("companyId", "==", id)
      .get(),
  ]);

  if (!companyResult.exists) throw Error("Company not found");

  if (membersResult.empty) throw Error("Company members not found");

  const company = schemas.companySchema.validateSync(companyResult.data());

  const members = membersResult.docs.reduce<Company.Data["members"]>(
    (acc, doc) => {
      try {
        const member = schemas.memberSchema.validateSync(doc.data());

        acc[doc.id] = {
          ...member,
          id: doc.id,
          email: "",
        };
      } catch (error) {
        showDevelopmentError({
          error,
          additionalTexts: ["COMPANY MEMBER VALIDATION ERROR"],
        });
      }

      return acc;
    },
    {},
  );

  return {
    ...company,
    id: companyResult.id,
    members,
  };
};

export const getExternalCompanyById = async (
  id: Company.Data["id"],
): Promise<Company.Data> => {
  const doc = await firestore()
    .collection(COLLECTION_IDS.companies)
    .doc(id)
    .get();

  if (!doc.exists) throw Error("Company not found");

  const company = schemas.externalCompanySchema.validateSync(doc.data());

  return {
    ...COMPANY_INITIAL_DATA,
    ...company,
    id: doc.id,
  };
};

export const updateCompany = ({
  id,
  changes,
}: Store.UpdateEntity<Company.Data>): Promise<void> =>
  firestore()
    .collection(COLLECTION_IDS.companies)
    .doc(id)
    .set(changes, { merge: true });

export const updateCompanyMember = ({
  id,
  changes,
}: Store.UpdateEntity<Pick<Company.Member, "companyRole">>): Promise<void> =>
  firestore()
    .collection(COLLECTION_IDS.users)
    .doc(id)
    .set(changes, { merge: true });

export const checkIsCompanyExists = (
  id: Company.Data["id"],
): Promise<boolean> =>
  firestore()
    .collection(COLLECTION_IDS.companies)
    .doc(id)
    .get()
    .then((doc) => doc.exists);

export const subscribeOnCompany = (
  companyId: Company.Data["id"],
  callback: (value: Company.Data) => void,
): (() => void) => {
  const docRef = firestore()
    .collection(COLLECTION_IDS.companies)
    .doc(companyId);

  return docRef.onSnapshot((doc) => {
    try {
      if (!doc.exists) throw Error("Company not found");

      const company = schemas.companySchema.validateSync(doc.data());

      callback({
        ...company,
        id: doc.id,
        members: {},
      });
    } catch (error) {
      console.error(error);
    }
  });
};
