import { RouteComponentProps } from "react-router-dom";

export const updateQueryParams = (
  value: Record<string, string>,
  history: RouteComponentProps["history"],
): void => {
  const queryParams = new URLSearchParams(
    formatQueryParamsValue(value, history),
  ).toString();

  history.replace(
    `${history.location.pathname}?${queryParams}`,
    history.location.state,
  );
};

function formatQueryParamsValue(
  value: Record<string, string>,
  history: RouteComponentProps["history"],
): Record<string, string> {
  const currentQueryParams = new URLSearchParams(history.location.search);

  const mergedValue: Record<string, string> = {
    ...Object.fromEntries(currentQueryParams),
    ...value,
  };

  const result: Record<string, string> = {};

  for (const key in mergedValue) {
    const param = value[key];

    if (!param) continue;

    result[key] = param;
  }

  return result;
}
