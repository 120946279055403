import { FC, useMemo } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import styles from "./DashboardInfo.module.scss";
import { MinusCircleFill } from "src/assets/icons";
import { Button, Translation } from "src/components";
import { formatToDayMonthFullYearDate } from "src/utils";
import { TrackerKeywordsDataSourcesIcon } from "src/features";
import {
  selectDashboardById,
  selectDashboardOwnerName,
  selectIsUserDashboardOwner,
} from "src/store/selectors";

type Props = {
  usedTrackers: Tracker.Data[];
  trackersCollectionId: Dashboard.Data["id"];
  removeUsedTracker: (trackerId: Tracker.Data["id"]) => void;
};

export const DashboardInfo: FC<Props> = ({
  usedTrackers,
  removeUsedTracker,
  trackersCollectionId,
}) => {
  const { t } = useTranslation();

  const dashboard = useSelector((state: Store.RootState) =>
    selectDashboardById(state, trackersCollectionId),
  );

  const isUserDashboardOwner = useSelector((state: Store.RootState) =>
    selectIsUserDashboardOwner(state, trackersCollectionId),
  );

  const dashboardAuthorFullName = useSelector((state: Store.RootState) =>
    selectDashboardOwnerName(state, trackersCollectionId),
  );

  const dashboardAuthorText = useMemo<string>(() => {
    if (isUserDashboardOwner) return t("user.name.current_user");

    return dashboardAuthorFullName;
  }, [dashboardAuthorFullName, isUserDashboardOwner, t]);

  const privacyText = useMemo<string>(() => {
    switch (dashboard?.visibility) {
      case "company":
        return t("dashboard.label.visibility_company");
      case "public":
      default:
        return t("dashboard.label.visibility_public");
    }
  }, [dashboard?.visibility, t]);

  const createdAtText = useMemo<string>(() => {
    let date = "";

    if (!dashboard?.createdAt) return date;

    try {
      date = formatToDayMonthFullYearDate(dashboard?.createdAt);
    } catch (error) {
      console.error(error);
    }

    return date;
  }, [dashboard?.createdAt]);

  if (!dashboard) return null;

  return (
    <div className={styles.dashboardInfoWrapper}>
      <div className={styles.infoAboutDashboard}>
        <div className={styles.name} title={dashboard.name}>
          {dashboard.name}
        </div>
        <div className={styles.additionalDashboardInfo}>
          {createdAtText && (
            <div>
              <Translation
                i18nKey="page.dashboard.label.dashboard_created_at"
                values={{ createdAt: createdAtText }}
              />
            </div>
          )}
          <div>
            <Translation
              i18nKey="page.dashboard.label.dashboard_author_name"
              values={{ ownerFullName: dashboardAuthorText }}
            />
          </div>
          {isUserDashboardOwner && (
            <div>
              <Translation
                i18nKey="page.dashboard.label.dashboard_visibility"
                values={{ privacy: privacyText }}
              />
            </div>
          )}
        </div>
      </div>
      <div className={styles.trackersInfo}>
        <div className={styles.trackersInfoText}>
          <div className={styles.trackersInfoTitle}>
            {t("page.dashboard.label.dashboard_trackers")}
          </div>
        </div>
        <div className={styles.usedTrackers}>
          {usedTrackers.map(({ name, id }) => (
            <Button
              key={id}
              title={name}
              buttonSize="small"
              className={styles.usedTracker}
              onClick={() => removeUsedTracker(id)}
            >
              <TrackerKeywordsDataSourcesIcon trackerId={id} />
              <span>{name}</span>
              <MinusCircleFill />
            </Button>
          ))}
        </div>
      </div>
    </div>
  );
};
