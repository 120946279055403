import { useCallback } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { useAbortController } from "src/hooks";
import { DEFAULT_TRACKER_CATEGORY } from "src/constants";
import { getSuggestedSearches } from "src/store/searches/searchesApi";
import {
  selectLanguagesEntities,
  selectLocationsEntities,
} from "src/store/selectors";
import {
  showDevelopmentError,
  showToastNotification,
  isAbortedRequestErrorTypeGuard,
} from "src/utils";

const SUGGESTED_SEARCHES_LIMIT_PER_REQUEST = 5 as const;

type Props = {
  updateStatusHandler: (status: LoadingStatus) => void;
  updateExcludedSearchesHandler: (searches: Search.CreationData[]) => void;
};

export const useGetSuggestedSearches = ({
  updateStatusHandler,
  updateExcludedSearchesHandler,
}: Props) => {
  const { t } = useTranslation();

  const abortController = useAbortController();

  const locations = useSelector(selectLocationsEntities);

  const languages = useSelector(selectLanguagesEntities);

  const getExcludedSubjects = useCallback(
    (searches: Search.CreationData[]): string[] => {
      const prepareSearches = searches.map(({ subject }) => subject);

      return Array.from(new Set(prepareSearches));
    },
    [],
  );

  const getLocation = useCallback(
    (locationId: Location.Data["id"]): Location.Data | undefined =>
      locations[locationId],
    [locations],
  );

  const getLanguage = useCallback(
    (languageId: Language.Data["id"]): Language.Data | undefined =>
      languages[languageId],
    [languages],
  );

  const _getSuggestedSearches = async ({
    query,
    callback,
    languageId,
    locationId,
    description,
    keywordsDataSource,
    excludedSearches = [],
    category = DEFAULT_TRACKER_CATEGORY,
    limit = SUGGESTED_SEARCHES_LIMIT_PER_REQUEST,
  }: {
    query: string;
    limit?: number;
    locationId: Location.Data["id"];
    languageId: Language.Data["id"];
    category: Tracker.Data["category"];
    description: Tracker.Data["description"];
    excludedSearches?: Search.CreationData[];
    keywordsDataSource: Search.KeywordsDataSource;
    callback?: (searches: Search.CreationData[]) => void;
  }): Promise<Search.CreationData[]> => {
    const [location, language] = [
      getLocation(locationId),
      getLanguage(languageId),
    ];

    if (!location || !language) return [];

    try {
      updateStatusHandler("loading");

      const result = await getSuggestedSearches(
        {
          query,
          limit,
          location,
          language,
          category,
          description,
          keywordsDataSource,
          excludedSubjects: getExcludedSubjects(excludedSearches),
        },
        { signal: abortController?.signal },
      );

      updateStatusHandler("succeeded");
      updateExcludedSearchesHandler([...excludedSearches, ...result]);

      callback?.(result);

      return result;
    } catch (error) {
      if (isAbortedRequestErrorTypeGuard(error)) return [];

      showDevelopmentError({ error });

      showToastNotification({
        type: "error",
        text: t("common.error.server_error"),
      });

      updateStatusHandler("failed");

      callback?.([]);

      return [];
    }
  };

  const cancelGetSuggestedSearches = (): void => {
    if (abortController.signal.aborted) return;

    return abortController.abort();
  };

  return {
    cancelGetSuggestedSearches,
    getSuggestedSearches: _getSuggestedSearches,
  };
};
