import isEqual from "lodash/isEqual";

export const getDifferenceInObjects = <T extends Object>(
  obj1: T,
  obj2: T,
  options?: { ignoredKeys?: Array<keyof T>; selectedKeys?: Array<keyof T> },
): Partial<T> | null => {
  const { ignoredKeys = [], selectedKeys = [] } = options || {};

  const difference: Partial<T> = {};

  for (const key in obj1) {
    if (ignoredKeys.includes(key)) continue;

    if (selectedKeys.length && !selectedKeys.includes(key)) continue;

    const isPropsExist = obj2.hasOwnProperty(key);

    const isPropsEqual = isEqual(obj1[key], obj2[key]);

    if (!isPropsExist || !isPropsEqual) difference[key] = obj1[key];
  }

  const hasDifference = Boolean(Object.keys(difference).length);

  if (!hasDifference) return null;

  return difference;
};

export const cleanStringsInObject = <T>(object: T): void => {
  if (!object || typeof object !== "object") return;

  for (const key in object) {
    if (!object.hasOwnProperty(key)) continue;

    const value = object[key];

    if (typeof value !== "string") continue;

    object[key] = value.replace(/\s+/g, " ").trim() as any;
  }
};
