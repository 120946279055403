export function executeCapterra() {
  var capterra_vkey = "638fa582725889ce664c055b6ec96b5b",
    capterra_vid = "2148345",
    capterra_prefix =
      "https:" === document.location.protocol
        ? "https://ct.capterra.com"
        : "http://ct.capterra.com";
  var ct = document.createElement("script");
  ct.type = "text/javascript";
  ct.async = true;
  ct.src =
    capterra_prefix +
    "/capterra_tracker.js?vid=" +
    capterra_vid +
    "&vkey=" +
    capterra_vkey;
  var s = document.getElementsByTagName("script")[0];
  s.parentNode.insertBefore(ct, s);
}
