import { FC, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import cx from "classnames";

import styles from "./SidebarTile.module.scss";
import { isTouchDevice } from "src/utils";
import { useModal } from "src/hooks";
import { Button, Tooltip } from "src/components";
import {
  PreReleaseComponent,
  UpgradeSubscriptionPlanModal,
} from "src/features";
import { Lock, PlusCircleFill, Loader } from "src/assets/icons";
import { selectDashboardWidgetSettings } from "src/store/selectors";

// Inner imports
import { WidgetSidebarTile } from "../../types";

export const SidebarTile: FC<WidgetSidebarTile> = ({
  widgetId,
  dashboardId,
  icon: widgetIcon,
  title,
  isAvailableInSubPlan,
  addTileToDashboardHandler,
}) => {
  const { t } = useTranslation();

  const { setModal } = useModal();

  const dashboardWidgetSettings = useSelector((state: Store.RootState) =>
    selectDashboardWidgetSettings(state, dashboardId, widgetId),
  );

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const isWidgetAvailable = useMemo<boolean>(
    () => isAvailableInSubPlan,
    [isAvailableInSubPlan],
  );

  const isDraggable = useMemo<boolean>(
    () => isWidgetAvailable && !isTouchDevice,
    [isWidgetAvailable],
  );

  const widgetName = useMemo<string>(
    () => t(dashboardWidgetSettings?.name || title),
    [dashboardWidgetSettings?.name, t, title],
  );

  const onAddTileToDashboardHandle = useCallback(async () => {
    setIsLoading(true);

    try {
      await addTileToDashboardHandler?.();
    } catch (error) {
      setIsLoading(false);
    }
  }, [addTileToDashboardHandler]);

  const widgetTileIcon = useMemo<JSX.Element>(() => {
    if (isLoading) {
      return (
        <Button
          className={styles.addWidgetIcon}
          buttonSize="small"
          buttonStyle="transparent"
          disabled
        >
          <Loader className={styles.loader} />
        </Button>
      );
    }

    if (!isAvailableInSubPlan) {
      return (
        <Button
          className={styles.lockBar}
          buttonStyle="transparent"
          buttonSize="small"
          onClick={() =>
            setModal(
              "upgrade-subscription-plan",
              <UpgradeSubscriptionPlanModal content="component.widget_tile.status.info.upgrade_subscription_plan" />,
            )
          }
        >
          <Tooltip
            content={t(
              "component.sidebar.dashboard_widgets.label.upgrade_required",
            )}
          >
            <Lock />
          </Tooltip>
        </Button>
      );
    }

    return (
      <Button
        className={styles.addWidgetIcon}
        onClick={onAddTileToDashboardHandle}
        buttonStyle="transparent"
        buttonSize="small"
      >
        <PlusCircleFill />
      </Button>
    );
  }, [
    isLoading,
    isAvailableInSubPlan,
    onAddTileToDashboardHandle,
    t,
    setModal,
  ]);

  return (
    <div className={styles.tile} draggable={isDraggable}>
      <div className={styles.sideBarPreReleaseWrapper}>
        <PreReleaseComponent widgetId={widgetId} viewType="compact" />
      </div>
      <div
        className={cx(
          styles.tileContent,
          styles[isWidgetAvailable ? "" : "tileContentBlocked"],
        )}
      >
        {widgetIcon}
        <div className={styles.widgetTitle} title={widgetName}>
          {widgetName}
        </div>
      </div>
      {widgetTileIcon}
    </div>
  );
};
