import {
  sortValues,
  stringFilter,
  isKeywordsDataSourceTypeGuard,
} from "src/utils";

export const sortTrackers = ({
  type,
  trackers,
  lastViewedTrackerIds,
}: {
  type: Tracker.ListSort;
  trackers: Tracker.Data[];
  lastViewedTrackerIds: User.Data["lastViewedTrackerIds"];
}): Tracker.Data[] => {
  switch (type) {
    case "name":
      return [...trackers].sort((a, b) => sortValues(a.name, b.name, "ASC"));
    case "createdAt":
      return [...trackers].sort((a, b) =>
        sortValues(a.createdAt, b.createdAt, "DESC"),
      );
    case "lastViewed": {
      const reversedLastViewedTrackerIds = [...lastViewedTrackerIds].reverse();

      return [...trackers].sort(
        (a, b) =>
          reversedLastViewedTrackerIds.indexOf(b.id) -
            reversedLastViewedTrackerIds.indexOf(a.id) ||
          sortValues(a.createdAt, b.createdAt, "DESC"),
      );
    }
    default:
      return trackers;
  }
};

export const getLatestTrackerConfig = (
  tracker: Tracker.Data,
  searches: Search.CreationData[],
): Application.UserLatestTrackerConfig => {
  const [locationIds, languageIds] = [
    new Set<Location.Data["id"]>(),
    new Set<Language.Data["id"]>(),
  ];

  const trackerKeywordsDataSource = tracker.keywordsDataSources[0] || null;

  if (!searches.length)
    return { locationId: null, languageId: null, keywordsDataSource: null };

  for (const { locationId, languageId } of searches) {
    locationIds.add(locationId);
    languageIds.add(languageId);
  }

  if (locationIds.size > 1 || languageIds.size > 1)
    return {
      locationId: null,
      languageId: null,
      keywordsDataSource: trackerKeywordsDataSource,
    };

  const [[latestLocationId = null], [latestLanguageId = null]] = [
    locationIds,
    languageIds,
  ];

  return {
    locationId: latestLocationId,
    languageId: latestLanguageId,
    keywordsDataSource: trackerKeywordsDataSource,
  };
};

export const filterTrackers = ({
  query,
  trackers,
  authorId,
  keywordsDataSource,
}: {
  query: string;
  trackers: Tracker.Data[];
  authorId: Tracker.Data["authorId"];
  keywordsDataSource: Search.KeywordsDataSource | "all";
}): Tracker.Data[] => {
  const filteredTrackers = new Set<Tracker.Data>();

  const [
    isKeywordsDataSourceFilterActive,
    isAuthorIdFilterActive,
    isQueryFilterActive,
  ] = [keywordsDataSource !== "all", authorId !== "all", Boolean(query)];

  for (const tracker of trackers) {
    if (
      isKeywordsDataSourceFilterActive &&
      isKeywordsDataSourceTypeGuard(keywordsDataSource)
    ) {
      const isFilteredByKeywordsDataSource =
        tracker.keywordsDataSources.includes(keywordsDataSource);

      if (!isFilteredByKeywordsDataSource) continue;
    }

    if (isAuthorIdFilterActive) {
      const isFilteredByAuthorId = tracker.authorId === authorId;

      if (!isFilteredByAuthorId) continue;
    }

    if (isQueryFilterActive) {
      const isFilteredByQuery = stringFilter(tracker.name, query);

      if (!isFilteredByQuery) continue;
    }

    filteredTrackers.add(tracker);
  }

  return Array.from(filteredTrackers);
};
