import {
  createEntityAdapter,
  createAsyncThunk,
  createSlice,
} from "@reduxjs/toolkit";

import * as api from "./locationsApi";
import { PRIORITIZED_LOCATION_ID } from "src/constants";
import { onStateFulfilled, onStatePending, onStateRejected } from "../utils";

export const locationsAdapter = createEntityAdapter<Location.Data>({
  sortComparer: locationsDefaultSort,
});

const initialState = locationsAdapter.getInitialState<Store.InitialState>({
  status: "idle",
  error: null,
});

export const fetchAllLocations = createAsyncThunk<Location.Data[]>(
  "locations/fetch-all",
  api.getLocations,
);

const locationsSlice = createSlice({
  name: "locations",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAllLocations.pending, onStatePending);
    builder.addCase(fetchAllLocations.rejected, onStateRejected);
    builder.addCase(fetchAllLocations.fulfilled, (...args) => {
      onStateFulfilled(...args);
      locationsAdapter.setAll(...args);
    });
  },
});

export default locationsSlice.reducer;

function locationsDefaultSort(a: Location.Data, b: Location.Data): number {
  const [isAPrioritizedLocationId, isBPrioritizedLocationId] = [
    a.id === PRIORITIZED_LOCATION_ID,
    b.id === PRIORITIZED_LOCATION_ID,
  ];

  if (isAPrioritizedLocationId !== isBPrioritizedLocationId)
    return isAPrioritizedLocationId ? -1 : 1;

  const [[countryA, regionA, cityA], [countryB, regionB, cityB]] = [
    [a.country || "", a.region || "", a.city || ""],
    [b.country || "", b.region || "", b.city || ""],
  ];

  return (
    countryA.localeCompare(countryB) ||
    regionA.localeCompare(regionB) ||
    cityA.localeCompare(cityB)
  );
}
