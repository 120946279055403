import { ComponentType, forwardRef } from "react";
import cx from "classnames";

import styles from "./withError.module.scss";

type InjectedProps = {
  error?: string;
  info?: string;
  errorBorderColor?: string;
  errorTextColor?: string;
};

export const withError = <BaseProps extends object>(
  Component: ComponentType<BaseProps>,
) =>
  forwardRef<HTMLElement, BaseProps & InjectedProps>(
    (
      { error, errorBorderColor, errorTextColor, info, ...componentProps },
      ref,
    ) => (
      <div className={styles.withError}>
        <Component ref={ref} {...(componentProps as BaseProps)} />
        {error && (
          <div
            className={cx(styles.text, styles.error)}
            style={{ borderColor: errorBorderColor, color: errorTextColor }}
            data-text={error}
          />
        )}
        {info && !error && (
          <div className={cx(styles.text, styles.info)} data-text={info} />
        )}
      </div>
    ),
  );
