import { useEffect, useRef } from "react";

export const useUnmountEffect = (callback: () => void): void => {
  const willComponentUnmount = useRef<boolean>(false);

  useEffect(
    () => () => {
      willComponentUnmount.current = true;
    },
    [],
  );

  useEffect(
    () => () => {
      if (!willComponentUnmount.current) return;

      callback();
    },
    [callback],
  );
};
