import { FC, useMemo } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import styles from "./Billing.module.scss";
import {
  selectCompany,
  selectHasSubscriptionPlanManageAccess,
} from "src/store/selectors";

// Inner imports
import {
  BillingDetailsForm,
  SubscriptionPlanInfo,
  SubscriptionPlanUsage,
} from "./components";

const Billing: FC = () => {
  const { t } = useTranslation();

  const { stripeCustomerId, fortnoxCustomerId } = useSelector(selectCompany);

  const { hasSubscriptionPlanManageAccess } = useSelector(
    selectHasSubscriptionPlanManageAccess,
  );

  const isBillingDetailsShown = useMemo<boolean>(
    () =>
      !fortnoxCustomerId &&
      Boolean(stripeCustomerId) &&
      Boolean(hasSubscriptionPlanManageAccess),
    [fortnoxCustomerId, stripeCustomerId, hasSubscriptionPlanManageAccess],
  );

  const isSubscriptionPlanUsageShown = useMemo<boolean>(
    () => Boolean(hasSubscriptionPlanManageAccess),
    [hasSubscriptionPlanManageAccess],
  );

  return (
    <div className={styles.wrapper}>
      <div className={styles.section}>
        <span className={styles.title}>
          {t("page.settings.subscription_plan.label.current_subscription_plan")}
        </span>
        <SubscriptionPlanInfo />
      </div>
      {isSubscriptionPlanUsageShown && (
        <div className={styles.section}>
          <span className={styles.title}>
            {t("page.settings.subscription_plan.label.subscription_plan_usage")}
          </span>
          <SubscriptionPlanUsage />
        </div>
      )}
      {isBillingDetailsShown && (
        <div className={styles.section}>
          <span className={styles.title}>
            {t("page.settings.subscription_plan.label.billing_details")}
          </span>
          <BillingDetailsForm />
        </div>
      )}
    </div>
  );
};

export default Billing;
