import { FC, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import styles from "./SelectTrackerPerspectivesModal.module.scss";
import { ConfirmModal } from "src/features";
import { Form, Input } from "src/components";
import { useElementFocus, useModal } from "src/hooks";
import {
  TRACKER_INPUT_LIMIT,
  DEFAULT_TRACKER_PERSPECTIVE,
} from "src/constants";

type Props = {
  perspective: Tracker.Perspective;
  submitHandler: (value: Tracker.Perspective) => void;
};

export const SelectTrackerPerspectiveModal: FC<Props> = ({
  submitHandler,
  perspective: defaultPerspective,
}) => {
  const { t } = useTranslation();

  const { closeModal } = useModal();

  const [ref, setFocus] = useElementFocus<HTMLInputElement>();

  const [perspective, setPerspective] =
    useState<Tracker.Perspective>(defaultPerspective);

  const hasDefaultPerspective = useMemo<boolean>(
    () => Boolean(defaultPerspective),
    [defaultPerspective],
  );

  useEffect(() => setFocus(), [setFocus]);

  const onSubmit = (): void => {
    submitHandler(perspective);

    closeModal("select-tracker-perspective");
  };

  const onClear = (): void => {
    submitHandler(DEFAULT_TRACKER_PERSPECTIVE);

    closeModal("select-tracker-perspective");
  };

  return (
    <ConfirmModal
      id="select-tracker-perspective"
      type="success"
      size="medium"
      title={t("component.modal.select_tracker_perspective.title")}
      extraButtons={[
        {
          onClick: onClear,
          buttonStyle: "transparent",
          style: { display: hasDefaultPerspective ? "initial" : "none" },
          text: t("component.modal.select_tracker_perspective.button.clear"),
        },
      ]}
      cancelButton={{
        text: t("component.modal.select_tracker_perspective.button.cancel"),
        onClick: () => closeModal("select-tracker-perspective"),
      }}
      acceptButton={{
        text: t("component.modal.select_tracker_perspective.button.submit"),
        onClick: onSubmit,
      }}
    >
      <Form className={styles.formWrapper} onSubmit={onSubmit}>
        <Input
          ref={ref}
          value={perspective}
          changeHandler={setPerspective}
          placeholder={t(
            "component.modal.select_tracker_perspective.form.placeholder.perspective",
          )}
          characterLimit={TRACKER_INPUT_LIMIT}
        />
      </Form>
    </ConfirmModal>
  );
};
