import { useEffect, useState } from "react";

export const useCurrentTime = (delay: number = 1000): Date => {
  const [currentTime, setCurrentTime] = useState<Date>(new Date());

  useEffect(() => {
    const intervalId = setInterval(() => setCurrentTime(new Date()), delay);

    return () => clearInterval(intervalId);
  }, [delay]);

  return currentTime;
};
