export const getMemberName = ({
  firstName,
  lastName,
  email,
}: Company.Member): string => {
  switch (true) {
    case Boolean(firstName && lastName):
      return `${firstName} ${lastName}`;
    case Boolean(firstName && !lastName):
      return firstName;
    case Boolean(!firstName && lastName):
      return lastName;
    default:
      return email;
  }
};
