import { FC } from "react";

import styles from "./SearchSharePreview.module.scss";
import { NoWidgetDataStub } from "../../../WidgetTile/components";

export const SearchSharePreview: FC<Widget.PreviewProps> = ({
  views,
  view,
}) => {
  if (!views.length)
    return (
      <div className={styles.widgetPreview}>
        <NoWidgetDataStub />
      </div>
    );

  return (
    <div className={styles.widgetPreview}>
      <div className={styles.chartWrapper}>{view?.chart}</div>
    </div>
  );
};
