import { FC, useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import styles from "./ConfirmDashboardDeletionModal.module.scss";
import { useAppDispatch } from "src/store";
import { MultiSelect } from "src/components";
import { showToastNotification } from "src/utils";
import { useGlobalPreloader, useModal } from "src/hooks";
import { ConfirmModal, TrackerSelectOption } from "src/features";
import { selectTrackersCollectionSpecificTrackers } from "src/store/selectors";
import { MultiSelectOption } from "src/components/selectors/MultiSelect/types";
import {
  removeTrackers,
  removeDashboard,
  removeTrackersCollection,
} from "src/store/actions";

type Props = {
  trackersCollectionId: TrackersCollection.Data["id"];
};

export const ConfirmDashboardDeletionModal: FC<Props> = ({
  trackersCollectionId,
}) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const { showGlobalPreloader, hideGlobalPreloader } = useGlobalPreloader();

  const { closeModal } = useModal();

  const trackers = useSelector((state: Store.RootState) =>
    selectTrackersCollectionSpecificTrackers(state, trackersCollectionId),
  );

  const defaultSelectedTrackersIds = useMemo<Tracker.Data["id"][]>(
    () => trackers.map(({ id }) => id),
    [trackers],
  );

  const [selectedTrackerIds, setSelectedTrackerIds] = useState<
    Tracker.Data["id"][]
  >(defaultSelectedTrackersIds);

  const trackerOptions = useMemo<MultiSelectOption[]>(() => {
    const trackerOptions = new Set<MultiSelectOption>();

    for (const tracker of trackers)
      trackerOptions.add({
        value: tracker.id,
        label: tracker.name,
        renderLabel: () => <TrackerSelectOption tracker={tracker} />,
      });

    return [...trackerOptions].sort((a, b) => a.label.localeCompare(b.label));
  }, [trackers]);

  const selectedTrackerOptions = useMemo<MultiSelectOption[]>(() => {
    const selectedTrackerOptions = new Set<MultiSelectOption>();

    for (const id of selectedTrackerIds) {
      const tracker = trackers.find((value) => value.id === id);

      if (!tracker) continue;

      selectedTrackerOptions.add({ value: id, label: tracker.name });
    }

    return [...selectedTrackerOptions];
  }, [selectedTrackerIds, trackers]);

  const isDeleteTrackersShown = useMemo<boolean>(
    () => Boolean(trackerOptions.length),
    [trackerOptions.length],
  );

  const onConfirmDeleteDashboard = useCallback(async () => {
    showGlobalPreloader();

    try {
      await Promise.all([
        dispatch(removeDashboard(trackersCollectionId)).unwrap(),
        dispatch(removeTrackersCollection(trackersCollectionId)).unwrap(),
        selectedTrackerIds.length &&
          dispatch(removeTrackers(selectedTrackerIds)).unwrap(),
      ]);

      showToastNotification({
        type: "success",
        text: t(
          "component.modal.delete_dashboard.status.success.dashboard_deleted",
        ),
      });
    } catch (error) {
      console.error(error);

      showToastNotification({
        type: "error",
        text: t("common.error.server_error_reload"),
      });
    } finally {
      closeModal("confirm-dashboard-deletion");

      hideGlobalPreloader();
    }
  }, [
    t,
    dispatch,
    closeModal,
    selectedTrackerIds,
    hideGlobalPreloader,
    showGlobalPreloader,
    trackersCollectionId,
  ]);

  const onSelectTrackerId = ({ value }: Option): void => {
    const isTrackerIdIncluded = selectedTrackerIds.includes(value);

    const updatedSelectedTrackerIds = isTrackerIdIncluded
      ? selectedTrackerIds.filter((id) => id !== value)
      : [...selectedTrackerIds, value];

    return setSelectedTrackerIds(updatedSelectedTrackerIds);
  };

  const onSelectAllTrackerIds = (): void => {
    const [selectedTrackerOptionsLength, trackerOptionsLength] = [
      selectedTrackerOptions.length,
      trackerOptions.length,
    ];

    const updatedSelectedTrackerOptions =
      selectedTrackerOptionsLength === trackerOptionsLength
        ? []
        : trackerOptions.map(({ value }) => value);

    return setSelectedTrackerIds(updatedSelectedTrackerOptions);
  };

  return (
    <ConfirmModal
      id="confirm-dashboard-deletion"
      type="error"
      title={t("component.modal.delete_dashboard.title")}
      cancelButton={{
        onClick: () => closeModal("confirm-dashboard-deletion"),
        text: t("component.modal.delete_dashboard.button.cancel"),
      }}
      acceptButton={{
        buttonType: "error",
        onClick: onConfirmDeleteDashboard,
        text: t("component.modal.delete_dashboard.button.submit"),
      }}
    >
      <div className={styles.wrapper}>
        <span>
          {t("component.modal.delete_dashboard.label.delete_dashboard")}
        </span>
        {isDeleteTrackersShown && (
          <div className={styles.trackersWrapper}>
            <span>
              {t("component.modal.delete_dashboard.label.delete_trackers")}
            </span>
            <div className={styles.wrapper}>
              <MultiSelect
                hasSelectAll={false}
                options={trackerOptions}
                inputClassName={styles.input}
                openingDirection="bottom-start"
                onCheckHandler={onSelectTrackerId}
                selectedOptions={selectedTrackerOptions}
                onSelectAllHandler={onSelectAllTrackerIds}
              />
            </div>
          </div>
        )}
      </div>
    </ConfirmModal>
  );
};
