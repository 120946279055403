import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";

import { updateUserLastActiveAt } from "src/store/user/userApi";
import { showDevelopmentError, triggerGtmEvent } from "src/utils";
import {
  selectUser,
  selectUserId,
  selectCompany,
  selectCompanySubscriptionPlan,
} from "src/store/selectors";

export const useUpdateUserLastActiveAt = () => {
  const userId = useSelector(selectUserId);

  useEffect(() => {
    if (!userId) return;

    updateUserLastActiveAt(userId).catch((error) =>
      showDevelopmentError({
        error,
        additionalTexts: ["Error in updateUserLastActiveAt"],
      }),
    );
  }, [userId]);
};

export const useUpdateUserGtmData = (): void => {
  const user = useSelector(selectUser);

  const company = useSelector(selectCompany);

  const subscriptionPlan = useSelector(selectCompanySubscriptionPlan);

  const {
    id: userId,
    email,
    firstName,
    lastName,
    createdAt,
  } = useMemo<User.Data>(() => user, [user]);

  const {
    id: companyId,
    whiteLabel,
    subscriptionPlanId,
  } = useMemo<Company.Data>(() => company, [company]);

  const isDataLoaded = useMemo<boolean>(
    () => Boolean(userId && companyId && subscriptionPlan?.id),
    [companyId, subscriptionPlan?.id, userId],
  );

  useEffect(() => {
    if (!isDataLoaded) return;

    triggerGtmEvent("UserUpdateIntercomData", {
      userId,
      companyId,
      userEmail: email,
      isVersion4: true,
      subscriptionPlanId,
      whiteLabelId: whiteLabel,
      userCreatedAt: createdAt,
      companyName: company.name,
      userName: `${firstName} ${lastName}`,
      companyCreatedAt: company.createdAt,
      subscriptionPlanName: subscriptionPlan?.name || "",
      companyMembersCount: Object.keys(company.members).length,
    });
  }, [
    email,
    userId,
    firstName,
    lastName,
    createdAt,
    companyId,
    whiteLabel,
    company.name,
    isDataLoaded,
    company.members,
    company.createdAt,
    subscriptionPlanId,
    subscriptionPlan?.name,
  ]);
};
