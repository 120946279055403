import { useEffect, useState } from "react";

import {
  Keyword,
  KeywordsTableKeywordData,
  KeywordsTableProps,
} from "../types";

type Props = Pick<KeywordsTableProps, "data" | "isDuplicatedKeywordsShown">;

export const useKeywordsTableExpand = ({
  data,
  isDuplicatedKeywordsShown,
}: Props) => {
  const [expandedRowKeys, setExpandedRowKeys] = useState<Keyword[]>([]);

  useEffect(() => {
    if (!isDuplicatedKeywordsShown) return;

    const defaultExpandedRowKeys = data.map(({ value }) => value);

    setExpandedRowKeys(defaultExpandedRowKeys);
  }, [data, isDuplicatedKeywordsShown]);

  const onExpandChange = (isExpanded: boolean, rowKey: never): void => {
    const _rowKey = rowKey as KeywordsTableKeywordData;

    setExpandedRowKeys((state) => {
      const newState = new Set<Keyword>(state);

      if (!isExpanded) newState.delete(_rowKey.value);
      else newState.add(_rowKey.value);

      return [...newState];
    });
  };

  return {
    expandedRowKeys,
    onExpandChange,
  };
};
