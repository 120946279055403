import { FC, useMemo } from "react";
import isAfter from "date-fns/isAfter";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import differenceInDays from "date-fns/differenceInDays";

import styles from "./SubscriptionPlanInfo.module.scss";
import { Translation } from "src/components";
import { UpdatePlanButton } from "src/features";
import {
  selectCompany,
  selectApplicationInfo,
  selectCompanySubscriptionPlan,
} from "src/store/selectors";

export const SubscriptionPlanInfo: FC = () => {
  const { t } = useTranslation();

  const company = useSelector(selectCompany);

  const { sessionDate } = useSelector(selectApplicationInfo);

  const subscriptionPlan = useSelector(selectCompanySubscriptionPlan);

  const isSubscriptionPlanExpirationDateShown = useMemo<boolean>(
    () =>
      Boolean(
        subscriptionPlan &&
          subscriptionPlan.category === "trial" &&
          company.subscriptionPlanExpirationDate,
      ),
    [company.subscriptionPlanExpirationDate, subscriptionPlan],
  );

  const subscriptionPlanExpirationDateInDays = useMemo<number>(() => {
    if (
      !subscriptionPlan ||
      subscriptionPlan.category !== "trial" ||
      !company.subscriptionPlanExpirationDate
    )
      return 0;

    const [formattedSessionDate, formattedSubPlanExpirationDate] = [
      new Date(sessionDate),
      new Date(company.subscriptionPlanExpirationDate),
    ];

    const isSubscriptionPlanExpired = isAfter(
      formattedSessionDate,
      formattedSubPlanExpirationDate,
    );

    if (isSubscriptionPlanExpired) return 0;

    return differenceInDays(
      formattedSubPlanExpirationDate,
      formattedSessionDate,
    );
  }, [company.subscriptionPlanExpirationDate, sessionDate, subscriptionPlan]);

  const isInvoiceCustomer = useMemo<boolean>(
    () => Boolean(company.fortnoxCustomerId),
    [company.fortnoxCustomerId],
  );

  const updateButtonLabel = useMemo<string>(
    () =>
      isInvoiceCustomer
        ? t("page.settings.subscription_plan.button.contact_us")
        : t("page.settings.subscription_plan.button.update_subscription_plan"),
    [isInvoiceCustomer, t],
  );

  if (!subscriptionPlan) return null;

  return (
    <div className={styles.wrapper}>
      <span className={styles.name}>{subscriptionPlan.name}</span>
      <div className={styles.content}>
        {isSubscriptionPlanExpirationDateShown && (
          <span className={styles.info}>
            <Translation
              i18nKey="page.settings.subscription_plan.label.subscription_plan_expire"
              values={{ count: subscriptionPlanExpirationDateInDays }}
            />
          </span>
        )}
        <UpdatePlanButton
          buttonStyle="outlined"
          className={styles.button}
          preselectedComponent="sidebar"
        >
          <span>{updateButtonLabel}</span>
        </UpdatePlanButton>
      </div>
    </div>
  );
};
