import { useCallback, useMemo, useState } from "react";

import { sortValues } from "src/utils";

type Sort<T> = Partial<Record<keyof T, SortDirection>> | null;

type Props<T> = {
  defaultSort?: Sort<T>;
  items: T[];
  customPreSort?: (a: T, b: T) => number;
  customAfterSort?: (a: T, b: T) => number;
};

type ReturnType<T> = {
  sortedItems: T[];
  sort: Sort<T>;
  setSort: (field: keyof T, direction?: SortDirection) => void;
};

export const useSortedItems = <T extends {}>({
  defaultSort = null,
  items,
  customPreSort,
  customAfterSort,
}: Props<T>): ReturnType<T> => {
  const [sort, setSort] = useState<Sort<T>>(defaultSort);

  const _setSort = useCallback(
    (field: keyof T, direction?: SortDirection): void => {
      let newSort: Sort<T>;

      if (direction) {
        newSort = <Sort<T>>{ [field]: direction };
      } else {
        const previousField = Object.keys(sort || {})[0];
        const previousDirection = sort?.[field];

        if (field === previousField) {
          newSort =
            previousDirection === "DESC"
              ? <Sort<T>>{ [field]: "ASC" }
              : defaultSort;
        } else {
          newSort = <Sort<T>>{ [field]: "DESC" };
        }
      }

      return setSort(newSort);
    },
    [defaultSort, sort],
  );

  const sortedItems = useMemo<T[]>(() => {
    if (!sort) return items;

    const [sortField, sortDirection] = [
      Object.keys(sort)[0] as keyof T,
      Object.values(sort)[0] as SortDirection,
    ];

    if (!sortField || !sortDirection) return items;

    const _sortField = sortField as keyof T;

    return [...items].sort((a, b) => {
      const [valueA, valueB] = [a[_sortField] ?? "", b[_sortField] ?? ""];

      // PreSort - sorting before main (e.x. keep isPinned/isActive/... items on top)
      // MainSort - main sorting chosen by user (e.x. sort by name/createdAt/updatedAt/...)
      // AfterSort - sorting after main (e.x. if name/searchId/dashboardId/... is equal for 2 items, sort them by createdAt/updatedAt/...)
      const [preSort, mainSort, afterSort] = [
        customPreSort ? customPreSort(a, b) : 0,
        sortValues(valueA, valueB, sortDirection),
        customAfterSort ? customAfterSort(a, b) : 0,
      ];

      return preSort || mainSort || afterSort;
    });
  }, [sort, items, customPreSort, customAfterSort]);

  return {
    sortedItems,
    sort,
    setSort: _setSort,
  };
};
