import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";

import { shadeColor } from "src/utils";
import { onStateFulfilled, onStatePending, onStateRejected } from "../utils";
import * as api from "./whiteLabelApi";

export const WHITE_LABEL_INITIAL_DATA: WhiteLabel.Data = {
  id: "",
  subDomainName: "",
  name: "",
  countryCode: "",
  explorePageTopics: [],
  promptSuggestions: [],
  features: {
    signupEnabled: false,
  },
  defaultDashboard: {
    layouts: {
      large: [],
      medium: [],
      small: [],
    },
    tiles: {},
  },
  logos: {
    big: "",
    medium: "",
    small: "",
    favicon: "",
  },
  styles: {
    fontFamily: "",
    primaryColor: "",
    secondaryColor: "",
  },
  widgetNames: {
    "widget-articles": null,
    "widget-insights": null,
    "widget-search-share": null,
    "widget-volume-share": null,
    "widget-top-keywords": null,
    "widget-trending-keywords": null,
  },
};

const whiteLabelAdapter = createEntityAdapter<WhiteLabel.Data>();

const initialState = whiteLabelAdapter.getInitialState<Store.InitialState>({
  status: "idle",
  error: null,
});

export const fetchWhiteLabel = createAsyncThunk<WhiteLabel.Data>(
  "white-label/fetch-by-id",
  async () => {
    const whiteLabel = await api.getWhiteLabel();

    applyWhiteLabelStyles(whiteLabel);

    return whiteLabel;
  },
);

const whiteLabelSlice = createSlice({
  name: "white-label",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchWhiteLabel.pending, onStatePending);
    builder.addCase(fetchWhiteLabel.rejected, onStateRejected);
    builder.addCase(fetchWhiteLabel.fulfilled, (...args) => {
      whiteLabelAdapter.addOne(...args);
      onStateFulfilled(...args);
    });
  },
});

export default whiteLabelSlice.reducer;

function applyWhiteLabelStyles({
  styles: { primaryColor, fontFamily },
  logos: { favicon },
  name,
}: WhiteLabel.Data): void {
  if (name) document.title = name;

  if (favicon)
    document
      .querySelector<HTMLLinkElement>(`link[rel="icon"]`)
      ?.setAttribute("href", favicon);

  if (primaryColor) {
    document.documentElement.style.setProperty("--color-brand", primaryColor);

    document.documentElement.style.setProperty(
      "--color-brand-dark",
      shadeColor(primaryColor, 1.05),
    );
  }

  if (fontFamily)
    document.documentElement.style.setProperty(
      "--font-family-heading",
      fontFamily,
    );
}
