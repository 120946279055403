import { FC, memo } from "react";
import { useTranslation } from "react-i18next";

import styles from "./EmptyTablePlaceholder.module.scss";
import { EmptyTable } from "src/assets/icons";

type Props = {
  label?: string | JSX.Element;
};

export const EmptyTablePlaceholder: FC<Props> = memo(({ label }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.emptyTable}>
      <EmptyTable />
      <p className={styles.emptyTableLabel}>
        {label || t("table_empty_label")}
      </p>
    </div>
  );
});
