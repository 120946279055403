import { FC, useMemo } from "react";
import cx from "classnames";

import styles from "./SuggestedTracker.module.scss";
import { Button } from "src/components";
import { MinusCircleOutline, PlusCircleOutline } from "src/assets/icons";

type Props = {
  tracker: Tracker.CreationData;
  selectedTrackers: Tracker.CreationData[];
  selectTrackerHandler: (value: Tracker.CreationData) => void;
  unselectTrackerHandler: (value: Tracker.CreationData) => void;
};

export const SuggestedTracker: FC<Props> = ({
  tracker,
  selectedTrackers,
  selectTrackerHandler,
  unselectTrackerHandler,
}) => {
  const description = useMemo<string>(
    () => tracker.description || "",
    [tracker],
  );

  const isSelected = useMemo<boolean>(
    () =>
      selectedTrackers.some(
        (selectedTracker) => selectedTracker.id === tracker.id,
      ),
    [selectedTrackers, tracker],
  );

  const onClick = (): void => {
    if (isSelected) unselectTrackerHandler(tracker);
    else selectTrackerHandler(tracker);
  };

  return (
    <Button
      type="button"
      onClick={onClick}
      shouldPreventEnter
      buttonStyle="outlined"
      className={cx(styles.wrapper, isSelected ? styles.wrapperActive : "")}
    >
      <div className={styles.content}>
        <span className={styles.name}>{tracker.name}</span>
        <span className={styles.description}>{description}</span>
      </div>
      {isSelected ? <MinusCircleOutline /> : <PlusCircleOutline />}
    </Button>
  );
};
