import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import styles from "../TopKeywordsPreview.module.scss";
import { TableChart, TagCloudChart } from "src/components";
import { arrayInsert, formatToLocaleNumber } from "src/utils";
import { selectWidgetData } from "src/store/widgets/widgetsSelectors";
import { TableChartColumn } from "src/components/charts/TableChart/types";
import {
  selectTrackersEntities,
  selectTrackersCollectionById,
} from "src/store/selectors";

// Inner imports
import { WIDGET_ID } from "../constants";
import {
  renderKeywordValue,
  renderKeywordTracker,
  prepareTopKeywordsForTagCloud,
} from "../utils";

export const useTopKeywordsCharts = ({
  trackerIds,
  isExpanded,
  trackersCollectionId,
  dashboardDateRangeId,
}: Widget.ChartProps): Widget.Chart[] => {
  const { t } = useTranslation();

  const widgetData = useSelector((state: Store.RootState) =>
    selectWidgetData(state, dashboardDateRangeId, WIDGET_ID),
  );

  const trackers = useSelector(selectTrackersEntities);

  const trackersCollection = useSelector((state: Store.RootState) =>
    selectTrackersCollectionById(state, trackersCollectionId),
  );

  const trackersCollectionTrackerIds = useMemo<
    TrackersCollection.Data["trackerIds"]
  >(
    () => trackersCollection?.trackerIds || [],
    [trackersCollection?.trackerIds],
  );

  const formattedData = useMemo<Widgets.TopKeywords.KeywordData[]>(() => {
    if (!widgetData?.data) return [];

    const formattedData = new Set<Widgets.TopKeywords.KeywordData>();

    for (const trackerId of trackerIds) {
      const trackerData = widgetData.data.listChart[trackerId];

      if (!trackerData) continue;

      for (const data of trackerData) formattedData.add(data);
    }

    return [...formattedData].sort((a, b) => b.volume - a.volume);
  }, [widgetData?.data, trackerIds]);

  const tableColumns = useMemo<
    TableChartColumn<Widgets.TopKeywords.KeywordData>[]
  >(() => {
    const hasMultipleTrackers = trackersCollectionTrackerIds.length > 1;

    const columns: TableChartColumn<Widgets.TopKeywords.KeywordData>[] = [
      {
        key: "keyword",
        label: t("widget.top_keywords.table.label.keyword"),
        style: { padding: "15px 0", textTransform: "capitalize" },
        valueFormatter: (value) =>
          renderKeywordValue(value, trackers, trackersCollectionTrackerIds),
      },
      {
        width: "20%",
        key: "volume",
        style: { padding: "15px 0", justifyContent: "flex-end" },
        label: t("widget.top_keywords.table.label.search_volume"),
        valueFormatter: ({ volume }) => formatToLocaleNumber(volume),
      },
    ];

    if (isExpanded && hasMultipleTrackers)
      arrayInsert(columns, 1, {
        width: "25%",
        key: "trackerId",
        style: { padding: "15px 0" },
        label: t("widget.top_keywords.table.label.tracker_name"),
        valueFormatter: (value) => renderKeywordTracker(value, trackers),
      });

    return columns;
  }, [isExpanded, t, trackers, trackersCollectionTrackerIds]);

  return useMemo<Widget.Chart[]>(() => {
    const charts: Widget.Chart[] = [];

    if (formattedData.length) {
      const tagsData = prepareTopKeywordsForTagCloud(
        formattedData,
        trackersCollectionTrackerIds,
      );

      charts.push({
        type: "keywords",
        chart: (
          <TagCloudChart
            hasHoverValue
            tags={tagsData}
            tagStyle="filled"
            icon="TopKeywords"
            className={styles.cloudWrapper}
            tagClassName={styles.cloudKeyword}
            valueFormatter={formatToLocaleNumber}
            tagCloudClassName={styles.cloudKeywords}
          />
        ),
      });
    }

    if (formattedData.length)
      charts.push({
        type: "table",
        chart: (
          <TableChart
            hasHead={isExpanded}
            data={formattedData}
            columns={tableColumns}
            className={styles.tableWrapper}
            defaultSort={{ volume: "DESC" }}
          />
        ),
      });

    return charts;
  }, [isExpanded, formattedData, tableColumns, trackersCollectionTrackerIds]);
};
