import { FC, memo, useMemo } from "react";
import Pagination, { ReactPaginateProps } from "react-paginate";

import styles from "./TablePagination.module.scss";
import { ChevronLeft, ChevronRight } from "src/assets/icons";

type Props = ReactPaginateProps & {
  totalLabel?: JSX.Element | string;
};

export const TablePagination: FC<Props> = memo(
  ({
    pageCount,
    totalLabel = false,
    pageRangeDisplayed = 1,
    marginPagesDisplayed = 2,
    ...props
  }) => {
    const isTotalLabelShown = useMemo<boolean>(
      () => Boolean(totalLabel),
      [totalLabel],
    );

    const isPaginationButtonsShown = useMemo<boolean>(
      () => pageCount > 1,
      [pageCount],
    );

    return (
      <div className={styles.wrapper}>
        {isTotalLabelShown && (
          <div className={styles.totalLabel}>{totalLabel}</div>
        )}
        {isPaginationButtonsShown && (
          <Pagination
            pageCount={pageCount}
            previousLabel={<ChevronLeft />}
            nextLabel={<ChevronRight />}
            marginPagesDisplayed={marginPagesDisplayed}
            pageRangeDisplayed={pageRangeDisplayed}
            containerClassName={styles.pagination}
            pageLinkClassName={styles.paginationItem}
            activeLinkClassName={styles.paginationActiveItem}
            breakLinkClassName={styles.paginationBreakItem}
            nextLinkClassName={styles.paginationNavigation}
            previousLinkClassName={styles.paginationNavigation}
            disabledClassName={styles.paginationDisabled}
            {...props}
          />
        )}
      </div>
    );
  },
);
