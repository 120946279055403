import { FC, memo, useMemo } from "react";

import styles from "./ArticlesChartSortDropdown.module.scss";
import { Select } from "src/components";
import type { ArticlesSortKey } from "../../types";

// Inner imports
import { ARTICLES_SORT, ARTICLES_SORT_OPTIONS } from "./constants";

type Props = {
  sortBy: keyof Widgets.ArticlesChart.Article;
  onSortChange: (key: ArticlesSortKey, direction: SortDirection) => void;
  sortOptions?: ArticlesSortKey[];
};

export const ArticlesChartSortDropdown: FC<Props> = memo(
  ({
    sortBy,
    onSortChange,
    sortOptions: _sortOptions = ["title", "date", "score"],
  }) => {
    const sortOptions = useMemo<Option[]>(
      () =>
        ARTICLES_SORT_OPTIONS.filter(({ value }) =>
          _sortOptions.includes(value),
        ),
      [_sortOptions],
    );

    const onOptionSelect = (value: string): void => {
      const key = value as ArticlesSortKey;

      onSortChange(key, ARTICLES_SORT[key]);
    };

    return (
      <div className={styles.wrapper}>
        <Select
          selectClassName={styles.select}
          inputClassName={styles.input}
          dropdownClassName={styles.dropdown}
          value={sortBy}
          changeHandler={onOptionSelect}
          options={sortOptions}
          hasFilter
          openingDirection="bottom-end"
        />
      </div>
    );
  },
);
