import { FC, useMemo } from "react";
import { useSelector } from "react-redux";

import { selectTrackerById } from "src/store/selectors";
import { renderTrackerKeywordsDataSourcesIcon } from "./utils";

type Props = {
  trackerId?: Tracker.Data["id"];
  keywordsDataSources?: Search.KeywordsDataSource[];
};

export const TrackerKeywordsDataSourcesIcon: FC<Props> = ({
  trackerId = "",
  keywordsDataSources = [],
}) => {
  const tracker = useSelector((state: Store.RootState) =>
    selectTrackerById(state, trackerId),
  );

  return useMemo<JSX.Element | null>(
    () => renderTrackerKeywordsDataSourcesIcon(tracker, keywordsDataSources),
    [keywordsDataSources, tracker],
  );
};
