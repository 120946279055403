import {
  memo,
  useMemo,
  forwardRef,
  ChangeEvent,
  InputHTMLAttributes,
} from "react";
import cx from "classnames";
import isString from "lodash/isString";
import { useTranslation } from "react-i18next";

import { limitString, sanitizeValue } from "src/utils";

import styles from "./Input.module.scss";

type InputProps = {
  title?: string;
  className?: string;
  wordLimit?: number;
  inputClassName?: string;
  characterLimit?: number;
  changeHandler?: (value: string) => void;
} & Omit<InputHTMLAttributes<HTMLInputElement>, "className">;

export const Input = memo(
  forwardRef<HTMLInputElement, InputProps>(
    (
      {
        title = "",
        value,
        className = "",
        wordLimit,
        inputClassName = "",
        characterLimit,
        changeHandler = () => "",
        ...props
      },
      ref,
    ) => {
      const { t } = useTranslation();

      const valueWords = useMemo<string[]>(() => {
        if (!value || !isString(value)) return [];

        return value.split(" ").filter(Boolean);
      }, [value]);

      const onInputChange = (e: ChangeEvent<HTMLInputElement>): void => {
        const formattedValue = limitString(e.target.value, {
          wordLimit,
          characterLimit,
        });

        const sanitizedValue = sanitizeValue(formattedValue);

        changeHandler(sanitizedValue);
      };

      const hasCharacterLimit = useMemo<boolean>(
        () => Boolean(characterLimit && isString(value)),
        [characterLimit, value],
      );

      const hasWordLimit = useMemo<boolean>(
        () => Boolean(wordLimit && isString(value)),
        [wordLimit, value],
      );

      const characterLimitText = useMemo<string>(() => {
        if (!characterLimit || !isString(value)) return "";

        return t("component.input.label.limit", {
          length: value.length,
          limit: characterLimit,
        });
      }, [characterLimit, value, t]);

      const wordLimitText = useMemo<string>(() => {
        if (!wordLimit || !isString(value)) return "";

        return t("component.input.label.limit", {
          limit: wordLimit,
          length: valueWords.length,
        });
      }, [wordLimit, value, t, valueWords.length]);

      return (
        <div className={cx(styles.inputWrapper, className)}>
          <input
            ref={ref}
            title={title}
            value={value}
            className={cx(styles.input, inputClassName)}
            {...props}
            onChange={onInputChange}
          />
          <div className={styles.limitWrapper}>
            {hasCharacterLimit && (
              <div className={styles.limit}>
                <span>{characterLimitText}</span>
              </div>
            )}
            {hasWordLimit && (
              <div className={styles.limit}>
                <span>{wordLimitText}</span>
              </div>
            )}
          </div>
        </div>
      );
    },
  ),
  memoFunction,
);

function memoFunction(prevState: InputProps, nextState: InputProps) {
  const {
    value: prevStateValue,
    type: prevStateType,
    className: prevStateClassName,
    disabled: prevStateDisabled,
  } = prevState;
  const {
    value: nextStateValue,
    type: nextStateType,
    className: nextStateClassName,
    disabled: nextStateDisabled,
  } = nextState;

  const isValueSame = prevStateValue === nextStateValue;
  const isTypeSame = prevStateType === nextStateType;
  const isClassNameSame = prevStateClassName === nextStateClassName;
  const isDisabledSame = prevStateDisabled === nextStateDisabled;

  return isValueSame && isTypeSame && isClassNameSame && isDisabledSame;
}
