import * as yup from "yup";

export const keywordLineChartDataSchema = yup
  .object({
    date: yup.string().required(),
    volume: yup.number().required(),
  })
  .required();

export type KeywordLineChartData = yup.InferType<
  typeof keywordLineChartDataSchema
>[];
