import { FC, memo } from "react";

import { TablePagination } from "src/components";

const WIDGET_ARTICLES_PAGINATION_PROPS = {
  pageRangeDisplayed: 3,
  marginPagesDisplayed: 1,
};

type Props = {
  pageNumber: number;
  pagesCount: number;
  setPageNumber: (pageNumber: number) => void;
};

export const ArticlesChartPagination: FC<Props> = memo(
  ({ pageNumber, pagesCount, setPageNumber }) => (
    <TablePagination
      pageCount={pagesCount}
      forcePage={pageNumber}
      onPageChange={({ selected }) => setPageNumber(selected)}
      {...WIDGET_ARTICLES_PAGINATION_PROPS}
    />
  ),
);
