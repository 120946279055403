import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { useAppDispatch } from "src/store";
import { checkIsCompanyExists } from "src/store/company/companyApi";
import { showDevelopmentError, showToastNotification } from "src/utils";
import { getUserLatestTrackerConfig } from "src/store/trackers/trackersApi";
import {
  fetchUser,
  fetchCompany,
  fetchWhiteLabel,
  fetchAllDashboards,
  fetchAllWidgetsInfo,
  fetchEventsByCompanyId,
  updateLatestTrackerConfig,
  fetchTrackersByCompanyId,
  fetchExternalCompanyById,
  fetchSubscriptionPlanById,
  fetchTrackersCollectionsByCompanyId,
} from "src/store/actions";
import {
  selectUser,
  selectUserId,
  selectCompany,
  selectCompanyId,
  selectCompanyById,
  selectEventsStatus,
  selectUserTrackers,
  selectUserCompanyId,
  selectTrackersStatus,
  selectDashboardsStatus,
  selectWhiteLabelStatus,
  selectWidgetsInfoStatus,
  selectCompanySubscriptionPlan,
  selectTrackersCollectionsStatus,
} from "src/store/selectors";

export const useUserDataLoader = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const { id: userId } = useSelector(selectUser);

  const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>("idle");

  useEffect(() => {
    if (loadingStatus === "succeeded" && !userId)
      return setLoadingStatus("idle");

    if (loadingStatus !== "idle" || !userId) return;

    setLoadingStatus("loading");

    dispatch(fetchUser(userId))
      .unwrap()
      .then(() => setLoadingStatus("succeeded"))
      .catch((error) => {
        errorHandler(userId, "user", error);

        setLoadingStatus("failed");

        showToastNotification({ type: "error", text: t("user_data_error") });
      });
  }, [userId, loadingStatus, dispatch, t]);

  return {
    loadingStatus,
  };
};

export const useCompanyDataLoader = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const { id: userId, companyId: userCompanyId } = useSelector(selectUser);

  const { id: companyId } = useSelector(selectCompany);

  const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>("idle");

  useEffect(() => {
    if (
      loadingStatus === "succeeded" &&
      (!companyId || userCompanyId !== companyId)
    )
      return setLoadingStatus("idle");

    if (loadingStatus !== "idle" || !userCompanyId) return;

    setLoadingStatus("loading");

    dispatch(fetchCompany(userCompanyId))
      .unwrap()
      .then(() => setLoadingStatus("succeeded"))
      .catch((error) => {
        errorHandler(userId, "company", error);

        setLoadingStatus("failed");

        showToastNotification({ type: "error", text: t("user_data_error") });
      });
  }, [t, userId, dispatch, companyId, loadingStatus, userCompanyId]);

  return { loadingStatus };
};

export const useExternalCompanyDataLoader = (companyId: Company.Data["id"]) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>("idle");

  const company = useSelector((state: Store.RootState) =>
    selectCompanyById(state, companyId),
  );

  useEffect(() => {
    if (!companyId || loadingStatus !== "idle" || company) return;

    (async () => {
      try {
        setLoadingStatus("loading");

        const isCompanyExists = await checkIsCompanyExists(companyId);

        if (isCompanyExists)
          await dispatch(fetchExternalCompanyById(companyId)).unwrap();

        setLoadingStatus("succeeded");
      } catch (error) {
        setLoadingStatus("failed");

        errorHandler(companyId, "company", error);

        showToastNotification({ type: "error", text: t("user_data_error") });
      }
    })();
  }, [companyId, loadingStatus, dispatch, t, company]);

  return { loadingStatus };
};

export const useWhiteLabelDataLoader = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const userId = useSelector(selectUserId);
  const loadingStatus = useSelector(selectWhiteLabelStatus);

  useEffect(() => {
    if (loadingStatus !== "idle") return;

    (async () => {
      try {
        await dispatch(fetchWhiteLabel()).unwrap();
      } catch (error) {
        errorHandler(userId, "company", error);
        showToastNotification({ type: "error", text: t("user_data_error") });
      }
    })();
  }, [userId, loadingStatus, dispatch, t]);

  return { loadingStatus };
};

export const useDashboardsLoader = () => {
  const dispatch = useAppDispatch();

  const loadingStatus = useSelector(selectDashboardsStatus);
  const companyId = useSelector(selectUserCompanyId);

  useEffect(() => {
    if (loadingStatus !== "idle" || !companyId) return;

    (async () => {
      try {
        await dispatch(fetchAllDashboards(companyId)).unwrap();
      } catch (error) {
        const errorTitle = "ERROR IN ALL DASHBOARDS FETCHING";

        showDevelopmentError({ additionalTexts: [errorTitle], error });
      }
    })();
  }, [loadingStatus, companyId, dispatch]);

  return { loadingStatus };
};

export const useEventsLoader = () => {
  const dispatch = useAppDispatch();

  const loadingStatus = useSelector(selectEventsStatus);
  const companyId = useSelector(selectUserCompanyId);

  useEffect(() => {
    if (loadingStatus !== "idle" || !companyId) return;

    (async () => {
      try {
        await dispatch(fetchEventsByCompanyId(companyId)).unwrap();
      } catch (error) {
        const errorTitle = "ERROR IN ALL EVENTS FETCHING";

        showDevelopmentError({ additionalTexts: [errorTitle], error });
      }
    })();
  }, [loadingStatus, companyId, dispatch]);

  return { loadingStatus };
};

export const useSubscriptionPlanLoader = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const { id: userId } = useSelector(selectUser);

  const { subscriptionPlanId: companySubscriptionPlanId } =
    useSelector(selectCompany);

  const subscriptionPlan = useSelector(selectCompanySubscriptionPlan);

  const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>("idle");

  useEffect(() => {
    if (loadingStatus === "succeeded" && !subscriptionPlan)
      return setLoadingStatus("idle");

    if (loadingStatus !== "idle" || !companySubscriptionPlanId) return;

    setLoadingStatus("loading");

    dispatch(fetchSubscriptionPlanById(companySubscriptionPlanId))
      .unwrap()
      .then(() => setLoadingStatus("succeeded"))
      .catch((error) => {
        errorHandler(userId, "subscriptionPlan", error);

        setLoadingStatus("failed");

        showToastNotification({ type: "error", text: t("user_data_error") });
      });
  }, [
    t,
    userId,
    dispatch,
    loadingStatus,
    subscriptionPlan,
    companySubscriptionPlanId,
  ]);

  return { loadingStatus };
};

export const useTrackersLoader = () => {
  const dispatch = useAppDispatch();

  const companyId = useSelector(selectUserCompanyId);

  const loadingStatus = useSelector(selectTrackersStatus);

  useEffect(() => {
    if (loadingStatus !== "idle" || !companyId) return;

    (async () => {
      try {
        await dispatch(fetchTrackersByCompanyId(companyId)).unwrap();
      } catch (error) {
        const errorTitle = "ERROR IN COMPANY TRACKERS FETCHING";
        showDevelopmentError({ additionalTexts: [errorTitle], error });
      }
    })();
  }, [loadingStatus, companyId, dispatch]);

  return { loadingStatus };
};

export const useTrackersCollectionsLoader = () => {
  const dispatch = useAppDispatch();

  const companyId = useSelector(selectUserCompanyId);

  const loadingStatus = useSelector(selectTrackersCollectionsStatus);

  useEffect(() => {
    if (loadingStatus !== "idle" || !companyId) return;

    (async () => {
      try {
        await dispatch(fetchTrackersCollectionsByCompanyId(companyId)).unwrap();
      } catch (error) {
        const errorTitle = "ERROR IN COMPANY TRACKERS COLLECTIONS FETCHING";
        showDevelopmentError({ additionalTexts: [errorTitle], error });
      }
    })();
  }, [loadingStatus, companyId, dispatch]);

  return { loadingStatus };
};

export const useWidgetsDataLoader = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const userId = useSelector(selectUserId);
  const loadingStatus = useSelector(selectWidgetsInfoStatus);

  useEffect(() => {
    if (loadingStatus !== "idle") return;

    (async () => {
      try {
        await dispatch(fetchAllWidgetsInfo()).unwrap();
      } catch (error) {
        errorHandler(userId, "widgetsData", error);
        showToastNotification({ type: "error", text: t("user_data_error") });
      }
    })();
  }, [userId, loadingStatus, dispatch, t]);

  return { loadingStatus };
};

export const useUserLatestTrackerConfigLoader = (
  trackersLoadingStatus: LoadingStatus,
) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const userId = useSelector(selectUserId);

  const companyId = useSelector(selectCompanyId);

  const trackers = useSelector(selectUserTrackers);

  const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>("idle");

  useEffect(() => {
    if (trackersLoadingStatus !== "succeeded") return;

    if (loadingStatus === "succeeded" && !userId)
      return setLoadingStatus("idle");

    if (loadingStatus !== "idle") return;

    if (!trackers.length) {
      setLoadingStatus("succeeded");

      return;
    }

    setLoadingStatus("loading");

    getUserLatestTrackerConfig(trackers)
      .then((value) => {
        dispatch(updateLatestTrackerConfig(value));

        setLoadingStatus("succeeded");
      })
      .catch((error) => {
        errorHandler(userId, "latestTrackerConfig", error);

        setLoadingStatus("failed");

        showToastNotification({ type: "error", text: t("user_data_error") });
      });
  }, [
    t,
    userId,
    dispatch,
    trackers,
    companyId,
    loadingStatus,
    trackersLoadingStatus,
  ]);

  return { loadingStatus };
};

function errorHandler(
  userId: string,
  type:
    | "user"
    | "company"
    | "whiteLabel"
    | "searches"
    | "subscriptionPlan"
    | "widgetsData"
    | "latestTrackerConfig",
  error: any,
) {
  let typeError = "";

  switch (type) {
    case "user":
      typeError = "Error occurred in readUser function";
      break;
    case "company":
      typeError = "Error occurred in readCompany function";
      break;
    case "whiteLabel":
      typeError = "Error occurred in readWhiteLabel function";
      break;
    case "searches":
      typeError = "Error occurred in readSearches function";
      break;
    case "subscriptionPlan":
      typeError = "Error occurred in readSubscriptionPlan function";
      break;
    case "widgetsData":
      typeError = "Error occurred in readWidgetsData function";
      break;
    case "latestTrackerConfig":
      typeError = "Error occurred in readLatestTrackerConfig function";
      break;
  }

  showDevelopmentError({
    error,
    additionalTexts: [typeError],
    reportMetadata: {
      message: `[${userId}]: Error on loading initial data for user: ${error.message}`,
    },
  });
}
