import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";

import styles from "./EnterpriseSubscriptionPlanDescription.module.scss";
import { Translation } from "src/components";
import { CheckMark } from "src/assets/icons";

// Inner imports
import { SubscriptionPlanDescriptionSection } from "../../types";
import { formatSubscriptionPlanDescriptionSection } from "../utils";
import {
  SUBSCRIPTION_PLAN_CUSTOM_LIMITS,
  ENTERPRISE_SUBSCRIPTION_PLAN_DESCRIPTION_SECTIONS,
} from "../../constants";

export const EnterpriseSubscriptionPlanDescription: FC = () => {
  const { t } = useTranslation();

  const formattedDescriptionSections = useMemo<
    SubscriptionPlanDescriptionSection[]
  >(
    () =>
      ENTERPRISE_SUBSCRIPTION_PLAN_DESCRIPTION_SECTIONS.map((section) =>
        formatSubscriptionPlanDescriptionSection(
          section,
          SUBSCRIPTION_PLAN_CUSTOM_LIMITS,
          t,
        ),
      ),
    [t],
  );

  return (
    <div className={styles.wrapper}>
      <span className={styles.title}>
        <Translation
          i18nKey="page.settings.subscription_plan.label.subscription_plan_features"
          values={{
            name: t(
              "page.settings.subscription_plan.label.custom_subscription_plan",
            ),
          }}
        />
      </span>
      <div className={styles.sectionsWrapper}>
        {formattedDescriptionSections.map(({ key, labelKey, count }) => (
          <div className={styles.section} key={key}>
            <CheckMark />
            <Translation i18nKey={labelKey} values={{ count }} />
          </div>
        ))}
      </div>
    </div>
  );
};
