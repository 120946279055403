import { FC, useMemo } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import styles from "./SubscriptionPlanDescription.module.scss";
import { CheckMark } from "src/assets/icons";
import { Translation } from "src/components";
import { selectSubscriptionPlanById } from "src/store/selectors";

// Inner imports
import {
  formatSubscriptionPlanDescriptionSection,
  calculateSubscriptionPlanTrackerPriceMonthly,
} from "../utils";
import { SubscriptionPlanDescriptionSection } from "../../types";
import {
  SUBSCRIPTION_PLAN_DESCRIPTION_SECTIONS,
  SUBSCRIPTION_PLAN_FREE_DESCRIPTION_SECTIONS,
  SUBSCRIPTION_PLAN_TRIAL_DESCRIPTION_SECTIONS,
} from "../../constants";

type Props = {
  billingPeriod: SubscriptionPlan.BillingPeriod;
  subscriptionPlanId: SubscriptionPlan.Data["id"];
};

export const SubscriptionPlanDescription: FC<Props> = ({
  billingPeriod,
  subscriptionPlanId,
}) => {
  const { t } = useTranslation();

  const subscriptionPlan = useSelector((state: Store.RootState) =>
    selectSubscriptionPlanById(state, subscriptionPlanId),
  );

  const formattedDescriptionSections = useMemo<
    SubscriptionPlanDescriptionSection[]
  >(() => {
    if (!subscriptionPlan) return [];

    const { trackers, team, dashboards, events } = subscriptionPlan.limits;

    let sections: SubscriptionPlanDescriptionSection[] =
      SUBSCRIPTION_PLAN_DESCRIPTION_SECTIONS;

    if (subscriptionPlan.category === "free")
      sections = SUBSCRIPTION_PLAN_FREE_DESCRIPTION_SECTIONS;

    if (subscriptionPlan.category === "trial")
      sections = SUBSCRIPTION_PLAN_TRIAL_DESCRIPTION_SECTIONS;

    const price = calculateSubscriptionPlanTrackerPriceMonthly(
      subscriptionPlan,
      billingPeriod,
    );

    return sections.map((section) =>
      formatSubscriptionPlanDescriptionSection(
        section,
        { team, events, trackers, dashboards, price },
        t,
      ),
    );
  }, [billingPeriod, subscriptionPlan, t]);

  if (!subscriptionPlan) return null;

  return (
    <div className={styles.wrapper}>
      <span className={styles.title}>
        <Translation
          i18nKey="page.settings.subscription_plan.label.subscription_plan_features"
          values={{ name: subscriptionPlan.name }}
        />
      </span>
      <div className={styles.sectionsWrapper}>
        {formattedDescriptionSections.map(({ key, labelKey, count }) => (
          <div className={styles.section} key={key}>
            <CheckMark />
            <span>
              <Translation i18nKey={labelKey} values={{ count }} />
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};
