import { useInsightsActionButtons } from "../../Components/Insights/hooks";

export const WIDGET_ACTION_BUTTONS_HOOKS: Record<
  Widget.IdType,
  (props: Widget.ChartProps) => Widget.ActionButton[]
> = {
  "widget-insights": useInsightsActionButtons,
  "widget-search-share": () => [],
  "widget-top-keywords": () => [],
  "widget-trending-keywords": () => [],
  "widget-volume-share": () => [],
  "widget-articles": () => [],
};
