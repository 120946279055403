import { useEffect, useState } from "react";

export const useAbortController = (): AbortController => {
  const [controller, setController] = useState<AbortController>(
    new AbortController(),
  );

  useEffect(() => {
    if (controller.signal.aborted) setController(new AbortController());
  }, [controller.signal.aborted]);

  return controller;
};
