import firestore from "src/services/firestore";
import { COLLECTION_IDS } from "../constants";

// Inner imports
import * as schemas from "./applicationSchema";

export const subscribeOnVersionInfo = (
  callback: (data: Application.VersionInfo) => void,
): (() => void) => {
  const docRef = firestore().doc(COLLECTION_IDS.applicationVersionInfo);

  return docRef.onSnapshot((doc) => {
    try {
      const validatedData = schemas.versionInfoSchema.validateSync(doc.data());

      callback(validatedData);
    } catch (error) {
      console.error(error);
    }
  });
};

export const subscribeOnKeywordToolSettings = (
  callback: (data: Application.KeywordsSettings) => void,
): (() => void) => {
  const docRef = firestore().doc(COLLECTION_IDS.keywordsSettings);

  return docRef.onSnapshot((doc) => {
    try {
      const { latestKeywordsDateMap } =
        schemas.keywordsSettingsSchema.validateSync(doc.data());

      callback(latestKeywordsDateMap);
    } catch (error) {
      console.error(error);
    }
  });
};

export const subscribeOnHardReloadInfo = (
  callback: (data: Application.HardReloadInfo) => void,
): (() => void) => {
  const docRef = firestore().doc(COLLECTION_IDS.hardReloadInfo);

  return docRef.onSnapshot((doc) => {
    try {
      const validatedData = schemas.hardReloadSchema.validateSync(doc.data());

      callback(validatedData);
    } catch (error) {
      console.error(error);
    }
  });
};
