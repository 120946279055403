import compareAsc from "date-fns/compareAsc";

// Inner imports
import { FormattedMember } from "./types";

export const membersAfterSort = (
  a?: FormattedMember,
  b?: FormattedMember,
): number => {
  const [
    { name: aName = "", email: aEmail = "", lastActiveAt: aLastActiveAt = "" },
    { name: bName = "", email: bEmail = "", lastActiveAt: bLastActiveAt = "" },
  ] = [a || {}, b || {}];

  const [lastActiveAtCompare, nameCompare, emailCompare] = [
    compareAsc(Date.parse(bLastActiveAt), Date.parse(aLastActiveAt)),
    aName.localeCompare(bName),
    aEmail.localeCompare(bEmail),
  ];

  return lastActiveAtCompare || nameCompare || emailCompare;
};

export const calculateCompanyRoleValue = (member: Company.Member): number => {
  switch (member.companyRole) {
    case "owner":
      return 3;
    case "admin":
      return 2;
    case "member":
      return 1;
    default:
      return 0;
  }
};
