export const getSearchesKeywordsDataSources = (
  searches: (Search.Data | Search.CreationData)[],
): Search.KeywordsDataSource[] => {
  const keywordsDataSources = new Set<Search.KeywordsDataSource>();

  for (const { keywordsDataSource } of searches)
    keywordsDataSources.add(keywordsDataSource);

  return Array.from(keywordsDataSources);
};
