import { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import BarLoader from "react-spinners/BarLoader";
import BeatLoader from "react-spinners/BeatLoader";
import ScaleLoader from "react-spinners/ScaleLoader";
import cx from "classnames";

import styles from "./Preloader.module.scss";
import { getElementContrastedColor } from "src/utils";
import { selectWhiteLabel } from "src/store/selectors";

// Inner imports
import { PRELOADER_COLOR_DARK, PRELOADER_COLOR_LIGHT } from "./constants";

type Props = {
  className?: string;
  color?: string;
  type?: "scale" | "bar" | "beat";
  modifier?: "global" | "inner";
  text?: string;
};

export const Preloader = ({
  text = "",
  type = "scale",
  modifier,
  className = "",
  color: defaultColor = "",
}: Props) => {
  const { styles: whiteLabelStyles } = useSelector(selectWhiteLabel);

  const [preloaderColor, setPreloaderColor] = useState<string>(
    defaultColor || whiteLabelStyles.primaryColor,
  );

  const PreloaderComponent = useMemo<JSX.Element>(() => {
    switch (type) {
      case "beat":
        return <BeatLoader color={preloaderColor} size="10px" loading />;
      case "bar":
        return (
          <BarLoader color={preloaderColor} width="80px" height="4px" loading />
        );
      case "scale":
      default:
        return (
          <ScaleLoader
            color={preloaderColor}
            width="8px"
            height="40px"
            loading
          />
        );
    }
  }, [preloaderColor, type]);

  const refCallback = useCallback(
    (element: HTMLElement | null): void => {
      const parentElement = element?.parentElement;

      if (!element || !parentElement || defaultColor) return;

      const color = getElementContrastedColor({
        element,
        colors: [PRELOADER_COLOR_LIGHT, PRELOADER_COLOR_DARK],
        parentElement,
      });

      setPreloaderColor(color);
    },
    [defaultColor],
  );

  return (
    <div
      className={cx(
        styles.preloader,
        modifier ? styles["preloader_" + modifier] : "",
        className,
      )}
      style={{ color: preloaderColor }}
      ref={refCallback}
    >
      {PreloaderComponent}
      {text && <span style={{ color: preloaderColor }}>{text}</span>}
    </div>
  );
};
