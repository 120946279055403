import { TFunction } from "i18next";

import { showToastNotification } from "src/utils";
import { sendVerifyEmail } from "src/store/user/userApi";

export const showWarningAndSendNewLink = (
  userId: User.Data["id"],
  err: string,
  whiteLabel: WhiteLabel.Data,
  t: TFunction,
) => {
  if (!userId) {
    return showToastNotification({
      type: "warning",
      text: t("authentication.email_confirmation.status.error.broken_link"),
    });
  }

  const isUserNotFound = err.toLowerCase().includes("not found");

  let warningText = "";

  switch (true) {
    case isUserNotFound:
      warningText = t(
        "authentication.email_confirmation.status.error.user_not_found",
      );
      break;
    case err === "jwt expired":
      sendVerifyEmail(userId, whiteLabel);
      warningText = t(
        "authentication.email_confirmation.status.error.token_expired",
      );
      break;
    default:
      sendVerifyEmail(userId, whiteLabel);
      warningText = t("authentication.email_confirmation.status.error");
  }

  showToastNotification({
    type: "warning",
    text: warningText,
  });
};
