import { useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";

export const useLocationState = () => {
  const history = useHistory();

  const location = useLocation<LocationState>();

  const locationState = useMemo<LocationState>(
    () => location.state || {},
    [location.state],
  );

  const setLocationState = (value: LocationState): void =>
    history.replace(location.pathname, { ...locationState, ...value });

  return { locationState, setLocationState };
};
