import * as yup from "yup";

const trackersCollectionSchema = yup
  .object({
    authorId: yup.string().required(),
    companyId: yup.string().required(),

    trackerIds: yup.array(yup.string().default("")).default([]),

    name: yup.string().default(""),
    tags: yup.array(yup.string().default("")).default([]),

    createdAt: yup.string().default(""),
    updatedAt: yup.string().default(""),
  })
  .noUnknown()
  .required();

export const trackerQueryDefinitionSchema = yup.string().required();

export default trackersCollectionSchema;
