import { useState, useRef, useEffect } from "react";

export const useTemporaryErrors = (ms = 3000) => {
  const [errors, setErrors] = useState<Errors>({});
  const timeout = useRef({});

  useEffect(() => {
    if (Object.keys(errors).length) {
      if (typeof timeout.current === "number") clearTimeout(timeout.current);
      timeout.current = setTimeout(() => {
        setErrors({});
      }, ms);
    }
    return () => {
      if (typeof timeout.current === "number") clearTimeout(timeout.current);
    };
  }, [errors, ms]);

  return { errors, setErrors };
};
