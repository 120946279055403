import { FC, useMemo } from "react";

import { Transition } from "src/components";
import { CreateTrackerStep } from "../../types";

type Props = {
  step: CreateTrackerStep;
  currentStep: CreateTrackerStep;
};

// const TRANSITION_TIMEOUT: number = 500;

export const CreateTrackersStep: FC<Props> = ({
  step,
  children,
  currentStep,
}) => {
  // const previousCurrentStep = usePreviousState(currentStep);

  const isCurrentStep = useMemo<boolean>(
    () => step === currentStep,
    [currentStep, step],
  );

  // const stepIndex = useMemo<number>(
  //   () => CREATE_TRACKERS_STEPS.indexOf(step),
  //   [step],
  // );

  // const nextStep = useMemo<CreateTrackerStep>(
  //   () => CREATE_TRACKERS_STEPS[stepIndex + 1] || CREATE_TRACKERS_DEFAULT_STEP,
  //   [stepIndex],
  // );

  // const enterClassName = useMemo<string | undefined>(
  //   () =>
  //     previousCurrentStep === nextStep
  //       ? styles.slideInTop
  //       : styles.slideInBottom,
  //   [nextStep, previousCurrentStep],
  // );

  // const exitClassName = useMemo<string | undefined>(
  //   () =>
  //     currentStep === nextStep ? styles.slideOutTop : styles.slideOutBottom,
  //   [currentStep, nextStep],
  // );

  if (!isCurrentStep) return null;

  return (
    <Transition
      appear
      unmountOnExit
      in={isCurrentStep}
      // timeout={TRANSITION_TIMEOUT}
      // classNames={{ exit: exitClassName, enter: enterClassName }}
    >
      {children}
    </Transition>
  );
};
