import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";

import styles from "./SuggestedPerspectives.module.scss";
import { Preloader } from "src/components";

// Inner imports
import { CustomPerspective, SuggestedPerspective } from "./components";

type Props = {
  selectedPerspective: Tracker.Perspective;
  suggestedPerspectives: Tracker.Perspective[];
  selectPerspectiveHandler: (value: Tracker.Perspective) => void;
  unselectPerspectiveHandler: (value: Tracker.Perspective) => void;
  isSuggestedTrackersLoading: boolean;
  isSuggestedPerspectivesLoading: boolean;
};

export const SuggestedPerspectives: FC<Props> = ({
  selectedPerspective,
  suggestedPerspectives,
  selectPerspectiveHandler,
  unselectPerspectiveHandler,
  isSuggestedTrackersLoading,
  isSuggestedPerspectivesLoading,
}) => {
  const { t } = useTranslation();

  const SuggestedPerspectives = useMemo<JSX.Element>(() => {
    switch (true) {
      case Boolean(suggestedPerspectives.length):
        return (
          <>
            {isSuggestedPerspectivesLoading && (
              <div className={styles.loaderWrapper}>
                <Preloader
                  type="bar"
                  text={t(
                    "page.create_tracker.select_trackers.loader.suggested_perspectives",
                  )}
                />
              </div>
            )}
            {suggestedPerspectives.map((perspective) => (
              <SuggestedPerspective
                key={perspective}
                isDisabled={isSuggestedTrackersLoading}
                perspective={perspective}
                selectedPerspective={selectedPerspective}
                selectPerspectiveHandler={selectPerspectiveHandler}
                unselectPerspectiveHandler={unselectPerspectiveHandler}
              />
            ))}
            <CustomPerspective
              isDisabled={isSuggestedTrackersLoading}
              selectedPerspective={selectedPerspective}
              suggestedPerspectives={suggestedPerspectives}
              selectPerspectiveHandler={selectPerspectiveHandler}
            />
          </>
        );
      case isSuggestedPerspectivesLoading:
        return (
          <Preloader
            type="bar"
            className={styles.loaderWrapper}
            text={t(
              "page.create_tracker.select_trackers.loader.suggested_perspectives",
            )}
          />
        );
      default:
        return (
          <div className={styles.placeholder}>
            {t(
              "page.create_tracker.select_trackers.placeholder.suggested_perspectives",
            )}
          </div>
        );
    }
  }, [
    t,
    selectedPerspective,
    suggestedPerspectives,
    selectPerspectiveHandler,
    unselectPerspectiveHandler,
    isSuggestedTrackersLoading,
    isSuggestedPerspectivesLoading,
  ]);

  return <div className={styles.wrapper}>{SuggestedPerspectives}</div>;
};
