import { useMemo, useCallback } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useModal, useTrackerBlocker } from "src/hooks";
import { useAppDispatch } from "src/store";
import { MenuOption } from "src/components/MenuDropdown/types";
import { updateDashboard, fetchWidgets } from "src/store/actions";
import { ROUTES, DASHBOARD_VISIBILITY_OPTIONS } from "src/constants";
import { isWidgetIdTypeGuard, showToastNotification } from "src/utils";
import { getDashboardDefaultLayoutSettings } from "src/store/dashboards/utils";
import {
  CreateEventModal,
  ShareDashboardModal,
  AddTrackerToCompare,
  RenameDashboardModal,
  DashboardEventsSidebar,
  ConfirmDashboardDeletionModal,
  TrackerKeywordsDataSourcesIcon,
} from "src/features";
import {
  selectUser,
  selectWhiteLabel,
  selectWidgetsInfo,
  selectDashboardById,
  selectIsCompanyDashboard,
  selectTrackersCollectionTrackers,
  selectAvailableWidgetIdsByCompanyId,
} from "src/store/selectors";

type Props = {
  closeSidebar: () => void;
  setSidebarContent: (content: JSX.Element) => void;
  trackersCollectionId: TrackersCollection.Data["id"];
  dashboardDateRangeId: DashboardDateRange.Data["id"];
  setExpandedWidgetId: (value: Widget.IdType | null) => void;
};

export const useDashboardSettingsOptions = ({
  closeSidebar,
  setSidebarContent,
  setExpandedWidgetId,
  trackersCollectionId,
  dashboardDateRangeId,
}: Props): MenuOption[] => {
  const { t } = useTranslation();

  const history = useHistory();

  const dispatch = useAppDispatch();

  const { setModal, closeModal } = useModal();

  const { hasTrackerAccess, showSubscriptionPlanLimitNotification } =
    useTrackerBlocker();

  const dashboard = useSelector((state: Store.RootState) =>
    selectDashboardById(state, trackersCollectionId),
  );

  const dashboardCompanyId = useMemo<Company.Data["id"]>(
    () => dashboard?.companyId || "",
    [dashboard?.companyId],
  );

  const trackers = useSelector((state: Store.RootState) =>
    selectTrackersCollectionTrackers(state, trackersCollectionId),
  );

  const user = useSelector(selectUser);

  const widgetsInfo = useSelector(selectWidgetsInfo);

  const isCompanyDashboard = useSelector((state: Store.RootState) =>
    selectIsCompanyDashboard(state, trackersCollectionId),
  );

  const availableWidgetIds = useSelector((state: Store.RootState) =>
    selectAvailableWidgetIdsByCompanyId(state, dashboardCompanyId),
  );

  const { defaultDashboard: whiteLabelDefaultDashboard } =
    useSelector(selectWhiteLabel);

  const isUserDashboardOwner = useMemo<boolean>(
    () => user.id === dashboard?.authorId,
    [dashboard?.authorId, user.id],
  );

  const onRenameTrackersViewClickHandler = useCallback(
    (): void =>
      setModal(
        "rename-dashboard",
        <RenameDashboardModal dashboardId={trackersCollectionId} />,
      ),
    [trackersCollectionId, setModal],
  );

  const onResetDashboardLayout = useCallback(async (): Promise<void> => {
    const { layouts, tiles } = getDashboardDefaultLayoutSettings({
      widgetsInfo,
      availableWidgetIds: [...availableWidgetIds],
      whiteLabelDefaultDashboard,
    });

    const widgetIdsToFetch: Widget.IdType[] = [];

    for (const widgetId in tiles) {
      if (!isWidgetIdTypeGuard(widgetId)) continue;

      const isWidgetAdded = Boolean(dashboard?.tiles[widgetId]);

      if (!isWidgetAdded) widgetIdsToFetch.push(widgetId);
    }

    try {
      await dispatch(
        updateDashboard({
          id: trackersCollectionId,
          changes: { tiles, layouts },
        }),
      ).unwrap();

      await dispatch(
        fetchWidgets({
          dashboardDateRangeId,
          trackersCollectionId,
          widgetIds: widgetIdsToFetch,
        }),
      ).unwrap();

      setExpandedWidgetId(null);
    } catch (error) {
      showToastNotification({
        type: "error",
        text: t("common.error.server_error"),
      });

      console.error(error);
    }
  }, [
    t,
    dispatch,
    widgetsInfo,
    dashboard?.tiles,
    availableWidgetIds,
    setExpandedWidgetId,
    trackersCollectionId,
    dashboardDateRangeId,
    whiteLabelDefaultDashboard,
  ]);

  const onDeleteTrackersViewClickHandler = useCallback(
    (): void =>
      setModal(
        "confirm-dashboard-deletion",
        <ConfirmDashboardDeletionModal
          trackersCollectionId={trackersCollectionId}
        />,
      ),
    [setModal, trackersCollectionId],
  );

  const onEditTrackerClick = useCallback(
    ({ id }: Tracker.Data): void => {
      if (!hasTrackerAccess) return showSubscriptionPlanLimitNotification();

      return history.push(`${ROUTES.trackersPage}/edit/${id}`, {
        redirectUrl: history.location.pathname,
      });
    },
    [history, hasTrackerAccess, showSubscriptionPlanLimitNotification],
  );

  const onChangeVisibilityClick = useCallback(
    async (visibility: Dashboard.Visibility): Promise<void> => {
      if (!isUserDashboardOwner) return;

      try {
        await dispatch(
          updateDashboard({
            id: trackersCollectionId,
            changes: { visibility },
          }),
        ).unwrap();

        showToastNotification({
          type: "success",
          text: t("page.dashboard.status.success.dashboard_visibility_updated"),
        });
      } catch (error) {
        showToastNotification({
          type: "error",
          text: t("common.error.server_error"),
        });

        console.error(error);
      }
    },
    [isUserDashboardOwner, trackersCollectionId, dispatch, t],
  );

  const onAddTrackerSubmit = useCallback((): void => {
    closeSidebar();

    closeModal("add-tracker");
  }, [closeModal, closeSidebar]);

  const onAddToCompareButtonClick = useCallback((): void => {
    if (!isCompanyDashboard) return;

    setSidebarContent(
      <AddTrackerToCompare
        submitHandler={onAddTrackerSubmit}
        trackersCollectionId={trackersCollectionId}
      />,
    );
  }, [
    setSidebarContent,
    isCompanyDashboard,
    onAddTrackerSubmit,
    trackersCollectionId,
  ]);

  const onShareDashboardClick = useCallback(
    (): void =>
      setModal(
        "share-dashboard",
        <ShareDashboardModal
          dashboardId={trackersCollectionId}
          dashboardDateRangeId={dashboardDateRangeId}
        />,
      ),
    [dashboardDateRangeId, trackersCollectionId, setModal],
  );

  const editTrackerOptions = useMemo<MenuOption[]>(
    () =>
      trackers.map((tracker) => ({
        label: tracker.name,
        onClick: () => onEditTrackerClick(tracker),
        icon: <TrackerKeywordsDataSourcesIcon trackerId={tracker.id} />,
      })),
    [onEditTrackerClick, trackers],
  );

  const changeVisibilityOptions = useMemo<MenuOption[]>(() => {
    const options: MenuOption[] = [];

    for (const option of DASHBOARD_VISIBILITY_OPTIONS) {
      const { value, label, checkIsAvailable } = option;

      if (value === dashboard?.visibility) continue;

      if (checkIsAvailable && !checkIsAvailable(user)) continue;

      options.push({
        label: t(label),
        onClick: () => onChangeVisibilityClick(value),
      });
    }

    return options;
  }, [dashboard?.visibility, onChangeVisibilityClick, t, user]);

  const eventsOptions = useMemo<MenuOption[]>(() => {
    const options: MenuOption[] = [];

    options.push(
      {
        label: t("page.dashboard.button.create_event"),
        onClick: () =>
          setModal(
            "create-event",
            <CreateEventModal dashboardId={trackersCollectionId} />,
          ),
      },
      {
        label: t("page.dashboard.button.open_edit_events"),
        onClick: () =>
          setSidebarContent(
            <DashboardEventsSidebar dashboardId={trackersCollectionId} />,
          ),
      },
    );

    return options;
  }, [t, setModal, trackersCollectionId, setSidebarContent]);

  return useMemo<MenuOption[]>(() => {
    const options: MenuOption[] = [
      {
        icon: "Edit",
        responsivenessType: "tablet",
        options: editTrackerOptions,
        label: t("page.dashboard.button.edit_tracker"),
      },
      {
        icon: "Compare",
        responsivenessType: "tablet",
        onClick: onAddToCompareButtonClick,
        label: t("page.dashboard.button.compare_tracker"),
      },
      {
        icon: "Timer",
        label: t("page.dashboard.label.events"),
        options: eventsOptions,
      },
      {
        icon: "Rename",
        label: t("page.dashboard.button.rename_dashboard"),
        onClick: onRenameTrackersViewClickHandler,
      },
    ];

    if (dashboard?.layoutView === "full")
      options.push({
        icon: "Reset",
        label: t("page.dashboard.button.reset_layout"),
        onClick: onResetDashboardLayout,
      });

    if (isUserDashboardOwner)
      options.push({
        icon: "LockOutlined",
        label: t("page.dashboard.button.update_dashboard_visibility"),
        options: changeVisibilityOptions,
      });

    options.push(
      {
        icon: "LinkOutline",
        responsivenessType: "tablet",
        onClick: onShareDashboardClick,
        label: t("page.dashboard.button.share_dashboard"),
      },
      {
        icon: "TrashFill",
        label: t("page.dashboard.button.delete_dashboard"),
        onClick: onDeleteTrackersViewClickHandler,
      },
    );

    return options;
  }, [
    t,
    eventsOptions,
    editTrackerOptions,
    isUserDashboardOwner,
    dashboard?.layoutView,
    onShareDashboardClick,
    onResetDashboardLayout,
    changeVisibilityOptions,
    onAddToCompareButtonClick,
    onRenameTrackersViewClickHandler,
    onDeleteTrackersViewClickHandler,
  ]);
};
