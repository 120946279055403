const requirements = {
  notEnoughCharacters: "component.password_input.requirements.label.min_length",
  oneLowercaseCharacter:
    "component.password_input.requirements.label.lowercase_character",
  oneUppercaseCharacter:
    "component.password_input.requirements.label.uppercase_character",
  oneNumber: "component.password_input.requirements.label.numbers",
};

const REGEXPS = {
  passwordLengthRegex: new RegExp(".{8,}"),
  oneLowercaseCharacterRegex: new RegExp("(?=.*?[a-z])"),
  oneUppercaseCharacterRegex: new RegExp("(?=.*?[A-Z])"),
  atLeastOneNumberRegex: new RegExp("(?=.*?[0-9])"),
  atLeastOneSpecialSymbol: new RegExp("(?=.*?[#?!@$%^&*-])"),
};

export { requirements, REGEXPS };
