import { LOCATION_WEBSITE_DOMAIN_EXCEPTION } from "src/constants";

const GOOGLE_SEARCH_URL = "https://www.google.com/search";

type SearchLinkType = "image" | "news" | "video";

export const getKeywordSearchLink = ({
  keyword,
  location,
  type,
}: {
  keyword: string;
  location?: Location.Data;
  type?: SearchLinkType;
}): string => {
  const url = new URL(GOOGLE_SEARCH_URL);

  const query = location
    ? getGoogleSearchQueryWithLocation(keyword, location)
    : keyword;

  url.searchParams.append("q", query);

  if (type) url.searchParams.append("tbm", getGoogleSearchTbm(type));

  return url.toString();
};

function getGoogleSearchQueryWithLocation(
  query: string,
  { countryCode = "" }: Location.Data,
): string {
  if (!countryCode) return query;

  const formattedCountryCode =
    LOCATION_WEBSITE_DOMAIN_EXCEPTION[countryCode] ?? countryCode;

  if (!formattedCountryCode) return query;

  return `${query} site:.${formattedCountryCode.toLowerCase()}`;
}

function getGoogleSearchTbm(type?: SearchLinkType): string {
  switch (type) {
    case "image":
      return "isch";
    case "video":
      return "vid";
    case "news":
      return "nws";
    default:
      return "";
  }
}
