export const checkIfElementOverflowsParentVertical = (
  element: HTMLElement,
  parentElement: HTMLElement,
): boolean => {
  const [
    { top: elementTop, bottom: elementBottom },
    { top: parentTop, bottom: parentBottom },
  ] = [calculateElementRect(element), parentElement.getBoundingClientRect()];

  const [isTopOverflown, isBottomOverflown] = [
    parentTop < elementTop,
    parentBottom < elementBottom,
  ];

  return isTopOverflown && isBottomOverflown;
};

function calculateElementRect(element: HTMLElement) {
  const elementRect = element.getBoundingClientRect();

  return {
    top: elementRect.y,
    bottom: elementRect.y + elementRect.height,
    left: elementRect.x,
    right: elementRect.x + elementRect.width,
  };
}
