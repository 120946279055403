import { getIconByName } from "src/utils";
import { MultipleDataSources } from "src/assets/icons";
import { SEARCH_KEYWORDS_DATA_SOURCE_MAP } from "src/constants";

export const renderTrackerKeywordsDataSourcesIcon = (
  tracker?: Tracker.Data,
  keywordsDataSources: Search.KeywordsDataSource[] = [],
): JSX.Element | null => {
  const hasMultipleKeywordsDataSources =
    keywordsDataSources.length > 1 ||
    (tracker ? tracker.keywordsDataSources.length > 1 : false);

  if (hasMultipleKeywordsDataSources) return <MultipleDataSources />;

  if (keywordsDataSources.length) {
    const keywordsDataSource = keywordsDataSources[0];

    if (!keywordsDataSource) return null;

    const iconName = SEARCH_KEYWORDS_DATA_SOURCE_MAP[keywordsDataSource].icon;

    return getIconByName(iconName);
  }

  if (tracker) {
    const keywordsDataSource = tracker.keywordsDataSources[0];

    if (!keywordsDataSource) return null;

    const iconName = SEARCH_KEYWORDS_DATA_SOURCE_MAP[keywordsDataSource].icon;

    return getIconByName(iconName);
  }

  return null;
};
