import { FC } from "react";
import { useTranslation } from "react-i18next";

import styles from "./SearchKeywordsModal.module.scss";
import { useModal } from "src/hooks";
import { ConfirmModal, SearchKeywords } from "src/features";

type Props = {
  search: Search.Data | Search.CreationData;
  keywordsData?: Search.KeywordsData;
};

export const SearchKeywordsModal: FC<Props> = ({ search, keywordsData }) => {
  const { t } = useTranslation();

  const { closeModal } = useModal();

  return (
    <ConfirmModal
      id="search-keywords"
      type="success"
      title={t("component.modal.search_keywords.title")}
      className={styles.wrapper}
    >
      <SearchKeywords
        search={search}
        keywordsData={keywordsData}
        closeSidebarHandler={() => closeModal("search-keywords")}
      />
    </ConfirmModal>
  );
};
