import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { useAppDispatch } from "src/store";
import { WIDGET_IDS } from "src/constants";
import { fetchWidgets } from "src/store/actions";
import {
  selectWidgetData,
  selectDefaultDashboardDateRangeIdByTrackersCollectionId,
} from "src/store/selectors";

const WIDGET_ID_TO_CHECK_DATA_AVAILABILITY: Widget.IdType =
  "widget-volume-share";

type Props = {
  trackersCollectionId: TrackersCollection.Data["id"];
};

export const useDefaultDashboardDateRangeWidgetsDataLoader = ({
  trackersCollectionId,
}: Props) => {
  const dispatch = useAppDispatch();

  const defaultDashboardDateRangeId = useSelector((state: Store.RootState) =>
    selectDefaultDashboardDateRangeIdByTrackersCollectionId(
      state,
      trackersCollectionId,
    ),
  );

  const volumeShareWidgetData = useSelector((state: Store.RootState) =>
    selectWidgetData(
      state,
      defaultDashboardDateRangeId,
      WIDGET_ID_TO_CHECK_DATA_AVAILABILITY,
    ),
  );

  const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>("idle");

  const hasWidgetData = useMemo<boolean>(
    () => Boolean(volumeShareWidgetData?.data),
    [volumeShareWidgetData?.data],
  );

  useEffect(() => {
    if (!defaultDashboardDateRangeId) return;

    if (loadingStatus !== "idle" || hasWidgetData)
      return setLoadingStatus("succeeded");

    setLoadingStatus("idle");

    dispatch(
      fetchWidgets({
        trackersCollectionId,
        widgetIds: Array.from(WIDGET_IDS),
        dashboardDateRangeId: defaultDashboardDateRangeId,
      }),
    )
      .unwrap()
      .then(() => setLoadingStatus("succeeded"))
      .catch(() => setLoadingStatus("failed"));
  }, [
    dispatch,
    loadingStatus,
    hasWidgetData,
    trackersCollectionId,
    defaultDashboardDateRangeId,
  ]);

  return { loadingStatus };
};
