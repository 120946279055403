import { RefObject, useCallback, useEffect, useRef } from "react";

export const useElementOutsideClick = (
  onOutsideClick: (isOpen: boolean) => void,
): RefObject<HTMLDivElement> => {
  const ref = useRef<HTMLDivElement>(null);

  const handleClickOutside = useCallback(
    (e: MouseEvent): void => {
      const isClickOutside = getIsClickOutside(e, ref);

      if (isClickOutside) onOutsideClick(false);
    },
    [ref, onOutsideClick],
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [handleClickOutside]);

  return ref;
};

function getIsClickOutside(
  { target }: MouseEvent,
  ref: RefObject<HTMLElement>,
): boolean {
  if (!ref?.current) return false;

  if (target === ref.current) return false;

  return target === ref.current.parentElement;
}
