export const checkVolumeShareWidgetData = ({
  data: { lineChart, pieChart, tableChart },
}: Omit<Widgets.VolumeShare.Data, "updatedAt">): boolean => {
  for (const trackerId in pieChart) {
    const value = pieChart[trackerId];

    if (value?.volume) return true;
  }

  for (const trackerId in lineChart) {
    const values = lineChart[trackerId];

    if (!values) continue;

    for (const { volume } of values) if (volume) return true;
  }

  for (const trackerId in tableChart) {
    const values = tableChart[trackerId];

    if (!values) continue;

    for (const value of Object.values(values)) if (value) return true;
  }

  return false;
};

export const checkSearchShareWidgetData = ({
  data: { lineChart, pieChart },
}: Omit<Widgets.SearchShare.Data, "updatedAt">): boolean => {
  for (const trackerId in pieChart) {
    const value = pieChart[trackerId];

    if (value?.volume) return true;
  }

  for (const trackerId in lineChart) {
    const values = lineChart[trackerId];

    if (!values) continue;

    for (const { volume } of values) if (volume) return true;
  }

  return false;
};
