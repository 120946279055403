import { useContext } from "react";

import styles from "./TableBody.module.scss";
import { useScrollbarWidth } from "src/hooks";
import { genericMemo } from "src/utils";

// Inner imports
import type { TableItemGenericProps, TableProps } from "../../../../types";
import { TableContext } from "../../../../context";
import { TableRow } from "../";

type Props<T> = {
  isTableEmpty: boolean;
} & Pick<
  TableProps<T>,
  "items" | "tableColumns" | "isTableShort" | "getActionMenuOptions"
>;

export const TableBody = genericMemo(
  <T extends TableItemGenericProps>({
    items,
    tableColumns,
    getActionMenuOptions,
  }: Props<T>) => {
    const { contentRef } = useContext(TableContext);

    const scrollbarWidth = useScrollbarWidth();

    return (
      <tbody
        className={styles.tableBody}
        style={{
          width: `calc(100% + ${scrollbarWidth}px`,
        }}
        ref={contentRef}
      >
        {items.map((item) => (
          <TableRow
            key={item.id}
            item={item}
            tableColumns={tableColumns}
            getActionMenuOptions={getActionMenuOptions}
          />
        ))}
      </tbody>
    );
  },
);
