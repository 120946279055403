import { useCallback, useEffect, useState } from "react";

import { useAppDispatch } from "src/store";
import { subscribeOnSyncsByDashboardDateRangeId } from "src/store/widgetSyncs/widgetSyncsApi";
import {
  addWidgetSync,
  removeWidgetSync,
  updateWidgetSync,
  removeAllWidgetSyncs,
} from "src/store/actions";

export const useDashboardWidgetSyncsObserver = (
  trackersCollectionId: TrackersCollection.Data["id"],
) => {
  const dispatch = useAppDispatch();

  const [isObserverSet, setIsObserverSet] = useState<boolean>(false);

  const callback = (): void => setIsObserverSet(true);

  const addCallback = useCallback(
    (value: WidgetSync.Data): void => {
      dispatch(addWidgetSync(value));
    },
    [dispatch],
  );

  const updateCallback = useCallback(
    (value: WidgetSync.Data): void => {
      dispatch(updateWidgetSync({ id: value.id, changes: value }));
    },
    [dispatch],
  );

  const deleteCallback = useCallback(
    (value: WidgetSync.Data): void => {
      dispatch(removeWidgetSync(value.id));
    },
    [dispatch],
  );

  useEffect(() => {
    if (!trackersCollectionId) return;

    let observer = () => {};

    try {
      observer = subscribeOnSyncsByDashboardDateRangeId({
        callback,
        addCallback,
        deleteCallback,
        updateCallback,
        trackersCollectionId,
      });
    } catch (err) {
      console.error(err);
      setIsObserverSet(true);
    }

    return () => {
      observer();

      dispatch(removeAllWidgetSyncs());
    };
  }, [
    dispatch,
    addCallback,
    deleteCallback,
    updateCallback,
    trackersCollectionId,
  ]);

  return { isObserverSet };
};
