import { AxiosResponse } from "axios";

const CONTENT_DISPOSITION_HEADER_FILE_NAME_REG_EXP = new RegExp(
  '(?<=filename=")(.*)(?=")',
  "gm",
);

export const getResponseFileName = (
  response: AxiosResponse<unknown>,
): string => {
  const contentDispositionHeader = response.request.getResponseHeader(
    "content-disposition",
  );

  if (typeof contentDispositionHeader !== "string") return "";

  const fileNameValue = contentDispositionHeader.match(
    CONTENT_DISPOSITION_HEADER_FILE_NAME_REG_EXP,
  )?.[0];

  if (!fileNameValue) return "";

  return decodeURI(fileNameValue);
};
