import { useMemo } from "react";

// Inner imports
import type { ButtonType, ButtonStyle } from "../types";

export const useButtonTypeClassName = (
  buttonType: ButtonType,
  buttonStyle: ButtonStyle,
) => {
  const buttonTypeClassName = useMemo<string>(() => {
    if (buttonStyle !== "filled") return "";

    switch (buttonType) {
      case "dark":
        return "button-dark";
      case "secondary":
        return "button-secondary";
      case "success":
        return "button-success";
      case "error":
        return "button-error";
      default:
        return "button-primary";
    }
  }, [buttonType, buttonStyle]);

  return buttonTypeClassName;
};
