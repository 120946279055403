import { FC, memo, useState } from "react";
import { useTranslation } from "react-i18next";
import cx from "classnames";

import styles from "./InputPassword.module.scss";
import { Input } from "src/components";
import { withError } from "src/hocs";
import { CrossedEye, Eye } from "src/assets/icons";

// Inner imports
import { requirements, REGEXPS } from "./constants";

type InputPasswordProps = {
  className?: string;
  value: string;
  onChangeHandler: (value: string, requirementsErrors?: Errors) => void;
  error?: string;
  placeholder?: string;
  isPasswordRequirementsShow?: boolean;
  tabIndex?: number;
};

const InputWithError = withError(Input);

const InputPassword: FC<InputPasswordProps> = memo(
  ({
    className = "",
    value,
    onChangeHandler,
    error,
    placeholder,
    isPasswordRequirementsShow = false,
    tabIndex,
  }) => {
    const { t } = useTranslation();

    const [isPasswordShown, setIsPasswordShown] = useState<boolean>(false);
    const [isRequirementsShow, setIsRequirementsShow] =
      useState<boolean>(false);
    const [errors, setErrors] = useState<Errors>({});

    const onPasswordChangeHandler = (value: string) => {
      if (!isPasswordRequirementsShow) {
        onChangeHandler(value);
      }

      const errors: Errors = {};

      const {
        passwordLengthRegex,
        oneLowercaseCharacterRegex,
        oneUppercaseCharacterRegex,
        atLeastOneNumberRegex,
        atLeastOneSpecialSymbol,
      } = REGEXPS;

      if (!passwordLengthRegex.test(value)) {
        const key = "notEnoughCharacters";
        errors[key] = requirements[key];
      }

      if (!oneLowercaseCharacterRegex.test(value)) {
        const key = "oneLowercaseCharacter";
        errors[key] = requirements[key];
      }

      if (!oneUppercaseCharacterRegex.test(value)) {
        const key = "oneUppercaseCharacter";
        errors[key] = requirements[key];
      }

      if (
        !atLeastOneNumberRegex.test(value) &&
        !atLeastOneSpecialSymbol.test(value)
      ) {
        const key = "oneNumber";
        errors[key] = requirements[key];
      }

      onChangeHandler(value, errors);
      setErrors(errors);
    };

    return (
      <div
        className={cx(styles.inputWrapper, className)}
        onFocus={() => setIsRequirementsShow(true)}
        onBlur={() => setIsRequirementsShow(false)}
      >
        <InputWithError
          className={styles.input}
          type={isPasswordShown ? "text" : "password"}
          value={value}
          changeHandler={onPasswordChangeHandler}
          placeholder={placeholder}
          error={error}
          tabIndex={tabIndex}
          autoComplete={"new-password"}
        />
        <button
          className={styles.showButton}
          type="button"
          onClick={() => setIsPasswordShown((state) => !state)}
          tabIndex={-1}
        >
          {isPasswordShown ? <CrossedEye /> : <Eye />}
        </button>
        {isPasswordRequirementsShow && isRequirementsShow && (
          <div className={styles.passwordRequirements}>
            <span>{t("component.password_input.requirement.title")}</span>
            <ul className={styles.passwordRequirementsList}>
              {Object.entries(requirements).map(([error, text]) => (
                <li
                  key={error}
                  className={styles[value && !errors[error] ? "done" : ""]}
                >
                  {t(text)}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    );
  },
  memoFunction,
);

function memoFunction(
  prevState: InputPasswordProps,
  nextState: InputPasswordProps,
) {
  const { value: prevStateValue, error: prevStateError } = prevState;
  const { value: nextStateValue, error: nextStateError } = nextState;

  const isValueSame = prevStateValue === nextStateValue;
  const isErrorSame = prevStateError === nextStateError;

  return isValueSame && isErrorSame;
}

export { InputPassword };
