import ReactGridLayout from "react-grid-layout";
import isEqualWith from "lodash/isEqualWith";

import { isWidgetIdTypeGuard } from "src/utils";

export const formatDashboardToGridLayout = (
  layout: Dashboard.GridItem[],
  tiles: Dashboard.Tiles,
): ReactGridLayout.Layout[] => {
  const formattedLayout = new Set<ReactGridLayout.Layout>();

  for (const { widgetId, xAxis, yAxis, width, height } of layout) {
    const isWidgetOnDashboard = Boolean(tiles[widgetId]);

    if (!isWidgetOnDashboard) continue;

    formattedLayout.add({
      i: widgetId,
      x: xAxis,
      y: yAxis,
      w: width,
      h: height,
    });
  }

  return [...formattedLayout];
};

export const formatGridToDashboardLayout = (
  layout: ReactGridLayout.Layout[],
  tiles: Dashboard.Tiles,
): Dashboard.GridItem[] => {
  const formattedLayout = new Set<Dashboard.GridItem>();

  for (const { i, x, y, w, h } of layout) {
    if (!isWidgetIdTypeGuard(i)) continue;

    const isWidgetOnDashboard = Boolean(tiles[i]);

    if (!isWidgetOnDashboard) continue;

    formattedLayout.add({
      widgetId: i,
      xAxis: x,
      yAxis: y,
      width: w,
      height: h,
    });
  }

  return [...formattedLayout];
};

export const calculateUpdatedLayouts = (
  layouts: ReactGridLayout.Layouts,
  dashboardLayouts: Dashboard.Layouts,
  tiles: Dashboard.Tiles,
): Partial<Dashboard.Layouts> => {
  const updatedLayouts: Partial<Dashboard.Layouts> = {};

  for (const layoutSize in layouts) {
    if (!checkIsStringDashboardLayoutSize(layoutSize)) continue;

    const [layout, dashboardLayout] = [
      layouts[layoutSize],
      dashboardLayouts[layoutSize],
    ];

    if (!layout) continue;

    const formattedLayout = formatGridToDashboardLayout(layout, tiles);

    if (
      !dashboardLayout ||
      checkIsLayoutUpdated(formattedLayout, dashboardLayout)
    )
      updatedLayouts[layoutSize] = formattedLayout;
  }

  return updatedLayouts;
};

function checkIsLayoutUpdated(
  layout: Dashboard.GridItem[],
  dashboardLayout: Dashboard.GridItem[],
): boolean {
  if (layout.length !== dashboardLayout.length) return true;

  return !isEqualWith(layout, dashboardLayout, isLayoutEqualCustomizer);
}

function isLayoutEqualCustomizer(
  layout: Dashboard.GridItem[],
  dashboardLayout: Dashboard.GridItem[],
): boolean {
  for (const aItem of layout) {
    const bItem = dashboardLayout.find(
      ({ widgetId }) => widgetId === aItem.widgetId,
    );

    if (!bItem) return false;

    const isEqual =
      aItem.widgetId === bItem.widgetId &&
      aItem.xAxis === bItem.xAxis &&
      aItem.yAxis === bItem.yAxis &&
      aItem.width === bItem.width &&
      aItem.height === bItem.height;

    if (!isEqual) return false;
  }

  return true;
}

function checkIsStringDashboardLayoutSize(
  value: string,
): value is Dashboard.LayoutSize {
  return value === "small" || value === "medium" || value === "large";
}
