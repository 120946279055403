import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { useAppDispatch } from "src/store";
import { showDevelopmentError, showToastNotification } from "src/utils";
import {
  fetchAllLocations,
  fetchAllLanguages,
  updateCompanyMembersWithEmail,
} from "src/store/actions";
import {
  selectCompanyId,
  selectLocationsStatus,
  selectLanguagesStatus,
  selectIsCompanyMembersEmailLoaded,
} from "src/store/selectors";

export const useLocationsLoader = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const locationsStatus = useSelector(selectLocationsStatus);

  const isLocationsLoaded = useMemo<boolean>(
    () => locationsStatus !== "idle",
    [locationsStatus],
  );

  useEffect(() => {
    if (isLocationsLoaded) return;

    dispatch(fetchAllLocations())
      .unwrap()
      .catch((error) => {
        console.error(error);

        showToastNotification({
          type: "error",
          text: t("common.error.server_error"),
        });
      });
  }, [isLocationsLoaded, dispatch, t]);
};

export const useLanguagesLoader = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const languagesStatus = useSelector(selectLanguagesStatus);

  const isLanguagesLoaded = useMemo<boolean>(
    () => languagesStatus !== "idle",
    [languagesStatus],
  );

  useEffect(() => {
    if (isLanguagesLoaded) return;

    dispatch(fetchAllLanguages())
      .unwrap()
      .catch((error) => {
        console.error(error);

        showToastNotification({
          type: "error",
          text: t("common.error.server_error"),
        });
      });
  }, [isLanguagesLoaded, dispatch, t]);
};

export const useCompanyMembersEmailLoader = (): boolean => {
  const dispatch = useAppDispatch();

  const companyId = useSelector(selectCompanyId);

  const isMembersEmailLoaded = useSelector(selectIsCompanyMembersEmailLoaded);

  useEffect(() => {
    if (!companyId || isMembersEmailLoaded) return;

    dispatch(updateCompanyMembersWithEmail())
      .unwrap()
      .catch((error) => {
        const errorTitle = "ERROR IN MEMBERS EMAIL FETCHING";

        showDevelopmentError({ additionalTexts: [errorTitle], error });
      });
  }, [companyId, isMembersEmailLoaded, dispatch]);

  return isMembersEmailLoaded;
};
