import { FC, useMemo } from "react";
import { useSelector } from "react-redux";

import { getIconByName } from "src/utils";
import { MultipleDataSources } from "src/assets/icons";
import { SEARCH_KEYWORDS_DATA_SOURCE_MAP } from "src/constants";
import { selectTrackersCollectionKeywordsDataSources } from "src/store/selectors";

type Props = {
  trackersCollectionId: TrackersCollection.Data["id"];
};

export const DashboardKeywordsDataSourcesIcon: FC<Props> = ({
  trackersCollectionId,
}) => {
  const keywordsDataSources = useSelector((state: Store.RootState) =>
    selectTrackersCollectionKeywordsDataSources(state, trackersCollectionId),
  );

  const Icon = useMemo<JSX.Element | null>(() => {
    if (keywordsDataSources.length > 1) return <MultipleDataSources />;

    const keywordsDataSource = keywordsDataSources[0];

    if (!keywordsDataSource) return null;

    const iconName = SEARCH_KEYWORDS_DATA_SOURCE_MAP[keywordsDataSource].icon;

    return getIconByName(iconName);
  }, [keywordsDataSources]);

  if (!Icon) return null;

  return Icon;
};
