import { useEffect, useState } from "react";

type OpeningDirection =
  | "top-start"
  | "top"
  | "top-end"
  | "bottom-end"
  | "bottom"
  | "bottom-start";

const ELEMENT_MAX_HEIGHT_MARGIN = 10;

export const useElementMaxHeightAdaptiveToWindow = (
  element: HTMLDivElement | null,
  openingDirection: OpeningDirection,
  defaultMaxHeight = 0,
): number => {
  const [dropdownMaxHeight, setDropdownMaxHeight] =
    useState<number>(defaultMaxHeight);

  useEffect(() => {
    const onResize = (): void => {
      if (!element) return;

      const maxHeight = calculateDropdownMaxHeight(
        element,
        openingDirection,
        defaultMaxHeight,
      );

      setDropdownMaxHeight(maxHeight);
    };

    onResize();

    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, [defaultMaxHeight, element, openingDirection]);

  return dropdownMaxHeight;
};

function calculateDropdownMaxHeight(
  element: HTMLDivElement,
  openingDirection: OpeningDirection,
  defaultMaxHeight: number,
): number {
  switch (openingDirection) {
    case "bottom":
    case "bottom-end":
    case "bottom-start":
      return calculateDropdownMaxHeightToBottom(element, defaultMaxHeight);
    case "top":
    case "top-end":
    case "top-start":
      return calculateDropdownMaxHeightToTop(element, defaultMaxHeight);
    default:
      return 0;
  }
}

function calculateDropdownMaxHeightToBottom(
  element: HTMLDivElement,
  defaultMaxHeight: number,
): number {
  const elementBottom = element.getBoundingClientRect().bottom;

  const windowInnerHeight = window.innerHeight;

  const calculatedMaxHeight =
    windowInnerHeight - elementBottom - ELEMENT_MAX_HEIGHT_MARGIN;

  return Math.max(defaultMaxHeight, calculatedMaxHeight);
}

function calculateDropdownMaxHeightToTop(
  element: HTMLDivElement,
  defaultMaxHeight: number,
): number {
  const elementTop = element.getBoundingClientRect().top;

  const calculatedMaxHeight = elementTop - ELEMENT_MAX_HEIGHT_MARGIN;

  return Math.max(defaultMaxHeight, calculatedMaxHeight);
}
