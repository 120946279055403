import { FC, useMemo } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import styles from "./WidgetSource.module.scss";
import { selectWidgetInfoById } from "src/store/selectors";

type Props = {
  widgetId: Widget.IdType;
};

export const WidgetSource: FC<Props> = ({ widgetId }) => {
  const { t } = useTranslation();

  const widget = useSelector((state: Store.RootState) =>
    selectWidgetInfoById(state, widgetId),
  );

  const label = useMemo<string>(() => {
    if (!widget) return "";

    return t("component.widget_tile.label.keyword_tool_data_source");
  }, [t, widget]);

  if (!label.length) return null;

  return (
    <div className={styles.wrapper}>
      <span>{t("component.widget_tile.label.source", { label })}</span>
    </div>
  );
};
