import { useMemo, useState } from "react";
import { saveAs } from "file-saver";
import { useTranslation } from "react-i18next";

import { useGlobalPreloader } from "src/hooks";
import { showToastNotification, triggerGtmEvent } from "src/utils";

// Inner imports
import { WIDGET_EXPORT_EXCEL } from "../constants";

export const useExportWidgetData = ({
  widgetId,
  dashboardDateRangeId,
}: {
  widgetId: Widget.IdType;
  trackersCollectionId: TrackersCollection.Data["id"];
  dashboardDateRangeId: DashboardDateRange.Data["id"];
}) => {
  const { t } = useTranslation();

  const { showGlobalPreloader, hideGlobalPreloader } = useGlobalPreloader();

  const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>("idle");

  const exportWidgetDataHandler = useMemo(
    () => WIDGET_EXPORT_EXCEL[widgetId],
    [widgetId],
  );

  const hasWidgetDataExport = useMemo<boolean>(
    () => Boolean(exportWidgetDataHandler),
    [exportWidgetDataHandler],
  );

  const exportWidgetData = async (): Promise<void> => {
    if (!hasWidgetDataExport || !exportWidgetDataHandler) return;

    try {
      setLoadingStatus("loading");

      showGlobalPreloader();

      const { data, name } = await exportWidgetDataHandler(
        dashboardDateRangeId,
      );

      saveAs(data, name);

      triggerGtmEvent("WidgetDownloadExcel", { widgetId });

      setLoadingStatus("succeeded");
    } catch (error) {
      console.error(error);

      showToastNotification({
        type: "error",
        text: t("common.error.server_error"),
      });

      setLoadingStatus("failed");
    } finally {
      hideGlobalPreloader();
    }
  };

  return {
    loadingStatus,
    exportWidgetData,
    hasWidgetDataExport,
  };
};
