import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { DEFAULT_LOCATION_ID } from "src/constants";
import { selectLocationsByKeywordsDataSource } from "src/store/selectors";

export const useLocationId = ({
  keywordsDataSource = null,
  locationId: defaultLocationId = null,
}: {
  locationId?: Nullish<Location.Data["id"]>;
  keywordsDataSource?: Nullish<Search.KeywordsDataSource>;
}): [
  Nullable<Location.Data["id"]>,
  Dispatch<SetStateAction<Nullable<Location.Data["id"]>>>,
] => {
  const locations = useSelector((state: Store.RootState) =>
    selectLocationsByKeywordsDataSource(state, keywordsDataSource),
  );

  const [locationId, setLocationId] =
    useState<Nullable<Location.Data["id"]>>(null);

  useEffect(() => {
    if (defaultLocationId) setLocationId(defaultLocationId);
  }, [defaultLocationId]);

  useEffect(() => {
    if (!locationId || !keywordsDataSource) return;

    const isLocationAvailable = locations.some(({ id }) => id === locationId);

    if (!isLocationAvailable) setLocationId(DEFAULT_LOCATION_ID);
  }, [keywordsDataSource, locationId, locations]);

  return [locationId, setLocationId];
};
