import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { isNumber } from "src/utils";
import { WIDGET_CHART_ICON } from "src/constants";
import { selectIsTrackersCollectionSingleBranded } from "src/store/selectors";

// Inner imports
import { WIDGET_CHART_HOOKS } from "./constants";
import type {
  WidgetViewsType,
  UseWidgetViewData,
  UseWidgetPreviewResult,
  UseWidgetViewDataResult,
  UseWidgetViewIndexResult,
} from "./types";

export const useWidgetPreview = (
  widgetChartProps: Widget.ChartProps,
): UseWidgetPreviewResult => {
  const { widgetId, trackersCollectionId } = useMemo<Widget.ChartProps>(
    () => widgetChartProps,
    [widgetChartProps],
  );

  const charts = WIDGET_CHART_HOOKS[widgetId](widgetChartProps);

  const { filteredWidgetViews } = useWidgetViewData({
    charts,
    widgetId,
    trackersCollectionId,
  });

  const [viewIndex, setViewIndex] = useWidgetViewIndex(filteredWidgetViews);

  return {
    viewIndex,
    setViewIndex,
    widgetViews: filteredWidgetViews,
  };
};

function useWidgetViewIndex(
  widgetViews: Widget.ChartView[],
): UseWidgetViewIndexResult {
  const [viewIndex, _setViewIndex] = useState<number>(0);

  const selectedView = useMemo<Widget.ChartView | undefined>(
    () => widgetViews[viewIndex],
    [viewIndex, widgetViews],
  );

  const setViewIndex = (viewIndex: number): void => _setViewIndex(viewIndex);

  useEffect(() => {
    if (!selectedView) return _setViewIndex(0);

    _setViewIndex(viewIndex);
  }, [selectedView, viewIndex]);

  return [viewIndex, setViewIndex];
}

function useWidgetViewData({
  widgetId,
  trackersCollectionId,
  charts,
}: UseWidgetViewData): UseWidgetViewDataResult {
  const widgetAvailableViews = useSelector(
    ({ widgetsInfo }: Store.RootState): WidgetViewsType | undefined => {
      const { views } = widgetsInfo.entities[widgetId] || {};

      return views;
    },
  );

  const isTrackersCollectionSingleBranded = useSelector(
    (state: Store.RootState) =>
      selectIsTrackersCollectionSingleBranded(state, trackersCollectionId),
  );

  const allWidgetViews = useMemo<Widget.ChartView[]>(() => {
    const formattedCharts = charts.map((chart) => ({
      ...chart,
      id: getWidgetChartId(chart.type, chart.subType),
      buttonIcon: getWidgetChartIcon(chart.type, chart.subType),
      order: getChartOrder(chart.type, chart.subType, widgetAvailableViews),
    }));

    return formattedCharts.sort((a, b) => (a?.order || 0) - (b.order || 0));
  }, [charts, widgetAvailableViews]);

  const filteredWidgetViews = useMemo<Widget.ChartView[]>(
    () => filterOutUnavailableViews(allWidgetViews, widgetAvailableViews),
    [allWidgetViews, widgetAvailableViews],
  );

  return {
    widgetAvailableViews,
    isCompetitorsPresent: !isTrackersCollectionSingleBranded,
    allWidgetViews,
    filteredWidgetViews,
  };
}

function getWidgetChartId(
  chartType: Widget.ChartType,
  chartSubtype?: string,
): string {
  if (chartType && chartSubtype) return `${chartType}_${chartSubtype}`;

  if (chartType) return chartType;

  return "";
}

function getWidgetChartIcon(
  chartType: Widget.ChartType,
  chartSubtype: string = "default",
): AppIcon {
  const icon = WIDGET_CHART_ICON[chartType][chartSubtype];

  if (!icon) return WIDGET_CHART_ICON[chartType].default;

  return icon;
}

function getChartOrder(
  chartType: Widget.ChartType,
  chartSubtype: string = "",
  availableCharts?: WidgetViewsType,
): number {
  const { order = 0 } =
    availableCharts?.find(
      ({ type: availableChartType, subType: availableChartSubType = "" }) =>
        chartType === availableChartType &&
        chartSubtype === availableChartSubType,
    ) || {};

  return order;
}

function filterOutUnavailableViews(
  views: Widget.ChartView[],
  availableCharts?: WidgetViewsType,
): Widget.ChartView[] {
  if (!availableCharts) return [];
  const filteredViews: Widget.ChartView[] = [];

  for (const view of views) {
    const { type, subType = "" } = view;

    const widgetView = availableCharts.find(
      ({ type: availableChartType, subType: availableChartSubType = "" }) =>
        type === availableChartType && subType === availableChartSubType,
    );

    if (widgetView)
      filteredViews.push({
        ...view,
        order: widgetView.order,
        buttonNumber: getUniqButtonNumbersToView(view, views),
      });
  }

  return filteredViews;
}

function getUniqButtonNumbersToView(
  view: Widget.ChartView,
  views: Widget.ChartView[],
): number | undefined {
  const { buttonIcon, buttonNumber, id } = view;

  if (isNumber(buttonNumber)) return buttonNumber;

  const viewsWithSameButtonIcon = views.filter(
    (item) => item.buttonIcon === buttonIcon,
  );

  if (viewsWithSameButtonIcon.length < 2) return;

  const newButtonNumber = viewsWithSameButtonIcon.findIndex(
    (item) => item.id === id,
  );

  if (newButtonNumber < 0) return;

  return newButtonNumber + 1;
}
