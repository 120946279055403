import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import Table from "rsuite/Table";
import cx from "classnames";

import styles from "./CheckboxCell.module.scss";
import { Warning } from "src/assets/icons";
import { Checkbox, Tooltip } from "src/components";
import type { KeywordsTableKeywordData, SelectStatus } from "../../types";
import { KEYWORDS_TABLE_STYLES } from "../../constants";

// Inner imports
import type { CheckboxCellProps } from "./types";
import { writeKeywordsRecursively } from "./utils";

const { Cell } = Table;

export const CheckboxCell: FC<CheckboxCellProps> = ({
  rowData: keywordData = {},
  selectedKeywords,
  keywordsSelectHandler,
  isDuplicatedKeywordsShown,
  ...props
}) => {
  const { t } = useTranslation();

  const { label, value, children, possibleDuplicateGroupId } =
    useMemo<KeywordsTableKeywordData>(() => keywordData, [keywordData]);

  const isGroupedCheckbox = useMemo<boolean>(
    () => Boolean(children?.length),
    [children?.length],
  );

  const allKeywords = useMemo<Set<string>>(() => {
    const accumulator = new Set<string>();

    if (isGroupedCheckbox && children?.length) {
      writeKeywordsRecursively(accumulator, children);
    } else {
      accumulator.add(value);
    }

    return accumulator;
  }, [isGroupedCheckbox, children, value]);

  const checkboxCellType = useMemo<SelectStatus>(() => {
    const isParentKeywordSelected = Boolean(selectedKeywords[value]);

    if (!isGroupedCheckbox)
      return isParentKeywordSelected ? "checked" : "unchecked";

    let [isAllSelected, isPartSelected] = [true, false];

    for (const keyword of allKeywords) {
      const isKeywordSelected = Boolean(selectedKeywords[keyword]);

      if (!isKeywordSelected) {
        isAllSelected = false;

        continue;
      }

      isPartSelected = true;
    }

    switch (true) {
      case isAllSelected:
        return "checked";
      case !isAllSelected && isPartSelected:
        return "partial";
      case !isAllSelected && !isPartSelected:
      default:
        return "unchecked";
    }
  }, [allKeywords, selectedKeywords, isGroupedCheckbox, value]);

  const isDuplicatedKeywordLabelShown = useMemo<boolean>(
    () => !isDuplicatedKeywordsShown && Boolean(possibleDuplicateGroupId),
    [isDuplicatedKeywordsShown, possibleDuplicateGroupId],
  );

  const onKeywordSelect = (): void => {
    if (isGroupedCheckbox && checkboxCellType === "checked")
      return keywordsSelectHandler([...allKeywords]);

    if (isGroupedCheckbox && checkboxCellType !== "checked") {
      const unselectedKeywords = new Set<string>();

      for (const keyword of allKeywords) {
        const isKeywordSelected = selectedKeywords[keyword];

        if (!isKeywordSelected) unselectedKeywords.add(keyword);
      }

      return keywordsSelectHandler([...unselectedKeywords]);
    }

    return keywordsSelectHandler([value]);
  };

  return (
    <Cell
      {...props}
      className={styles.checkboxCellWrapper}
      rowData={keywordData}
      hasChildren={isGroupedCheckbox}
      height={KEYWORDS_TABLE_STYLES.cellHeight}
    >
      <div
        className={cx(
          styles.checkboxCell,
          styles[isGroupedCheckbox ? "group" : ""],
        )}
      >
        <Checkbox
          onClick={onKeywordSelect}
          isChecked={checkboxCellType === "checked"}
          isPartChecked={checkboxCellType === "partial"}
        />
        <div
          className={cx(
            styles.labelWrapper,
            styles[isGroupedCheckbox ? "group" : ""],
          )}
          title={label}
        >
          {label}
        </div>
        {isDuplicatedKeywordLabelShown && (
          <Tooltip
            content={t("component.keywords_table.tooltip.duplicate_group")}
          >
            <Warning className={styles.duplicatedKeywordIcon} />
          </Tooltip>
        )}
      </div>
    </Cell>
  );
};
