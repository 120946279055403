import { FC, useCallback, useContext, useMemo } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import styles from "./Stubs.module.scss";
import { useModal } from "src/hooks";
import { Compare } from "src/assets/icons";
import { Button, Preloader } from "src/components";
import { AddTrackerToCompare } from "src/features";
import { DashboardPageContext } from "src/pages/Dashboards/DashboardPageWrapper/context";
import {
  selectWidgetInfoById,
  selectIsCompanyDashboard,
  selectDashboardWidgetSettings,
} from "src/store/selectors";

type Props = {
  widgetId: Widget.IdType;
  dashboardId: Dashboard.Data["id"];
};

export const TrackersCollectionWasDeletedStub: FC = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.placeholder}>
      {t("component.widget_tile.status.info.deleted_trackers_collection")}
    </div>
  );
};

export const NoDataForSelectedTrackerStub: FC = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.placeholder__withIcon}>
      {t("component.widget_tile.label.no_tracker_data")}
    </div>
  );
};

export const NoWidgetDataStub: FC = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.placeholder__withIcon}>
      {t("component.widget_tile.label.no_widget_data")}
    </div>
  );
};

export const PendingStub: FC = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.placeholder__withIcon}>
      <Preloader
        type="bar"
        text={t("component.widget_tile.loader.calculate_widget_data")}
      />
    </div>
  );
};

export const CompetitorRequiredStub: FC<Props> = ({
  widgetId,
  dashboardId,
}) => {
  const { t } = useTranslation();

  const { setSidebarContent, closeSidebar } = useContext(DashboardPageContext);

  const { closeModal } = useModal();

  const widgetInfo = useSelector((state: Store.RootState) =>
    selectWidgetInfoById(state, widgetId),
  );

  const dashboardWidgetSettings = useSelector((state: Store.RootState) =>
    selectDashboardWidgetSettings(state, dashboardId, widgetId),
  );

  const isCompanyDashboard = useSelector((state: Store.RootState) =>
    selectIsCompanyDashboard(state, dashboardId),
  );

  const widgetName = useMemo<string>(
    () => t(dashboardWidgetSettings?.name || widgetInfo?.placeHolderOnUi || ""),
    [t, dashboardWidgetSettings?.name, widgetInfo?.placeHolderOnUi],
  );

  const onAddTrackerSubmit = useCallback((): void => {
    closeSidebar();

    closeModal("add-tracker");
  }, [closeModal, closeSidebar]);

  const onTrackerCompareClick = (): void => {
    if (!isCompanyDashboard) return;

    setSidebarContent(
      <AddTrackerToCompare
        submitHandler={onAddTrackerSubmit}
        trackersCollectionId={dashboardId}
      />,
    );
  };

  return (
    <div className={styles.placeholder}>
      <div className={styles.titleWrapper}>
        <span className={styles.title}>
          {t("component.widget_tile.label.comparison_required", {
            name: widgetName,
          })}
        </span>
        <span className={styles.subTitle}>
          {t("component.widget_tile.label.proceed_with_comparison", {
            name: widgetName,
          })}
        </span>
      </div>
      <Button
        buttonStyle="transparent"
        className={styles.button}
        onClick={onTrackerCompareClick}
      >
        <Compare />
        <span>{t("component.widget_tile.button.tracker_compare")}</span>
      </Button>
    </div>
  );
};
