import firestore from "src/services/firestore";

import { WIDGET_IDS } from "src/constants";
import { isWidgetIdTypeGuard, showDevelopmentError } from "src/utils";
import { COLLECTION_IDS } from "../constants";

// Inner imports
import { widgetsInfoSchema, widgetsInfoViewsSchema } from "./widgetsInfoSchema";

export const readWidgetsInfo = async (): Promise<WidgetsInfo.Data[]> => {
  const collection = await firestore().collection(COLLECTION_IDS.widgets).get();

  if (collection.empty) throw Error("Widget data collection empty");

  const widgets: WidgetsInfo.Data[] = [];

  for (const doc of collection.docs) {
    try {
      const { id: widgetId } = doc;

      if (!isWidgetIdTypeGuard(widgetId)) continue;

      const parsedDoc = parseWidgetsInfo(doc);

      const errors = validate(widgetId, parsedDoc);

      if (errors.length)
        errors.forEach((err) =>
          showDevelopmentError({ additionalTexts: [err] }),
        );

      if (!parsedDoc.isActive || errors.length) continue;

      widgets.push({ id: widgetId, ...parsedDoc });
    } catch (error) {
      showDevelopmentError({
        error,
        additionalTexts: [
          `Widget parsing error. ${doc.id} has missed some property`,
        ],
      });
    }
  }

  if (!widgets.length) throw Error("None of the widgets passed validation");

  return widgets;
};

function parseWidgetsInfo(
  doc: firestore.QueryDocumentSnapshot<firestore.DocumentData>,
): Omit<WidgetsInfo.Data, "id"> {
  const [data, widgetId] = [doc.data(), doc.id];

  const validatedData = widgetsInfoSchema.validateSync(data);

  const { resultCollection, views } = validatedData;

  const validatedViews: WidgetsInfo.Data["views"] = [];

  for (const view of views) {
    try {
      const validatedView = widgetsInfoViewsSchema.validateSync(view);

      validatedViews.push(validatedView);
    } catch (error) {
      showDevelopmentError({
        error,
        additionalTexts: [
          `Error in ${widgetId} widget`,
          `${view.type} doesn't included in WIDGET_CHARTS union`,
        ],
      });
    }
  }

  return {
    ...validatedData,
    resultCollection,
    views: validatedViews,
  };
}

function validate(widgetId: string, widgetData: Omit<WidgetsInfo.Data, "id">) {
  const errors: string[] = [];

  const isWidgetIdSuits = checkIfWidgetIdSuits(widgetId);
  const isWidgetResultCollectionSuits = checkIfWidgetIdSuits(
    widgetData.resultCollection,
  );

  if (
    !isWidgetIdSuits &&
    !isWidgetResultCollectionSuits &&
    widgetData.isActive
  ) {
    errors.push(`"${widgetId}" not included in general WIDGET_IDS union`);
  }

  return errors;
}

function checkIfWidgetIdSuits(widgetId: string) {
  return WIDGET_IDS.includes(widgetId as Widget.IdType);
}
