import { CSSProperties } from "react";
import isNull from "lodash/isNull";

import { isNumber } from "src/utils/isNumber";
import { CHART_COLORS, GRAY, SENTIMENT_COLORS } from "src/constants";

export const applyUniqColor = <T extends {}>(
  values: Array<T>,
): Array<T & { color: string }> => {
  const [updatedValues, usedColors] = [
    new Set<T & { color: string }>(),
    new Set<string>(),
  ];

  for (const value of values) {
    const color =
      CHART_COLORS.filter((color) => !usedColors.has(color))[0] || GRAY;

    updatedValues.add({ ...value, color });
    usedColors.add(color);
  }

  return [...updatedValues];
};

export const calculateValueChangeStyle = (
  value: number | string = 0,
): CSSProperties => {
  if (!isNumber(value)) return { color: "inherit" };

  switch (true) {
    case value > 0:
      return { color: SENTIMENT_COLORS.positive };
    case value < 0:
      return { color: SENTIMENT_COLORS.negative };
    default:
      return { color: "inherit" };
  }
};

export const shadeColor = (color: string, decimal: number): string => {
  const formattedRgb = formatRgb(color);

  if (!formattedRgb) return color;

  let { red: r, green: g, blue: b } = formattedRgb;

  r = Math.round(r / decimal);
  g = Math.round(g / decimal);
  b = Math.round(b / decimal);

  r = r < 255 ? r : 255;
  g = g < 255 ? g : 255;
  b = b < 255 ? b : 255;

  const [rr, gg, bb] = [
    r.toString(16).length === 1 ? `0${r.toString(16)}` : r.toString(16),
    g.toString(16).length === 1 ? `0${g.toString(16)}` : g.toString(16),
    b.toString(16).length === 1 ? `0${b.toString(16)}` : b.toString(16),
  ];

  return `#${rr}${gg}${bb}`;
};

export const checkColorContrast = (
  foregroundColor: string,
  backgroundColor: string,
  minRatio: number = 4.5,
): boolean => {
  const [foregroundLuminance, backgroundLuminance] = [
    getRelativeLuminance(foregroundColor),
    getRelativeLuminance(backgroundColor),
  ];

  if (isNull(foregroundLuminance) || isNull(backgroundLuminance)) return false;

  const ratio = getRatio(foregroundLuminance, backgroundLuminance);

  return ratio >= minRatio;
};

function getRelativeLuminance(string: string): number | null {
  const formattedRgb = formatRgb(string);

  if (!formattedRgb) return null;

  const { red: _red, green: _green, blue: _blue } = formattedRgb;

  const [red, green, blue] = [
    getChannel(_red),
    getChannel(_green),
    getChannel(_blue),
  ];

  return red * 0.2126 + green * 0.7152 + blue * 0.0722;
}

function getChannel(_rgb: number): number {
  const rgb = _rgb / 255;

  return rgb <= 0.03928 ? rgb / 12.92 : Math.pow((rgb + 0.055) / 1.055, 2.4);
}

function getRatio(
  foregroundLuminance: number,
  backgroundLuminance: number,
): number {
  return (
    (Math.max(foregroundLuminance, backgroundLuminance) + 0.05) /
    (Math.min(foregroundLuminance, backgroundLuminance) + 0.05)
  );
}

function formatRgb(string: string): {
  red: number;
  green: number;
  blue: number;
} | null {
  if (!string) return null;

  const isColor = checkIsCorrectColorString(string);

  if (!isColor) return null;

  // #000000 -> { red: 0, green: 0, blue: 0 }
  if (string.includes("#")) {
    const parsedHex = parseInt(string.replace("#", ""), 16);

    const [red, green, blue] = [
      (parsedHex >> 16) & 255,
      (parsedHex >> 8) & 255,
      parsedHex & 255,
    ];

    return { red, green, blue };
  }

  // rgb(0, 0, 0, 0) -> { red: 0, green: 0, blue: 0 }
  if (string.includes("rgb")) {
    const parsedRgb = string.replace(/[^\d,]/g, "").split(",");

    const [red, green, blue, alpha] = [
      Number(parsedRgb[0]),
      Number(parsedRgb[1]),
      Number(parsedRgb[2]),
      Number(parsedRgb[3]),
    ];

    if (!isNumber(red) || !isNumber(green) || !isNumber(blue)) return null;

    if (isNumber(alpha) && alpha <= 0.2)
      return { red: 255, green: 255, blue: 255 };

    return { red, green, blue };
  }

  return null;
}

function checkIsCorrectColorString(color: string): boolean {
  const regExp = new RegExp(
    "^#(?:[\\da-f]{3}){1,2}$|^#(?:[\\da-f]{4}){1,2}$|(rgb|hsl)a?\\((\\s*-?\\d+%?\\s*,){2}(\\s*-?\\d+%?\\s*)\\)|(rgb|hsl)a?\\((\\s*-?\\d+%?\\s*,){3}\\s*(0|(0?\\.\\d+)|1)\\)",
    "gmi",
  );

  return regExp.test(color);
}
