import { useMemo } from "react";

import { useWindowSize } from "src/hooks";
import {
  DASHBOARD_GRID_ROW_HEIGHT,
  DASHBOARD_GRID_ROW_MIN_HEIGHT,
} from "../constants";

export const useDashboardGridRowHeight = (
  expandedWidgetId: Widget.IdType | null,
): number => {
  const { windowHeight } = useWindowSize();

  return useMemo<number>(() => {
    if (!expandedWidgetId) return DASHBOARD_GRID_ROW_HEIGHT;

    // Equation: y = x * 0.5 - 100
    const calculatedHeight = windowHeight * 0.5 - 100;

    return Math.max(calculatedHeight, DASHBOARD_GRID_ROW_MIN_HEIGHT);
  }, [expandedWidgetId, windowHeight]);
};
