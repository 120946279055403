import { FC, useEffect } from "react";
import { createPortal } from "react-dom";

const PORTAL = document.getElementById("tooltip");

export const TooltipPortal: FC = ({ children }) => {
  const ELEMENT = document.createElement("div");

  useEffect(() => {
    if (!PORTAL || ELEMENT) return;

    PORTAL.appendChild(ELEMENT);

    return () => PORTAL.removeChild(ELEMENT);
  }, [ELEMENT]);

  if (!PORTAL) return null;

  return createPortal(<div>{children}</div>, PORTAL);
};
