import { useCallback, useEffect, useState } from "react";

import { isSearchCreatedTypeGuard } from "src/utils";
import { subscribeOnSearchStatus } from "src/store/searches/searchesApi";

export const useSearchStatusObserver = (search?: Search.CreationData) => {
  const [isObserverSet, setIsObserverSet] = useState<boolean>(false);

  const [searchStatus, setSearchStatus] = useState<Search.Status | null>(null);

  const callback = useCallback((data: Search.Status | null): void => {
    setSearchStatus(data);

    setIsObserverSet(true);
  }, []);

  useEffect(() => {
    if (!search || !isSearchCreatedTypeGuard(search)) return;

    let observer = () => {};

    try {
      observer = subscribeOnSearchStatus(search.id, callback);
    } catch (err) {
      console.error(err);
    }

    return () => {
      observer();
    };
  }, [callback, search]);

  return { isObserverSet, searchStatus };
};
