import { useContext, Fragment } from "react";
import { createPortal } from "react-dom";

import styles from "./ModalPortal.module.scss";
import context from "src/context";

const PORTAL = document.getElementById("modal");

export const ModalPortal = () => {
  const { modalElements } = useContext(context);

  if (!Object.keys(modalElements).length || !PORTAL) return null;

  return createPortal(
    <Fragment>
      {Object.entries(modalElements).map(([id, element]) => (
        <div className={styles.wrapper} key={id}>
          {element}
        </div>
      ))}
    </Fragment>,
    PORTAL,
  );
};
