import { renderToStaticMarkup } from "react-dom/server";
import { compiler } from "markdown-to-jsx";

export const capitalizeString = (string: string): string =>
  `${string.charAt(0).toUpperCase()}${string.slice(1)}`;

export const convertMarkdownToText = (markdown: string): string => {
  const element = compiler(markdown);

  const htmlString = renderToStaticMarkup(element);

  const htmlDoc = new DOMParser().parseFromString(htmlString, "text/html");

  return htmlDoc.body.textContent || "";
};
