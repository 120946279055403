export const DASHBOARD_VISIBILITY = ["private", "company", "public"] as const;

export const DASHBOARD_LAYOUT_VIEWS = ["light", "full"] as const;

export const DASHBOARD_DEFAULT_VISIBILITY: Dashboard.Visibility = "private";

export const DASHBOARD_INPUT_LIMIT: number = 150;

export const DASHBOARD_SORT_OPTIONS: Option[] = [
  { value: "name", label: "dashboard.label.sort_name" },
  { value: "createdAt", label: "dashboard.label.sort_created_at" },
  { value: "lastViewed", label: "dashboard.label.last_viewed" },
];

export const DASHBOARD_LIGHT_WIDGET_IDS: Widget.IdType[] = [
  "widget-volume-share",
  "widget-insights",
];

export const DASHBOARD_VISIBILITY_OPTIONS: Dashboard.VisibilityOption[] = [
  {
    label: "dashboard.label.visibility_private",
    value: "private",
  },
  {
    label: "dashboard.label.visibility_company",
    value: "company",
  },
  {
    label: "dashboard.label.visibility_public",
    value: "public",
    checkIsAvailable: ({ isWhiteLabelAdmin }: User.Data): boolean =>
      isWhiteLabelAdmin,
  },
];

export const DASHBOARD_LIGHT_LAYOUT: Record<
  Dashboard.LayoutSize,
  Dashboard.GridItem[]
> = {
  small: [
    {
      widgetId: "widget-volume-share",
      height: 1,
      width: 1,
      xAxis: 0,
      yAxis: 0,
    },
    {
      widgetId: "widget-insights",
      height: 1,
      width: 1,
      xAxis: 0,
      yAxis: 1,
    },
  ],
  medium: [
    {
      widgetId: "widget-volume-share",
      height: 1,
      width: 2,
      xAxis: 0,
      yAxis: 0,
    },
    {
      widgetId: "widget-insights",
      height: 1,
      width: 2,
      xAxis: 0,
      yAxis: 1,
    },
  ],
  large: [
    {
      widgetId: "widget-volume-share",
      height: 1,
      width: 2,
      xAxis: 1,
      yAxis: 0,
    },
    {
      widgetId: "widget-insights",
      height: 1,
      width: 2,
      xAxis: 1,
      yAxis: 1,
    },
  ],
};

export const DASHBOARD_LIGHT_TILES: Partial<Record<Widget.IdType, true>> = {
  "widget-volume-share": true,
  "widget-insights": true,
};

export const DASHBOARDS_COLUMN_SIZES = { small: 1, medium: 2, large: 4 };

export const DASHBOARD_DEFAULT_NAME_CONNECTOR: string = " vs ";
