import { FC, memo, MouseEvent } from "react";
import cx from "classnames";

import styles from "./WidgetViewButton.module.scss";
import { getIconByName } from "src/utils";
import { Button } from "src/components";

type Props = {
  icon?: AppIcon;
  className?: string;
  number?: number;
  onButtonClick?: (e: MouseEvent) => void;
  isSelected?: boolean;
  isDisabled?: boolean;
};

export const WidgetViewButton: FC<Props> = memo(
  ({
    className = "",
    icon,
    number,
    onButtonClick,
    isSelected,
    isDisabled = false,
  }) => (
    <Button
      className={cx(
        styles.viewButton,
        className,
        isSelected ? styles.selectedView : "",
      )}
      onClick={(e) => onButtonClick?.(e)}
      disabled={isDisabled}
      buttonSize="small"
      buttonStyle="transparent"
    >
      {getIconByName(icon)}
      {number !== undefined && <div>{number}</div>}
    </Button>
  ),
  memoFunction,
);

function memoFunction(prevState: Props, nextState: Props) {
  const {
    className: prevStateClassName,
    isDisabled: prevStateDisabled,
    isSelected: prevStateSelected,
  } = prevState;
  const {
    className: nextStateClassName,
    isDisabled: nextStateDisabled,
    isSelected: nextStateSelected,
  } = nextState;

  const isClassNameSame = prevStateClassName === nextStateClassName;
  const isDisabledSame = prevStateDisabled === nextStateDisabled;
  const isSelectedSame = prevStateSelected === nextStateSelected;

  return isClassNameSame && isDisabledSame && isSelectedSame;
}
