import { useMemo } from "react";

import styles from "./EventLabel.module.scss";
import { Triangle } from "src/assets/icons";
import type { Axis, LineChartEvent } from "../../types";
import { LINE_CHART_EVENT_ACTIVE_PADDING_TOP } from "../../constants";

type Props = {
  event: LineChartEvent;
  chartWidth: number;
  yAxisPadding: Axis["yAxisVerticalPadding"];
  formatDateHandler: (time: number) => string;
  onEventClick: (id: Event.Data["id"]) => void;
};

export const EventLabel = ({
  event,
  chartWidth,
  onEventClick,
  yAxisPadding,
  formatDateHandler,
}: Props) => {
  const { id, name, startDate, endDate, color } = useMemo<LineChartEvent>(
    () => event,
    [event],
  );

  const chartTopPadding = useMemo<number>(() => {
    if (!yAxisPadding?.top) return 0;

    return yAxisPadding.top - LINE_CHART_EVENT_ACTIVE_PADDING_TOP;
  }, [yAxisPadding?.top]);

  return (
    <foreignObject onClick={() => onEventClick(id)} className={styles.wrapper}>
      <div
        title={name}
        className={styles.label}
        style={{
          width: chartWidth,
          minWidth: chartWidth,
          top: chartTopPadding,
        }}
      >
        <div className={styles.date}>
          <span>{formatDateHandler(startDate)}</span>
          <Triangle color={color} />
        </div>
        <div className={styles.name} style={{ color }}>
          {name}
        </div>
        <div className={styles.date}>
          <Triangle color={color} />
          <span>{formatDateHandler(endDate)}</span>
        </div>
      </div>
    </foreignObject>
  );
};
