import { FC, useMemo } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import styles from "./TrackerSelectOption.module.scss";
import { Tooltip } from "src/components";
import { getUserFullName } from "src/utils";
import { User, Warning } from "src/assets/icons";
import { TrackerKeywordsDataSourcesIcon } from "src/features";
import {
  selectUser,
  selectCompanyMembers,
  selectApplicationInfo,
} from "src/store/selectors";

type Props = {
  tracker: Tracker.Data;
};

export const TrackerSelectOption: FC<Props> = ({ tracker }) => {
  const { t } = useTranslation();

  const user = useSelector(selectUser);

  const companyMembers = useSelector(selectCompanyMembers);

  const { trackerIdsWithPartialData } = useSelector(selectApplicationInfo);

  const isTrackerWithPartialData = useMemo<boolean>(
    () => trackerIdsWithPartialData.includes(tracker.id),
    [tracker.id, trackerIdsWithPartialData],
  );

  const trackerAuthorName = useMemo<string>(() => {
    const trackerAuthor = companyMembers[tracker.authorId];

    if (!trackerAuthor) return "";

    if (user.id === tracker.authorId)
      return t("component.modal.create_dashboard.label.current_user");

    return getUserFullName(trackerAuthor);
  }, [companyMembers, tracker.authorId, user.id, t]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.nameWrapper}>
        <span>{tracker.name}</span>
        <TrackerKeywordsDataSourcesIcon trackerId={tracker.id} />
        {isTrackerWithPartialData && (
          <Tooltip
            content={t(
              "component.modal.create_dashboard.status.warning.incomplete_tracker",
            )}
          >
            <Warning className={styles.warningIcon} />
          </Tooltip>
        )}
      </div>
      <div className={styles.metadataWrapper}>
        <div className={styles.authorWrapper}>
          <User />
          <span>{trackerAuthorName}</span>
        </div>
      </div>
    </div>
  );
};
