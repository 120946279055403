import { FC, useEffect, useMemo } from "react";

import styles from "./AddTrackerToCompare.module.scss";
import { useAppDispatch } from "src/store";
import { triggerGtmEvent } from "src/utils";
import { useGlobalPreloader, useModal } from "src/hooks";
import { updateTrackersCollection } from "src/store/actions";
import { CreateNewTrackersCollectionModal } from "src/features";

// Inner imports
import { DashboardInfo, TrackersSection } from "./components";
import { useUsedTrackers } from "./hooks";

type Props = {
  submitHandler: () => void;
  trackersCollectionId: TrackersCollection.Data["id"];
};

export const AddTrackerToCompare: FC<Props> = ({
  submitHandler,
  trackersCollectionId,
}) => {
  const dispatch = useAppDispatch();

  const { showGlobalPreloader, hideGlobalPreloader } = useGlobalPreloader();

  const { setModal } = useModal();

  const [
    usedTrackers,
    addUsedTracker,
    removeUsedTracker,
    isUsedTrackersChanged,
  ] = useUsedTrackers(trackersCollectionId);

  const usedTrackersId = useMemo<Tracker.Data["id"][]>(
    () => usedTrackers.map(({ id }) => id),
    [usedTrackers],
  );

  useEffect(() => {
    triggerGtmEvent("DashboardAddComparison", {
      dashboardId: trackersCollectionId,
    });
  }, [trackersCollectionId]);

  const onCreateNewCollectionClickHandler = () => {
    setModal(
      "create-new-trackers-collection",
      <CreateNewTrackersCollectionModal
        trackerIds={usedTrackersId}
        submitHandler={submitHandler}
      />,
    );
  };

  async function onUpdateClickHandler() {
    try {
      showGlobalPreloader();

      const changes = {
        trackerIds: usedTrackers.map(({ id }) => id),
      };

      await dispatch(
        updateTrackersCollection({ id: trackersCollectionId, changes }),
      ).unwrap();
    } catch (error) {
      console.error(error);
    } finally {
      hideGlobalPreloader();

      submitHandler();
    }
  }

  return (
    <div className={styles.wrapper}>
      <DashboardInfo
        usedTrackers={usedTrackers}
        removeUsedTracker={removeUsedTracker}
        trackersCollectionId={trackersCollectionId}
      />
      <TrackersSection
        usedTrackers={usedTrackers}
        addUsedTracker={addUsedTracker}
        removeUsedTracker={removeUsedTracker}
        trackersCollectionId={trackersCollectionId}
        onUpdateClickHandler={onUpdateClickHandler}
        isUsedTrackersChanged={isUsedTrackersChanged}
        onCreateNewCollectionClickHandler={onCreateNewCollectionClickHandler}
      />
    </div>
  );
};
