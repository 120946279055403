import { WIDGET_SYNC_ID_DIVIDER } from "src/constants";

// Inner imports
import { isWidgetIdTypeGuard } from "./typeGuards";

export const composeWidgetSyncId = ({
  widgetId,
  trackersCollectionId,
  dashboardDateRangeId,
}: {
  widgetId: Widget.IdType;
  trackersCollectionId: TrackersCollection.Data["id"];
  dashboardDateRangeId: DashboardDateRange.Data["id"];
}): WidgetSync.Data["id"] =>
  [trackersCollectionId, widgetId, dashboardDateRangeId].join(
    WIDGET_SYNC_ID_DIVIDER,
  );

export const parseWidgetSyncId = (
  widgetSyncId: WidgetSync.Data["id"],
): {
  widgetId: Widget.IdType;
  trackersCollectionId: TrackersCollection.Data["id"];
  dashboardDateRangeId: DashboardDateRange.Data["id"];
} => {
  const [trackersCollectionId = "", widgetId = "", dashboardDateRangeId = ""] =
    widgetSyncId.split(WIDGET_SYNC_ID_DIVIDER);

  if (!isWidgetIdTypeGuard(widgetId)) throw new Error("Invalid widgetId");

  return { widgetId, trackersCollectionId, dashboardDateRangeId };
};
