export const COMPANY_ROLES = [
  "owner",
  "admin",
  "member",
  "creator",
  "consumer",
] as const;

export const COMPANY_SUBSCRIPTION_PLAN_UPDATE_PROPERTIES: (keyof Company.Data)[] =
  [
    "stripeCustomerId",
    "subscriptionPlanId",
    "customTrackersLimit",
    "stripeSubscriptionId",
    "subscriptionPlanExpirationDate",
  ];
