import * as previewComponents from "./Components";

export const WIDGETS_COMPONENTS: Widget.ComponentsMetaData = {
  "widget-volume-share": {
    icon: "ShareOfVolume",
    previewComponent: previewComponents["VolumeSharePreview"],
  },
  "widget-search-share": {
    icon: "ShareOfSearch",
    previewComponent: previewComponents["SearchSharePreview"],
  },
  "widget-top-keywords": {
    icon: "TopKeywords",
    previewComponent: previewComponents["TopKeywordsPreview"],
  },
  "widget-trending-keywords": {
    icon: "TrendingWords",
    previewComponent: previewComponents["TrendingKeywordsPreview"],
  },
  "widget-articles": {
    icon: "Article",
    previewComponent: previewComponents["ArticlesPreview"],
  },
  "widget-insights": {
    icon: "Insights",
    previewComponent: previewComponents["InsightsPreview"],
  },
};
