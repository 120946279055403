import { FC, useMemo } from "react";
import copy from "copy-to-clipboard";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import styles from "./Members.module.scss";
import { LinkOutline } from "src/assets/icons";
import { Button, Translation } from "src/components";
import { showToastNotification, triggerGtmEvent } from "src/utils";
import { selectCompanyId, selectCompanyTeamLimit } from "src/store/selectors";

// Inner imports
import { MembersTable } from "./components";

const Members: FC = () => {
  const { t } = useTranslation();

  const companyId = useSelector(selectCompanyId);

  const { teamLimit, isTeamLimitExceeded } = useSelector(
    selectCompanyTeamLimit,
  );

  const inviteUserLink = useMemo<string>(() => {
    if (!companyId) return "";

    return `${window.location.origin}/signup?company=${companyId}`;
  }, [companyId]);

  const saveInviteLinkToClipboard = (): void => {
    if (!inviteUserLink) return;

    if (isTeamLimitExceeded)
      return showToastNotification({
        id: "company_team_limit",
        type: "warning",
        text: (
          <Translation
            i18nKey="company.team.status.warning.team_limit"
            values={{ count: teamLimit }}
          />
        ),
      });

    copy(inviteUserLink);

    triggerGtmEvent("CompanyCopyInviteLink", { companyId });

    showToastNotification({
      id: "settings-members-invitation-link",
      type: "success",
      text: t("page.settings.company_members.status.success.link_copied"),
    });
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <Button
          className={styles.copyLink}
          buttonStyle="transparent"
          buttonSize="small"
          onClick={saveInviteLinkToClipboard}
        >
          <LinkOutline />
          <span>{t("page.settings.company_members.label.invite_link")}</span>
        </Button>
      </div>
      <MembersTable />
    </div>
  );
};

export default Members;
