import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import { checkIsLocalEnvironment } from "src/utils";
import {
  LOCALIZATION_DATA,
  LOCALIZATION_DEFAULT_LANGUAGE_ID,
} from "src/constants";

const resources = checkIsLocalEnvironment() ? LOCALIZATION_DATA : {};

i18n
  .use(initReactI18next)
  .init({
    resources,
    interpolation: { escapeValue: false },
    lng: LOCALIZATION_DEFAULT_LANGUAGE_ID,
    fallbackLng: LOCALIZATION_DEFAULT_LANGUAGE_ID,
  })
  .catch(console.error);
