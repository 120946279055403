import { FC, memo, useCallback } from "react";

import styles from "./Sidebar.module.scss";
import { Button } from "src/components";
import { Plus } from "src/assets/icons";
import { useElementOutsideClick, useKeyboardListener } from "src/hooks";

type Props = {
  isSidebarOpen: boolean;
  setIsSidebarOpen: (value: boolean) => void;
};

export const Sidebar: FC<Props> = memo(
  ({ isSidebarOpen, setIsSidebarOpen, children }) => {
    const closeSidebar = useCallback(
      (): void => setIsSidebarOpen(false),
      [setIsSidebarOpen],
    );

    const ref = useElementOutsideClick(closeSidebar);

    useKeyboardListener({
      key: "Escape",
      type: "keydown",
      callback: closeSidebar,
    });

    if (!isSidebarOpen) return null;

    return (
      <div className={styles.wrapper}>
        <div className={styles.content} ref={ref}>
          <Button className={styles.closeButton} onClick={closeSidebar}>
            <Plus />
          </Button>
          {children}
        </div>
      </div>
    );
  },
);
