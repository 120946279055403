import { widgetSyncsAdapter } from "./widgetSyncsSlice";
import { createDraftSafeSelector } from "@reduxjs/toolkit";

export const {
  selectAll: selectWidgetSyncs,
  selectEntities: selectWidgetSyncsEntities,
  selectById: selectWidgetSyncById,
  selectTotal: selectWidgetSyncsCount,
} = widgetSyncsAdapter.getSelectors<Store.RootState>(
  ({ widgetSyncs }) => widgetSyncs,
);

export const selectWidgetSyncsByTrackersCollectionId = createDraftSafeSelector(
  (
    state: Store.RootState,
    trackersCollectionId: TrackersCollection.Data["id"],
  ): [ReturnType<typeof selectWidgetSyncs>, TrackersCollection.Data["id"]] => [
    selectWidgetSyncs(state),
    trackersCollectionId,
  ],
  ([widgetSyncs, trackersCollectionId]) => {
    const filteredWidgetSyncs = new Set<WidgetSync.Data>();

    for (const widgetSync of widgetSyncs) {
      const isTrackersCollectionWidgetSync =
        widgetSync.trackersCollectionId === trackersCollectionId;

      if (isTrackersCollectionWidgetSync) filteredWidgetSyncs.add(widgetSync);
    }

    return [...filteredWidgetSyncs];
  },
);
