import { FC, useMemo } from "react";

import { getIconByName } from "src/utils";
import { SEARCH_KEYWORDS_DATA_SOURCE_MAP } from "src/constants";

type Props = Pick<Search.Data, "keywordsDataSource">;

export const SearchKeywordsDataSourceIcon: FC<Props> = ({
  keywordsDataSource,
}) => {
  const Icon = useMemo<JSX.Element | null>(() => {
    const iconName = SEARCH_KEYWORDS_DATA_SOURCE_MAP[keywordsDataSource].icon;

    return getIconByName(iconName);
  }, [keywordsDataSource]);

  if (!Icon) return null;

  return Icon;
};
