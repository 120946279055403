import { FC, memo, useMemo } from "react";
import cx from "classnames";

import styles from "./TableChartSortIcon.module.scss";
import { Triangle } from "src/assets/icons";

type Props = {
  sortDirection?: SortDirection;
};

export const TableChartSortIcon: FC<Props> = memo(({ sortDirection }) => {
  const sortDirectionClassName = useMemo<string>(() => {
    if (!sortDirection) return "";

    if (sortDirection === "ASC" && styles.sortIconAsc)
      return styles.sortIconAsc;

    if (sortDirection === "DESC" && styles.sortIconDesc)
      return styles.sortIconDesc;

    return "";
  }, [sortDirection]);

  return (
    <div className={cx(styles.sortIcon, sortDirectionClassName)}>
      <Triangle />
      <Triangle />
    </div>
  );
});
