import { createElement } from "react";

import { getElementDimensions } from "src/utils";

// Inner imports
import {
  TOOLTIP_STYLE,
  TOOLTIP_EXTRA_SPACE,
  TOOLTIP_SPACE_BETWEEN_ELEMENT,
} from "./constants";

export const calculateTooltipCoordinates = (
  element: HTMLDivElement,
  title: string | JSX.Element,
): Coordinates => {
  const {
    top: elementTop,
    left: elementLeft,
    width: elementWidth,
    height: elementHeight,
  } = element.getBoundingClientRect();

  const { width, height } = getTooltipDimensions(title);

  let [x, y] = [
    elementLeft + elementWidth / 2,
    elementTop + elementHeight + TOOLTIP_SPACE_BETWEEN_ELEMENT,
  ];

  const [xWithContent, yWithContent] = [x + width / 2, y + height];

  // Right overflown
  if (xWithContent >= window.innerWidth) x -= xWithContent - window.innerWidth;

  // Left overflown
  if (xWithContent <= width) x = width / 2;

  // Bottom overflown
  if (yWithContent >= window.innerHeight) y = elementTop - height;

  return { x, y };
};

function getTooltipDimensions(title: string | JSX.Element): Dimensions {
  if (!title) return { width: 0, height: 0 };

  let element: JSX.Element;

  if (typeof title === "object") element = title;
  else element = createElement("span", {}, title);

  const { width, height } = getElementDimensions(element, TOOLTIP_STYLE);

  return {
    width: width + TOOLTIP_EXTRA_SPACE,
    height: height + TOOLTIP_EXTRA_SPACE,
  };
}
