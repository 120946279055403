import { FC, useMemo } from "react";
import cx from "classnames";
import { useTranslation } from "react-i18next";

import styles from "./LineChartSettings.module.scss";
import { Button, Tooltip } from "src/components";
import { LineLinear, LineLogarithmic, Trend } from "src/assets/icons";

// Inner imports
import { LineChartScale } from "../../types";

type Props = {
  scale: LineChartScale;
  hasTrendLine: boolean;
  isTrendLineShown: boolean;
  handleScaleChange: (value: LineChartScale) => void;
  handleIsTrendLineShownChange: (value: boolean) => void;
};

export const LineChartSettings: FC<Props> = ({
  scale,
  hasTrendLine,
  isTrendLineShown,
  handleScaleChange,
  handleIsTrendLineShownChange,
}) => {
  const { t } = useTranslation();

  const isLinearScale = useMemo<boolean>(() => scale === "linear", [scale]);

  const tooltip = useMemo<string>(
    () =>
      t(
        isLinearScale
          ? "chart.line.data.scale.logarithmic"
          : "chart.line.data.scale.linear",
      ),
    [isLinearScale, t],
  );

  const ScaleIcon = useMemo<JSX.Element>(
    () => (isLinearScale ? <LineLogarithmic /> : <LineLinear />),
    [isLinearScale],
  );

  const onScaleChange = () =>
    handleScaleChange(isLinearScale ? "log" : "linear");

  const onIsTrendLineShownChange = () =>
    handleIsTrendLineShownChange(!isTrendLineShown);

  return (
    <div className={styles.wrapper}>
      {hasTrendLine && (
        <Tooltip content={t("chart.line.data.trend_line")}>
          <Button
            buttonSize="small"
            buttonStyle="transparent"
            onClick={onIsTrendLineShownChange}
            className={cx(
              styles.button,
              isTrendLineShown ? "" : styles.buttonInactive,
            )}
          >
            <Trend />
          </Button>
        </Tooltip>
      )}
      <Tooltip content={tooltip}>
        <Button
          className={styles.button}
          onClick={onScaleChange}
          buttonSize="small"
          buttonStyle="transparent"
        >
          {ScaleIcon}
        </Button>
      </Tooltip>
    </div>
  );
};
