import { FC } from "react";
import { useTranslation } from "react-i18next";

import { ConfirmModal } from "src/features";
import { showToastNotification } from "src/utils";
import { useGlobalPreloader, useModal } from "src/hooks";

type Props = {
  trackerId: Tracker.Data["id"];
  updateTrackerHandler: () => Promise<void>;
};

export const ConfirmEditTrackerModal: FC<Props> = ({
  trackerId,
  updateTrackerHandler,
}) => {
  const { t } = useTranslation();

  const { closeModal } = useModal();

  const { showGlobalPreloader, hideGlobalPreloader } = useGlobalPreloader();

  const onSubmit = async (): Promise<void> => {
    if (!trackerId) return;

    try {
      showGlobalPreloader();

      await updateTrackerHandler();
    } catch (error) {
      console.error(error);

      showToastNotification({
        type: "error",
        text: t("common.error.server_error_reload"),
      });
    } finally {
      closeModal("confirm-edit-tracker");

      hideGlobalPreloader();
    }
  };

  return (
    <ConfirmModal
      id="confirm-edit-tracker"
      type="warning"
      acceptButton={{
        text: t(
          "component.modal.confirm_edit_tracker_configuration.button.submit",
        ),
        onClick: onSubmit,
      }}
      cancelButton={{
        text: t(
          "component.modal.confirm_edit_tracker_configuration.button.cancel",
        ),
        onClick: () => closeModal("confirm-edit-tracker"),
      }}
      title={t("component.modal.confirm_edit_tracker_configuration.title")}
    >
      <span>
        {t("component.modal.confirm_edit_tracker_configuration.body")}
      </span>
    </ConfirmModal>
  );
};
