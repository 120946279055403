import * as yup from "yup";

// Inner imports
import * as schemas from "./widgetsSchema";

//#region SEARCH SHARE FORMATTER
type SearchShareWidgetSchemaType = Omit<
  yup.InferType<typeof schemas.searchShareWidgetSchema>,
  "updatedAt"
>;

type SearchShareWidgetReturnType = Omit<Widgets.SearchShare.Data, "updatedAt">;

export const formatSearchShareData = ({
  dateFrom,
  dateTo,
  ...rest
}: SearchShareWidgetSchemaType): SearchShareWidgetReturnType => ({
  ...rest,
  endDate: dateTo,
  startDate: dateFrom,
});
//#endregion SEARCH SHARE FORMATTER

//#region VOLUME SHARE FORMATTER
type VolumeShareWidgetSchemaType = Omit<
  yup.InferType<typeof schemas.volumeShareWidgetSchema>,
  "updatedAt"
>;

type VolumeShareWidgetReturnType = Omit<Widgets.VolumeShare.Data, "updatedAt">;

export const formatVolumeShareData = ({
  dateFrom,
  dateTo,
  ...rest
}: VolumeShareWidgetSchemaType): VolumeShareWidgetReturnType => ({
  ...rest,
  endDate: dateTo,
  startDate: dateFrom,
});
//#endregion VOLUME SHARE FORMATTER

//#region TOP KEYWORDS FORMATTER
type TopKeywordsWidgetSchemaType = Omit<
  yup.InferType<typeof schemas.topKeywordsWidgetSchema>,
  "updatedAt"
>;

type TopKeywordsReturnType = Omit<Widgets.TopKeywords.Data, "updatedAt">;

export const formatTopKeywordsData = ({
  data,
  dateTo,
  dateFrom,
  ...rest
}: TopKeywordsWidgetSchemaType): TopKeywordsReturnType => ({
  ...rest,
  endDate: dateTo,
  startDate: dateFrom,
  data: formatTopKeywordsWidgetData(data),
});

function formatTopKeywordsWidgetData({
  listChart,
}: TopKeywordsWidgetSchemaType["data"]): Widgets.TopKeywords.Data["data"] {
  const formattedData: Widgets.TopKeywords.Data["data"] = {
    listChart: {},
  };

  for (const trackerId in listChart) {
    const trackerData = listChart[trackerId];

    if (!trackerData) continue;

    formattedData.listChart[trackerId] = trackerData.map((data) => ({
      ...data,
      trackerId,
    }));
  }

  return formattedData;
}
//#endregion TOP KEYWORDS FORMATTER

//#region TRENDING KEYWORDS FORMATTER
type TrendingKeywordsWidgetSchemaType = Omit<
  yup.InferType<typeof schemas.trendingKeywordsWidgetSchema>,
  "updatedAt"
>;

type TrendingKeywordsReturnType = Omit<
  Widgets.TrendingKeywords.Data,
  "updatedAt"
>;

export const formatTrendingKeywordsData = ({
  data,
  dateTo,
  dateFrom,
  ...rest
}: TrendingKeywordsWidgetSchemaType): TrendingKeywordsReturnType => ({
  ...rest,
  endDate: dateTo,
  startDate: dateFrom,
  data: formatTrendingKeywordsWidgetData(data),
});

function formatTrendingKeywordsWidgetData({
  listChart,
}: TrendingKeywordsWidgetSchemaType["data"]): Widgets.TrendingKeywords.Data["data"] {
  const formattedData: Widgets.TrendingKeywords.Data["data"] = {
    listChart: {},
  };

  for (const trackerId in listChart) {
    const trackerData = listChart[trackerId];

    if (!trackerData) continue;

    formattedData.listChart[trackerId] = trackerData.map((data) => ({
      ...data,
      trackerId,
    }));
  }

  return formattedData;
}
//#endregion TOP KEYWORDS FORMATTER

//#region INSIGHTS FORMATTER
type InsightsWidgetSchemaType = Omit<
  yup.InferType<typeof schemas.insightsWidgetSchema>,
  "updatedAt"
>;

type InsightsReturnType = Omit<Widgets.Insights.Data, "updatedAt">;

export const formatInsightsData = ({
  data,
  dateTo,
  dateFrom,
  ...rest
}: InsightsWidgetSchemaType): InsightsReturnType => ({
  ...rest,
  startDate: dateFrom,
  endDate: dateTo,
  data: {
    ...data,
    customPrompt: {
      ...data.customPrompt,
      value: data.customPrompt?.value || "",
      authorId: data.customPrompt?.authorId || "",
      updatedAt: data.customPrompt?.updatedAt || "",
    },
  },
});
//#endregion INSIGHTS FORMATTER

//#region TRENDING KEYWORDS FORMATTER
type ArticlesWidgetSchemaType = Omit<
  yup.InferType<typeof schemas.articlesWidgetSchema>,
  "updatedAt" | "dateFrom" | "dateTo"
>;

type ArticlesReturnType = Omit<Widgets.Articles.Data, "updatedAt">;

export const formatArticlesData = (
  data: ArticlesWidgetSchemaType,
): ArticlesReturnType => ({ ...data, endDate: "", startDate: "" });
//#endregion TOP KEYWORDS FORMATTER
