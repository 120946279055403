import { FC, memo } from "react";
import { ResponsiveContainer, AreaChart, Area } from "recharts";
import { CategoricalChartProps } from "recharts/types/chart/generateCategoricalChart";

// Inner imports
import { SMALL_LINE_CHART_STYLES } from "./constants";

const { width, height, margin, opacity, strokeWidth } = SMALL_LINE_CHART_STYLES;

type Props = CategoricalChartProps & {
  color: string;
};

export const SmallLineChart: FC<Props> = memo(({ color, ...props }) => (
  <ResponsiveContainer width={width} height={height}>
    <AreaChart {...props} margin={margin}>
      <Area
        isAnimationActive={false}
        type="monotone"
        dataKey="volume"
        stroke={color}
        fill={color}
        fillOpacity={opacity}
        strokeWidth={strokeWidth}
      />
    </AreaChart>
  </ResponsiveContainer>
));
