import { useMemo } from "react";
import { useSelector } from "react-redux";

import { selectWidgetData } from "src/store/selectors";

// Inner imports
import { WIDGET_ID } from "../constants";

export const useIsVolumeShareForecastCalculated = ({
  dashboardDateRangeId,
}: Pick<Widget.ChartProps, "dashboardDateRangeId">): boolean => {
  const widgetData = useSelector((state: Store.RootState) =>
    selectWidgetData(state, dashboardDateRangeId, WIDGET_ID),
  );

  return useMemo<boolean>(() => {
    const lineChartData = widgetData?.data?.lineChart;

    if (!lineChartData) return false;

    for (const trackerId in lineChartData) {
      const trackerData = widgetData.data.lineChart[trackerId];

      if (!trackerData) continue;

      const hasForecast = trackerData.some(({ isForecasted }) => isForecasted);

      if (hasForecast) return true;
    }

    return false;
  }, [widgetData]);
};
