import { useMemo } from "react";
import { useSelector } from "react-redux";

import {
  selectTrackersById,
  selectTrackersCollectionById,
} from "src/store/selectors";
import { CHART_COLORS, GRAY } from "src/constants";

// Inner imports
import type { ChartLabel } from "../types";
import { CATEGORY_TREND_LINE } from "../constants";

type Props = {
  isTrendLineShown: boolean;
  trackerIds: Tracker.Data["id"][];
  trackersCollectionId: TrackersCollection.Data["id"];
};

type ReturnProps = ChartLabel[];

export const useLineChartLabels = ({
  trackerIds,
  isTrendLineShown,
  trackersCollectionId,
}: Props): ReturnProps => {
  const trackersCollection = useSelector((state: Store.RootState) =>
    selectTrackersCollectionById(state, trackersCollectionId),
  );

  const trackersCollectionTrackerIds = useMemo<
    TrackersCollection.Data["trackerIds"]
  >(
    () => trackersCollection?.trackerIds || [],
    [trackersCollection?.trackerIds],
  );

  const trackers = useSelector((state: Store.RootState) =>
    selectTrackersById(state, trackersCollectionTrackerIds),
  );

  return useMemo<ChartLabel[]>(() => {
    const mappedLabels = new Map<Tracker.Data["id"], ChartLabel>();

    for (const [index, { id, name }] of trackers.entries()) {
      if (!trackerIds.includes(id)) continue;

      mappedLabels.set(id, {
        value: id,
        label: name,
        priority: mappedLabels.size + 1,
        color: CHART_COLORS[index] || GRAY,
      });
    }

    const labels = Array.from(mappedLabels.values());

    if (isTrendLineShown) labels.push({ priority: 0, ...CATEGORY_TREND_LINE });

    return labels.sort(
      ({ priority: aPriority = -1 }, { priority: bPriority = -1 }) =>
        aPriority - bPriority,
    );
  }, [isTrendLineShown, trackerIds, trackers]);
};
