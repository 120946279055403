import { FC, useMemo, useState } from "react";
import add from "date-fns/add";
import sub from "date-fns/sub";
import { useImmer } from "use-immer";
import isAfter from "date-fns/isAfter";
import isBefore from "date-fns/isBefore";
import { useSelector } from "react-redux";
import isSameDay from "date-fns/isSameDay";
import { useTranslation } from "react-i18next";

import styles from "./CreateDashboardDateRangeForm.module.scss";
import { withError } from "src/hocs";
import { Loader } from "src/assets/icons";
import { useAppDispatch } from "src/store";
import { useTemporaryErrors } from "src/hooks";
import { createDashboardDateRange } from "src/store/actions";
import { Button, Form, Label, MonthSelector } from "src/components";
import { formatToYearMonth, showToastNotification } from "src/utils";
import {
  selectUserId,
  selectCompanyId,
  selectKeywordsDataSourcesLatestDataDate,
  selectTrackersCollectionKeywordsDataSources,
  selectDashboardDateRangesByTrackersCollectionId,
} from "src/store/selectors";

type Props = {
  trackersCollectionId: TrackersCollection.Data["id"];
  submitHandler: (value: DashboardDateRange.Data["id"]) => void;
};

const MonthSelectorWithError = withError(MonthSelector);

export const CreateDashboardDateRangeForm: FC<Props> = ({
  submitHandler,
  trackersCollectionId,
}) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const { errors, setErrors } = useTemporaryErrors();

  const userId = useSelector(selectUserId);

  const companyId = useSelector(selectCompanyId);

  const keywordsDataSources = useSelector((state: Store.RootState) =>
    selectTrackersCollectionKeywordsDataSources(state, trackersCollectionId),
  );

  const keywordsDataSourcesLatestDate = useSelector((state: Store.RootState) =>
    selectKeywordsDataSourcesLatestDataDate(state, keywordsDataSources),
  );

  const dashboardDateRanges = useSelector((state: Store.RootState) =>
    selectDashboardDateRangesByTrackersCollectionId(
      state,
      trackersCollectionId,
    ),
  );

  const defaultDashboardDateRange = useMemo<
    Omit<DashboardDateRange.Custom, "id">
  >(() => {
    const currentDate = keywordsDataSourcesLatestDate
      ? new Date(keywordsDataSourcesLatestDate)
      : new Date();

    const previousMonth = sub(currentDate, { months: 1 });

    const [startDate, endDate] = [
      formatToYearMonth(previousMonth),
      formatToYearMonth(currentDate),
    ];

    return {
      endDate,
      startDate,
      companyId,
      type: null,
      createdAt: "",
      updatedAt: "",
      category: "custom",
      authorId: userId,
      trackersCollectionId,
    };
  }, [companyId, keywordsDataSourcesLatestDate, trackersCollectionId, userId]);

  const [dashboardDateRange, setDashboardDateRange] = useImmer<
    Omit<DashboardDateRange.Custom, "id">
  >(defaultDashboardDateRange);

  const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>("idle");

  const isLoading = useMemo<boolean>(
    () => loadingStatus === "loading",
    [loadingStatus],
  );

  const minDate = useMemo<string>(() => {
    const formattedDate = new Date(dashboardDateRange.startDate);

    return add(formattedDate, { months: 1 }).toISOString();
  }, [dashboardDateRange.startDate]);

  const onStartDateChange = (value: string): void => {
    setDashboardDateRange((draft) => {
      draft.startDate = value;
    });

    const [formattedStartDate, formattedEndDate] = [
      new Date(value),
      new Date(dashboardDateRange.endDate),
    ];

    if (!isBefore(formattedStartDate, formattedEndDate))
      setDashboardDateRange((draft) => {
        const formattedValue = new Date(value);

        draft.endDate = add(formattedValue, { months: 1 }).toISOString();
      });
  };

  const onEndDateChange = (value: string): void =>
    setDashboardDateRange((draft) => {
      draft.endDate = value;
    });

  const onSubmit = async (): Promise<void> => {
    const errors = validate();

    if (Object.keys(errors).length) return setErrors(errors);

    try {
      setLoadingStatus("loading");

      const { id: dashboardDateRangeId } = await dispatch(
        createDashboardDateRange(dashboardDateRange),
      ).unwrap();

      setLoadingStatus("succeeded");

      showToastNotification({
        type: "success",
        text: t(
          "page.dashboard.form.status.success.dashboard_date_range_created",
        ),
      });

      submitHandler(dashboardDateRangeId);
    } catch (error) {
      setLoadingStatus("failed");

      console.error(error);

      showToastNotification({
        type: "error",
        text: t("common.error.server_error"),
      });
    }
  };

  function validate(): Errors {
    const validationErrors: typeof errors = {};

    const { startDate, endDate } = dashboardDateRange;

    const [formattedStartDate, formattedEndDate] = [
      new Date(startDate),
      new Date(endDate),
    ];

    const isExistingDateRange = dashboardDateRanges.some(
      ({ startDate, endDate }) => {
        if (!startDate || !endDate) return false;

        return (
          isSameDay(new Date(startDate), formattedStartDate) &&
          isSameDay(new Date(endDate), formattedEndDate)
        );
      },
    );

    if (isExistingDateRange)
      validationErrors.startDate = validationErrors.endDate = t(
        "page.dashboard.form.create_dashboard_date_range.validation.date_exists",
      );

    if (isAfter(formattedStartDate, formattedEndDate))
      validationErrors.startDate = t(
        "page.dashboard.form.create_dashboard_date_range.validation.invalid_date",
      );

    return validationErrors;
  }

  return (
    <Form onSubmit={onSubmit}>
      <div className={styles.formWrapper}>
        <span className={styles.title}>{t("page.dashboard.form.title")}</span>
        <div className={styles.inputWrapper}>
          <Label
            leftText={t(
              "page.dashboard.form.create_dashboard_date_range.label.start_date",
            )}
          />
          <MonthSelectorWithError
            error={errors.startDate}
            onChange={onStartDateChange}
            value={dashboardDateRange.startDate}
            maxValue={keywordsDataSourcesLatestDate}
          />
        </div>
        <div className={styles.inputWrapper}>
          <Label
            leftText={t(
              "page.dashboard.form.create_dashboard_date_range.label.endDate",
            )}
          />
          <MonthSelectorWithError
            minValue={minDate}
            error={errors.endDate}
            onChange={onEndDateChange}
            value={dashboardDateRange.endDate}
            maxValue={keywordsDataSourcesLatestDate}
          />
        </div>
        <Button buttonSize="small" disabled={isLoading} type="submit">
          {isLoading && <Loader className={styles.loader} />}
          <span>
            {t("page.dashboard.form.create_dashboard_date_range.submit")}
          </span>
        </Button>
      </div>
    </Form>
  );
};
