import { ReactChild, createContext, useState } from "react";

type DashboardPageContext = {
  sidebarContent: JSX.Element | null;
  setSidebarContent: (content: JSX.Element | null) => void;
  closeSidebar: () => void;
  resetContext: () => void;
};

export const DashboardPageContext = createContext<DashboardPageContext>({
  sidebarContent: null,
  setSidebarContent: () => {},
  closeSidebar: () => {},
  resetContext: () => {},
});

const DashboardPageContextProvider = ({
  children,
}: {
  children: ReactChild;
}) => {
  const [sidebarContent, _setSidebarContent] = useState<JSX.Element | null>(
    null,
  );

  const setSidebarContent = (content: JSX.Element | null): void =>
    _setSidebarContent(content);

  const closeSidebar = (): void => _setSidebarContent(null);

  const resetContext = (): void => _setSidebarContent(null);

  return (
    <DashboardPageContext.Provider
      value={{ sidebarContent, setSidebarContent, closeSidebar, resetContext }}
    >
      {children}
    </DashboardPageContext.Provider>
  );
};

export default DashboardPageContextProvider;
