import { FC, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";

import styles from "./SelectedSearches.module.scss";
import { useModal } from "src/hooks";
import { Button } from "src/components";
import { AddSearchModal } from "src/features";
import { PencilOutline } from "src/assets/icons";

// Inner imports
import { SelectedSearch } from "./components";

type Props = {
  keywords: Record<string, Search.KeywordsData>;
  selectedSearches: Search.CreationData[];
  selectSearchHandler: (search: Search.CreationData) => void;
  unselectSearchHandler: (search: Search.CreationData) => void;
  selectCustomSearchHandler: (subject: Search.CreationData["subject"]) => void;
  updateSearchHandler: ({
    id,
    changes,
  }: {
    id: Search.Data["id"];
    changes: Search.CreationData;
  }) => void;
  updateSearchKeywordsHandler: (
    searchId: Search.Data["id"],
    keywords: Search.Keyword[],
    status: LoadingStatus,
  ) => void;
};

export const SelectedSearches: FC<Props> = ({
  keywords,
  selectedSearches,
  selectSearchHandler,
  updateSearchHandler,
  unselectSearchHandler,
  selectCustomSearchHandler,
  updateSearchKeywordsHandler,
}) => {
  const { t } = useTranslation();

  const ref = useRef<HTMLDivElement>(null);

  const { setModal } = useModal();

  const hasSearches = useMemo<boolean>(
    () => Boolean(selectedSearches.length),
    [selectedSearches.length],
  );

  const openAddSearchModal = (): void =>
    setModal(
      "add-search",
      <AddSearchModal submitHandler={selectCustomSearchHandler} />,
    );

  return (
    <div className={styles.wrapper}>
      <div className={styles.heading}>
        <span className={styles.title}>
          {t("page.create_tracker.select_searches.label.selected_searches")}
        </span>
        <div className={styles.settings}>
          <Button
            onClick={openAddSearchModal}
            className={styles.button}
            buttonStyle="outlined"
          >
            <span>
              {t("page.create_tracker.select_searches.button.add_manually")}
            </span>
            <PencilOutline />
          </Button>
        </div>
      </div>
      {hasSearches && (
        <div className={styles.searches} ref={ref}>
          {selectedSearches.map((search) => (
            <SelectedSearch
              ref={ref}
              key={search.id}
              search={search}
              keywordsData={keywords[search.id]}
              updateSearchHandler={updateSearchHandler}
              selectSearchHandler={selectSearchHandler}
              unselectSearchHandler={unselectSearchHandler}
              updateSearchKeywordsHandler={updateSearchKeywordsHandler}
            />
          ))}
        </div>
      )}
    </div>
  );
};
