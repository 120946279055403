import { createDraftSafeSelector } from "@reduxjs/toolkit";

import { trackersCollectionsAdapter } from "./trackersCollectionsSlice";
import {
  selectUser,
  selectTrackersEntities,
  selectTrackersWithSearchId,
  selectDashboardsWithTrackerIds,
} from "../selectors";

export const {
  selectAll: selectTrackersCollections,
  selectEntities: selectTrackersCollectionsEntities,
  selectById: selectTrackersCollectionById,
} = trackersCollectionsAdapter.getSelectors<Store.RootState>(
  ({ trackersCollections }) => trackersCollections,
);

export const selectTrackersCollectionsStatus = createDraftSafeSelector(
  ({ trackersCollections }: Store.RootState) => trackersCollections,
  ({ status }) => status,
);

export const selectCompanyTrackersCollections = createDraftSafeSelector(
  selectTrackersCollections,
  selectUser,
  (trackersCollections, user) => {
    const filteredTrackersCollections = new Set<TrackersCollection.Data>();

    for (const trackersCollection of trackersCollections) {
      const isCompanyTrackersCollection =
        trackersCollection.companyId === user.companyId;

      if (isCompanyTrackersCollection)
        filteredTrackersCollections.add(trackersCollection);
    }

    return [...filteredTrackersCollections];
  },
);

export const selectTrackersCollectionsNames = createDraftSafeSelector(
  selectCompanyTrackersCollections,
  (trackersCollections) => {
    const names: TrackersCollection.Data["name"][] = [];

    for (const trackersCollection of trackersCollections) {
      if (!trackersCollection) continue;

      names.push(trackersCollection.name);
    }

    return names;
  },
);

export const selectTrackersCollectionUpdateDate = createDraftSafeSelector(
  selectTrackersCollectionById,
  (trackersCollection) => trackersCollection?.updatedAt || "",
);

export const selectIsTrackersCollectionSingleBranded = createDraftSafeSelector(
  selectTrackersCollectionById,
  (tracker) => {
    if (!tracker) return true;

    return tracker.trackerIds.length <= 1;
  },
);

export const selectTrackersCollectionTrackers = createDraftSafeSelector(
  selectTrackersCollectionById,
  selectTrackersEntities,
  (trackersCollection, trackersEntities) => {
    const trackerIds = trackersCollection?.trackerIds || [];

    const trackers = new Set<Tracker.Data>();

    for (const trackerId of trackerIds) {
      const tracker = trackersEntities[trackerId];

      if (tracker) trackers.add(tracker);
    }

    return Array.from(trackers);
  },
);

export const selectTrackersCollectionSearchIds = createDraftSafeSelector(
  selectTrackersCollectionById,
  selectTrackersEntities,
  (trackersCollection, trackersEntities) => {
    const trackerIds = trackersCollection?.trackerIds || [];

    const searchIds = new Set<Search.Data["id"]>();

    for (const trackerId of trackerIds) {
      const tracker = trackersEntities[trackerId];

      if (!tracker) continue;

      for (const searchId of tracker.searchIds) searchIds.add(searchId);
    }

    return [...searchIds];
  },
);

export const selectTrackersCollectionMainTracker = createDraftSafeSelector(
  selectTrackersCollectionById,
  selectTrackersEntities,
  (trackersCollection, trackersEntities) => {
    const trackerIds = trackersCollection?.trackerIds || [];

    const mainTrackerId = trackerIds[0] || "";

    return trackersEntities[mainTrackerId];
  },
);

export const selectTrackersCollectionMainTrackerSearchIds =
  createDraftSafeSelector(
    selectTrackersCollectionById,
    selectTrackersEntities,
    (trackersCollection, trackersEntities) => {
      const trackerId = trackersCollection?.trackerIds[0] || "";

      const tracker = trackersEntities[trackerId];

      if (!tracker) return [];

      const searchIds = new Set<Search.Data["id"]>();

      for (const searchId of tracker.searchIds) searchIds.add(searchId);

      return [...searchIds];
    },
  );

export const selectTrackersCollectionSpecificTrackers = createDraftSafeSelector(
  (
    state: Store.RootState,
    trackersCollectionId: TrackersCollection.Data["id"],
  ): [
    TrackersCollection.Data["id"],
    ReturnType<typeof selectDashboardsWithTrackerIds>,
    ReturnType<typeof selectTrackersCollectionTrackers>,
  ] => [
    trackersCollectionId,
    selectDashboardsWithTrackerIds(state),
    selectTrackersCollectionTrackers(state, trackersCollectionId),
  ],
  ([trackersCollectionId, dashboards, trackers]) => {
    const filteredTrackers = new Set<Tracker.Data>(trackers);

    for (const tracker of trackers)
      for (const dashboard of dashboards) {
        if (dashboard.id === trackersCollectionId) continue;

        if (!dashboard.trackerIds.includes(tracker.id)) continue;

        filteredTrackers.delete(tracker);

        break;
      }

    return Array.from(filteredTrackers);
  },
);

export const selectTrackersCollectionsWithSearchId = createDraftSafeSelector(
  (
    state: Store.RootState,
    searchId: Search.Data["id"],
  ): [TrackersCollection.Data[], Tracker.Data[]] => [
    selectTrackersCollections(state),
    selectTrackersWithSearchId(state, searchId),
  ],
  ([trackersCollections, trackers]) => {
    const trackersCollectionsWithSearchId = new Set<TrackersCollection.Data>();

    const trackerIds = trackers.map(({ id }) => id);

    for (const trackersCollection of trackersCollections) {
      const isSearchIdIncluded = trackersCollection.trackerIds.some((id) =>
        trackerIds.includes(id),
      );

      if (isSearchIdIncluded)
        trackersCollectionsWithSearchId.add(trackersCollection);
    }

    return [...trackersCollectionsWithSearchId];
  },
);

export const selectTrackersCollectionsWithTrackerId = createDraftSafeSelector(
  (
    state: Store.RootState,
    trackerId: Tracker.Data["id"],
  ): [TrackersCollection.Data[], Tracker.Data["id"]] => [
    selectTrackersCollections(state),
    trackerId,
  ],
  ([trackersCollections, trackerId]) => {
    const trackersCollectionsWithTrackerId = new Set<TrackersCollection.Data>();

    for (const trackersCollection of trackersCollections) {
      const isTrackerIdIncluded =
        trackersCollection.trackerIds.includes(trackerId);

      if (isTrackerIdIncluded)
        trackersCollectionsWithTrackerId.add(trackersCollection);
    }

    return [...trackersCollectionsWithTrackerId];
  },
);

export const selectIsCompanyTrackersCollection = createDraftSafeSelector(
  (
    state: Store.RootState,
    trackersCollectionId: TrackersCollection.Data["id"],
  ): [
    ReturnType<typeof selectUser>,
    ReturnType<typeof selectTrackersCollectionById>,
  ] => [
    selectUser(state),
    selectTrackersCollectionById(state, trackersCollectionId),
  ],
  ([user, trackersCollection]) => {
    if (!user || !trackersCollection) return false;

    return user.companyId === trackersCollection.companyId;
  },
);

export const selectTrackersCollectionKeywordsDataSources =
  createDraftSafeSelector(
    (
      state: Store.RootState,
      trackersCollectionId: TrackersCollection.Data["id"],
    ): [ReturnType<typeof selectTrackersCollectionTrackers>] => [
      selectTrackersCollectionTrackers(state, trackersCollectionId),
    ],
    ([trackers]) => {
      const trackersCollectionKeywordsDataSources =
        new Set<Search.KeywordsDataSource>();

      for (const { keywordsDataSources } of trackers)
        for (const keywordDataSource of keywordsDataSources)
          trackersCollectionKeywordsDataSources.add(keywordDataSource);

      return [...trackersCollectionKeywordsDataSources];
    },
  );
