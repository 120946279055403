import { useMemo, useState } from "react";

// Inner imports
import * as constants from "../constants";

type ReturnProps = {
  forecastLineAnimationDelay: number;
  isInitialAnimationFinished: boolean;
  isActualDataLineAnimationFinished: boolean;
  isForecastDataLineAnimationFinished: boolean;
  setIsActualDataLineAnimationFinished: (value: boolean) => void;
  setIsForecastDataLineAnimationFinished: (value: boolean) => void;
};

export const useLineAnimationConfig = (): ReturnProps => {
  const [
    isActualDataLineAnimationFinished,
    setIsActualDataLineAnimationFinished,
  ] = useState<boolean>(false);

  const [
    isForecastDataLineAnimationFinished,
    setIsForecastDataLineAnimationFinished,
  ] = useState<boolean>(false);

  const isInitialAnimationFinished = useMemo<boolean>(
    () =>
      isActualDataLineAnimationFinished && isForecastDataLineAnimationFinished,
    [isActualDataLineAnimationFinished, isForecastDataLineAnimationFinished],
  );

  const forecastLineAnimationDelay = useMemo<number>(
    () =>
      isInitialAnimationFinished
        ? 0
        : constants.LINE_CHART_FORECAST_LINE_ANIMATION_DELAY,
    [isInitialAnimationFinished],
  );

  return {
    isInitialAnimationFinished,
    forecastLineAnimationDelay,
    isActualDataLineAnimationFinished,
    isForecastDataLineAnimationFinished,
    setIsActualDataLineAnimationFinished,
    setIsForecastDataLineAnimationFinished,
  };
};
