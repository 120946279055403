import * as yup from "yup";
import isNull from "lodash/isNull";
import isString from "lodash/isString";
import isBefore from "date-fns/isAfter";

import {
  DASHBOARD_DATE_RANGE_TYPES,
  DASHBOARD_DATE_RANGE_CATEGORIES,
} from "src/constants";

export const dashboardDateRangeSchema = yup
  .object({
    authorId: yup.string().required(),
    companyId: yup.string().required(),
    trackersCollectionId: yup.string().required(),
    category: yup
      .string<DashboardDateRange.Category>()
      .oneOf([...DASHBOARD_DATE_RANGE_CATEGORIES])
      .required(),
    type: yup
      .string<DashboardDateRange.Type>()
      .oneOf([...DASHBOARD_DATE_RANGE_TYPES])
      .nullable(),
    dateFrom: yup.string().nullable().default(null),
    dateTo: yup
      .string()
      .nullable()
      .test((dateTo, { parent: { dateFrom } }) => {
        if (isNull(dateTo) && isNull(dateFrom)) return true;

        if (!isString(dateTo) || !isString(dateFrom)) return false;

        const [formattedStartDate, formatterEndDate] = [
          new Date(dateFrom),
          new Date(dateTo),
        ];

        return !isBefore(formattedStartDate, formatterEndDate);
      })
      .default(null),
    createdAt: yup.string().default(""),
    updatedAt: yup.string().default(""),
  })
  .noUnknown()
  .required();

export type DashboardDateRangeSchemaType = yup.InferType<
  typeof dashboardDateRangeSchema
>;
