import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";

import { selectUserId } from "../user/userSelector";
import { selectCompanyId } from "../company/companySelector";
import { DASHBOARD_DATE_RANGE_DEFAULT_CATEGORY } from "src/constants";
import { onStateFulfilled, onStatePending, onStateRejected } from "../utils";

// Inner imports
import * as api from "./dashboardDateRangesApi";

export const dashboardDateRangesAdapter =
  createEntityAdapter<DashboardDateRange.Data>({
    sortComparer: (a, b) => a.createdAt.localeCompare(b.createdAt),
  });

const initialState =
  dashboardDateRangesAdapter.getInitialState<Store.InitialState>({
    status: "idle",
    error: null,
  });

export const fetchDashboardDateRangesByCompanyId = createAsyncThunk<
  DashboardDateRange.Data[],
  Company.Data["id"]
>(
  "dashboard-date-ranges/fetch-by-company-id",
  api.getDashboardDateRangesByCompanyId,
);

export const fetchDashboardDateRangesByTrackersCollectionId = createAsyncThunk<
  DashboardDateRange.Data[],
  TrackersCollection.Data["id"],
  { state: Store.RootState }
>(
  "dashboard-date-ranges/fetch-by-trackers-collection-id",
  (payload, { getState }) => {
    const state = getState();

    const companyId = selectCompanyId(state);

    return api.getDashboardDateRangesByTrackersCollectionId(payload, companyId);
  },
);

export const fetchDashboardDateRangesByIds = createAsyncThunk<
  DashboardDateRange.Data[],
  DashboardDateRange.Data["id"][]
>("dashboard-date-ranges/fetch-by-ids", api.getDashboardDateRangesByIds);

export const createDashboardDateRange = createAsyncThunk<
  DashboardDateRange.Data,
  Store.CreateEntity<DashboardDateRange.Data>,
  { state: Store.RootState }
>("dashboard-date-ranges/create-one", (payload, { getState }) => {
  const state = getState();

  const [companyId, authorId] = [selectCompanyId(state), selectUserId(state)];

  return api.createDashboardDateRange({ ...payload, companyId, authorId });
});

export const createDashboardDefaultDateRanges = createAsyncThunk<
  DashboardDateRange.Data[],
  {
    authorId: User.Data["id"];
    companyId: Company.Data["id"];
    types: DashboardDateRange.Type[];
    trackersCollectionId: TrackersCollection.Data["id"];
  },
  { state: Store.RootState }
>(
  "dashboard-date-ranges/create-many",
  ({ trackersCollectionId, types, authorId, companyId }) => {
    const formattedDateRanges = new Set<
      Store.CreateEntity<DashboardDateRange.Data>
    >();

    for (const type of types)
      formattedDateRanges.add({
        type,
        authorId,
        companyId,
        endDate: null,
        createdAt: "",
        updatedAt: "",
        startDate: null,
        trackersCollectionId,
        category: DASHBOARD_DATE_RANGE_DEFAULT_CATEGORY,
      });

    return api.createDashboardDateRanges([...formattedDateRanges]);
  },
);

export const updateDashboardDateRangesByAuthorId = createAsyncThunk<
  Store.UpdateEntity<DashboardDateRange.Data>[],
  {
    changes: Store.UpdateEntity<DashboardDateRange.Data>["changes"];
    authorId: DashboardDateRange.Data["authorId"];
  },
  { state: Store.RootState }
>("dashboard-date-ranges/update-by-author-id", (payload, { getState }) => {
  const state = getState();

  const companyId = selectCompanyId(state);

  return api.updateDashboardDateRangesByAuthorId(payload, companyId);
});

export const removeDashboardDateRange = createAsyncThunk<
  DashboardDateRange.Data["id"],
  DashboardDateRange.Data["id"]
>("dashboard-date-ranges/remove-one", api.deleteDashboardDateRange);

export const removeDashboardDateRanges = createAsyncThunk<
  DashboardDateRange.Data["id"][],
  DashboardDateRange.Data["id"][]
>("dashboard-date-ranges/remove-many", api.deleteDashboardDateRanges);

const dashboardDateRangesSlice = createSlice({
  name: "dashboard-date-ranges",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      fetchDashboardDateRangesByCompanyId.pending,
      onStatePending,
    );
    builder.addCase(
      fetchDashboardDateRangesByCompanyId.rejected,
      onStateRejected,
    );
    builder.addCase(
      fetchDashboardDateRangesByCompanyId.fulfilled,
      (...args) => {
        dashboardDateRangesAdapter.addMany(...args);
        onStateFulfilled(...args);
      },
    );

    builder.addCase(
      fetchDashboardDateRangesByTrackersCollectionId.fulfilled,
      (...args) => {
        dashboardDateRangesAdapter.addMany(...args);
        onStateFulfilled(...args);
      },
    );

    builder.addCase(fetchDashboardDateRangesByIds.fulfilled, (...args) => {
      dashboardDateRangesAdapter.addMany(...args);
      onStateFulfilled(...args);
    });

    builder.addCase(
      createDashboardDateRange.fulfilled,
      dashboardDateRangesAdapter.addOne,
    );

    builder.addCase(
      createDashboardDefaultDateRanges.fulfilled,
      dashboardDateRangesAdapter.addMany,
    );

    builder.addCase(
      updateDashboardDateRangesByAuthorId.fulfilled,
      dashboardDateRangesAdapter.updateMany,
    );

    builder.addCase(
      removeDashboardDateRange.fulfilled,
      dashboardDateRangesAdapter.removeOne,
    );

    builder.addCase(
      removeDashboardDateRanges.fulfilled,
      dashboardDateRangesAdapter.removeMany,
    );
  },
});

export default dashboardDateRangesSlice.reducer;
