import { useTranslation } from "react-i18next";

import styles from "./Login.module.scss";
import { LoginForm } from "src/features";

const Login = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.login}>
      <h1 className={styles.title}>{t("authentication.login.title")}</h1>
      <LoginForm />
    </div>
  );
};

export default Login;
