import { FC, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";

import styles from "./SelectedTrackers.module.scss";
import { useModal } from "src/hooks";
import { Button } from "src/components";
import { AddTrackerModal } from "src/features";
import { PencilOutline } from "src/assets/icons";

// Inner imports
import { SelectedTracker } from "./components";

type Props = {
  selectedTrackers: Tracker.CreationData[];
  selectedSearches: Record<Tracker.CreationData["id"], Search.CreationData[]>;
  resetSearchesHandler: (tracker: Tracker.CreationData) => void;
  selectTrackerHandler: (tracker: Tracker.CreationData) => void;
  unselectTrackerHandler: (tracker: Tracker.CreationData) => void;
  duplicateTrackerHandler: (tracker: Tracker.CreationData) => void;
  openTrackerSettingsHandler: (tracker: Tracker.CreationData) => void;
  selectCustomTrackerHandler: (name: Tracker.CreationData["name"]) => void;
  updateTrackerHandler: ({
    id,
    changes,
    callback,
  }: {
    id: Tracker.CreationData["id"];
    callback?: () => void;
    changes: Tracker.CreationData;
  }) => void;
  selectSearchHandler: (
    tracker: Tracker.CreationData,
    search: Search.CreationData,
  ) => void;
};

export const SelectedTrackers: FC<Props> = ({
  selectedTrackers,
  selectedSearches,
  selectSearchHandler,
  resetSearchesHandler,
  updateTrackerHandler,
  selectTrackerHandler,
  unselectTrackerHandler,
  selectCustomTrackerHandler,
  openTrackerSettingsHandler,
}) => {
  const { t } = useTranslation();

  const ref = useRef<HTMLDivElement>(null);

  const { setModal } = useModal();

  const hasTrackers = useMemo<boolean>(
    () => Boolean(selectedTrackers.length),
    [selectedTrackers.length],
  );

  const openAddTrackerModal = (): void =>
    setModal(
      "add-tracker",
      <AddTrackerModal submitHandler={selectCustomTrackerHandler} />,
    );

  return (
    <div className={styles.wrapper} ref={ref}>
      <div className={styles.heading}>
        <span className={styles.title}>
          {t("page.create_tracker.select_trackers.label.selected_trackers")}
        </span>
        <div className={styles.settings}>
          <Button
            onClick={openAddTrackerModal}
            className={styles.button}
            buttonStyle="outlined"
          >
            <span>
              {t("page.create_tracker.select_trackers.button.add_manually")}
            </span>
            <PencilOutline />
          </Button>
        </div>
      </div>
      {hasTrackers && (
        <div className={styles.trackers}>
          {selectedTrackers.map((tracker) => (
            <SelectedTracker
              ref={ref}
              key={tracker.id}
              tracker={tracker}
              selectedTrackers={selectedTrackers}
              selectedSearches={selectedSearches[tracker.id] || []}
              selectSearchHandler={selectSearchHandler}
              resetSearchesHandler={resetSearchesHandler}
              updateTrackerHandler={updateTrackerHandler}
              duplicateTrackerHandler={selectTrackerHandler}
              unselectTrackerHandler={unselectTrackerHandler}
              openTrackerSettingsHandler={openTrackerSettingsHandler}
            />
          ))}
        </div>
      )}
    </div>
  );
};
