// DEV-75266

import { useCallback } from "react";

type Data = { tab?: string; page?: number };

type ReturnType = { data: Data; setter: (props: Data) => void };

export const useTableSettings = (type: string): ReturnType => {
  const data = getData(type);

  const setter = useCallback(
    ({ tab, page = 0 }: Data) => {
      if (!tab && typeof page !== "number") return;
      const data = getData(type);

      const tablesSetting = sessionStorage.getItem("tablesSetting");

      const newData = {
        ...(tablesSetting ? JSON.parse(tablesSetting) : {}),
        [type]: {
          ...data,
          ...(tab ? { tab } : {}),
          ...(typeof page === "number" ? { page } : {}),
        },
      };

      newData;

      sessionStorage.setItem("tablesSetting", JSON.stringify(newData));
    },
    [type],
  );

  return { data, setter };
};

function getData(type: string) {
  const data: { tab?: string; page?: number } = {};
  const tablesSetting = sessionStorage.getItem("tablesSetting");

  if (tablesSetting) {
    try {
      const parsedData = JSON.parse(tablesSetting);

      if (parsedData && typeof parsedData === "object") {
        const selectedTableSettings = parsedData[type];

        const selectedTableTab = selectedTableSettings?.["tab"];
        const selectedTablePage = selectedTableSettings?.["page"];

        if (typeof selectedTableTab === "string") {
          data["tab"] = selectedTableTab;
        }

        if (typeof selectedTablePage === "number") {
          data["page"] = selectedTablePage;
        }
      }
    } catch (err) {}
  }

  return data;
}
