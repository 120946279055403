import { useCallback, useEffect, useState } from "react";

import { useAppDispatch } from "src/store";
import { updateTrackerIdsWithPartialData } from "src/store/actions";
import { subscribeOnTrackersWithPartialData } from "src/store/trackers/trackersApi";

export const useTrackersWithPartialDataObserver = () => {
  const dispatch = useAppDispatch();

  const [isObserverSet, setIsObserverSet] = useState<boolean>(false);

  const callback = useCallback(
    (data: Tracker.Data["id"][] | null): void => {
      if (!data) return;

      dispatch(updateTrackerIdsWithPartialData(data));

      setIsObserverSet(true);
    },
    [dispatch],
  );

  useEffect(() => {
    let observer = () => {};

    try {
      observer = subscribeOnTrackersWithPartialData(callback);
    } catch (err) {
      console.error(err);
    }

    return () => {
      observer();
    };
  }, [callback]);

  return { isObserverSet };
};
