import * as icons from "src/assets/icons";

export const getIconByName = (
  iconName?: AppIcon,
  className?: string,
): JSX.Element | null => {
  if (!iconName) return null;

  const Icon = icons[iconName];

  return <Icon className={className || ""} />;
};
