import { useCallback, useEffect, useState } from "react";
import firebase from "firebase/compat/app";

import { useAppDispatch } from "src/store";
import firebaseAuth from "src/services/firebaseAuth";
import { logOutUser, setUser, USER_INITIAL_DATA } from "src/store/actions";

export const useAuthObserver = () => {
  const dispatch = useAppDispatch();

  const [isObserverSet, setIsObserverSet] = useState<boolean>(false);

  const [isUserAuthenticated, setIsUserAuthenticated] =
    useState<boolean>(false);

  const callback = useCallback(
    (data: firebase.User | null): void => {
      data?.reload();

      if (data) {
        const { uid, email } = data;

        dispatch(
          setUser({ ...USER_INITIAL_DATA, id: uid, email: email ?? "" }),
        );

        setIsUserAuthenticated(true);
      } else {
        dispatch(logOutUser());

        setIsUserAuthenticated(false);
      }

      setIsObserverSet(true);
    },
    [dispatch],
  );

  useEffect(() => {
    let observer = () => {};

    try {
      observer = firebaseAuth().onAuthStateChanged(callback);
    } catch (err) {
      console.error(err);
    }

    return () => {
      observer();
    };
  }, [callback]);

  return { isUserAuthenticated, isObserverSet };
};
