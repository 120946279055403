/* eslint-disable @typescript-eslint/no-unused-vars */
import { createContext, ReactNode } from "react";
import { ModalId } from "src/features/Modals/ConfirmModal/types";

export default createContext({
  modalElements: {},
  setModalElements: (id: ModalId, element: ReactNode | null) => {},
  globalPreloaderText: "",
  setGlobalPreloaderText: (value: string) => {},
  isGlobalPreloaderShown: false,
  setIsGlobalPreloaderShown: (value: boolean) => {},
  isUserAuthenticated: false,
  isAuthenticationObserverSet: false,
});
