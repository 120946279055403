import { useEffect, useMemo, useState } from "react";
import uniq from "lodash/uniq";
import omit from "lodash/omit";
import { useSelector } from "react-redux";

import { Translation } from "src/components";
import { generateDocId } from "src/store/utils";
import { selectTrackerSearchesLimit } from "src/store/selectors";
import { SEARCH_UPDATED_PROPERTIES_TO_RECREATE } from "src/constants";
import {
  showToastNotification,
  getDifferenceInObjects,
  isSearchCreatedTypeGuard,
} from "src/utils";
import { useImmer } from "use-immer";

type Props = {
  trackerId: Tracker.Data["id"];
  selectedSearches: Array<Search.Data | Search.CreationData>;
};

export const useSelectedTrackerSearches = ({
  trackerId,
  selectedSearches: defaultSelectedSearches = [],
}: Props) => {
  const { trackerSearchesLimit } = useSelector(selectTrackerSearchesLimit);

  const [selectedSearches, setSelectedSearches] = useState<
    Array<Search.Data | Search.CreationData>
  >(defaultSelectedSearches);

  const [unselectedSearches, setUnselectedSearches] = useState<
    Search.CreationData[]
  >([]);

  const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>(
    () => "idle",
  );

  const defaultSearchKeywords = useMemo<
    Record<Search.Data["id"], Search.KeywordsData>
  >(() => {
    const keywords = new Map<Search.Data["id"], Search.KeywordsData>();

    for (const search of selectedSearches)
      keywords.set(search.id, { keywords: [], status: "idle" });

    return Object.fromEntries(keywords);
  }, [selectedSearches]);

  const [searchKeywords, setSearchKeywords] = useImmer<
    Record<Search.Data["id"], Search.KeywordsData>
  >(defaultSearchKeywords);

  const selectedSearchIds = useMemo<Search.Data["id"][]>(() => {
    const searchIds = new Set<Search.Data["id"]>();

    for (const search of selectedSearches)
      if (isSearchCreatedTypeGuard(search)) searchIds.add(search.id);

    return [...searchIds];
  }, [selectedSearches]);

  const isSearchesChanged = useMemo<boolean>(
    () =>
      getIsSearchesContentChanged(selectedSearches, defaultSelectedSearches),
    [selectedSearches, defaultSelectedSearches],
  );

  useEffect(() => setLoadingStatus("idle"), [trackerId]);

  const selectSearch = (
    search: Search.CreationData,
    callback?: () => void,
  ): void => {
    if (selectedSearches.length >= trackerSearchesLimit)
      return showToastNotification({
        id: "tracker-search-limit",
        type: "warning",
        text: (
          <Translation
            i18nKey="tracker.status.warning.tracker_search_limit"
            values={{ count: trackerSearchesLimit }}
          />
        ),
      });

    setSelectedSearches((state) => uniq([...state, search]));

    callback?.();
  };

  const unselectSearch = (search: Search.CreationData): void => {
    setSelectedSearches((state) =>
      state.filter((selectedSearch) => selectedSearch.id !== search.id),
    );

    if (isSearchCreatedTypeGuard(search))
      setUnselectedSearches((state) => uniq([...state, search]));
  };

  const saveSearch = (search: Search.Data): void =>
    setSelectedSearches((state) => {
      const searches: Array<Search.Data | Search.CreationData> = [];

      for (const selectedSearch of state) {
        const isSelectedSearch = selectedSearch.id === search.id;

        if (isSelectedSearch) searches.push(search);
        else searches.push(selectedSearch);
      }

      return searches;
    });

  const updateSearch = ({
    id,
    changes,
    callback,
  }: {
    id: Search.CreationData["id"];
    changes: Search.CreationData;
    callback?: () => void;
  }): void => {
    const isSearchIdUpdated = changes.id !== id;

    setSelectedSearches((state) =>
      state.map((search) => {
        if (search.id !== id) return search;

        const isDataUpdated = Boolean(
          getDifferenceInObjects(search, changes, {
            selectedKeys: SEARCH_UPDATED_PROPERTIES_TO_RECREATE,
          }),
        );

        const shouldRecreateSearch = !isSearchIdUpdated && isDataUpdated;

        if (!shouldRecreateSearch) return { ...changes };

        return omit(
          { ...changes, id: generateDocId() },
          "createdAt",
          "updatedAt",
        );
      }),
    );

    callback?.();
  };

  const updateSearchKeywords = (
    searchId: Search.Data["id"],
    keywords: Search.Keyword[],
    status: LoadingStatus,
  ): void =>
    setSearchKeywords((draft) => {
      draft[searchId] = { keywords, status };
    });

  return {
    loadingStatus,
    selectedSearches,
    isSearchesChanged,
    selectedSearchIds,
    unselectedSearches,
    searchKeywords,
    saveSearch,
    updateSearch,
    selectSearch,
    unselectSearch,
    updateSearchKeywords,
  };
};

function getIsSearchesContentChanged(
  newSearches: (Search.Data | Search.CreationData)[],
  searches: (Search.Data | Search.CreationData)[],
): boolean {
  const [newSearchIds, searchIds] = [
    newSearches.map(({ id }) => id).sort(),
    searches.map(({ id }) => id).sort(),
  ];

  const isSearchIdsChanged = getIsSearchIdsChanged(newSearchIds, searchIds);

  if (isSearchIdsChanged) return true;

  for (const newSearch of newSearches) {
    const isNewSearchCreated = isSearchCreatedTypeGuard(newSearch);

    if (!isNewSearchCreated) continue;

    for (const search of searches) {
      if (newSearch.id !== search.id) continue;

      const isSearchCreated = isSearchCreatedTypeGuard(search);

      if (!isSearchCreated) continue;

      const searchUpdate = getDifferenceInObjects(newSearch, search, {
        ignoredKeys: ["status", "updatedAt"],
      });

      if (searchUpdate) return true;
    }
  }

  return false;
}

function getIsSearchIdsChanged(
  newSearchIds: Search.Data["id"][],
  searchIds: Search.Data["id"][],
): boolean {
  return JSON.stringify(newSearchIds) !== JSON.stringify(searchIds);
}
