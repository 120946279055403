import { FC, HTMLAttributes, memo, useMemo } from "react";
import { useTranslation } from "react-i18next";
import cx from "classnames";

import styles from "./Label.module.scss";

type Props = {
  leftText: string;
  rightText?: string;
  isOptional?: boolean;
} & HTMLAttributes<HTMLDivElement>;

export const Label: FC<Props> = memo(
  ({ leftText, rightText, className, isOptional, ...props }) => {
    const { t } = useTranslation();

    const optionalLabel = useMemo<string>(
      () => (isOptional ? t("component.form.label.optional") : ""),
      [isOptional, t],
    );

    return (
      <div className={cx(styles.label, className)} {...props}>
        <div
          className={styles.leftText}
          title={leftText}
          data-optional-label={optionalLabel}
        >
          {leftText}
        </div>
        {rightText && <div className={styles.rightText}>{rightText}</div>}
      </div>
    );
  },
);
