import { Fragment, useState } from "react";
import cx from "classnames";

import styles from "./TableRow.module.scss";
import { genericMemo } from "src/utils";
import { TableCell } from "../../../";
import type { TableItemGenericProps, TableProps } from "../../../../types";

// Inner imports
import { SubTable } from "../";

type Props<T> = {
  item: T;
  isSubTable?: boolean;
} & Pick<TableProps<T>, "tableColumns" | "getActionMenuOptions">;

export const TableRow = genericMemo(
  <T extends TableItemGenericProps>({
    item,
    tableColumns,
    getActionMenuOptions,
    isSubTable = false,
  }: Props<T>) => {
    const [isRowCollapsed, setRowCollapsed] = useState<boolean>(false);

    const toggleCollapse = (): void => setRowCollapsed((state) => !state);

    return (
      <Fragment key={item.id}>
        <tr
          className={cx(styles.wrapper, isSubTable ? styles.subTableRow : "")}
        >
          {tableColumns.map((tableColumn, index) => (
            <TableCell
              key={String(tableColumn.key)}
              item={item}
              tableColumn={tableColumn}
              isRowCollapsed={isRowCollapsed}
              toggleCollapse={toggleCollapse}
              index={index}
              {...(index === tableColumns.length - 1
                ? { getActionMenuOptions }
                : {})}
            />
          ))}
        </tr>
        {!!item.subTableProps?.items?.length && isRowCollapsed && (
          <SubTable {...item.subTableProps} />
        )}
      </Fragment>
    );
  },
);
