import { MenuDropdownSubmenuDirection } from "./types";

const MENU_DROPDOWN_SUBMENU_EXTRA_SPACE = 50 as const;

export const calculateSubmenuDirection = (
  element: HTMLElement,
): MenuDropdownSubmenuDirection => {
  const { right: elementRight } = element.getBoundingClientRect();

  if (window.innerWidth < elementRight + MENU_DROPDOWN_SUBMENU_EXTRA_SPACE)
    return "left";

  return "right";
};
