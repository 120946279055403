import axios from "src/services/axios";
import firestore from "src/services/firestore";
import { showDevelopmentError } from "src/utils";
import { API_ENDPOINTS, COLLECTION_IDS } from "../constants";
import { companySchema } from "../company/companySchema";

// Inner imports
import * as schemas from "./subscriptionPlansSchema";

export const getSubscriptionPlanById = async (
  id: SubscriptionPlan.Data["id"],
): Promise<SubscriptionPlan.Data> => {
  const doc = await firestore()
    .collection(COLLECTION_IDS.subscriptionPlans)
    .doc(id)
    .get();

  if (!doc.exists) throw Error("Subscription plan not found");

  const subPlan = schemas.subscriptionPlanSchema.validateSync(doc.data());

  return { ...subPlan, id: doc.id };
};

export const getSubscriptionPlanByCompanyId = async (
  companyId: Company.Data["id"],
): Promise<SubscriptionPlan.Data> => {
  const companyDoc = await firestore()
    .collection(COLLECTION_IDS.companies)
    .doc(companyId)
    .get();

  if (!companyDoc.exists) throw Error("Company not found");

  const company = companySchema.validateSync(companyDoc.data());

  const subPlanDoc = await firestore()
    .collection(COLLECTION_IDS.subscriptionPlans)
    .doc(company.subscriptionPlanId)
    .get();

  if (!subPlanDoc.exists) throw Error("Company not found");

  const subPlan = schemas.subscriptionPlanSchema.validateSync(
    subPlanDoc.data(),
  );

  return { ...subPlan, id: subPlanDoc.id };
};

export const getAllSubscriptionPlans = async (): Promise<
  SubscriptionPlan.Data[]
> => {
  const result = await firestore()
    .collection(COLLECTION_IDS.subscriptionPlans)
    .where("isSelectable", "==", true)
    .get();

  if (result.empty) throw Error("Subscription plans not found");

  return result.docs.reduce<SubscriptionPlan.Data[]>((acc, doc) => {
    try {
      const subPlan = schemas.subscriptionPlanSchema.validateSync(doc?.data());

      acc.push({ ...subPlan, id: doc.id });
    } catch (error) {
      const errorTitle = "SUB PLAN VALIDATION ERROR";
      showDevelopmentError({
        error,
        additionalTexts: [errorTitle],
        reportMetadata: { message: errorTitle },
      });
    }

    return acc;
  }, []);
};

export const createStripeCheckoutToken = async ({
  userId,
  quantity,
  billingPeriod,
  subscriptionPlanId,
}: {
  userId: User.Data["id"];
  quantity?: number;
  billingPeriod: SubscriptionPlan.BillingPeriod;
  subscriptionPlanId: SubscriptionPlan.Data["id"];
}): Promise<string> => {
  const response = await axios.post(API_ENDPOINTS.createStripeCheckoutToken, {
    userId,
    quantity,
    billingPeriod,
    subscriptionPlanId,
  });

  return schemas.stripeCheckoutTokenSchema.validateSync(response.data);
};

export const createStripePortalToken = async (
  companyId: Company.Data["id"],
  returnURL: string,
  type?: "subscription_update" | null,
): Promise<string> => {
  const response = await axios.post(API_ENDPOINTS.createStripePortalToken, {
    type,
    returnURL,
    companyId,
  });

  return schemas.stripePortalTokenSchema.validateSync(response.data);
};

export const sendEnterpriseSubscriptionPlanRequest = async (
  userId: User.Data["id"],
): Promise<void> =>
  axios.post(API_ENDPOINTS.sendEnterpriseSubscriptionPlanRequest, { userId });
