import { FC, useMemo } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import styles from "./WidgetsSidebar.module.scss";
import { getIconByName } from "src/utils";
import { EmptySelection } from "src/assets/icons";
import { WIDGETS_COMPONENTS } from "src/features/Widgets/constants";
import {
  selectActiveWidgets,
  selectAvailableDashboardById,
  selectAvailableWidgetIdsByCompanyId,
  selectIsTrackersCollectionSingleBranded,
  selectAvailableWidgetsIdsForOneBrandTracker,
} from "src/store/selectors";

// Inner imports
import type {
  WidgetSidebarTile,
  WidgetSidebarTilesSection,
  AccumulatedSidebarTiles,
} from "./types";
import { SidebarTile } from "./components";
import { useAddWidgetToDashboard } from "./hooks";

type Props = {
  dashboardId: Dashboard.Data["id"];
  dashboardDateRangeId: DashboardDateRange.Data["id"];
};

export const WidgetsSidebar: FC<Props> = ({
  dashboardId,
  dashboardDateRangeId,
}) => {
  const { t } = useTranslation();

  const dashboard = useSelector((state: Store.RootState) =>
    selectAvailableDashboardById(state, dashboardId),
  );

  const trackersCollectionId = useMemo<Dashboard.Data["trackersCollectionId"]>(
    () => dashboard?.trackersCollectionId || "",
    [dashboard?.trackersCollectionId],
  );

  const activeWidgets = useSelector(selectActiveWidgets);

  const availableWidgetsInSubscriptionPlan = useSelector(
    (state: Store.RootState) =>
      selectAvailableWidgetIdsByCompanyId(state, dashboard?.companyId || ""),
  );

  const widgetsAvailableForOneBrand = useSelector(
    selectAvailableWidgetsIdsForOneBrandTracker,
  );

  const isTrackersCollectionHasCompetitors = useSelector(
    (state: Store.RootState) =>
      !selectIsTrackersCollectionSingleBranded(state, trackersCollectionId),
  );

  const addWidgetToDashboard = useAddWidgetToDashboard(
    dashboardId,
    dashboardDateRangeId,
  );

  const formattedTiles = useMemo<WidgetSidebarTile[]>(() => {
    const tiles: WidgetSidebarTile[] = [];

    if (!dashboard) return tiles;

    activeWidgets.forEach(({ id, placeHolderOnUi }) => {
      // It means that widget has been added already to dashboard;
      if (dashboard.tiles?.[id]) return;

      const iconName = WIDGETS_COMPONENTS[id]?.icon;

      const tile = {
        widgetId: id,
        dashboardId,
        title: t(placeHolderOnUi),
        icon: getIconByName(iconName, styles.widgetIcon),
        isAvailableInSubPlan: availableWidgetsInSubscriptionPlan.has(id),
        isCompetitorRequired: checkIsCompetitorRequired(
          isTrackersCollectionHasCompetitors,
          widgetsAvailableForOneBrand,
          id,
        ),
      };

      tiles.push(tile);
    });

    return tiles;
  }, [
    t,
    dashboard,
    dashboardId,
    activeWidgets,
    widgetsAvailableForOneBrand,
    availableWidgetsInSubscriptionPlan,
    isTrackersCollectionHasCompetitors,
  ]);

  const tilesSections = useMemo<AccumulatedSidebarTiles>(() => {
    const available: WidgetSidebarTilesSection = {
      title: t("component.sidebar.dashboard_widgets.label.available"),
      tiles: [],
    };
    const requireCompetitor: WidgetSidebarTilesSection = {
      title: t("component.sidebar.dashboard_widgets.label.competitor_required"),
      tiles: [],
    };
    const locked: WidgetSidebarTilesSection = {
      title: t("component.sidebar.dashboard_widgets.label.unavailable"),
      tiles: [],
    };

    for (const tile of formattedTiles) {
      const { isAvailableInSubPlan, isCompetitorRequired, widgetId } = tile;
      switch (true) {
        case isAvailableInSubPlan && !isCompetitorRequired:
          available.tiles.push({
            ...tile,
            addTileToDashboardHandler: () => addWidgetToDashboard(widgetId),
          });
          break;
        case isAvailableInSubPlan && isCompetitorRequired:
          requireCompetitor.tiles.push({
            ...tile,
            addTileToDashboardHandler: () => addWidgetToDashboard(widgetId),
          });
          break;
        case !isAvailableInSubPlan:
          locked.tiles.push(tile);
          break;
        default:
          break;
      }
    }

    return { available, requireCompetitor, locked };
  }, [formattedTiles, addWidgetToDashboard, t]);

  const isAtLeastOneTilePresent = useMemo<boolean>(
    () =>
      Object.values(tilesSections).some(({ tiles }) => Boolean(tiles.length)),
    [tilesSections],
  );

  // Empty plug;
  if (!isAtLeastOneTilePresent) {
    return (
      <div className={styles.tip}>
        <EmptySelection />
        {t("component.sidebar.dashboard_widgets.label.no_widgets")}
      </div>
    );
  }

  // Main markup;
  return (
    <div className={styles.tiles}>
      {Object.values(tilesSections).map(
        ({ title, tiles }) =>
          !!tiles.length && (
            <div className={styles.tilesSection} key={title}>
              <p className={styles.tilesSectionTitle}>{title}</p>
              {tiles.map((tile) => (
                <SidebarTile
                  key={`${trackersCollectionId}-${tile.widgetId}`}
                  {...tile}
                />
              ))}
            </div>
          ),
      )}
    </div>
  );
};

function checkIsCompetitorRequired(
  isTrackerHasCompetitors: boolean,
  widgetsAvailableForOneBrand: Set<Partial<Widget.IdType>>,
  widgetId: Widget.IdType,
): boolean {
  // It means that chosen tracker has competitors and partial widgets (not allowed for trackers without competitors) won't be blocked;
  if (isTrackerHasCompetitors) return false;

  // If chosen tracker has not competitors we have to check if widget allowed;
  return !widgetsAvailableForOneBrand.has(widgetId);
}
