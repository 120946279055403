import { memo, useMemo } from "react";
import cx from "classnames";

import styles from "./Checkbox.module.scss";
import {
  CheckboxCheck,
  CheckboxEmpty,
  CheckboxPartCheck,
} from "src/assets/icons";

type Props = {
  onClick?: () => void;
  isChecked?: boolean;
  className?: string;
  isDisabled?: boolean;
  isPartChecked?: boolean;
  color?: string;
  elementChecked?: JSX.Element | string | null;
  elementPartChecked?: JSX.Element | string | null;
  elementUnchecked?: JSX.Element | string | null;
  elementDisabled?: JSX.Element | string | null;
};

export const Checkbox = memo(
  ({
    color,
    onClick,
    isChecked = false,
    className = "",
    isPartChecked = false,
    isDisabled = false,
    elementChecked = null,
    elementPartChecked = null,
    elementUnchecked = null,
    elementDisabled = null,
  }: Props) => {
    const element = useMemo<JSX.Element | string | null>(() => {
      switch (true) {
        case isDisabled && Boolean(elementDisabled):
          return elementDisabled;
        case isPartChecked:
          return elementPartChecked || <CheckboxPartCheck color={color} />;
        case isChecked:
          return elementChecked || <CheckboxCheck color={color} />;
        case !isChecked:
          return elementUnchecked || <CheckboxEmpty />;
        default:
          return null;
      }
    }, [
      color,
      isChecked,
      isDisabled,
      isPartChecked,
      elementChecked,
      elementDisabled,
      elementUnchecked,
      elementPartChecked,
    ]);

    const isClickable = useMemo<boolean>(
      () => Boolean(onClick && !isDisabled),
      [isDisabled, onClick],
    );

    const handleOnClick = () => !isDisabled && onClick?.();

    if (!element) return null;

    return (
      <span
        className={cx(
          styles.checkbox,
          className,
          isChecked ? "" : styles.unchecked,
          isClickable ? styles.clickable : "",
          isDisabled ? styles.disabled : "",
        )}
        onClick={handleOnClick}
      >
        {element}
      </span>
    );
  },
);
