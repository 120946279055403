import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { useAbortController } from "src/hooks";
import { getSuggestedPerspectives } from "src/store/trackers/trackersApi";
import {
  selectLanguagesEntities,
  selectLocationsEntities,
} from "src/store/selectors";
import {
  showDevelopmentError,
  showToastNotification,
  isAbortedRequestErrorTypeGuard,
} from "src/utils";

type Props = {
  updateStatusHandler: (status: LoadingStatus) => void;
};

const SUGGESTED_PERSPECTIVES_LIMIT_PER_REQUEST: number = 6;

export const useGetSuggestedPerspectives = ({ updateStatusHandler }: Props) => {
  const { t } = useTranslation();

  const locations = useSelector(selectLocationsEntities);

  const languages = useSelector(selectLanguagesEntities);

  const abortController = useAbortController();

  const getLocation = useCallback(
    (locationId: Location.Data["id"]): Location.Data | undefined =>
      locations[locationId],
    [locations],
  );

  const getLanguage = useCallback(
    (languageId: Language.Data["id"]): Language.Data | undefined =>
      languages[languageId],
    [languages],
  );

  const _getSuggestedPerspectives = async ({
    callback,
    category,
    locationId,
    languageId,
    limit = SUGGESTED_PERSPECTIVES_LIMIT_PER_REQUEST,
  }: {
    limit?: number;
    category: Tracker.Category;
    callback?: (value: Tracker.Perspective[]) => void;
    languageId: Language.Data["id"];
    locationId: Location.Data["id"];
  }): Promise<Tracker.Perspective[]> => {
    const location = getLocation(locationId);

    const language = getLanguage(languageId);

    if (!location || !language) return [];

    try {
      updateStatusHandler("loading");

      const result = await getSuggestedPerspectives(
        { category, location, language, limit },
        { signal: abortController?.signal },
      );

      callback?.(result);

      updateStatusHandler("succeeded");

      return result;
    } catch (error) {
      if (isAbortedRequestErrorTypeGuard(error)) return [];

      showDevelopmentError({ error });

      showToastNotification({
        type: "error",
        text: t("common.error.server_error"),
      });

      callback?.([]);

      updateStatusHandler("failed");

      return [];
    }
  };

  const cancelGetSuggestedPerspectives = (): void => {
    if (abortController.signal.aborted) return;

    return abortController.abort();
  };

  return {
    cancelGetSuggestedPerspectives,
    getSuggestedPerspectives: _getSuggestedPerspectives,
  };
};
