import firestore from "src/services/firestore";
import { showDevelopmentError } from "src/utils";
import { COLLECTION_IDS } from "../constants";
import { getFirestoreEntitiesById } from "../utils";
import searchConfigurationSchema from "./searchesConfigurationSchema";

export const getTrackerSearchesConfiguration = async (
  searchIds: Array<Search.Data["id"]>,
) => {
  const searchesConfiguration = await getFirestoreEntitiesById(
    searchIds,
    COLLECTION_IDS.searchesConfiguration,
  );

  return searchesConfiguration.reduce<Search.Configuration[]>((acc, doc) => {
    try {
      const searchConfiguration = searchConfigurationSchema.validateSync(
        doc.data(),
      );

      acc.push({
        ...searchConfiguration,
        id: doc.id,
      });
    } catch (error) {
      const errorTitle = "SEARCH CONFIGURATION VALIDATION ERROR";

      showDevelopmentError({ error, additionalTexts: [errorTitle] });
    }

    return acc;
  }, []);
};

export const getSearchConfigurationById = async (
  searchId: Search.Data["id"],
): Promise<Search.Configuration> => {
  const res = await firestore()
    .collection(COLLECTION_IDS.searchesConfiguration)
    .doc(searchId)
    .get();

  const searchConfiguration = searchConfigurationSchema.validateSync(
    res.data(),
  );

  return { ...searchConfiguration, id: res.id };
};

export const updateSearchConfiguration = ({
  id,
  changes,
}: Store.UpdateEntity<Search.Configuration>): Promise<void> =>
  firestore()
    .collection(COLLECTION_IDS.searchesConfiguration)
    .doc(id)
    .set(changes, { merge: true });
