import { checkColorContrast } from "./colorUtils";

const COLOR_LIGHT = "#FFFFFF";
const COLOR_DARK = "#1E314F";

export const getElementContrastedColor = ({
  element,
  colors = [],
  parentElement,
  minRatio,
}: {
  element: HTMLElement;
  colors?: string[];
  parentElement?: HTMLElement;
  minRatio?: number;
}): string => {
  const [elementStyle, parentStyle] = [
    getComputedStyle(element),
    parentElement ? getComputedStyle(parentElement) : null,
  ];

  const [elementFontColor, elementBackgroundColor, parentBackgroundColor] = [
    elementStyle.getPropertyValue("color"),
    elementStyle.getPropertyValue("background-color"),
    parentStyle?.getPropertyValue("background-color"),
  ];

  const [foregroundColors, backgroundColor] = [
    [elementFontColor, ...colors, COLOR_LIGHT, COLOR_DARK],
    parentBackgroundColor || elementBackgroundColor,
  ];

  for (const foregroundColor of foregroundColors) {
    const isColorContrasted = checkColorContrast(
      foregroundColor,
      backgroundColor,
      minRatio,
    );

    if (isColorContrasted) return foregroundColor;
  }

  return elementFontColor;
};
