import { FC, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Table, { SortType } from "rsuite/Table";

import "./treeTableStyles.css";
import styles from "./KeywordsTable.module.scss";
import { useDebounce, usePagination } from "src/hooks";
import { Checkbox, TablePagination } from "src/components";
import { formatToLocaleNumber, triggerGtmEvent } from "src/utils";

// Inner imports
import { useKeywordsTableExpand } from "./hooks";
import type { KeywordsTableProps } from "./types";
import { KEYWORDS_TABLE_STYLES, KEYWORDS_TABLE_PAGINATION } from "./constants";
import {
  TableLoader,
  CheckboxCell,
  LineChartCell,
  SearchLinkCell,
  TotalVolumeCell,
  EmptyTablePlaceholder,
} from "./components";

const { HeaderCell, Column } = Table;

export const KeywordsTable: FC<KeywordsTableProps> = ({
  sort,
  data,
  searchId,
  keywords,
  searchedKeywords,
  selectedKeywords,
  isKeywordsLoading,
  isKeywordsPending,
  isSelectAllDisabled,
  keywordsSelectStatus,
  selectKeywordsHandler,
  selectAllKeywordsHandler,
  isDuplicatedKeywordsShown,
}) => {
  const { t } = useTranslation();

  const [scrollCoordinates, setScrollCoordinates] = useState<Coordinates>({
    x: 0,
    y: 0,
  });

  const {
    pageNumber,
    pagesCount,
    setPageNumber,
    slicedItems: paginatedData,
  } = usePagination({
    items: data,
    itemsPerPage: KEYWORDS_TABLE_PAGINATION.perPage,
  });

  const { expandedRowKeys, onExpandChange } = useKeywordsTableExpand({
    data,
    isDuplicatedKeywordsShown,
  });

  const availableKeywords = useMemo<Set<Search.Keyword["string"]>>(() => {
    const _keywords = new Set<Search.Keyword["string"]>();

    for (const { string } of keywords) _keywords.add(string);

    return _keywords;
  }, [keywords]);

  const keywordsCount = useMemo<number>(
    () => searchedKeywords.length,
    [searchedKeywords.length],
  );

  const selectedKeywordsCount = useMemo<number>(() => {
    let _selectedKeywordsCount = 0;

    for (const keyword in selectedKeywords) {
      const [isKeywordSelected, isKeywordsSearched] = [
        availableKeywords.has(keyword),
        searchedKeywords.some((value) => value.string === keyword),
      ];

      if (isKeywordSelected && isKeywordsSearched) _selectedKeywordsCount++;
    }

    return _selectedKeywordsCount;
  }, [availableKeywords, searchedKeywords, selectedKeywords]);

  const keywordsVolume = useMemo<string>(() => {
    let keywordsTotalVolume = 0;

    for (const keyword of searchedKeywords)
      keywordsTotalVolume += keyword.totalVolume;

    return formatToLocaleNumber(keywordsTotalVolume);
  }, [searchedKeywords]);

  const selectedKeywordsVolume = useMemo<string>(() => {
    let selectedKeywordsTotalVolume = 0;

    for (const keyword of keywords) {
      const [isKeywordSelected, isKeywordsSearched] = [
        selectedKeywords[keyword.string],
        searchedKeywords.some((value) => value.string === keyword.string),
      ];

      if (isKeywordSelected && isKeywordsSearched)
        selectedKeywordsTotalVolume += keyword.totalVolume;
    }

    return formatToLocaleNumber(selectedKeywordsTotalVolume);
  }, [keywords, searchedKeywords, selectedKeywords]);

  const isKeywordsLoaded = useMemo<boolean>(
    () => !isKeywordsLoading && !isKeywordsPending,
    [isKeywordsLoading, isKeywordsPending],
  );

  useEffect(() => setPageNumber(0), [data, setPageNumber]);

  const onTableSortChange = (
    dataKey: string,
    sortDirection?: SortType,
  ): void => {
    if (!sortDirection || !sort?.sortChangeHandler) return;

    sort.sortChangeHandler(dataKey, sortDirection);
  };

  const onTablePageNumberChange = (value: number): void => {
    setScrollCoordinates({ x: 0, y: 0 });
    setPageNumber(value);

    triggerGtmEvent("TrackerViewSelectKeywords", { searchId });
  };

  const onTableScroll = (x: number, y: number) =>
    setScrollCoordinates({ x, y });

  const onDebouncedTableScroll = useDebounce(onTableScroll, 500);

  return (
    <div className={styles.table}>
      <div className={styles.tableWrapper}>
        <Table
          isTree
          fillHeight
          virtualized
          data={paginatedData as never[]}
          renderLoading={() => (
            <TableLoader
              isKeywordsLoading={isKeywordsLoading}
              isKeywordsPending={isKeywordsPending}
            />
          )}
          rowHeight={KEYWORDS_TABLE_STYLES.cellHeight}
          loading={!isKeywordsLoaded}
          headerHeight={KEYWORDS_TABLE_STYLES.cellHeight}
          onSortColumn={onTableSortChange}
          rowKey="value"
          sortColumn={sort?.sortBy}
          sortType={sort?.sortDirection}
          shouldUpdateScroll={() => scrollCoordinates}
          onScroll={onDebouncedTableScroll}
          renderEmpty={() => (
            <EmptyTablePlaceholder
              text={t("component.keywords_table.label.no_keywords")}
            />
          )}
          onExpandChange={onExpandChange}
          expandedRowKeys={expandedRowKeys}
        >
          <Column
            treeCol
            align="left"
            key="value"
            flexGrow={1}
            minWidth={KEYWORDS_TABLE_STYLES.columnWidth}
          >
            <HeaderCell>
              <Checkbox
                onClick={selectAllKeywordsHandler}
                isChecked={keywordsSelectStatus === "checked"}
                isPartChecked={keywordsSelectStatus === "partial"}
                isDisabled={isSelectAllDisabled}
              />
              <span>{t("component.keywords_table.table.label.keywords")}</span>
              {isKeywordsLoaded && (
                <span>
                  {t("component.keywords_table.table.label.keywords_count", {
                    count: selectedKeywordsCount,
                    totalCount: keywordsCount,
                  })}
                </span>
              )}
            </HeaderCell>
            <CheckboxCell
              dataKey="value"
              isDuplicatedKeywordsShown={isDuplicatedKeywordsShown}
              selectedKeywords={selectedKeywords}
              keywordsSelectHandler={selectKeywordsHandler}
            />
          </Column>
          <Column
            key="totalVolume"
            align="right"
            width={KEYWORDS_TABLE_STYLES.columnWidth}
          >
            <HeaderCell>
              <div className={styles.totalVolumeWrapper}>
                <span>
                  {t("component.keywords_table.table.label.search_volume")}
                </span>
                {isKeywordsLoaded && (
                  <span>
                    {t("component.keywords_table.table.label.keywords_volume", {
                      volume: selectedKeywordsVolume,
                      totalVolume: keywordsVolume,
                    })}
                  </span>
                )}
              </div>
            </HeaderCell>
            <TotalVolumeCell dataKey="totalVolume" />
          </Column>
          <Column width={KEYWORDS_TABLE_STYLES.columnWidth}>
            <HeaderCell>{""}</HeaderCell>
            <LineChartCell dataKey="lineChart" />
          </Column>
          {!isDuplicatedKeywordsShown && (
            <Column
              key="searchLink"
              width={KEYWORDS_TABLE_STYLES.smallColumnWidth}
            >
              <HeaderCell>{""}</HeaderCell>
              <SearchLinkCell
                className={styles.tableLink}
                searchId={searchId}
                dataKey="value"
              />
            </Column>
          )}
          {!isDuplicatedKeywordsShown && (
            <Column
              key="searchLink"
              width={KEYWORDS_TABLE_STYLES.smallColumnWidth}
            >
              <HeaderCell>{""}</HeaderCell>
              <SearchLinkCell
                className={styles.tableLink}
                searchId={searchId}
                dataKey="value"
                searchLinkType="image"
              />
            </Column>
          )}
        </Table>
      </div>
      <div className={styles.paginationWrapper}>
        <TablePagination
          pageCount={pagesCount}
          pageRangeDisplayed={5}
          marginPagesDisplayed={1}
          forcePage={pageNumber}
          onPageChange={({ selected }) => onTablePageNumberChange(selected)}
        />
      </div>
    </div>
  );
};
