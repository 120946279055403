import { createDraftSafeSelector } from "@reduxjs/toolkit";

import firebaseAuth from "src/services/firebaseAuth";

// Inner imports
import { COMPANY_INITIAL_DATA, companyAdapter } from "./companySlice";

export const {
  selectAll: selectCompanies,
  selectEntities: selectCompaniesEntities,
  selectById: selectCompanyById,
  selectIds: selectCompanyIds,
} = companyAdapter.getSelectors<Store.RootState>(({ company }) => company);

export const selectCompany = createDraftSafeSelector(
  ({ company }: Store.RootState) => company.entities,
  (companyEntities) => {
    const { currentUser } = firebaseAuth();

    if (!currentUser) return COMPANY_INITIAL_DATA;

    for (const companyId in companyEntities) {
      const company = companyEntities[companyId];

      if (!company) continue;

      const userCompanyMember = company.members[currentUser.uid];

      if (userCompanyMember) return company;
    }

    return COMPANY_INITIAL_DATA;
  },
);

export const selectCompanyStatus = createDraftSafeSelector(
  ({ company }: Store.RootState) => company,
  (company) => company.status,
);

export const selectCompanyId = createDraftSafeSelector(
  selectCompany,
  (company) => company.id,
);

export const selectCompanyMembers = createDraftSafeSelector(
  selectCompany,
  (company) => company.members,
);

export const selectCompanyOwner = createDraftSafeSelector(
  selectCompanyMembers,
  (members) => {
    for (const userId in members) {
      const member = members[userId];

      if (!member) continue;

      if (member.companyRole === "owner") return member;
    }
  },
);

export const selectCompanyOwnerEmail = createDraftSafeSelector(
  selectCompanyOwner,
  (companyOwner) => {
    if (!companyOwner) return "";

    return companyOwner.email || "";
  },
);

export const selectIsCompanyMembersEmailLoaded = createDraftSafeSelector(
  selectCompanyMembers,
  (members) => Object.values(members).some(({ email }) => Boolean(email)),
);
