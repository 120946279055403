import * as yup from "yup";

import { LOCATION_TYPES, SEARCH_KEYWORDS_DATA_SOURCES } from "src/constants";

const locationKeywordsDataSourcesSchema = yup
  .string<Search.KeywordsDataSource>()
  .oneOf([...SEARCH_KEYWORDS_DATA_SOURCES])
  .required();

const locationSchema = yup
  .object({
    isActive: yup.boolean().required(),
    name: yup.string().required(),

    cityCode: yup.string().nullable().defined(),
    countryCode: yup.string().nullable().defined(),
    regionCode: yup.string().nullable().defined(),

    languageIds: yup.array(yup.string().required()).required(),

    city: yup.string().nullable().defined(),
    country: yup.string().nullable().defined(),
    region: yup.string().nullable().defined(),

    keywordsDataSources: yup
      .array(locationKeywordsDataSourcesSchema)
      .required(),

    type: yup
      .string<Location.Data["type"]>()
      .oneOf([...LOCATION_TYPES])
      .required(),
  })
  .noUnknown()
  .required();

export default locationSchema;
