import { TreeNode } from "rsuite/cjs/internals/Tree/types";

import { getLocationName, getLocationType } from "src/utils";

// Inner imports
import { LocationNode } from "./types";

export const createLocationTree = (locations: Location.Data[]): TreeNode[] => {
  const countryMap = new Map<Location.Data["id"], Location.Data>();
  const regionMap = new Map<Location.Data["id"], Location.Data>();
  const cityMap = new Map<Location.Data["id"], Location.Data>();

  for (const location of locations) {
    switch (true) {
      case Boolean(location.city): {
        cityMap.set(location.id, location);
        break;
      }
      case Boolean(location.region): {
        regionMap.set(location.id, location);
        break;
      }
      case Boolean(location.country):
      default: {
        countryMap.set(location.id, location);
        break;
      }
    }
  }

  const locationMap = new Map<Location.Data["id"], LocationNode>();

  for (const location of locations) {
    const locationType = getLocationType(location);

    if (locationType === "country" && location.country) {
      let parentId: Location.Data["id"] | null = null;

      locationMap.set(location.id, {
        data: location,
        value: location.id,
        label: getLocationName(location),
        parentId,
      });

      continue;
    }

    if (locationType === "region" && location.region) {
      let parentId: Location.Data["id"] | null = null;

      for (const [countryLocationId, countryLocation] of countryMap) {
        if (countryLocation.country !== location.country) continue;

        parentId = countryLocationId;

        break;
      }

      locationMap.set(location.id, {
        data: location,
        value: location.id,
        label: getLocationName(location),
        parentId,
      });

      continue;
    }

    if (locationType === "city" && location.city) {
      let parentId: Location.Data["id"] | null = null;

      for (const [regionLocationId, regionLocation] of regionMap) {
        if (regionLocation.region !== location.region) continue;

        parentId = regionLocationId;

        break;
      }

      if (!parentId) {
        for (const [countryLocationId, countryLocation] of countryMap) {
          if (countryLocation.country !== location.country) continue;

          parentId = countryLocationId;

          break;
        }
      }

      locationMap.set(location.id, {
        data: location,
        value: location.id,
        label: getLocationName(location),
        parentId,
      });
    }
  }

  const locationTree = formatLocationMapToTree(locationMap);

  return Array.from(locationTree);
};

function formatLocationMapToTree(
  locationMap: Map<Location.Data["id"], LocationNode>,
): Set<TreeNode> {
  const treeNodes = new Set<TreeNode>();
  const childrenMap = new Map<string, LocationNode[]>();

  for (const location of locationMap.values()) {
    if (!location.parentId) continue;

    const siblings = childrenMap.get(location.parentId) || [];

    childrenMap.set(location.parentId, [...siblings, location]);
  }

  for (const location of locationMap.values()) {
    if (location.parentId) continue;

    const node = createTreeNode(location, childrenMap);

    treeNodes.add(node);
  }

  return treeNodes;
}

function createTreeNode(
  location: LocationNode,
  childrenMap: Map<Location.Data["id"], LocationNode[]>,
): TreeNode {
  const node: TreeNode = {
    value: location.value,
    label: location.label,
  };

  const children = childrenMap.get(location.value) || [];

  if (children.length)
    node.children = children.map((child) => createTreeNode(child, childrenMap));

  return node;
}
