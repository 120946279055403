import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { PRIORITIZED_LOCATION_ID } from "src/constants";
import { selectLocationsEntities } from "src/store/selectors";

type Result = {
  id: Location.Data["id"];
  name: string;
  preciseName: string;
};

export const useTrackerLocation = (
  searches: Array<Search.Data | Search.CreationData>,
): Result => {
  const { t } = useTranslation();

  const locations = useSelector(selectLocationsEntities);

  return useMemo<Result>(() => {
    const locationIds = new Set<Location.Data["id"]>();

    for (const { locationId } of searches) {
      const location = locations[locationId];

      if (location) locationIds.add(locationId);
    }

    if (locationIds.size > 1)
      return {
        id: PRIORITIZED_LOCATION_ID,
        name: t("tracker.label.location_mixed"),
        preciseName: t("tracker.label.location_mixed"),
      };

    const locationId = [...locationIds][0];

    if (!locationId) return { id: "", name: "", preciseName: "" };

    const location = locations[locationId];

    if (!location) return { id: "", name: "", preciseName: "" };

    const [name, preciseName] = [
      location.city || location.region || location.country || location.name,
      location.name,
    ];

    return { id: locationId, name, preciseName };
  }, [locations, searches, t]);
};
