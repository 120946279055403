import { FC, useMemo } from "react";
import isEqual from "lodash/isEqual";
import { useTranslation } from "react-i18next";

import styles from "./KeywordsControl.module.scss";
import { Loader } from "src/assets/icons";
import { Keyword } from "src/features/KeywordsTable/types";
import { Button, Checkbox, InputWithIcon, Switch } from "src/components";

type Props = {
  isKeywordsLoading: boolean;
  isKeywordsPending: boolean;
  keywordsSearch: string;
  selectedKeywords: Record<Keyword, true>;
  hasDuplicates: boolean;
  setKeywordsSearch: (value: string) => void;
  autoSelectedKeywords: Record<Keyword, true> | null;
  isKeywordsExactMatch: boolean;
  setIsKeywordsExactMatch: (value: boolean) => void;
  isDuplicatedKeywordsShown: boolean;
  keywordsSelectLoadingStatus: LoadingStatus;
  setIsDuplicatedKeywordsShown: (value: boolean) => void;
  selectRecommendedKeywordsHandler: () => void;
};

export const KeywordsControl: FC<Props> = ({
  hasDuplicates,
  keywordsSearch,
  selectedKeywords,
  setKeywordsSearch,
  isKeywordsLoading,
  isKeywordsPending,
  isKeywordsExactMatch,
  autoSelectedKeywords,
  setIsKeywordsExactMatch,
  isDuplicatedKeywordsShown,
  keywordsSelectLoadingStatus,
  setIsDuplicatedKeywordsShown,
  selectRecommendedKeywordsHandler,
}) => {
  const { t } = useTranslation();

  const isSelectLoading = useMemo<boolean>(
    () => keywordsSelectLoadingStatus === "loading",
    [keywordsSelectLoadingStatus],
  );

  const hasKeywordsAutoSelected = useMemo<boolean>(
    () =>
      Boolean(autoSelectedKeywords) &&
      isEqual(selectedKeywords, autoSelectedKeywords),
    [autoSelectedKeywords, selectedKeywords],
  );

  const isSelectDisabled = useMemo<boolean>(
    () => isSelectLoading || hasKeywordsAutoSelected,
    [isSelectLoading, hasKeywordsAutoSelected],
  );

  const isActionButtonsShown = useMemo<boolean>(
    () => !isKeywordsPending && !isKeywordsLoading,
    [isKeywordsLoading, isKeywordsPending],
  );

  const onSmartSelectClick = (): void => selectRecommendedKeywordsHandler();

  return (
    <div className={styles.wrapper}>
      <InputWithIcon
        value={keywordsSearch}
        changeHandler={setKeywordsSearch}
        className={styles.keywordsSearchInput}
        icon="Search"
        hasClearButton
      />
      {isActionButtonsShown && (
        <div className={styles.buttonsWrapper}>
          <div className={styles.keywordsFilterSwitch}>
            <span>{t("component.keywords_table.label.related_keywords")}</span>
            <Switch
              checked={isKeywordsExactMatch}
              onChange={setIsKeywordsExactMatch}
            />
            <span>
              {t("component.keywords_table.label.exact_match_keywords")}
            </span>
          </div>
          {hasDuplicates && (
            <div className={styles.showDuplicatedCheckbox}>
              <Checkbox
                isChecked={isDuplicatedKeywordsShown}
                onClick={() =>
                  setIsDuplicatedKeywordsShown(!isDuplicatedKeywordsShown)
                }
              />
              <span>
                {t("component.keywords_table.button.show_duplicates")}
              </span>
            </div>
          )}
        </div>
      )}
      {isActionButtonsShown && (
        <Button
          buttonType="dark"
          buttonSize="small"
          disabled={isSelectDisabled}
          onClick={onSmartSelectClick}
          className={styles.smartSelectButton}
        >
          <span>{t("component.keywords_table.button.smart_select")}</span>
          {isSelectLoading && <Loader className={styles.loader} />}
        </Button>
      )}
    </div>
  );
};
