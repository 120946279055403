import { generateDocId } from "src/store/utils";
import {
  DEFAULT_LANGUAGE_ID,
  SEARCH_DEFAULT_STATUS,
  PRIORITIZED_LOCATION_ID,
  SEARCH_DEFAULT_KEYWORDS_SELECTION_METHOD,
} from "src/constants";

export const formatNewTrackerName = (
  name: Tracker.Data["name"],
  location: string,
): Tracker.Data["name"] => `${name} - ${location}`;

export const getDefaultSuggestedSearch = ({
  subject,
  description,
  locationId,
  languageId,
  keywordsDataSource,
  keywordsSelectionMethod = SEARCH_DEFAULT_KEYWORDS_SELECTION_METHOD,
}: {
  subject: string;
  description: string;
  locationId: Location.Data["id"];
  languageId: Language.Data["id"];
  keywordsDataSource: Search.KeywordsDataSource;
  keywordsSelectionMethod?: Search.KeywordsSelectionMethod;
}): Search.CreationData => ({
  subject,
  locationId,
  languageId,
  description,
  keywordsDataSource,
  id: generateDocId(),
  keywordsSelectionMethod,
  status: SEARCH_DEFAULT_STATUS,
});

export const formatTrackerWithSearches = (
  tracker: Tracker.CreationData,
  searches: Array<Search.Data | Search.CreationData>,
): Tracker.CreationData => {
  let [trackerLanguageId, trackerLocationId] = [
    tracker.languageId,
    tracker.locationId,
  ];

  const [languageIds, locationIds] = [
    new Set<Language.Data["id"]>(),
    new Set<Location.Data["id"]>(),
  ];

  for (const { locationId, languageId } of searches) {
    languageIds.add(languageId);

    locationIds.add(locationId);
  }

  if (languageIds.size === 1)
    trackerLanguageId = [...languageIds][0] || trackerLanguageId;
  if (languageIds.size > 1) trackerLanguageId = DEFAULT_LANGUAGE_ID;

  if (locationIds.size === 1)
    trackerLocationId = [...locationIds][0] || trackerLocationId;
  if (locationIds.size > 1) trackerLocationId = PRIORITIZED_LOCATION_ID;

  return {
    ...tracker,
    languageId: trackerLanguageId,
    locationId: trackerLocationId,
  };
};
