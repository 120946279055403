import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import uniq from "lodash/uniq";

import firebaseAuth from "src/services/firebaseAuth";
import { executeCapterra, isProductionEnv } from "src/utils";
import { selectWhiteLabel } from "../whiteLabel/whiteLabelSelector";
import { onStatePending, onStateRejected, onStateFulfilled } from "../utils";

// Inner imports
import * as api from "./userApi";
import { selectUser, selectUserId } from "./userSelector";

export type CreateUserPayload = {
  whiteLabel: WhiteLabel.Data["id"];
  whiteLabelDomainId: string;
  user: {
    companyId?: User.Data["companyId"];
    firstName: User.Data["firstName"];
    lastName: User.Data["lastName"];
    email: string;
    password: string;
  };
  company?: {
    name: Company.Data["name"];
    countryCode: Company.Data["countryCode"];
  };
  redirectUrl?: string;
};

export const USER_INITIAL_DATA: User.Data = {
  id: "",
  email: "",
  firstName: "",
  lastName: "",
  companyId: "",
  companyRole: "member",
  isWhiteLabelAdmin: false,
  emailVerified: false,
  role: "",
  phone: "",
  department: "",
  verifyEmailAttemptsNumber: 0,
  lastActiveAt: "",
  createdAt: "",
  updatedAt: "",
  lastViewedTrackerIds: [],
  lastViewedDashboardIds: [],
};

const userAdapter = createEntityAdapter<User.Data>();

const initialState = userAdapter.getInitialState<Store.InitialState>({
  status: "idle",
  error: null,
});

export const signUpUser = createAsyncThunk<User.Data["id"], CreateUserPayload>(
  "user/sign-up",
  async (payload) => {
    const userId = await api.createUser(payload);

    const isProd = isProductionEnv();

    if (isProd) {
      try {
        executeCapterra();
      } catch (error) {
        console.error(error);
      }
    }

    return userId;
  },
);

export const logOutUser = createAsyncThunk("user/log-out", (_, { dispatch }) =>
  firebaseAuth()
    .signOut()
    .then(() => dispatch({ type: "user/log-out" })),
);

export const logInWithEmailAndPassword = createAsyncThunk<
  void,
  {
    email: User.Data["email"];
    password: string;
    unverifiedUserCallback: (userId: User.Data["id"]) => void;
  },
  { state: Store.RootState }
>("user/log-in-with-email-and-password", async (payload, { getState }) => {
  const state = getState();

  const whiteLabel = selectWhiteLabel(state);

  await api.logInWithEmailAndPassword({ ...payload, whiteLabel });
});

export const logInWithVerificationToken = createAsyncThunk<
  void,
  { token: string; userId: User.Data["id"] },
  { state: Store.RootState }
>("user/log-in-with-verification-token", async (payload, { getState }) => {
  const state = getState();

  const whiteLabel = selectWhiteLabel(state);

  await api.logInWithVerificationToken({ ...payload, whiteLabel });
});

export const reauthenticateUser = createAsyncThunk<void, { password: string }>(
  "user/reauthenticate",
  api.reauthenticateUser,
);

export const fetchUser = createAsyncThunk<
  Store.UpdateEntity<User.Data>,
  User.Data["id"],
  { state: Store.RootState }
>("user/fetch-by-id", async (_, { getState }) => {
  const state = getState();
  const userId = selectUserId(state);
  const userFirebaseCollectionData = await api.getUserById(userId);

  return { id: userId, changes: userFirebaseCollectionData };
});

export const updateUser = createAsyncThunk<
  Store.UpdateEntity<User.Data>,
  Store.UpdateEntity<User.Data>["changes"],
  { state: Store.RootState }
>("user/update-one", async (changes, { getState }) => {
  const state = getState();
  const userId = selectUserId(state);

  return api.updateUser({ id: userId, changes });
});

export const updateUserLastViewedTrackerIds = createAsyncThunk<
  Store.UpdateEntity<User.Data>,
  Pick<Store.UpdateEntity<User.Data>["changes"], "lastViewedTrackerIds">,
  { state: Store.RootState }
>(
  "user/update-last-viewed-tracker-ids",
  async ({ lastViewedTrackerIds = [] }, { getState }) => {
    const state = getState();

    const user = selectUser(state);

    const updatedLastViewedTrackerIds = uniq([
      ...lastViewedTrackerIds,
      ...user.lastViewedTrackerIds,
    ]);

    return api.updateUserConfig({
      id: user.id,
      changes: { lastViewedTrackerIds: updatedLastViewedTrackerIds },
    });
  },
);

export const updateUserLastViewedDashboardIds = createAsyncThunk<
  Store.UpdateEntity<User.Data>,
  Pick<Store.UpdateEntity<User.Data>["changes"], "lastViewedDashboardIds">,
  { state: Store.RootState }
>(
  "user/update-last-viewed-dashboard-ids",
  async ({ lastViewedDashboardIds = [] }, { getState }) => {
    const state = getState();

    const user = selectUser(state);

    const updatedLastViewedDashboardIds = uniq([
      ...lastViewedDashboardIds,
      ...user.lastViewedDashboardIds,
    ]);

    return api.updateUserConfig({
      id: user.id,
      changes: { lastViewedDashboardIds: updatedLastViewedDashboardIds },
    });
  },
);

export const updateUserPassword = createAsyncThunk<void, string>(
  "user/update-password",
  api.updateUserPassword,
);

export const resetUserPassword = createAsyncThunk<
  void,
  User.Data["email"],
  { state: Store.RootState }
>("user/reset-password", async (payload, { getState }) => {
  const state = getState();

  const whiteLabel = selectWhiteLabel(state);

  await api.resetUserPassword(payload, whiteLabel);
});

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: userAdapter.upsertOne,
  },
  extraReducers: (builder) => {
    builder.addCase(signUpUser.pending, onStatePending);
    builder.addCase(signUpUser.rejected, onStateRejected);
    builder.addCase(signUpUser.fulfilled, onStateFulfilled);

    builder.addCase(logInWithEmailAndPassword.pending, onStatePending);
    builder.addCase(logInWithEmailAndPassword.rejected, onStateRejected);
    builder.addCase(logInWithEmailAndPassword.fulfilled, onStateFulfilled);

    builder.addCase(logInWithVerificationToken.pending, onStatePending);
    builder.addCase(logInWithVerificationToken.rejected, onStateRejected);
    builder.addCase(logInWithVerificationToken.fulfilled, onStateFulfilled);

    builder.addCase(reauthenticateUser.pending, onStatePending);
    builder.addCase(reauthenticateUser.rejected, onStateRejected);
    builder.addCase(reauthenticateUser.fulfilled, onStateFulfilled);

    builder.addCase(fetchUser.pending, onStatePending);
    builder.addCase(fetchUser.rejected, onStateRejected);
    builder.addCase(fetchUser.fulfilled, (...args) => {
      userAdapter.updateOne(...args);
      onStateFulfilled(...args);
    });

    builder.addCase(updateUser.pending, onStatePending);
    builder.addCase(updateUser.rejected, onStateRejected);
    builder.addCase(updateUser.fulfilled, (...args) => {
      userAdapter.updateOne(...args);
      onStateFulfilled(...args);
    });

    builder.addCase(updateUserLastViewedTrackerIds.fulfilled, (...args) => {
      userAdapter.updateOne(...args);
      onStateFulfilled(...args);
    });

    builder.addCase(updateUserLastViewedDashboardIds.fulfilled, (...args) => {
      userAdapter.updateOne(...args);
      onStateFulfilled(...args);
    });
  },
});

export const { setUser } = userSlice.actions;

export default userSlice.reducer;
