import {
  createEntityAdapter,
  createAsyncThunk,
  createSlice,
} from "@reduxjs/toolkit";

import * as api from "./subscriptionPlansApi";
import { onStateFulfilled, onStatePending, onStateRejected } from "../utils";

export const subscriptionPlansAdapter =
  createEntityAdapter<SubscriptionPlan.Data>({
    sortComparer: (a, b) => a.name.localeCompare(b.name),
  });

const initialState =
  subscriptionPlansAdapter.getInitialState<Store.InitialState>({
    status: "idle",
    error: null,
  });

export const fetchSubscriptionPlanById = createAsyncThunk<
  SubscriptionPlan.Data,
  SubscriptionPlan.Data["id"]
>("subscription-plans/fetch-by-id", api.getSubscriptionPlanById);

export const fetchSubscriptionPlanByCompanyId = createAsyncThunk<
  SubscriptionPlan.Data,
  SubscriptionPlan.Data["id"]
>("subscription-plans/fetch-by-company-id", api.getSubscriptionPlanByCompanyId);

export const fetchAllSubscriptionPlans = createAsyncThunk<
  SubscriptionPlan.Data[]
>(
  "subscription-plans/fetch-all",
  async () => await api.getAllSubscriptionPlans(),
);

const subscriptionPlansSlice = createSlice({
  name: "subscription-plans",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      fetchSubscriptionPlanById.fulfilled,
      subscriptionPlansAdapter.upsertOne,
    );

    builder.addCase(
      fetchSubscriptionPlanByCompanyId.fulfilled,
      subscriptionPlansAdapter.upsertOne,
    );

    builder.addCase(fetchAllSubscriptionPlans.pending, onStatePending);
    builder.addCase(fetchAllSubscriptionPlans.rejected, onStateRejected);
    builder.addCase(fetchAllSubscriptionPlans.fulfilled, (...args) => {
      subscriptionPlansAdapter.upsertMany(...args);
      onStateFulfilled(...args);
    });
  },
});

export default subscriptionPlansSlice.reducer;
