import { useMemo } from "react";
import { useSelector } from "react-redux";

import { checkIsSameMonth } from "src/utils";
import {
  selectWidgetData,
  selectDashboardDateRangeById,
  selectDefaultDashboardDateRangeIdByTrackersCollectionId,
} from "src/store/selectors";

type Props = {
  widgetId: Extract<
    Widget.IdType,
    "widget-search-share" | "widget-volume-share"
  >;
  dashboardDateRangeId: DashboardDateRange.Data["id"];
  trackersCollectionId: TrackersCollection.Data["id"];
};

export const useExtendedLineChartData = ({
  widgetId,
  dashboardDateRangeId,
  trackersCollectionId,
}: Props): Widgets.VolumeShare.LineChart | Widgets.SearchShare.LineChart => {
  const defaultDashboardDateRangeId = useSelector((state: Store.RootState) =>
    selectDefaultDashboardDateRangeIdByTrackersCollectionId(
      state,
      trackersCollectionId,
    ),
  );

  const defaultWidgetData = useSelector((state: Store.RootState) =>
    selectWidgetData(state, defaultDashboardDateRangeId, widgetId),
  );

  const dashboardDateRange = useSelector((state: Store.RootState) =>
    selectDashboardDateRangeById(state, dashboardDateRangeId),
  );

  const widgetData = useSelector((state: Store.RootState) =>
    selectWidgetData(state, dashboardDateRangeId, widgetId),
  );

  const isCustomDashboardDateRange = useMemo<boolean>(
    () => dashboardDateRange?.category === "custom",
    [dashboardDateRange],
  );

  return useMemo(() => {
    const [lineChart, defaultLineChart] = [
      widgetData?.data?.lineChart,
      defaultWidgetData?.data?.lineChart,
    ];

    if (!lineChart) return {};

    if (!defaultLineChart || !isCustomDashboardDateRange) return lineChart;

    const formattedLineChartData = { ...lineChart };

    for (const trackerId in lineChart) {
      const [trackerValues, trackerDefaultValues] = [
        lineChart[trackerId],
        defaultLineChart[trackerId],
      ];

      if (!trackerValues || !trackerDefaultValues) continue;

      const firstTrackerValue = trackerValues[trackerValues.length - 1];

      if (!firstTrackerValue) continue;

      const firstTrackerValueIndexInDefaultValues =
        trackerDefaultValues.findIndex(({ date }) =>
          checkIsSameMonth(date, firstTrackerValue.date),
        );

      if (
        firstTrackerValueIndexInDefaultValues === 0 ||
        firstTrackerValueIndexInDefaultValues === -1
      )
        continue;

      const previousTrackerDefaultValue =
        trackerDefaultValues[firstTrackerValueIndexInDefaultValues + 1];

      if (!previousTrackerDefaultValue) continue;

      formattedLineChartData[trackerId] = [
        ...trackerValues,
        previousTrackerDefaultValue,
      ];
    }

    return formattedLineChartData;
  }, [
    isCustomDashboardDateRange,
    widgetData?.data?.lineChart,
    defaultWidgetData?.data?.lineChart,
  ]);
};
