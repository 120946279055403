import type ReactGridLayout from "react-grid-layout";

import { DASHBOARD_LIGHT_LAYOUT, DASHBOARD_LIGHT_TILES } from "src/constants";

// Inner imports
import { formatDashboardToGridLayout } from "../utils";

const EXPANDED_WIDGET_GRID = {
  large: {
    w: 4,
    h: 2,
    x: 0,
    y: 0,
  },
  medium: {
    w: 2,
    h: 2,
    x: 0,
    y: 0,
  },
  small: {
    w: 1,
    h: 1,
    x: 0,
    y: 0,
  },
};

export const useDashboardLayouts = (
  layouts: Dashboard.Data["layouts"],
  tiles: Dashboard.Data["tiles"],
  layoutView: Dashboard.LayoutView,
  expandedDashboardId: Widget.IdType | null,
): ReactGridLayout.Layouts => {
  if (expandedDashboardId) {
    return {
      small: [
        {
          ...EXPANDED_WIDGET_GRID["small"],
          i: expandedDashboardId,
        },
      ],
      medium: [
        {
          ...EXPANDED_WIDGET_GRID["medium"],
          i: expandedDashboardId,
        },
      ],
      large: [
        {
          ...EXPANDED_WIDGET_GRID["large"],
          i: expandedDashboardId,
        },
      ],
    };
  }

  if (layoutView === "light") {
    const { small, medium, large } = DASHBOARD_LIGHT_LAYOUT;

    return {
      small: formatDashboardToGridLayout(small, DASHBOARD_LIGHT_TILES),
      medium: formatDashboardToGridLayout(medium, DASHBOARD_LIGHT_TILES),
      large: formatDashboardToGridLayout(large, DASHBOARD_LIGHT_TILES),
    };
  }

  const { small, medium, large } = layouts;

  return {
    small: formatDashboardToGridLayout(small, tiles),
    medium: formatDashboardToGridLayout(medium, tiles),
    large: formatDashboardToGridLayout(large, tiles),
  };
};
