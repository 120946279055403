import { createDraftSafeSelector } from "@reduxjs/toolkit";

import { removeExtraSpaces } from "src/utils";
import firebaseAuth from "src/services/firebaseAuth";
import { selectCompany } from "../company/companySelector";

// Inner imports
import { USER_INITIAL_DATA } from "./userSlice";

export const selectUser = createDraftSafeSelector(
  ({ user }: Store.RootState) => user.entities,
  (userEntities) => {
    const { currentUser } = firebaseAuth();

    if (!currentUser) return USER_INITIAL_DATA;

    const user = userEntities[currentUser.uid];

    return user || USER_INITIAL_DATA;
  },
);

export const selectUserId = createDraftSafeSelector(selectUser, ({ id }) => id);

export const selectUserFullName = createDraftSafeSelector(
  selectUser,
  ({ firstName, lastName }) => {
    const fullName = `${firstName} ${lastName}`;

    return removeExtraSpaces(fullName);
  },
);

export const selectUserCompanyId = createDraftSafeSelector(
  selectUser,
  (user) => user.companyId,
);

export const selectUserPermissions = createDraftSafeSelector(
  selectUserId,
  selectCompany,
  (userId: User.Data["id"], company: Company.Data) => {
    const { companyRole = "member" } = company.members[userId] || {};

    const isUserCompanyAdmin = companyRole === "admin";
    const isUserCompanyOwner = companyRole === "owner";

    return {
      isUserCompanyAdmin,
      isUserCompanyOwner,
      isUserCompanyOwnerOrAdmin: isUserCompanyAdmin || isUserCompanyOwner,
      isSimpleCompanyMember: companyRole === "member",
    };
  },
);
