import { createDraftSafeSelector } from "@reduxjs/toolkit";

import { selectTrackerById } from "../trackers/trackersSelector";

// inner imports
import { searchesAdapter } from "./searchesSlice";

export const {
  selectAll: selectSearches,
  selectEntities: selectSearchesEntities,
  selectById: selectSearchById,
  selectTotal: selectSearchesCount,
} = searchesAdapter.getSelectors<Store.RootState>(({ searches }) => searches);

export const selectSearchesStatus = createDraftSafeSelector(
  ({ searches }: Store.RootState) => searches,
  ({ status }) => status,
);

export const selectSearchesByAuthorId = createDraftSafeSelector(
  (
    state: Store.RootState,
    authorId: Search.Data["authorId"],
  ): [Store.RootState, Search.Data["authorId"]] => [state, authorId],
  ([state, authorId]) => {
    const searches = selectSearches(state);

    const filteredSearches = new Set<Search.Data>();

    for (const search of searches) {
      const isUserSearchOwner = search.authorId === authorId;

      if (isUserSearchOwner) filteredSearches.add(search);
    }

    return [...filteredSearches];
  },
);

export const selectSearchesByIds = createDraftSafeSelector(
  (
    state: Store.RootState,
    searchIds: Search.Data["id"][],
  ): [Store.RootState, Search.Data["id"][]] => [state, searchIds],
  ([state, searchIds]) => {
    const searches = selectSearches(state);

    const filteredSearches = new Set<Search.Data>();

    for (const search of searches)
      if (searchIds.includes(search.id)) filteredSearches.add(search);

    return [...filteredSearches];
  },
);

export const selectSearchesByTrackerId = createDraftSafeSelector(
  (
    state: Store.RootState,
    trackerId: Tracker.Data["id"],
  ): [
    ReturnType<typeof selectSearchesEntities>,
    ReturnType<typeof selectTrackerById>,
  ] => [selectSearchesEntities(state), selectTrackerById(state, trackerId)],
  ([searches, tracker]) => {
    const filteredSearches = new Set<Search.Data>();

    if (!tracker) return [...filteredSearches];

    for (const searchId of tracker.searchIds) {
      const search = searches[searchId];

      if (search) filteredSearches.add(search);
    }

    return [...filteredSearches];
  },
);
