import { FC } from "react";

import styles from "./ExternalTooltip.module.scss";

// Inner imports
import { EXTERNAL_TOOLTIP_IDS } from "./constants";

type Props = {
  tooltipId: (typeof EXTERNAL_TOOLTIP_IDS)[number];
};

export const ExternalTooltip: FC<Props> = ({ tooltipId }) => (
  <div className={styles.wrapper}>
    <div id={tooltipId} />
  </div>
);
