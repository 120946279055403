import { FC, useContext, useEffect, useState } from "react";

import { Sidebar } from "src/components";
import { DashboardPageContext } from "src/pages/Dashboards/DashboardPageWrapper/context";

export const DashboardSidebar: FC = () => {
  const { sidebarContent } = useContext(DashboardPageContext);

  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(
    Boolean(sidebarContent),
  );

  useEffect(() => {
    setIsSidebarOpen(Boolean(sidebarContent));
  }, [sidebarContent]);

  return (
    <Sidebar isSidebarOpen={isSidebarOpen} setIsSidebarOpen={setIsSidebarOpen}>
      {sidebarContent}
    </Sidebar>
  );
};
