import { searchesConfigurationAdapter } from "./searchesConfigurationSlice";

export const {
  selectAll: selectedSearchesConfiguration,
  selectEntities: selectSearchesConfigurationEntities,
  selectById: selectSearchConfigurationById,
  selectTotal: selectSearchesConfigurationCount,
} = searchesConfigurationAdapter.getSelectors<Store.RootState>(
  ({ searchesConfiguration }) => searchesConfiguration,
);
