import { FC, useMemo, memo } from "react";
import { escapeRegExp } from "lodash";

import styles from "./HighlightText.module.scss";

type Props = {
  text: string;
  highlight: string;
  className?: string;
};

export const HighlightText: FC<Props> = memo(
  ({ text, highlight, className }) => {
    const regExp = useMemo<RegExp>(
      () => new RegExp(`(${escapeRegExp(highlight)})`, "gi"),
      [highlight],
    );

    const textParts = useMemo<string[]>(
      () => text.split(regExp).filter(Boolean),
      [regExp, text],
    );

    const checkIsHighlighted = (textPart: string): boolean =>
      regExp.test(textPart);

    if (!highlight.trim()) return <span className={className}>{text}</span>;

    return (
      <span className={className}>
        {textParts.map((textPart, i) => (
          <span
            className={checkIsHighlighted(textPart) ? styles.highlightText : ""}
            key={`${textPart}-${i}`}
          >
            {textPart}
          </span>
        ))}
      </span>
    );
  },
);
