import { FC, useMemo } from "react";
import { useSelector } from "react-redux";

import styles from "./DashboardConfiguration.module.scss";
import { selectTrackersCollectionById } from "src/store/selectors";

// Inner imports
import { DashboardTrackers, DashboardDateRanges } from "./components";

type Props = {
  isReadOnly: boolean;
  dashboardDateRangeId: DashboardDateRange.Data["id"];
  trackersCollectionId: TrackersCollection.Data["id"];
  activeTrackerIds: Tracker.Data["id"][];
  setActiveTrackerIdsHandler: (value: Tracker.Data["id"][]) => void;
  selectDashboardDateRangeHandler: (
    value: DashboardDateRange.Data["id"],
  ) => void;
};

export const DashboardConfiguration: FC<Props> = ({
  isReadOnly,
  activeTrackerIds,
  dashboardDateRangeId,
  trackersCollectionId,
  setActiveTrackerIdsHandler,
  selectDashboardDateRangeHandler,
}) => {
  const trackersCollection = useSelector((state: Store.RootState) =>
    selectTrackersCollectionById(state, trackersCollectionId),
  );

  const trackerIds = useMemo<Tracker.Data["id"][]>(
    () => trackersCollection?.trackerIds || [],
    [trackersCollection?.trackerIds],
  );

  return (
    <div className={styles.wrapper}>
      <DashboardDateRanges
        isReadOnly={isReadOnly}
        dashboardDateRangeId={dashboardDateRangeId}
        trackersCollectionId={trackersCollectionId}
        selectDashboardDateRangeIdHandler={selectDashboardDateRangeHandler}
      />
      <DashboardTrackers
        trackerIds={trackerIds}
        activeTrackerIds={activeTrackerIds}
        setActiveTrackerIdsHandler={setActiveTrackerIdsHandler}
      />
    </div>
  );
};
