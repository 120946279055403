import { useCallback, useEffect, useMemo, useState } from "react";
import isAfter from "date-fns/isAfter";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { useAppDispatch } from "src/store";
import { showToastNotification } from "src/utils";
import { updateVersionInfo } from "src/store/actions";
import { selectApplicationInfo } from "src/store/selectors";
import { subscribeOnVersionInfo } from "src/store/application/applicationApi";

export const useVersionInfoObserver = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const {
    versionInfo: { updatedAt: versionUpdatedAt },
    sessionDate,
  } = useSelector(selectApplicationInfo);

  const [isObserverSet, setIsObserverSet] = useState<boolean>(false);

  const [formattedVersionUpdatedAt, formattedSessionDate] = useMemo<
    [Date, Date]
  >(
    () => [new Date(versionUpdatedAt), new Date(sessionDate)],
    [sessionDate, versionUpdatedAt],
  );

  const isVersionUpdateAvailable = useMemo<boolean>(() => {
    const currentDate = new Date();

    if (isAfter(formattedVersionUpdatedAt, currentDate)) return false;

    return isAfter(formattedVersionUpdatedAt, formattedSessionDate);
  }, [formattedSessionDate, formattedVersionUpdatedAt]);

  const versionInfoCallback = useCallback(
    (data: Partial<Application.VersionInfo>): void => {
      dispatch(updateVersionInfo(data));

      setIsObserverSet(true);
    },
    [dispatch],
  );

  useEffect(() => {
    let observer = () => {};

    try {
      observer = subscribeOnVersionInfo(versionInfoCallback);
    } catch (err) {
      console.error(err);
    }

    return () => {
      observer();
    };
  }, [versionInfoCallback]);

  useEffect(() => {
    if (!versionUpdatedAt || !isVersionUpdateAvailable) return;

    showToastNotification({
      id: "new-version-refresh",
      type: "info",
      text: t("component.notification.updated_version.body"),
      closeButton: {
        text: t("component.notification.updated_version.button.refresh"),
        onClick: () => location.reload(),
      },
      autoClose: false,
    });
  }, [t, isVersionUpdateAvailable, versionUpdatedAt]);

  return { isObserverSet };
};
