import { FC, useMemo } from "react";
import cx from "classnames";

import styles from "./SuggestedPerspective.module.scss";
import { Button } from "src/components";

type Props = {
  isDisabled?: boolean;
  perspective: Tracker.Perspective;
  selectedPerspective: Tracker.Perspective;
  unselectPerspectiveHandler: (value: Tracker.Perspective) => void;
  selectPerspectiveHandler: (value: Tracker.Perspective) => void;
};

export const SuggestedPerspective: FC<Props> = ({
  isDisabled = false,
  perspective,
  selectedPerspective,
  selectPerspectiveHandler,
}) => {
  const isSelected = useMemo<boolean>(
    () => perspective === selectedPerspective,
    [perspective, selectedPerspective],
  );

  const onClick = (): void => selectPerspectiveHandler(perspective);

  return (
    <Button
      type="button"
      onClick={onClick}
      disabled={isDisabled}
      buttonType="secondary"
      className={cx(styles.wrapper, isSelected ? styles.wrapperActive : "")}
      shouldPreventEnter
    >
      <div className={styles.content}>
        <span className={styles.name}>{perspective}</span>
      </div>
    </Button>
  );
};
