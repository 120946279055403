import { TFunction } from "i18next";

// Inner imports
import { SubscriptionPlanDescriptionSection } from "../types";
import {
  SUBSCRIPTION_PLAN_MIN_TRACKERS_COUNT,
  SUBSCRIPTION_PLAN_MONTHS_IN_YEAR,
  SUBSCRIPTION_PLAN_UNLIMITED_IDENTIFIER,
} from "../constants";

export const formatSubscriptionPlanDescriptionSection = (
  { key, labelKey }: SubscriptionPlanDescriptionSection,
  limits: Pick<
    SubscriptionPlan.Limits,
    "dashboards" | "team" | "events" | "trackers"
  > & { price: string | number },
  t: TFunction,
): SubscriptionPlanDescriptionSection => {
  let count: number | string = 0;

  switch (key) {
    case "trackers":
      count = limits.trackers;
      break;
    case "users":
      count = limits.team;
      break;
    case "dashboards":
      count = limits.dashboards;
      break;
    case "events":
      count = limits.events;
      break;
    case "additionalTrackers":
      count = limits.price;
      break;
    default:
      count = 0;
  }

  if (count >= SUBSCRIPTION_PLAN_UNLIMITED_IDENTIFIER)
    count = t("page.settings.subscription_plan.label.unlimited");

  return { key, labelKey, count };
};

export const calculateSubscriptionPlanTrackerPriceMonthly = (
  subscriptionPlan: SubscriptionPlan.Data,
  billingPeriod: SubscriptionPlan.BillingPeriod,
): string => {
  const { monthly, yearly } = subscriptionPlan.price;

  let priceString: string = "";

  if (billingPeriod === "monthly")
    priceString = Number(
      monthly / SUBSCRIPTION_PLAN_MIN_TRACKERS_COUNT,
    ).toFixed(2);

  if (billingPeriod === "yearly")
    priceString = Number(
      yearly /
        SUBSCRIPTION_PLAN_MIN_TRACKERS_COUNT /
        SUBSCRIPTION_PLAN_MONTHS_IN_YEAR,
    ).toFixed(2);

  return priceString;
};
