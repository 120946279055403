import { FC, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { useModal } from "src/hooks";
import { ConfirmModal } from "src/features";
import { useAppDispatch } from "src/store";
import { showToastNotification } from "src/utils";
import { selectEventByDashboardDateRangeId } from "src/store/selectors";
import { removeDashboardDateRange, removeEvent } from "src/store/actions";

type Props = {
  dashboardDateRangeId: DashboardDateRange.Data["id"];
};

export const ConfirmDeleteDashboardDateRangeModal: FC<Props> = ({
  dashboardDateRangeId,
}) => {
  const { t } = useTranslation();

  const { closeModal } = useModal();

  const dispatch = useAppDispatch();

  const dashboardDateRangeEvent = useSelector((state: Store.RootState) =>
    selectEventByDashboardDateRangeId(state, dashboardDateRangeId),
  );

  const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>("idle");

  const isLoading = useMemo<boolean>(
    () => loadingStatus === "loading",
    [loadingStatus],
  );

  const title = useMemo<string>(() => {
    if (dashboardDateRangeEvent)
      return t("component.modal.delete_dashboard_date_range.title.alt");

    return t("component.modal.delete_dashboard_date_range.title");
  }, [dashboardDateRangeEvent, t]);

  const bodyLabel = useMemo<string>(() => {
    if (dashboardDateRangeEvent)
      return t("component.modal.delete_dashboard_date_range.body.alt");

    return t("component.modal.delete_dashboard_date_range.body");
  }, [dashboardDateRangeEvent, t]);

  const onSubmit = async (): Promise<void> => {
    try {
      setLoadingStatus("loading");

      await Promise.all([
        dispatch(removeDashboardDateRange(dashboardDateRangeId)).unwrap(),
        dashboardDateRangeEvent &&
          dispatch(removeEvent(dashboardDateRangeEvent.id)).unwrap(),
      ]);

      setLoadingStatus("succeeded");

      showToastNotification({
        type: "success",
        text: t(
          "component.modal.delete_dashboard_date_range.status.success.dashboard_date_range_deleted",
        ),
      });

      closeModal("confirm-delete-dashboard-date-range");
    } catch (error) {
      console.error(error);

      setLoadingStatus("failed");

      showToastNotification({
        type: "error",
        text: t("common.error.server_error"),
      });
    }
  };

  return (
    <ConfirmModal
      id="confirm-delete-dashboard-date-range"
      type="warning"
      title={title}
      isLoading={isLoading}
      acceptButton={{
        onClick: onSubmit,
        buttonType: "error",
        text: t("component.modal.delete_dashboard_date_range.button.submit"),
      }}
      cancelButton={{
        onClick: () => closeModal("confirm-delete-dashboard-date-range"),
        text: t("component.modal.delete_dashboard_date_range.button.cancel"),
      }}
    >
      <span>{bodyLabel}</span>
    </ConfirmModal>
  );
};
