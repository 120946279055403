import { useInsightsFooter } from "src/features/Widgets/Components/Insights/hooks";

export const WIDGET_FOOTER_HOOKS: Record<
  Widget.IdType,
  (props: Widget.ChartProps) => JSX.Element | null
> = {
  "widget-insights": useInsightsFooter,
  "widget-search-share": () => null,
  "widget-top-keywords": () => null,
  "widget-trending-keywords": () => null,
  "widget-volume-share": () => null,
  "widget-articles": () => null,
};
