import { CSSProperties } from "react";

export const TOOLTIP_STYLE: CSSProperties = {
  position: "absolute",
  textAlign: "center",
  overflow: "hidden",
  padding: "5px 10px",
  fontWeight: 400,
  fontSize: "12px",
  lineHeight: "20px",
  maxWidth: "300px",
  width: "max-content",
  borderRadius: "5px",
  zIndex: 1000,
  backgroundColor: "rgba(36, 43, 67, .9)",
};

export const TOOLTIP_EXTRA_SPACE: number = 10;

export const TOOLTIP_SPACE_BETWEEN_ELEMENT: number = 5;
