import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";

type Props<T> = {
  items: T[];
  initialPageNumber?: number;
  skipPagination?: boolean;
  itemsPerPage?: number;
};

export type PaginationProps<T> = {
  slicedItems: T[];
  pageNumber: number;
  pagesCount: number;
  totalItemsCount: number;
  itemsPerPage: number;
  firstItemIndex: number;
  lastItemIndex: number;
  setItemsPerPage: Dispatch<SetStateAction<number>>;
  setPageNumber: Dispatch<SetStateAction<number>>;
};

export const PAGINATION_DEFAULT_ITEMS_COUNT = 10 as const;

export const usePagination = <T extends {}>({
  items,
  initialPageNumber = 0,
  itemsPerPage: _itemsPerPage,
}: Props<T>): PaginationProps<T> => {
  const [itemsPerPage, setItemsPerPage] = useState<number>(
    _itemsPerPage || PAGINATION_DEFAULT_ITEMS_COUNT,
  );

  const [pageNumber, setPageNumber] = useState<number>(initialPageNumber);

  const totalItemsCount = useMemo<number>(() => items.length, [items]);

  const firstItemIndex = useMemo<number>(
    () => pageNumber * itemsPerPage,
    [itemsPerPage, pageNumber],
  );

  const lastItemIndex = useMemo<number>(() => {
    const expectedLastItemIndex = firstItemIndex + itemsPerPage;

    return expectedLastItemIndex > totalItemsCount
      ? totalItemsCount
      : expectedLastItemIndex;
  }, [firstItemIndex, itemsPerPage, totalItemsCount]);

  const slicedItems = useMemo<T[]>(() => {
    if (items.length <= itemsPerPage) return items;

    return items.slice(firstItemIndex, firstItemIndex + itemsPerPage);
  }, [firstItemIndex, items, itemsPerPage]);

  const pagesCount = useMemo<number>(() => {
    if (!itemsPerPage) return 0;

    return Math.ceil(totalItemsCount / itemsPerPage);
  }, [itemsPerPage, totalItemsCount]);

  useEffect(() => {
    if (_itemsPerPage) {
      setItemsPerPage(_itemsPerPage);
      setPageNumber(initialPageNumber);
    }
  }, [_itemsPerPage, initialPageNumber, itemsPerPage]);

  useEffect(() => {
    if (!slicedItems.length && pageNumber > 0)
      setPageNumber((state) => state - 1);
  }, [pageNumber, slicedItems.length]);

  return {
    slicedItems,
    pageNumber,
    pagesCount,
    totalItemsCount,
    itemsPerPage,
    firstItemIndex,
    lastItemIndex,
    setItemsPerPage,
    setPageNumber,
  };
};
