// Inner imports
import {
  TEXTAREA_MIN_FONT_SIZE,
  TEXTAREA_DEFAULT_HEIGHT,
  TEXTAREA_DEFAULT_FONT_SIZE,
} from "./constants";

export const adjustTextareaHeightAndFontSize = ({
  value,
  element,
  minFontSize = TEXTAREA_MIN_FONT_SIZE,
  defaultHeight = TEXTAREA_DEFAULT_HEIGHT,
  defaultFontSize = TEXTAREA_DEFAULT_FONT_SIZE,
}: {
  value: string;
  element: HTMLElement;
  minFontSize?: number;
  defaultHeight?: number;
  defaultFontSize?: number;
}): void => {
  const fontSize = calculateDynamicFontSize({
    value,
    element,
    minFontSize: minFontSize,
    defaultFontSize: defaultFontSize,
  });

  const textWidth = getTextWidth(value, fontSize);

  const isTextOverflow = textWidth > element.clientWidth;

  element.style.fontSize = formatFontSizeToPixels(fontSize);

  if (isTextOverflow) {
    if (fontSize === minFontSize) element.style.whiteSpace = "pre-wrap";

    element.style.height = "auto";
    element.style.height = formatFontSizeToPixels(element.scrollHeight);
  } else {
    element.style.whiteSpace = "nowrap";

    element.style.height = formatFontSizeToPixels(defaultHeight);
  }
};

export const calculateDynamicFontSize = ({
  value,
  element,
  minFontSize = 0,
  defaultFontSize = 0,
}: {
  value: string;
  element: HTMLElement;
  minFontSize?: number;
  defaultFontSize?: number;
}): number => {
  const textWidth = getTextWidth(value, defaultFontSize);

  const ratio = element.clientWidth / textWidth;

  const newFontSize = Math.floor(defaultFontSize * ratio);

  return Math.max(minFontSize, Math.min(defaultFontSize, newFontSize));
};

export const getTextWidth = (value: string, fontSize: number): number => {
  const canvas = document.createElement("canvas");

  const context = canvas.getContext("2d");

  if (!context) return 0;

  context.font = `${fontSize}px Roboto`;

  return context.measureText(value).width;
};

export const formatFontSizeToPixels = (value: number): string => `${value}px`;
