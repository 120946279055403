import { FC, useMemo } from "react";
import cx from "classnames";
import { useTranslation } from "react-i18next";

import styles from "./PreReleaseLabel.module.scss";
import { VIEW_TYPES } from "../../constants";

// Inner imports
import { Props } from "./types";

export const PreReleaseLabel: FC<Props> = ({
  content,
  viewType = VIEW_TYPES.default,
}: Props) => {
  const { t } = useTranslation();

  const isDefaultView = useMemo<boolean>(
    () => viewType === VIEW_TYPES.default,
    [viewType],
  );

  const labelText = () => {
    switch (true) {
      case viewType === VIEW_TYPES.default:
        return content;
      case viewType === VIEW_TYPES.compact && content === "alpha":
        return t("pre_release_alpha");
      case viewType === VIEW_TYPES.compact && content === "beta":
        return t("pre_release_beta");
      default:
        return content;
    }
  };

  return (
    <div
      className={cx(
        styles.preReleaseLabel,
        isDefaultView
          ? styles.preReleaseLabelDefault
          : styles.preReleaseLabelCompact,
      )}
    >
      <span
        className={cx(
          styles.preReleaseLabelContent,
          isDefaultView ? styles.preReleaseLabelContentDefault : "",
        )}
      >
        {labelText()}
      </span>
    </div>
  );
};
