export const WIDGET_ARTICLES_ITEMS_PER_PAGE: number = 5;

export const WIDGET_ID = "widget-articles";

export const WIDGET_ARTICLES_PAGINATION_DEFAULT_PROPS: Required<Widgets.Articles.PaginationProps> =
  {
    pageNumber: 0,
    searchQuery: "",
    sortBy: "score",
    sortDirection: "DESC",
    limit: 25,
    trackers: [],
    offset: 0,
    searchIds: [],
    articlesPerPage: WIDGET_ARTICLES_ITEMS_PER_PAGE,
  };
