import { useMemo, useContext } from "react";
import { useTranslation } from "react-i18next";

import styles from "./TablePagination.module.scss";
import {
  Select,
  TablePagination as TablePaginationComponent,
} from "src/components";
import { useTableSettings } from "src/hooks";
import {
  PaginationProps,
  PAGINATION_DEFAULT_ITEMS_COUNT,
} from "src/hooks/usePagination";
import { genericMemo } from "src/utils";

// Inner imports
import type { TableItemGenericProps, TableProps } from "../../types";
import {
  TABLE_PAGINATION_ITEMS_PER_PAGE_OPTIONS,
  TABLE_PAGINATION_CONFIG,
} from "./constants";
import { TableContext } from "../../context";

type Props<T> = Pick<TableProps<T>, "isTableShort" | "type"> &
  PaginationProps<T>;

export const TablePagination = genericMemo(
  <T extends TableItemGenericProps>({
    type = "",
    pageNumber,
    pagesCount,
    firstItemIndex,
    lastItemIndex,
    totalItemsCount,
    itemsPerPage,
    setPageNumber,
    setItemsPerPage,
  }: Props<T>) => {
    const { t } = useTranslation();

    const { setter: setTableSettings } = useTableSettings(type);

    const { contentRef } = useContext(TableContext);

    const itemsCountLabel = useMemo<string>(
      () =>
        t("component.table.footer.pagination.index", {
          firstItemIndex: firstItemIndex + 1,
          lastItemIndex,
          totalItemsCount,
        }),
      [firstItemIndex, lastItemIndex, t, totalItemsCount],
    );

    const onItemPerPageChange = (value: string): void => {
      const newItemsPerPage = Number(value);

      if (isNaN(newItemsPerPage))
        return setItemsPerPage(PAGINATION_DEFAULT_ITEMS_COUNT);

      setPageNumber(0);
      setItemsPerPage(newItemsPerPage);
    };

    const onPageChange = ({ selected }: { selected: number }): void => {
      setTableSettings({ page: selected });
      setPageNumber(selected);

      if (contentRef && "current" in contentRef && contentRef.current)
        contentRef.current.scroll?.({ top: 0 });
    };

    if (totalItemsCount <= PAGINATION_DEFAULT_ITEMS_COUNT) return null;

    return (
      <div className={styles.tablePagination}>
        <div className={styles.itemsPerPage}>
          <span>{t("component.table.footer.pagination.items_per_page")}</span>
          <Select
            options={TABLE_PAGINATION_ITEMS_PER_PAGE_OPTIONS}
            value={itemsPerPage.toString()}
            changeHandler={onItemPerPageChange}
            selectClassName={styles.selectPerPage}
            inputClassName={styles.selectInputPerPage}
          />
        </div>
        <div className={styles.paginationContainer}>
          <TablePaginationComponent
            pageCount={pagesCount}
            forcePage={pageNumber}
            onPageChange={onPageChange}
            {...TABLE_PAGINATION_CONFIG}
          />
        </div>
        <div className={styles.itemsCount}>{itemsCountLabel}</div>
      </div>
    );
  },
);
