export const WIDGET_VIEW_BUTTONS_TOOLTIPS: {
  [chart in Widget.ChartType]: {
    default: string;
    [subType: string]: string;
  };
} = {
  lineChart: {
    default: "chart.line.name",
  },
  pieChart: {
    default: "chart.pie.name",
  },
  table: {
    default: "chart.table.name",
  },
  list: {
    default: "chart.list.name",
  },
  keywords: {
    default: "chart.tag_cloud.name",
  },
  text: {
    default: "chart.text.name",
  },
};
