import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";

import { selectUserId } from "../user/userSelector";
import { selectCompanyId } from "../company/companySelector";
import { onStateFulfilled, onStatePending, onStateRejected } from "../utils";

// Inner imports
import * as api from "./eventsApi";

export const eventsAdapter = createEntityAdapter<Event.Data>({
  sortComparer: (a, b) => a.createdAt.localeCompare(b.createdAt),
});

const initialState = eventsAdapter.getInitialState<Store.InitialState>({
  status: "idle",
  error: null,
});

export const fetchEventsByCompanyId = createAsyncThunk<
  Event.Data[],
  Company.Data["id"]
>("events/fetch-by-company-id", api.getEventsByCompanyId);

export const fetchEventsByDashboardId = createAsyncThunk<
  Event.Data[],
  Dashboard.Data["id"]
>("events/fetch-by-dashboard-id", api.getEventsByDashboardId);

export const fetchEventsByIds = createAsyncThunk<
  Event.Data[],
  Event.Data["id"][]
>("events/fetch-by-ids", api.getEventsByIds);

export const createEvent = createAsyncThunk<
  Event.Data,
  Store.CreateEntity<Event.Data>,
  { state: Store.RootState }
>("events/create-one", (payload, { getState }) => {
  const state = getState();

  const [companyId, authorId] = [selectCompanyId(state), selectUserId(state)];

  return api.createEvent({
    ...payload,
    companyId,
    authorId,
  });
});

export const updateEvent = createAsyncThunk<
  Store.UpdateEntity<Event.Data>,
  Store.UpdateEntity<Event.Data>
>("events/update-one", api.updateEvent);

export const updateEvents = createAsyncThunk<
  Store.UpdateEntity<Event.Data>[],
  Store.UpdateEntity<Event.Data>[]
>("events/update-many", api.updateEvents);

export const updateEventsByAuthorId = createAsyncThunk<
  Store.UpdateEntity<Event.Data>[],
  {
    changes: Store.UpdateEntity<Event.Data>["changes"];
    authorId: Event.Data["authorId"];
  },
  { state: Store.RootState }
>("events/update-by-author-id", (payload, { getState }) => {
  const state = getState();

  const companyId = selectCompanyId(state);

  return api.updateEventsByAuthorId(payload, companyId);
});

export const removeEvent = createAsyncThunk<Event.Data["id"], Event.Data["id"]>(
  "events/remove-one",
  api.deleteEvent,
);

export const removeEvents = createAsyncThunk<
  Event.Data["id"][],
  Event.Data["id"][]
>("events/remove-many", api.deleteEvents);

const eventsSlice = createSlice({
  name: "events",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchEventsByCompanyId.pending, onStatePending);
    builder.addCase(fetchEventsByCompanyId.rejected, onStateRejected);
    builder.addCase(fetchEventsByCompanyId.fulfilled, (...args) => {
      eventsAdapter.addMany(...args);
      onStateFulfilled(...args);
    });

    builder.addCase(fetchEventsByDashboardId.fulfilled, eventsAdapter.addMany);

    builder.addCase(fetchEventsByIds.fulfilled, eventsAdapter.addMany);

    builder.addCase(createEvent.fulfilled, eventsAdapter.addOne);

    builder.addCase(updateEvent.fulfilled, eventsAdapter.updateOne);

    builder.addCase(updateEvents.fulfilled, eventsAdapter.updateMany);

    builder.addCase(updateEventsByAuthorId.fulfilled, eventsAdapter.updateMany);

    builder.addCase(removeEvent.fulfilled, eventsAdapter.removeOne);

    builder.addCase(removeEvents.fulfilled, eventsAdapter.removeMany);
  },
});

export default eventsSlice.reducer;
