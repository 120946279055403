import { useEffect, RefObject, useMemo } from "react";

import { isKeyboardEventTypeGuard } from "src/utils";

type Props = {
  key?: string;
  ref?: RefObject<HTMLElement>;
  type: Extract<keyof DocumentEventMap, "keyup" | "keydown">;
  callback: () => void;
};

export const useKeyboardListener = ({ ref, type, key, callback }: Props) => {
  const element = useMemo<HTMLElement | Document>(() => {
    const element = ref?.current;

    if (element) return element;

    return document;
  }, [ref]);

  useEffect(() => {
    const keyboardListener = (event: Event): void => {
      if (!isKeyboardEventTypeGuard(event)) return;

      if (!key) callback();

      if (event.key === key) callback();
    };

    element.addEventListener(type, keyboardListener);

    return () => element.removeEventListener(type, keyboardListener);
  }, [callback, element, key, type]);
};
