import { useMemo } from "react";
import { useSelector } from "react-redux";

import { isWidgetIdTypeGuard } from "src/utils";
import { selectActiveWidgets } from "src/store/selectors";
import { DASHBOARD_LIGHT_WIDGET_IDS } from "src/constants";

export const useDashboardActiveWidgetIds = (
  tiles: Dashboard.Data["tiles"],
  layoutView: Dashboard.LayoutView,
  expandedDashboardId: Widget.IdType | null,
): Widget.IdType[] => {
  const dashboardActiveWidgetsIds = useSelector(selectActiveWidgets);

  return useMemo<Widget.IdType[]>(() => {
    const preparedDashboardActiveWidgetsIds: Widget.IdType[] = [];

    if (layoutView === "light") {
      for (const tile of DASHBOARD_LIGHT_WIDGET_IDS) {
        if (!isWidgetIdTypeGuard(tile)) continue;

        if (expandedDashboardId && tile !== expandedDashboardId) continue;

        const isWidgetActive = dashboardActiveWidgetsIds.has(tile);

        if (isWidgetActive) preparedDashboardActiveWidgetsIds.push(tile);
      }
    } else {
      for (const tile in tiles) {
        if (!isWidgetIdTypeGuard(tile)) continue;

        if (expandedDashboardId && tile !== expandedDashboardId) continue;

        const isWidgetActive = dashboardActiveWidgetsIds.has(tile);
        const isWidgetAddedToDashboard = tiles[tile] === true;

        if (isWidgetAddedToDashboard && isWidgetActive)
          preparedDashboardActiveWidgetsIds.push(tile);
      }
    }

    return preparedDashboardActiveWidgetsIds;
  }, [layoutView, expandedDashboardId, dashboardActiveWidgetsIds, tiles]);
};
