import { isNumber } from "./isNumber";

export const calculateAverage = (numbers: number[]): number => {
  if (!numbers.length) return 0;

  const total = numbers.reduce<number>((acc, c) => acc + c, 0);

  return total / numbers.length;
};

export const calculateSum = (numbers: number[]): number =>
  numbers.reduce<number>((sum, number) => sum + number, 0);

export const clamp = (value: number, min: number, max: number): number =>
  Math.min(Math.max(value, min), max);

export const calculatePercentage = (
  startValue: number,
  endValue: number,
  fractionDigits: number = 0,
): number => {
  let difference: number;

  switch (true) {
    case startValue === 0 && endValue > 0:
      difference = 1;
      break;
    case startValue === 0 && endValue < 0:
      difference = -1;
      break;
    case endValue !== 0 && startValue !== 0:
      difference = (endValue - startValue) / Math.abs(startValue);
      break;
    default:
      difference = 0;
  }

  const differenceInPercentage = Number(
    (difference * 100).toFixed(fractionDigits),
  );

  if (!isNumber(differenceInPercentage)) return 0;

  return differenceInPercentage;
};

export const roundToNearest = (number: number, nearest: number): number => {
  if (!nearest) return Math.ceil(number);

  return Math.ceil(number / nearest) * nearest;
};

export const roundNumber = (number: number, decimals: number = 0): number => {
  const roundedNumber = number.toFixed(decimals);

  const formattedNumber = Number(roundedNumber);

  if (!isNumber(formattedNumber)) return number;

  return formattedNumber;
};
