import { FC } from "react";
import cx from "classnames";
import { SelectPicker } from "rsuite";

import styles from "./CountriesDropdown.module.scss";
import { ChevronDown } from "src/assets/icons";
import { LOCATION_COUNTRIES } from "src/constants";

type Props = {
  error?: string;
  countryId: Nullable<Location.Data["id"]>;
  isDisabled?: boolean;
  setCountryId: (value: string) => void;
  hasDefaultStyles?: boolean;
};

export const CountriesDropdown: FC<Props> = ({
  error,
  countryId,
  isDisabled,
  setCountryId,
  hasDefaultStyles,
}) => {
  const onCountrySelect = (value: Language.Data["id"]): void =>
    setCountryId(value);

  const onCountryClean = (): void => setCountryId("");

  return (
    <div
      className={cx(
        styles.wrapper,
        hasDefaultStyles ? "" : styles.updatedWrapper,
      )}
    >
      <SelectPicker
        data={[...LOCATION_COUNTRIES]}
        value={countryId}
        onClean={onCountryClean}
        onSelect={onCountrySelect}
        disabled={isDisabled}
        cleanable={false}
        placement="autoVerticalStart"
        searchable
        caretAs={() => <ChevronDown />}
      />
      {Boolean(error) && (
        <div className={styles.error}>
          <span>{error}</span>
        </div>
      )}
    </div>
  );
};
