import { FC } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import { ROUTES } from "src/constants";

// Pages
import VerificationInfo from "src/pages/EmailVerification/VerificationInfo/VerificationInfo";
import TokenVerification from "src/pages/EmailVerification/TokenVerification/TokenVerification";

const EmailVerification: FC = () => (
  <Switch>
    <Route exact path={ROUTES.verifyEmailPage}>
      <VerificationInfo />
    </Route>
    <Route exact path={ROUTES.verifyToken}>
      <TokenVerification />
    </Route>
    <Redirect to="/" />
  </Switch>
);

export default EmailVerification;
