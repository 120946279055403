import { clamp } from "src/utils";

// Inner imports
import { TAG_CLOUD_DEFAULT_FONT_SIZE } from "./constants";

export const calculateWrapperSize = (
  viewType: Widget.ViewType,
  wrapperWidth: number,
  wrapperHeight: number,
): number => {
  if (viewType === "page") return wrapperWidth;

  return Math.min(wrapperHeight, wrapperWidth);
};

export const calculateTagFontSize = (
  size: number,
  count: number,
  averageTagsCount: number,
): number => {
  const percentage = count / averageTagsCount;

  const [initialFontSize, minFontSize, maxFontSize] = [
    TAG_CLOUD_DEFAULT_FONT_SIZE * percentage * (size / 250),
    size / 23,
    size / 9,
  ];

  return clamp(initialFontSize, minFontSize, maxFontSize);
};
