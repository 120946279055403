// Inner imports
import { SubscriptionPlanDescriptionSection } from "./types";

export const SUBSCRIPTION_PLAN_MIN_TRACKERS_COUNT: number = 100;

export const SUBSCRIPTION_PLAN_QUANTITY_MARGIN: number = 50;

export const SUBSCRIPTION_PLAN_MONTHS_IN_YEAR: number = 12;

export const SUBSCRIPTION_PLAN_DESCRIPTION_SECTIONS: SubscriptionPlanDescriptionSection[] =
  [
    {
      key: "trackers",
      labelKey:
        "page.settings.subscription_plan.label.subscription_plan_features_trackers_starting",
    },
    {
      key: "liveDashboards",
      labelKey:
        "page.settings.subscription_plan.label.subscription_plan_features_live_dashboards",
    },
    {
      key: "dataSources",
      labelKey:
        "page.settings.subscription_plan.label.subscription_plan_features_data_sources",
    },
    {
      key: "regions",
      labelKey:
        "page.settings.subscription_plan.label.subscription_plan_features_locations",
    },
    {
      key: "dashboards",
      labelKey:
        "page.settings.subscription_plan.label.subscription_plan_features_dashboards",
    },
    {
      key: "events",
      labelKey:
        "page.settings.subscription_plan.label.subscription_plan_features_events",
    },
    {
      key: "users",
      labelKey:
        "page.settings.subscription_plan.label.subscription_plan_features_users",
    },
  ];

export const SUBSCRIPTION_PLAN_FREE_DESCRIPTION_SECTIONS: SubscriptionPlanDescriptionSection[] =
  [
    {
      key: "viewDashboard",
      labelKey:
        "page.settings.subscription_plan.label.subscription_plan_features_view_dashboards",
    },
    {
      key: "dataSources",
      labelKey:
        "page.settings.subscription_plan.label.subscription_plan_features_data_sources",
    },
    {
      key: "regions",
      labelKey:
        "page.settings.subscription_plan.label.subscription_plan_features_locations",
    },
    {
      key: "users",
      labelKey:
        "page.settings.subscription_plan.label.subscription_plan_features_users",
    },
  ];

export const SUBSCRIPTION_PLAN_TRIAL_DESCRIPTION_SECTIONS: SubscriptionPlanDescriptionSection[] =
  [
    {
      key: "trackers",
      labelKey:
        "page.settings.subscription_plan.label.subscription_plan_features_trackers_starting",
    },
    {
      key: "liveDashboards",
      labelKey:
        "page.settings.subscription_plan.label.trial_subscription_plan_features_live_dashboards",
    },
    {
      key: "dataSources",
      labelKey:
        "page.settings.subscription_plan.label.subscription_plan_features_data_sources",
    },
    {
      key: "regions",
      labelKey:
        "page.settings.subscription_plan.label.subscription_plan_features_locations",
    },
    {
      key: "dashboards",
      labelKey:
        "page.settings.subscription_plan.label.subscription_plan_features_dashboards",
    },
    {
      key: "events",
      labelKey:
        "page.settings.subscription_plan.label.subscription_plan_features_events",
    },
    {
      key: "users",
      labelKey:
        "page.settings.subscription_plan.label.subscription_plan_features_users",
    },
  ];

export const ENTERPRISE_SUBSCRIPTION_PLAN_DESCRIPTION_SECTIONS: SubscriptionPlanDescriptionSection[] =
  [
    {
      key: "features",
      labelKey:
        "page.settings.subscription_plan.label.custom_subscription_plan_features",
    },
    {
      key: "whitelabel",
      labelKey:
        "page.settings.subscription_plan.label.custom_subscription_plan_features_whitelabel",
    },
    {
      key: "onboarding",
      labelKey:
        "page.settings.subscription_plan.label.custom_subscription_plan_features_onboarding",
    },
    {
      key: "support",
      labelKey:
        "page.settings.subscription_plan.label.custom_subscription_plan_features_support",
    },
  ];

export const SUBSCRIPTION_PLAN_DESCRIPTION_BOLD = (
  <span style={{ fontWeight: 500 }} />
);

export const SUBSCRIPTION_PLAN_UNLIMITED_IDENTIFIER: number = 999;

export const SUBSCRIPTION_PLAN_CUSTOM_LIMITS: Pick<
  SubscriptionPlan.Limits,
  "team" | "events" | "trackers" | "dashboards"
> & { price: number } = {
  trackers: 250,
  team: SUBSCRIPTION_PLAN_UNLIMITED_IDENTIFIER,
  price: SUBSCRIPTION_PLAN_UNLIMITED_IDENTIFIER,
  events: SUBSCRIPTION_PLAN_UNLIMITED_IDENTIFIER,
  dashboards: SUBSCRIPTION_PLAN_UNLIMITED_IDENTIFIER,
};
