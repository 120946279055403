import { useCallback, useContext } from "react";

import context from "src/context";

export const useGlobalPreloader = () => {
  const { setIsGlobalPreloaderShown, setGlobalPreloaderText } =
    useContext(context);

  const showGlobalPreloader = useCallback(
    (text?: string) => {
      setIsGlobalPreloaderShown(true);

      if (text) setGlobalPreloaderText(text);
    },
    [setIsGlobalPreloaderShown, setGlobalPreloaderText],
  );

  const hideGlobalPreloader = useCallback(() => {
    setIsGlobalPreloaderShown(false);

    setGlobalPreloaderText("");
  }, [setIsGlobalPreloaderShown, setGlobalPreloaderText]);

  return {
    showGlobalPreloader,
    hideGlobalPreloader,
  };
};
