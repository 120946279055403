import { commands, ICommand } from "@uiw/react-md-editor";

const {
  hr,
  bold,
  title,
  italic,
  divider,
  strikethrough,
  unorderedListCommand,
  orderedListCommand,
} = commands;

export const RICH_TEXT_EDITOR_COMMANDS: ICommand[] = [
  bold,
  italic,
  strikethrough,
  hr,
  title,
  divider,
  unorderedListCommand,
  orderedListCommand,
];

export const RICH_TEXT_EDITOR_EXTRA_COMMANDS: ICommand[] = [];
