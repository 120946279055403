import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import styles from "./DashboardEventsSidebar.module.scss";
import {
  selectEventsByDashboardId,
  selectAvailableDashboardById,
} from "src/store/selectors";

// Inner imports
import { EventsInfo, EventsSection } from "./components";

type Props = {
  dashboardId: Dashboard.Data["id"];
};

export const DashboardEventsSidebar: FC<Props> = ({ dashboardId }) => {
  const dashboard = useSelector((state: Store.RootState) =>
    selectAvailableDashboardById(state, dashboardId),
  );

  const events = useSelector((state: Store.RootState) =>
    selectEventsByDashboardId(state, dashboardId),
  );

  const [updatedEvents, setUpdatedEvents] = useState<Event.Data[]>([]);

  const [removedEvents, setRemovedEvents] = useState<Event.Data[]>([]);

  useEffect(() => {
    if (events) setUpdatedEvents(events);
  }, [events]);

  const onRemoveEvent = (eventId: Event.Data["id"]): void => {
    const event = events.find((event) => event.id === eventId);

    if (!event) return;

    setRemovedEvents((state) => [...state, event]);

    setUpdatedEvents((state) => state.filter((event) => event.id !== eventId));
  };

  const onUpdateEvent = (
    eventId: Event.Data["id"],
    changes: Partial<Event.Data>,
  ): void =>
    setUpdatedEvents((state) =>
      state.map((event) => {
        if (event.id !== eventId) return event;

        return {
          ...event,
          ...changes,
        };
      }),
    );

  if (!dashboard) return null;

  return (
    <div className={styles.wrapper}>
      <EventsInfo
        dashboardId={dashboardId}
        updatedEvents={updatedEvents}
        updateEventHandler={onUpdateEvent}
      />
      <EventsSection
        dashboardId={dashboardId}
        updatedEvents={updatedEvents}
        removedEvents={removedEvents}
        updateEventHandler={onUpdateEvent}
        removeEventHandler={onRemoveEvent}
      />
    </div>
  );
};
