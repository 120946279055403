import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { useAppDispatch } from "src/store";
import { selectTrackerIds } from "src/store/selectors";
import { subscribeOnTrackersCollection } from "src/store/trackersCollections/trackersCollectionsApi";
import {
  fetchTrackersByIds,
  updateSyncTrackersCollection,
} from "src/store/actions";

export const useTrackersCollectionObserver = (
  trackersCollectionId: TrackersCollection.Data["id"] = "",
  isReadOnly: boolean,
) => {
  const dispatch = useAppDispatch();

  const trackerIds = useSelector(selectTrackerIds);

  const [isObserverSet, setIsObserverSet] = useState<boolean>(false);

  const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>("idle");

  const callback = useCallback(
    (trackersCollection: TrackersCollection.Data): void => {
      const [currentTrackerIds, updatedTrackerIds] = [
        [...trackerIds].sort((a, b) => a.localeCompare(b)),
        [...trackersCollection.trackerIds].sort((a, b) => a.localeCompare(b)),
      ];

      const newTrackerIds = updatedTrackerIds.filter(
        (id) => !currentTrackerIds.includes(id),
      );

      dispatch(
        updateSyncTrackersCollection({
          id: trackersCollection.id,
          changes: trackersCollection,
        }),
      );

      if (newTrackerIds.length) {
        setLoadingStatus("loading");

        dispatch(fetchTrackersByIds(newTrackerIds))
          .unwrap()
          .then(() => setLoadingStatus("succeeded"))
          .catch((error) => {
            console.error(error);

            setLoadingStatus("failed");
          });
      }

      setIsObserverSet(true);
    },
    [dispatch, trackerIds],
  );

  useEffect(() => {
    if (!trackersCollectionId) return;

    if (!isReadOnly) return setIsObserverSet(true);

    let observer = () => {};

    try {
      observer = subscribeOnTrackersCollection(trackersCollectionId, callback);
    } catch (err) {
      console.error(err);
      setIsObserverSet(true);
    }

    return () => {
      observer();
    };
  }, [trackersCollectionId, isReadOnly, callback]);

  return [isObserverSet, loadingStatus];
};
