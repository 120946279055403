import firestore from "src/services/firestore";
import axios from "src/services/axios";

import { SEARCH_DEFAULT_STATUS } from "src/constants";
import {
  triggerGtmEvent,
  showDevelopmentError,
  deepRemoveExtraSpaces,
} from "src/utils";
import { API_ENDPOINTS, COLLECTION_IDS } from "../constants";
import {
  getTimestamps,
  generateDocId,
  getFirestoreEntitiesById,
} from "../utils";

// Inner imports
import * as schemas from "./searchesSchema";

export const getSearchById = async (
  searchId: Search.Data["id"],
): Promise<Search.Data> => {
  const res = await firestore()
    .collection(COLLECTION_IDS.searches)
    .doc(searchId)
    .get();

  const search = schemas.searchSchema.validateSync(res.data());

  return { ...search, id: res.id };
};

export const getSearchesByIds = async (
  searchIds: Search.Data["id"][],
): Promise<Search.Data[]> => {
  const searches = await getFirestoreEntitiesById(
    searchIds,
    COLLECTION_IDS.searches,
  );

  return searches.reduce<Search.Data[]>((acc, doc) => {
    try {
      const search = schemas.searchSchema.validateSync(doc.data());

      acc.push({
        ...search,
        id: doc.id,
      });
    } catch (error) {
      const errorTitle = "SEARCH VALIDATION ERROR";

      showDevelopmentError({ error, additionalTexts: [errorTitle] });
    }

    return acc;
  }, []);
};

export const getAllSearches = async (
  companyId: Company.Data["id"],
): Promise<Search.Data[]> => {
  const collection = await firestore()
    .collection(COLLECTION_IDS.searches)
    .where("companyId", "==", companyId)
    .get();

  return collection.docs.reduce<Search.Data[]>((acc, doc) => {
    try {
      const search = schemas.searchSchema.validateSync(doc.data());

      acc.push({
        ...search,
        id: doc.id,
      });
    } catch (error) {
      const errorTitle = "SEARCH VALIDATION ERROR";

      showDevelopmentError({ error, additionalTexts: [errorTitle] });
    }

    return acc;
  }, []);
};

export const getTrackerSearches = async (
  trackerSearchIds: Array<Search.Data["id"]>,
) => {
  const searches = await getFirestoreEntitiesById(
    trackerSearchIds,
    COLLECTION_IDS.searches,
  );

  return searches.reduce<Search.Data[]>((acc, doc) => {
    try {
      const search = schemas.searchSchema.validateSync(doc.data());

      acc.push({
        ...search,
        id: doc.id,
      });
    } catch (error) {
      const errorTitle = "SEARCH VALIDATION ERROR";

      showDevelopmentError({ error, additionalTexts: [errorTitle] });
    }

    return acc;
  }, []);
};

export const getSearchDescription = async (
  payload: Pick<Search.Data, "subject">,
  location: Location.Data,
  excludedDescriptions: Search.Data["description"][] = [],
): Promise<Search.Data["description"]> => {
  const response = await axios.post(API_ENDPOINTS.getSearchDescription, {
    subject: payload.subject,
    country: location.name,
    excludedDescriptions,
  });

  return schemas.searchDescriptionSchema.validateSync(response.data);
};

export const createSearch = async (
  payload: Omit<Search.Data, "id" | "createdAt" | "updatedAt" | "status">,
  location?: Location.Data,
): Promise<Search.Data["id"]> => {
  if (!payload.description && !location) payload.description = "";

  if (!payload.description && location) {
    try {
      payload.description = await getSearchDescription(payload, location);
    } catch (error) {
      showDevelopmentError({
        error,
        additionalTexts: ["SEARCH DESCRIPTION ERROR"],
      });

      payload.description = "";
    }
  }

  const response = await axios.post(API_ENDPOINTS.createSearch, {
    search: payload,
    configuration: { include: [], exclude: [], keywords: [] },
  });

  const searchId = schemas.createSearchSchema.validateSync(response.data);

  triggerGtmEvent("TrackerAddSearch", { searchId });

  return searchId;
};

export const createSearches = async (
  payload: Omit<Search.Data, "id" | "createdAt" | "updatedAt" | "status">[],
): Promise<Search.Data["id"][]> => {
  const promises: Promise<Search.Data["id"]>[] = [];

  for (const search of payload) {
    const createSearchPromise = axios
      .post(API_ENDPOINTS.createSearch, {
        search,
        configuration: {
          include: [],
          exclude: [],
          keywords: [],
        },
      })
      .then((response) => {
        const newSearchId = response?.data;

        if (typeof newSearchId !== "string") return "";

        triggerGtmEvent("TrackerAddSearch", { searchId: newSearchId });

        return newSearchId;
      });

    promises.push(createSearchPromise);
  }

  const res = await Promise.all(promises);

  return res.filter(Boolean);
};

export const updateSearch = async ({
  id,
  changes,
}: Store.UpdateEntity<Search.Data>): Promise<
  Store.UpdateEntity<Search.Data>
> => {
  const { updatedAt } = getTimestamps();

  const _changes = { ...changes, updatedAt };

  await firestore()
    .collection(COLLECTION_IDS.searches)
    .doc(id)
    .set(_changes, { merge: true });

  return { id, changes: _changes };
};

export const updateSearches = async (
  payload: Store.UpdateEntity<Search.Data>[],
): Promise<Store.UpdateEntity<Search.Data>[]> => {
  const { updatedAt } = getTimestamps();

  const updatedPayload: Store.UpdateEntity<Search.Data>[] = [];

  const batch = firestore().batch();

  for (const { id, changes } of payload) {
    const _changes = { ...changes, updatedAt };

    const docRef = firestore().collection(COLLECTION_IDS.searches).doc(id);

    batch.set(docRef, _changes, { merge: true });

    updatedPayload.push({ id, changes: _changes });
  }

  await batch.commit();

  return updatedPayload;
};

export const updateSearchesByAuthorId = async (
  {
    changes,
    authorId,
  }: {
    changes: Store.UpdateEntity<Search.Data>["changes"];
    authorId: Search.Data["authorId"];
  },
  companyId: Company.Data["id"],
): Promise<Store.UpdateEntity<Search.Data>[]> => {
  const { updatedAt } = getTimestamps();

  const updatedPayload: Store.UpdateEntity<Search.Data>[] = [];

  const batch = firestore().batch();

  const searchesByAuthorId = await firestore()
    .collection(COLLECTION_IDS.searches)
    .where("companyId", "==", companyId)
    .where("authorId", "==", authorId)
    .get();

  for (const { id, ref } of searchesByAuthorId.docs) {
    const _changes = { ...changes, updatedAt };

    batch.set(ref, _changes, { merge: true });

    updatedPayload.push({ id, changes: _changes });
  }

  await batch.commit();

  return updatedPayload;
};

export const deleteSearch = (searchId: Search.Data["id"]): Promise<void> =>
  firestore().collection(COLLECTION_IDS.searches).doc(searchId).delete();

export const deleteSearches = (
  searchIds: Search.Data["id"][],
): Promise<void> => {
  const batch = firestore().batch();

  for (const searchId of searchIds) {
    const docRef = firestore()
      .collection(COLLECTION_IDS.searches)
      .doc(searchId);

    batch.delete(docRef);
  }

  return batch.commit();
};

export const getSuggestedSearches: ServerRequest<
  {
    query: string;
    limit: number;
    location: Location.Data;
    language: Language.Data;
    excludedSubjects?: string[];
    category: Tracker.Data["category"];
    description: Tracker.Data["description"];
    keywordsDataSource: Search.KeywordsDataSource;
  },
  Promise<Search.CreationData[]>
> = async (data, configuration) => {
  const {
    query,
    location,
    language,
    keywordsDataSource,
    excludedSubjects = [],
    ...rest
  } = data;

  const { id: locationId, country } = location;

  const { id: languageId, name: languageName } = language;

  const response = await axios.post(
    API_ENDPOINTS.getSuggestedSearches,
    {
      country,
      subject: query,
      excludedSubjects,
      language: languageName,
      ...rest,
    },
    configuration,
  );

  const suggestedSearches = schemas.suggestedSearchesSchema.validateSync(
    response.data,
  );

  const filteredSuggestedSearches = suggestedSearches.filter(
    ({ subject }) => !excludedSubjects.includes(subject),
  );

  return formatSuggestedSearches({
    locationId,
    languageId,
    keywordsDataSource,
    searches: filteredSuggestedSearches,
  });
};

export const getSearchKeywords = async (
  searchId: Search.Data["id"],
): Promise<Search.Keyword[]> => {
  const response = await axios
    .post(API_ENDPOINTS.getSearchKeywords, { searchId })
    .then((response) => response?.data?.data || []);

  const keywords = new Set<Search.Keyword>();

  for (const keywordData of response) {
    try {
      const validatedKeywordData =
        schemas.searchKeywordSchema.validateSync(keywordData);

      keywords.add(validatedKeywordData);
    } catch (error) {
      console.error(error);
    }
  }

  return Array.from(keywords);
};

export const getSearchSelectedKeywords = async (
  searchId: Search.Data["id"],
): Promise<string[]> => {
  const response = await axios.post(API_ENDPOINTS.getSearchSelectedKeywords, {
    searchId,
  });

  return schemas.searchSelectedKeywordsSchema.validateSync(response.data)
    .keywords;
};

export const subscribeOnSearchStatus = (
  searchId: Search.Data["id"],
  callback: (status: Search.Status | null) => void,
): (() => void) => {
  const docRef = firestore().collection(COLLECTION_IDS.searches).doc(searchId);

  return docRef.onSnapshot((doc) => {
    try {
      if (!doc.exists) throw Error("Search not found");

      const { status } = schemas.searchSchema.validateSync(doc.data());

      callback(status);
    } catch (error) {
      console.error(error);

      callback(null);
    }
  });
};

export const subscribeOnSearchesStatus = (
  searchIds: Search.Data["id"][],
  callback: (search: Search.Data) => void,
): (() => void) => {
  const docsRef = firestore()
    .collection(COLLECTION_IDS.searches)
    .where(firestore.FieldPath.documentId(), "in", searchIds);

  return docsRef.onSnapshot((snapshot) => {
    for (const { doc } of snapshot.docChanges()) {
      if (!doc.exists) continue;

      try {
        const validatedData = schemas.searchSchema.validateSync(doc.data());

        const search = { ...validatedData, id: doc.id };

        callback(search);
      } catch (error) {
        console.error(error);
      }
    }
  });
};

function formatSuggestedSearches({
  searches,
  locationId,
  languageId,
  keywordsDataSource,
}: {
  locationId: Location.Data["id"];
  languageId: Search.Data["languageId"];
  keywordsDataSource: Search.KeywordsDataSource;
  searches: schemas.SuggestedSearchesSchemaType;
}): Search.CreationData[] {
  return searches.map(({ subject, description = "" }) =>
    deepRemoveExtraSpaces({
      subject,
      locationId,
      languageId,
      description,
      keywordsDataSource,
      id: generateDocId(),
      status: SEARCH_DEFAULT_STATUS,
    }),
  );
}
