import { AxiosError } from "axios";
import i18n from "i18next";
import { useState, useEffect } from "react";

import { getLocalization } from "src/store/localization/localizationApi";
import {
  LOCALIZATION_DEFAULT_NAMESPACE,
  LOCALIZATION_DEFAULT_LANGUAGE_ID,
} from "src/constants";
import {
  isErrorTypeGuard,
  showDevelopmentError,
  checkIsLocalEnvironment,
} from "src/utils";

export const useLocalizationLoader = () => {
  const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>("idle");

  useEffect(() => {
    if (loadingStatus !== "idle" || checkIsLocalEnvironment()) return;

    setLoadingStatus("loading");

    getLocalization(LOCALIZATION_DEFAULT_LANGUAGE_ID)
      .then((data) => {
        i18n.addResourceBundle(
          LOCALIZATION_DEFAULT_LANGUAGE_ID,
          LOCALIZATION_DEFAULT_NAMESPACE,
          data,
          true,
          true,
        );

        setLoadingStatus("succeeded");
      })
      .catch((error) => {
        let message = "";

        if (error instanceof AxiosError) message = error.response?.data?.error;

        if (isErrorTypeGuard(error)) message = error.message;

        showDevelopmentError({ additionalTexts: [message], error });

        setLoadingStatus("failed");
      });
  }, [loadingStatus]);

  return { loadingStatus };
};
