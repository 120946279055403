import { FC, HTMLProps, memo } from "react";
import cx from "classnames";

import styles from "./ExternalLink.module.scss";

type Props = HTMLProps<HTMLAnchorElement>;

export const ExternalLink: FC<Props> = memo(
  ({ className, children, ...props }) => (
    <a
      className={cx(styles.externalLink, className)}
      target="_blank"
      rel="noreferrer"
      {...props}
    >
      {children}
    </a>
  ),
);
