import { FC, useEffect, useMemo } from "react";
import { useImmer } from "use-immer";
import { useTranslation } from "react-i18next";

import styles from "./CopyTrackerModal.module.scss";
import { withError } from "src/hocs";
import { generateDocId } from "src/store/utils";
import { getDifferenceInObjects } from "src/utils";
import { Form, Input, Label } from "src/components";
import {
  DEFAULT_LANGUAGE_ID,
  DEFAULT_LOCATION_ID,
  TRACKER_INPUT_LIMIT,
} from "src/constants";
import {
  LocationsDropdown,
  LanguagesDropdown,
  KeywordsDataSourcesDropdown,
} from "src/features";
import {
  useModal,
  useLocationId,
  useLanguageId,
  useElementFocus,
  useTemporaryErrors,
} from "src/hooks";
import { ConfirmModal } from "../ConfirmModal/ConfirmModal";

const InputWithError = withError(Input);

type Props = {
  tracker: Tracker.CreationData;
  submitHandler: (value: Tracker.CreationData, callback: () => void) => void;
};

export const CopyTrackerModal: FC<Props> = ({
  submitHandler,
  tracker: initialTracker,
}) => {
  const { t } = useTranslation();

  const { closeModal } = useModal();

  const [ref, setFocus] = useElementFocus();

  const { errors, setErrors } = useTemporaryErrors(3000);

  const defaultTracker = useMemo<Tracker.CreationData>(
    () => ({ ...initialTracker, id: generateDocId() }),
    [initialTracker],
  );

  const [tracker, setTracker] = useImmer<Tracker.CreationData>(defaultTracker);

  const keywordsDataSource = useMemo<Nullable<Search.KeywordsDataSource>>(
    () => tracker.keywordsDataSources[0] || null,
    [tracker.keywordsDataSources],
  );

  const [locationId, setLocationId] = useLocationId({
    keywordsDataSource,
    locationId: tracker.locationId,
  });

  const [languageId, setLanguageId] = useLanguageId({
    locationId,
    keywordsDataSource,
    languageId: tracker.languageId,
  });

  const formattedTracker = useMemo<Tracker.CreationData>(
    () => ({
      ...tracker,
      locationId: locationId || DEFAULT_LOCATION_ID,
      languageId: languageId || DEFAULT_LANGUAGE_ID,
    }),
    [languageId, locationId, tracker],
  );

  const isTrackersConfigurationChanged = useMemo<boolean>(
    () => Boolean(getDifferenceInObjects(formattedTracker, defaultTracker)),
    [defaultTracker, formattedTracker],
  );

  const isDisabled = useMemo<boolean>(
    () => !isTrackersConfigurationChanged,
    [isTrackersConfigurationChanged],
  );

  useEffect(() => setFocus(), [setFocus]);

  const onNameChange = (value: Tracker.CreationData["name"]): void =>
    setTracker((draft) => {
      draft.name = value;
    });

  const onDescriptionChange = (
    value: Tracker.CreationData["description"],
  ): void =>
    setTracker((draft) => {
      draft.description = value;
    });

  const onCategoryChange = (value: Tracker.CreationData["category"]): void =>
    setTracker((draft) => {
      draft.category = value;
    });

  const onLocationIdChange = (value: string): void => setLocationId(value);

  const onLanguageIdChange = (value: string): void => setLanguageId(value);

  const onKeywordsDataSourceChange = (value: Search.KeywordsDataSource): void =>
    setTracker((draft) => {
      draft.keywordsDataSources = [value];
    });

  const onSubmit = (): void => {
    const errors = validate();

    if (Object.keys(errors).length) return setErrors(errors);

    submitHandler(formattedTracker, () => closeModal("copy-tracker"));
  };

  function validate() {
    const validationErrors: typeof errors = {};

    const { name, description = "", category } = formattedTracker;

    if (!name.trim().length)
      validationErrors.name = t(
        "component.modal.copy_tracker.form.validation.name_required",
      );

    if (!description.trim().length)
      validationErrors.description = t(
        "component.modal.copy_tracker.form.validation.description_required",
      );

    if (!category.trim().length)
      validationErrors.category = t(
        "component.modal.copy_tracker.form.validation.category_required",
      );

    return validationErrors;
  }

  return (
    <ConfirmModal
      id="copy-tracker"
      type="success"
      acceptButton={{
        onClick: onSubmit,
        disabled: isDisabled,
        text: t("component.modal.copy_tracker.button.submit"),
      }}
      cancelButton={{
        onClick: () => closeModal("copy-tracker"),
        text: t("component.modal.copy_tracker.button.cancel"),
      }}
      title={t("component.modal.copy_tracker.title")}
    >
      <Form
        onSubmit={onSubmit}
        disabled={isDisabled}
        className={styles.formWrapper}
      >
        <div className={styles.inputWrapper}>
          <Label leftText={t("component.modal.copy_tracker.form.label.name")} />
          <InputWithError
            ref={ref}
            error={errors.name}
            value={formattedTracker.name}
            changeHandler={onNameChange}
            characterLimit={TRACKER_INPUT_LIMIT}
            placeholder={t(
              "component.modal.copy_tracker.form.placeholder.name",
            )}
          />
        </div>
        <div className={styles.inputWrapper}>
          <Label
            leftText={t("component.modal.copy_tracker.form.label.description")}
          />
          <InputWithError
            error={errors.description}
            value={formattedTracker.description || ""}
            changeHandler={onDescriptionChange}
            characterLimit={TRACKER_INPUT_LIMIT}
            placeholder={t(
              "component.modal.copy_tracker.form.placeholder.description",
            )}
          />
        </div>
        <div className={styles.inputWrapper}>
          <Label
            leftText={t(
              "component.modal.copy_tracker.form.label.keywords_data_source",
            )}
          />
          <KeywordsDataSourcesDropdown
            dataSource={keywordsDataSource}
            setDataSource={onKeywordsDataSourceChange}
            hasDefaultStyles
          />
        </div>
        <div className={styles.groupWrapper}>
          <div className={styles.inputWrapper}>
            <Label
              leftText={t("component.modal.copy_tracker.form.label.location")}
            />
            <LocationsDropdown
              locationId={formattedTracker.locationId}
              setLocationId={onLocationIdChange}
              hasDefaultStyles
              keywordsDataSource={keywordsDataSource}
            />
          </div>
          <div className={styles.inputWrapper}>
            <Label
              leftText={t("component.modal.copy_tracker.form.label.language")}
            />
            <LanguagesDropdown
              languageId={formattedTracker.languageId}
              setLanguageId={onLanguageIdChange}
              hasDefaultStyles
              keywordsDataSource={keywordsDataSource}
            />
          </div>
        </div>
        <div className={styles.inputWrapper}>
          <Label
            leftText={t("component.modal.copy_tracker.form.label.category")}
          />
          <InputWithError
            error={errors.category}
            changeHandler={onCategoryChange}
            value={formattedTracker.category}
            characterLimit={TRACKER_INPUT_LIMIT}
            placeholder={t(
              "component.modal.copy_tracker.form.placeholder.category",
            )}
          />
        </div>
      </Form>
    </ConfirmModal>
  );
};
