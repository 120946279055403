import { createSlice, createEntityAdapter } from "@reduxjs/toolkit";

export const widgetSyncsAdapter = createEntityAdapter<WidgetSync.Data>({
  sortComparer: (a, b) => a.startedAt.localeCompare(b.startedAt),
});

const initialState = widgetSyncsAdapter.getInitialState<Store.InitialState>({
  status: "idle",
  error: null,
});

const widgetSyncsSlice = createSlice({
  name: "widget-syncs",
  initialState,
  reducers: {
    addWidgetSync: widgetSyncsAdapter.addOne,
    removeWidgetSync: widgetSyncsAdapter.removeOne,
    updateWidgetSync: widgetSyncsAdapter.updateOne,
    removeAllWidgetSyncs: widgetSyncsAdapter.removeAll,
  },
});

export const {
  addWidgetSync,
  removeWidgetSync,
  updateWidgetSync,
  removeAllWidgetSyncs,
} = widgetSyncsSlice.actions;

export default widgetSyncsSlice.reducer;
