import { memo } from "react";

import { useSortedItems } from "src/hooks";

// Inner imports
import type { TableItemGenericProps, TableProps } from "../../../../types";
import { TableRow } from "../";

type Props<T> = TableProps<T>;

export const SubTable = memo(
  <T extends TableItemGenericProps>({
    items: allItems = [],
    tableColumns = [],
    getActionMenuOptions,
    defaultSort = null,
    customPreSort,
    customAfterSort,
  }: Props<T>) => {
    const { sortedItems: items } = useSortedItems({
      items: allItems,
      defaultSort,
      customPreSort,
      customAfterSort,
    });

    return (
      <>
        {items.map((item) => (
          <TableRow
            key={item.id}
            item={item}
            tableColumns={tableColumns}
            getActionMenuOptions={getActionMenuOptions}
            isSubTable
          />
        ))}
      </>
    );
  },
);
