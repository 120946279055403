import { FC, useMemo } from "react";
import Table from "rsuite/Table";

import { formatToLocaleNumber, isNumber } from "src/utils";
import type { KeywordsTableCellProps } from "../../types";
import { KEYWORDS_TABLE_STYLES } from "../../constants";

const { Cell } = Table;

export const TotalVolumeCell: FC<KeywordsTableCellProps> = ({
  rowData: keywordData = {},
  dataKey,
  ...props
}) => {
  const totalVolume = useMemo<number>(
    () => (isNumber(keywordData?.totalVolume) ? keywordData.totalVolume : 0),
    [keywordData?.totalVolume],
  );

  return (
    <Cell
      {...props}
      dataKey={dataKey}
      height={KEYWORDS_TABLE_STYLES.cellHeight}
    >
      {formatToLocaleNumber(totalVolume)}
    </Cell>
  );
};
