import { FC } from "react";

import styles from "./SuggestedCategories.module.scss";
import { formatTrackerCategoryKey } from "src/utils";

// Inner imports
import { SuggestedCategory } from "./components";

type Props = {
  isLoading: boolean;
  selectedCategory: Tracker.Category | null;
  suggestedCategories: Tracker.Category[];
  selectCategoryHandler: (value: Tracker.Category) => void;
};

export const SuggestedCategories: FC<Props> = ({
  isLoading,
  selectedCategory,
  suggestedCategories,
  selectCategoryHandler,
}) => (
  <div className={styles.wrapper}>
    {suggestedCategories.map((suggestedCategory) => (
      <SuggestedCategory
        key={formatTrackerCategoryKey(suggestedCategory)}
        category={suggestedCategory}
        isLoading={isLoading}
        selectedCategory={selectedCategory}
        selectCategoryHandler={selectCategoryHandler}
      />
    ))}
  </div>
);
