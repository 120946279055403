import { FC, useCallback, useMemo, useState } from "react";

import styles from "./ProgressBar.module.scss";
import { getElementContrastedColor } from "src/utils";

type Props = {
  value: number;
  label?: string;
  placeholder?: string;
};

export const ProgressBar: FC<Props> = ({ value, label = "", placeholder }) => {
  const [fontColor, setFontColor] = useState<string>("");

  const progressPercents = useMemo<string>(() => `${value * 100}%`, [value]);

  const isLabelShown = useMemo<boolean>(() => Boolean(label), [label]);

  const isPlaceholderShown = useMemo<boolean>(
    () => !value && Boolean(placeholder),
    [value, placeholder],
  );

  const wrapperRefCallback = useCallback(
    (element: HTMLDivElement | null): void => {
      if (!element) return;

      const newFontColor = getElementContrastedColor({
        element,
        minRatio: 3.8,
      });

      setFontColor(newFontColor);
    },
    [],
  );

  return (
    <div className={styles.wrapper}>
      <div
        className={styles.progressWrapper}
        style={{ width: progressPercents, color: fontColor }}
        ref={wrapperRefCallback}
      >
        {isLabelShown && <span className={styles.progressLabel}>{label}</span>}
      </div>
      {isPlaceholderShown && (
        <div className={styles.placeholderWrapper}>
          <span>{placeholder}</span>
        </div>
      )}
    </div>
  );
};
