import { CheckCircle, ExclamationCircle, InfoCircle } from "src/assets/icons";

const MODAL_TITLE_ICON_SIZE = 19;

const MODAL_TITLE_ICONS = {
  success: <CheckCircle size={MODAL_TITLE_ICON_SIZE} color="#41B167" />,
  info: <InfoCircle size={MODAL_TITLE_ICON_SIZE} color="#1753FF" />,
  info_dark: <InfoCircle size={MODAL_TITLE_ICON_SIZE} color="#55E0FF" />,
  warning: <ExclamationCircle size={MODAL_TITLE_ICON_SIZE} color="#FFB800" />,
  error: <ExclamationCircle size={MODAL_TITLE_ICON_SIZE} color="#FF4451" />,
};

const getMessageIcon = (type: "success" | "info" | "warning" | "error") => {
  return MODAL_TITLE_ICONS[type] || null;
};

export { getMessageIcon };
