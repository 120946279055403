import cx from "classnames";
import { useTranslation } from "react-i18next";

import styles from "./TableHead.module.scss";
import { genericMemo } from "src/utils";

// Inner imports
import type {
  TableSort,
  TableProps,
  TableItemGenericProps,
} from "../../../../types";
import { TableSortIcon } from "../../../";

type Props<T> = Pick<TableProps<T>, "tableColumns" | "isTableShort"> & {
  sort: TableSort<T>;
  setSort: (field: keyof T) => void;
  isTableEmpty: boolean;
};

export const TableHead = genericMemo(
  <T extends TableItemGenericProps>({
    tableColumns,
    sort,
    setSort,
    isTableEmpty,
    isTableShort,
  }: Props<T>) => {
    const { t } = useTranslation();

    return (
      <thead
        className={cx(
          styles.tableHead,
          isTableShort ? styles.shortTableHead : "",
        )}
      >
        <tr>
          {tableColumns.map(
            ({ key, labelKey, isSortable, isAction, isNumeric, style }) => (
              <th
                key={String(key)}
                className={cx(
                  isSortable ? styles.sortable : "",
                  isAction ? styles.action : "",
                  isNumeric ? styles.numeric : "",
                )}
                style={style}
                onClick={() => isSortable && !isTableEmpty && setSort(key)}
              >
                <span title={t(labelKey)}>{t(labelKey)}</span>
                {isSortable && <TableSortIcon sortDirection={sort?.[key]} />}
              </th>
            ),
          )}
        </tr>
      </thead>
    );
  },
);
