import { FC, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";

import styles from "./ArticlesPreview.module.scss";
import {
  Preloader,
  Translation,
  InputWithIcon,
  ArticlesChartArticle,
  ArticlesChartPagination,
  ArticlesChartSortDropdown,
} from "src/components";
import { formatArticleKey } from "src/utils";

// Inner imports
import { useArticlesPagination } from "./hooks";
import { WIDGET_ARTICLES_PAGINATION_DEFAULT_PROPS } from "./constants";

export const ArticlesPreview: FC<Widget.PreviewProps> = ({
  trackerIds,
  isExpanded,
  trackersCollectionId,
  dashboardDateRangeId,
}) => {
  const { t } = useTranslation();

  const articlesRef = useRef<HTMLDivElement>(null);

  const {
    pageNumber,
    searchQuery,
    pagesCount,
    sort,
    hasArticles,
    slicedArticles,
    isLoading,
    totalArticlesCount,
    onPageChange,
    onSortChange,
    onSearchQueryChange,
  } = useArticlesPagination({
    trackerIds,
    trackersCollectionId,
    dashboardDateRangeId,
  });

  const isContentVisible = useMemo<boolean>(
    () => !isLoading && hasArticles,
    [hasArticles, isLoading],
  );

  const sortBy = useMemo<keyof Widgets.ArticlesChart.Article>(
    () =>
      (Object.keys(sort || {})[0] as keyof Widgets.ArticlesChart.Article) ||
      WIDGET_ARTICLES_PAGINATION_DEFAULT_PROPS.sortBy,
    [sort],
  );

  const isContentPlaceholderVisible = useMemo<boolean>(
    () => !isLoading && !hasArticles,
    [hasArticles, isLoading],
  );

  const resetArticlesScrollPosition = (): void =>
    articlesRef.current?.scrollTo(0, 0);

  const onPageNumberChange = (value: number): void => {
    resetArticlesScrollPosition();
    onPageChange(value);
  };

  return (
    <div className={styles.widgetWrapper}>
      <div className={styles.widgetSubHead}>
        <InputWithIcon
          type="text"
          className={styles.searchWrapper}
          inputClassName={styles.widgetSearch}
          value={searchQuery}
          changeHandler={onSearchQueryChange}
          icon="Magnifier"
          placeholder={t("widget.articles.form.placeholder.search_query")}
          hasClearButton
        />
        <div className={styles.widgetSubHeadSettings}>
          <span
            className={styles.totalArticles}
            title={totalArticlesCount.toString()}
          >
            <Translation
              i18nKey="widget.articles.label.total_count"
              values={{ count: totalArticlesCount }}
            />
          </span>
          <ArticlesChartSortDropdown
            sortBy={sortBy}
            onSortChange={onSortChange}
          />
        </div>
      </div>
      <div className={styles.articlesWrapper}>
        {isLoading && <Preloader type="bar" className={styles.loader} />}
        {isContentVisible && (
          <>
            <div className={styles.articles} ref={articlesRef}>
              {slicedArticles.map((article, index) => (
                <ArticlesChartArticle
                  article={article}
                  searchQuery={searchQuery}
                  key={formatArticleKey(article, index)}
                  isExpanded={isExpanded}
                />
              ))}
            </div>
            <ArticlesChartPagination
              pageNumber={pageNumber}
              pagesCount={pagesCount}
              setPageNumber={onPageNumberChange}
            />
          </>
        )}
        {isContentPlaceholderVisible && (
          <div className={styles.emptyArticlesList}>
            {t("widget.articles.data.placeholder.no_articles")}
          </div>
        )}
      </div>
    </div>
  );
};
