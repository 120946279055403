import { useMemo } from "react";

import styles from "./TableLayout.module.scss";
import { genericMemo } from "src/utils";

// Inner imports
import type { TableItemGenericProps, TableProps, TableSort } from "../../types";
import { TableBody, TableHead } from "./components";

type Props<T> = {
  sort: TableSort<T>;
  setSort: (field: keyof T) => void;
  isTableEmpty: boolean;
} & Pick<
  TableProps<T>,
  "items" | "tableColumns" | "isTableShort" | "getActionMenuOptions"
>;

export const TableLayout = genericMemo(
  <T extends TableItemGenericProps>({
    items,
    tableColumns,
    isTableShort,
    getActionMenuOptions,
    sort,
    setSort,
    isTableEmpty,
  }: Props<T>) => {
    const isTableBodyVisible = useMemo<boolean>(
      () => Boolean(items.length),
      [items],
    );

    return (
      <table className={styles.table}>
        <TableHead
          tableColumns={tableColumns}
          sort={sort}
          setSort={setSort}
          isTableEmpty={isTableEmpty}
          isTableShort={isTableShort}
        />
        {isTableBodyVisible && (
          <TableBody
            items={items}
            tableColumns={tableColumns}
            isTableShort={isTableShort}
            isTableEmpty={isTableEmpty}
            getActionMenuOptions={getActionMenuOptions}
          />
        )}
      </table>
    );
  },
);
