import { FC, useMemo } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import styles from "./AuthHeader.module.scss";
import { ROUTES } from "src/constants";
import { Button } from "src/components";
import { useQueryParams } from "src/hooks";
import { selectWhiteLabel } from "src/store/selectors";
import { UserAdd, UserLogIn } from "src/assets/icons";

export const AuthHeader: FC = () => {
  const { t } = useTranslation();

  const history = useHistory();

  const location = useLocation();

  const queryParams = useQueryParams();

  const {
    name,
    logos: { small, medium },
    features: { signupEnabled },
  } = useSelector(selectWhiteLabel);

  const isLoginPage = useMemo<boolean>(
    () => location.pathname === ROUTES.login,
    [location.pathname],
  );

  const { text, label, Icon } = useMemo(
    () => ({
      text: isLoginPage
        ? t("authentication.header.button.redirect_to_signup")
        : t("authentication.header.button.redirect_to_login"),
      label: isLoginPage
        ? t("authentication.header.button.signup")
        : t("authentication.header.button.login"),
      Icon: isLoginPage ? <UserAdd /> : <UserLogIn />,
    }),
    [isLoginPage, t],
  );

  const isAuthButtonsShown = useMemo<boolean>(
    () => signupEnabled || location.pathname === ROUTES.signUp,
    [location.pathname, signupEnabled],
  );

  const onButtonClick = (): void => {
    const location = isLoginPage ? ROUTES.signUp : ROUTES.login;

    const locationQueryParams = new URLSearchParams(queryParams).toString();

    history.push(`${location}?${locationQueryParams}`);
  };

  return (
    <div className={styles.wrapper}>
      <Link to={ROUTES.login} className={styles.logo}>
        <picture>
          <source media="(max-width:768px)" srcSet={small} />
          <img src={medium} alt={name} />
        </picture>
      </Link>
      {isAuthButtonsShown && (
        <div className={styles.buttonWrapper}>
          <p className={styles.buttonLabel}>{label}</p>
          <Button
            className={styles.button}
            onClick={onButtonClick}
            buttonType="dark"
          >
            {Icon}
            <span>{text}</span>
          </Button>
        </div>
      )}
    </div>
  );
};
