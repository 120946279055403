import { useEffect, useRef, useState, MutableRefObject, useMemo } from "react";

export const useScrollTo = <T extends Element | null>(
  options: ScrollIntoViewOptions,
  customRef?: MutableRefObject<T>,
): [MutableRefObject<T | null>, (value: boolean) => void] => {
  const _ref = useRef<T>(null);

  const ref = useMemo<MutableRefObject<T | null>>(
    () => customRef || _ref,
    [customRef],
  );

  const [shouldScrollTo, setShouldScrollTo] = useState<boolean>(false);

  useEffect(() => {
    if (ref.current && shouldScrollTo) {
      ref.current.scrollIntoView(options);

      setShouldScrollTo(false);
    }
  }, [options, ref, shouldScrollTo]);

  return [ref, setShouldScrollTo];
};
