import { FC, memo, useMemo } from "react";
import DatePicker from "react-datepicker";

import styles from "./MonthSelector.module.scss";
import { formatToYearMonth, formatStringToDate } from "src/utils";

// Inner imports
import { MonthSelectorInput } from "./components";

type Props = {
  value: string;
  minValue?: string | null;
  maxValue?: string | null;
  onChange: (value: string) => void;
};

export const MonthSelector: FC<Props> = memo(
  ({ value, minValue, maxValue, onChange }) => {
    const minDate = useMemo<Date | null>(() => {
      if (!minValue) return null;

      return formatStringToDate(minValue);
    }, [minValue]);

    const maxDate = useMemo<Date | null>(() => {
      if (!maxValue) return null;

      return formatStringToDate(maxValue);
    }, [maxValue]);

    const selectedDate = useMemo<Date>(() => {
      const formattedDate = formatStringToDate(value, new Date());

      if (!formattedDate) return new Date();

      return formattedDate;
    }, [value]);

    const handleChange = (value: Date | [Date, Date] | null): void => {
      if (!(value instanceof Date)) return;

      const formattedValue = formatToYearMonth(value);

      onChange(formattedValue);
    };

    return (
      <div className={styles.wrapper}>
        <DatePicker
          onChange={handleChange}
          selected={selectedDate}
          minDate={minDate}
          maxDate={maxDate}
          showMonthYearPicker
          wrapperClassName={styles.monthPickerWrapper}
          calendarClassName={styles.monthPickerCalendar}
          customInput={<MonthSelectorInput />}
          popperPlacement="bottom-end"
          popperClassName={styles.monthPickerPopper}
        />
      </div>
    );
  },
);
