import { useSelector } from "react-redux";
import { useEffect, useMemo, useState } from "react";

import { useInterval } from "src/hooks";
import {
  selectDashboardDataUpdatedAt,
  selectAvailableDashboardById,
} from "src/store/selectors";
import {
  formatToDistanceToNow,
  formatToDayMonthFullYearTimeDate,
} from "src/utils";

const DATE_UPDATE_INTERVAL_DELAY: number = 60000;

export const useDashboardUpdatedAt = (
  trackersCollectionId: TrackersCollection.Data["id"],
  dashboardDateRangeId: DashboardDateRange.Data["id"],
  isReadOnly: boolean,
) => {
  const dashboard = useSelector((state: Store.RootState) =>
    selectAvailableDashboardById(state, trackersCollectionId, isReadOnly),
  );

  const companyId = useMemo<Dashboard.Data["companyId"]>(
    () => dashboard?.companyId || "",
    [dashboard?.companyId],
  );

  const updatedAt = useSelector((state: Store.RootState) =>
    selectDashboardDataUpdatedAt(
      state,
      trackersCollectionId,
      dashboardDateRangeId,
      companyId,
    ),
  );

  const [updatedAtDistance, setUpdatedAtDistance] = useState<string>("");

  const formattedUpdatedAt = useMemo<string>(() => {
    if (!updatedAt) return "";

    return formatToDayMonthFullYearTimeDate(updatedAt);
  }, [updatedAt]);

  useEffect(() => {
    if (!updatedAt) return;

    setUpdatedAtDistance(formatToDistanceToNow(updatedAt));
  }, [updatedAt]);

  useInterval(() => {
    if (!updatedAt) return;

    setUpdatedAtDistance(formatToDistanceToNow(updatedAt));
  }, DATE_UPDATE_INTERVAL_DELAY);

  return {
    updatedAt: formattedUpdatedAt,
    updatedAtDistance,
  };
};
