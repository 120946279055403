import { FC, memo, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import styles from "./RenameDashboardModal.module.scss";
import { withError } from "src/hocs";
import { useAppDispatch } from "src/store";
import { Form, Input } from "src/components";
import { updateDashboard } from "src/store/actions";
import { DASHBOARD_INPUT_LIMIT } from "src/constants";
import {
  selectDashboardById,
  selectVisibleDashboards,
} from "src/store/selectors";
import { useModal, useElementFocus, useTemporaryErrors } from "src/hooks";
import {
  compareTwoStrings,
  removeExtraSpaces,
  showToastNotification,
} from "src/utils";
import { ConfirmModal } from "../ConfirmModal/ConfirmModal";

type Props = {
  dashboardId: Dashboard.Data["id"];
};

const InputWithError = withError(Input);

export const RenameDashboardModal: FC<Props> = memo(({ dashboardId }) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const { closeModal } = useModal();

  const { errors, setErrors } = useTemporaryErrors(3000);

  const [ref, setFocus] = useElementFocus();

  const dashboards = useSelector(selectVisibleDashboards);

  const dashboard = useSelector((state: Store.RootState) =>
    selectDashboardById(state, dashboardId),
  );

  const defaultName = useMemo<Dashboard.Data["name"]>(
    () => dashboard?.name || "",
    [dashboard?.name],
  );

  const [name, setName] = useState<Dashboard.Data["name"]>(defaultName);

  const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>("idle");

  const isLoading = useMemo<boolean>(
    () => loadingStatus === "loading",
    [loadingStatus],
  );

  const isDashboardNameChanged = useMemo<boolean>(
    () => name !== defaultName,
    [defaultName, name],
  );

  const isDisabled = useMemo<boolean>(
    () => isLoading || !isDashboardNameChanged,
    [isLoading, isDashboardNameChanged],
  );

  useEffect(() => setFocus(), [setFocus]);

  const onSubmit = async (): Promise<void> => {
    const errors = validate();

    if (Object.keys(errors).length) return setErrors(errors);

    try {
      setLoadingStatus("loading");

      await dispatch(
        updateDashboard({
          id: dashboardId,
          changes: { name: removeExtraSpaces(name) },
        }),
      ).unwrap();

      setLoadingStatus("succeeded");
    } catch (error) {
      console.error(error);

      setLoadingStatus("failed");

      showToastNotification({
        type: "error",
        text: t("common.error.server_error_reload"),
      });
    } finally {
      closeModal("rename-dashboard");
    }
  };

  function validate() {
    const errors: Errors = {};

    if (!name)
      errors["name"] = t(
        "component.modal.rename_dashboard.form.validation.name_required",
      );

    const isDashboardNameTaken = dashboards.some(
      (dashboard) =>
        compareTwoStrings(dashboard.name, name) && dashboard.id !== dashboardId,
    );

    if (isDashboardNameTaken)
      errors["name"] = t(
        "component.modal.rename_dashboard.form.validation.name_exists",
      );

    return errors;
  }

  return (
    <ConfirmModal
      id="rename-dashboard"
      type="success"
      acceptButton={{
        text: t("component.modal.rename_dashboard.button.submit"),
        onClick: onSubmit,
        disabled: isDisabled,
      }}
      cancelButton={{
        text: t("component.modal.rename_dashboard.button.cancel"),
        onClick: () => closeModal("rename-dashboard"),
      }}
      title={t("component.modal.rename_dashboard.title")}
      isLoading={isLoading}
    >
      <Form
        onSubmit={onSubmit}
        disabled={isDisabled}
        className={styles.formWrapper}
      >
        <div className={styles.inputWrapper}>
          <InputWithError
            ref={ref}
            value={name}
            error={errors.name}
            changeHandler={setName}
            characterLimit={DASHBOARD_INPUT_LIMIT}
            placeholder={t(
              "component.modal.rename_dashboard.form.placeholder.name",
            )}
          />
        </div>
      </Form>
    </ConfirmModal>
  );
});
