import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useImmer } from "use-immer";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import styles from "./SearchKeywords.module.scss";
import { Button } from "src/components";
import { Loader } from "src/assets/icons";
import { useAppDispatch } from "src/store";
import { usePreviousState } from "src/hooks";
import { removeExtraSpaces, showToastNotification } from "src/utils";
import { useSearchStatusObserver } from "src/pages/Trackers/CreateTrackers/components/SelectSearchesSection/hooks";
import {
  getSearchKeywords,
  getSearchSelectedKeywords,
} from "src/store/searches/searchesApi";
import {
  selectTrackersWithSearchId,
  selectSearchConfigurationById,
  selectTrackersCollectionsWithSearchId,
} from "src/store/selectors";
import {
  updateSearch,
  updateTrackers,
  updateSearchConfiguration,
  updateTrackersCollections,
} from "src/store/actions";
import { KeywordsTable } from "../KeywordsTable/KeywordsTable";
import type { Keyword, SelectStatus } from "../KeywordsTable/types";

// Inner imports
import { KeywordsControl, SelectedSearch } from "./components";
import {
  getGroupedKeywords,
  getDuplicatedKeywords,
  getExactMatchKeywords,
  getExactMatchDuplicatedKeywords,
} from "./utils";

type Props = {
  search: Search.CreationData;
  keywordsData?: Search.KeywordsData;
  closeSidebarHandler: () => void;
};

export const SearchKeywords: FC<Props> = ({
  search,
  closeSidebarHandler,
  keywordsData: defaultKeywordsData,
}) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const trackersCollections = useSelector((state: Store.RootState) =>
    selectTrackersCollectionsWithSearchId(state, search.id),
  );

  const trackers = useSelector((state: Store.RootState) =>
    selectTrackersWithSearchId(state, search.id),
  );

  const searchConfiguration = useSelector((state: Store.RootState) =>
    selectSearchConfigurationById(state, search.id),
  );

  const defaultSelectedKeywords = useMemo<Record<Keyword, true>>(() => {
    const searchSelectedKeywords = searchConfiguration?.keywords || [];

    const keywords = new Map<Keyword, true>();

    for (const keyword of searchSelectedKeywords) keywords.set(keyword, true);

    return Object.fromEntries(keywords);
  }, [searchConfiguration?.keywords]);

  const { searchStatus, isObserverSet } = useSearchStatusObserver(search);

  const [selectedKeywords, setSelectedKeywords] = useState<
    Record<Keyword, true>
  >(defaultSelectedKeywords);

  const [autoSelectedKeywords, setAutoSelectedKeywords] =
    useState<Nullable<Record<Keyword, true>>>(null);

  const [isKeywordsExactMatch, setIsKeywordsExactMatch] =
    useState<boolean>(true);

  const [isDuplicatedKeywordsShown, setIsDuplicatedKeywordsShown] =
    useState<boolean>(false);

  const [keywordsSearch, setKeywordsSearch] = useState<string>("");

  const [updateLoadingStatus, setUpdateLoadingStatus] =
    useState<LoadingStatus>("idle");

  const [keywordsSelectLoadingStatus, setKeywordsSelectLoadingStatus] =
    useState<LoadingStatus>("idle");

  const [keywordsData, setKeywordsData] = useImmer<
    Search.KeywordsData | undefined
  >(defaultKeywordsData);

  const keywordsDataStatus = useMemo<LoadingStatus>(
    () => keywordsData?.status || "idle",
    [keywordsData?.status],
  );

  const previousKeywordsDataStatus = usePreviousState(keywordsDataStatus);

  const keywords = useMemo<Search.Keyword[]>(
    () => keywordsData?.keywords || [],
    [keywordsData?.keywords],
  );

  const isKeywordsLoading = useMemo<boolean>(
    () => keywordsDataStatus === "loading",
    [keywordsDataStatus],
  );

  const isKeywordsPending = useMemo<boolean>(
    () => !isObserverSet || searchStatus === "PENDING",
    [searchStatus, isObserverSet],
  );

  const exactMatchKeywords = useMemo<Search.Keyword[]>(() => {
    if (!search) return keywords;

    return getExactMatchKeywords(keywords, search);
  }, [keywords, search]);

  const filteredKeywords = useMemo<Search.Keyword[]>(() => {
    if (!search || !isKeywordsExactMatch) return keywords;

    return exactMatchKeywords;
  }, [search, isKeywordsExactMatch, keywords, exactMatchKeywords]);

  const duplicatedKeywords = useMemo<Search.Keyword[]>(() => {
    if (!search || !isKeywordsExactMatch)
      return getDuplicatedKeywords(keywords);

    return getExactMatchDuplicatedKeywords(keywords, search);
  }, [isKeywordsExactMatch, keywords, search]);

  const searchedKeywords = useMemo<Search.Keyword[]>(() => {
    const keywords = isDuplicatedKeywordsShown
      ? duplicatedKeywords
      : filteredKeywords;

    if (!search || !keywordsSearch) return keywords;

    const formattedKeywordsSearch = removeExtraSpaces(
      keywordsSearch.toLowerCase(),
    );

    return keywords.filter(({ string }) =>
      removeExtraSpaces(string.toLowerCase()).includes(formattedKeywordsSearch),
    );
  }, [
    search,
    keywordsSearch,
    filteredKeywords,
    duplicatedKeywords,
    isDuplicatedKeywordsShown,
  ]);

  const isSelectAllDisabled = useMemo<boolean>(
    () => !searchedKeywords.length,
    [searchedKeywords.length],
  );

  const { groupedKeywords, groupedDuplicates } = useMemo(() => {
    if (!search) return { groupedKeywords: [], groupedDuplicates: [] };

    const groupedData = getGroupedKeywords(searchedKeywords, search);

    for (const [index, keyword] of groupedData.groupedDuplicates.entries())
      keyword.label = t("component.keywords_table.label.duplicate_group", {
        number: index + 1,
      });

    return groupedData;
  }, [search, searchedKeywords, t]);

  const keywordsTableData = useMemo<Search.FormattedKeyword[]>(
    () => (isDuplicatedKeywordsShown ? groupedDuplicates : groupedKeywords),
    [groupedDuplicates, groupedKeywords, isDuplicatedKeywordsShown],
  );

  const hasDuplicates = useMemo<boolean>(
    () => Boolean(groupedDuplicates.length),
    [groupedDuplicates],
  );

  const keywordsSelectStatus = useMemo<SelectStatus>(() => {
    if (!searchedKeywords.length) return "unchecked";

    let [isAllSelected, isPartSelected] = [true, false];

    for (const { string: keyword } of searchedKeywords) {
      const isKeywordSelected = Boolean(selectedKeywords[keyword]);

      if (!isKeywordSelected) {
        isAllSelected = false;

        continue;
      }

      isPartSelected = true;
    }

    switch (true) {
      case isAllSelected:
        return "checked";
      case !isAllSelected && isPartSelected:
        return "partial";
      case !isAllSelected && !isPartSelected:
      default:
        return "unchecked";
    }
  }, [searchedKeywords, selectedKeywords]);

  const shouldKeywordsLoad = useMemo<boolean>(
    () =>
      keywordsDataStatus === "loading" &&
      defaultKeywordsData?.status === "loading",
    [defaultKeywordsData?.status, keywordsDataStatus],
  );

  const isKeywordsSelectionChanged = useMemo<boolean>(() => {
    const [defaultSelectedKeywordsArray, selectedKeywordsArray] = [
      Object.keys(defaultSelectedKeywords).sort(),
      Object.keys(selectedKeywords).sort(),
    ];

    return (
      JSON.stringify(defaultSelectedKeywordsArray) !==
      JSON.stringify(selectedKeywordsArray)
    );
  }, [defaultSelectedKeywords, selectedKeywords]);

  const isUpdateLoading = useMemo<boolean>(
    () => updateLoadingStatus === "loading",
    [updateLoadingStatus],
  );

  const isUpdateKeywordsDisabled = useMemo<boolean>(
    () =>
      isUpdateLoading ||
      !Object.keys(selectedKeywords).length ||
      !isKeywordsSelectionChanged,
    [isUpdateLoading, selectedKeywords, isKeywordsSelectionChanged],
  );

  const selectKeywordsHandler = useCallback(
    (values: Keyword[]): void => {
      const newSelectedKeywords = { ...selectedKeywords };

      for (const value of values) {
        const isKeywordSelected = selectedKeywords[value] || false;

        if (isKeywordSelected) {
          delete newSelectedKeywords[value];
        } else {
          newSelectedKeywords[value] = true;
        }
      }

      setSelectedKeywords(newSelectedKeywords);
    },
    [selectedKeywords],
  );

  const updateKeywordsData = useCallback(
    (keywords: Search.Keyword[], status: LoadingStatus) =>
      setKeywordsData((draft) => {
        if (!draft) return;

        draft.status = status;
        draft.keywords = keywords;
      }),
    [setKeywordsData],
  );

  useEffect(() => {
    if (
      isKeywordsPending ||
      (keywordsDataStatus !== "idle" && !shouldKeywordsLoad)
    )
      return;

    updateKeywordsData([], "loading");

    getSearchKeywords(search.id)
      .then((keywords) => updateKeywordsData(keywords, "succeeded"))
      .catch(() => {
        updateKeywordsData([], "failed");

        showToastNotification({
          type: "error",
          text: t("common.error.server_error"),
        });
      });
  }, [
    t,
    search.id,
    isKeywordsPending,
    shouldKeywordsLoad,
    keywordsDataStatus,
    updateKeywordsData,
  ]);

  useEffect(() => {
    if (
      isKeywordsPending ||
      keywordsDataStatus === "idle" ||
      keywordsDataStatus === "loading" ||
      !keywords.length ||
      exactMatchKeywords.length
    )
      return;

    setIsKeywordsExactMatch(false);
  }, [
    keywords.length,
    isKeywordsPending,
    keywordsDataStatus,
    exactMatchKeywords.length,
  ]);

  useEffect(() => {
    const hasKeywordsLoaded =
      previousKeywordsDataStatus === "loading" &&
      keywordsDataStatus === "succeeded";

    if (hasKeywordsLoaded) setSelectedKeywords(defaultSelectedKeywords);
  }, [defaultSelectedKeywords, keywordsDataStatus, previousKeywordsDataStatus]);

  const selectAllKeywordsHandler = (): void => {
    if (keywordsSelectStatus === "checked")
      return selectKeywordsHandler(
        searchedKeywords.map(({ string }) => string),
      );

    const unselectedKeywords = new Set<Keyword>();

    for (const { string: keyword } of searchedKeywords) {
      const isKeywordSelected = Boolean(selectedKeywords[keyword]);

      if (!isKeywordSelected) unselectedKeywords.add(keyword);
    }

    return selectKeywordsHandler([...unselectedKeywords]);
  };

  const selectRecommendedKeywordsHandler = async (): Promise<void> => {
    if (!search) return;

    try {
      setKeywordsSelectLoadingStatus("loading");

      const keywords = await getSearchSelectedKeywords(search.id);

      const selectedKeywords = new Map<Keyword, true>();

      for (const keyword of keywords) selectedKeywords.set(keyword, true);

      setSelectedKeywords(Object.fromEntries(selectedKeywords));

      setAutoSelectedKeywords(Object.fromEntries(selectedKeywords));

      setKeywordsSelectLoadingStatus("succeeded");
    } catch (error) {
      console.error(error);

      setKeywordsSelectLoadingStatus("failed");

      showToastNotification({
        type: "error",
        text: t("common.error.server_error"),
      });
    }
  };

  const saveSelectedKeywordsHandler = async (): Promise<void> => {
    if (isUpdateKeywordsDisabled) return;

    try {
      setUpdateLoadingStatus("loading");

      await dispatch(
        updateSearchConfiguration({
          id: search.id,
          changes: { keywords: Object.keys(selectedKeywords) },
        }),
      ).unwrap();

      const trackersCollectionsPayload = trackersCollections.map(({ id }) => ({
        id,
        changes: {},
      }));

      const trackersPayload = trackers.map(({ id }) => ({ id, changes: {} }));

      const searchPayload: Store.UpdateEntity<Search.Data> = {
        id: search.id,
        changes: { status: "READY" },
      };

      await Promise.all([
        dispatch(updateSearch(searchPayload)),
        dispatch(updateTrackers(trackersPayload)),
        dispatch(
          updateTrackersCollections(trackersCollectionsPayload),
        ).unwrap(),
      ]);

      showToastNotification({
        type: "success",
        text: t("component.keywords_table.status.success.keywords_updated"),
      });

      setUpdateLoadingStatus("succeeded");

      closeSidebarHandler();
    } catch (error) {
      console.error(error);

      setUpdateLoadingStatus("failed");

      showToastNotification({
        type: "error",
        text: t("common.error.server_error"),
      });
    }
  };

  return (
    <div className={styles.wrapper}>
      <SelectedSearch search={search} />
      <div className={styles.keywordsTable}>
        <KeywordsControl
          hasDuplicates={hasDuplicates}
          keywordsSearch={keywordsSearch}
          selectedKeywords={selectedKeywords}
          isKeywordsLoading={isKeywordsLoading}
          setKeywordsSearch={setKeywordsSearch}
          isKeywordsPending={isKeywordsPending}
          autoSelectedKeywords={autoSelectedKeywords}
          isKeywordsExactMatch={isKeywordsExactMatch}
          setIsKeywordsExactMatch={setIsKeywordsExactMatch}
          isDuplicatedKeywordsShown={isDuplicatedKeywordsShown}
          keywordsSelectLoadingStatus={keywordsSelectLoadingStatus}
          setIsDuplicatedKeywordsShown={setIsDuplicatedKeywordsShown}
          selectRecommendedKeywordsHandler={selectRecommendedKeywordsHandler}
        />
        <KeywordsTable
          searchId={search.id}
          data={keywordsTableData}
          keywords={keywords}
          searchedKeywords={searchedKeywords}
          selectedKeywords={selectedKeywords}
          isKeywordsLoading={isKeywordsLoading}
          isKeywordsPending={isKeywordsPending}
          isSelectAllDisabled={isSelectAllDisabled}
          keywordsSelectStatus={keywordsSelectStatus}
          isDuplicatedKeywordsShown={isDuplicatedKeywordsShown}
          selectAllKeywordsHandler={selectAllKeywordsHandler}
          selectKeywordsHandler={selectKeywordsHandler}
        />
        <div className={styles.buttonsWrapper}>
          <Button
            buttonStyle="outlined"
            className={styles.button}
            onClick={closeSidebarHandler}
          >
            {t("component.keywords_table.button.cancel")}
          </Button>
          <Button
            className={styles.button}
            onClick={saveSelectedKeywordsHandler}
            disabled={isUpdateKeywordsDisabled}
          >
            {isUpdateLoading ? (
              <Loader className={styles.loader} />
            ) : (
              t("component.keywords_table.button.submit")
            )}
          </Button>
        </div>
      </div>
    </div>
  );
};
