import { FC, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { useModal } from "src/hooks";
import { useAppDispatch } from "src/store";
import { showToastNotification } from "src/utils";
import { selectUserId } from "src/store/selectors";
import { getMemberName } from "src/pages/Settings/Members/utils";
import {
  removeCompanyMember,
  updateEventsByAuthorId,
  updateSearchesByAuthorId,
  updateTrackersByAuthorId,
  updateDashboardsByAuthorId,
  updateDashboardDateRangesByAuthorId,
  updateTrackersCollectionsByAuthorId,
} from "src/store/actions";
import { ConfirmModal } from "../ConfirmModal/ConfirmModal";

type Props = {
  member: Company.Member;
};

export const ConfirmRemoveMemberModal: FC<Props> = ({ member }) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const { closeModal } = useModal();

  const userId = useSelector(selectUserId);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const memberName = useMemo<string>(() => getMemberName(member), [member]);

  const removeMember = async (): Promise<void> => {
    try {
      setIsLoading(true);

      const entityUpdates = {
        authorId: member.id,
        changes: { authorId: userId },
      };

      await Promise.all([
        dispatch(updateEventsByAuthorId(entityUpdates)).unwrap(),
        dispatch(updateSearchesByAuthorId(entityUpdates)).unwrap(),
        dispatch(updateTrackersByAuthorId(entityUpdates)).unwrap(),
        dispatch(updateDashboardsByAuthorId(entityUpdates)).unwrap(),
        dispatch(updateTrackersCollectionsByAuthorId(entityUpdates)).unwrap(),
        dispatch(updateDashboardDateRangesByAuthorId(entityUpdates)).unwrap(),
      ]);

      await dispatch(removeCompanyMember(member.id)).unwrap();
    } catch (error) {
      console.error(error);

      showToastNotification({
        type: "error",
        text: t("common.error.server_error"),
      });
    } finally {
      setIsLoading(false);

      closeModal("confirm-remove-member");
    }
  };

  return (
    <ConfirmModal
      id="confirm-remove-member"
      type="warning"
      title={t("component.modal.delete_company_member.title", {
        name: memberName,
      })}
      isLoading={isLoading}
      acceptButton={{
        text: t("component.modal.delete_company_member.button.submit"),
        buttonType: "error",
        onClick: removeMember,
      }}
      cancelButton={{
        text: t("component.modal.delete_company_member.button.cancel"),
        onClick: () => closeModal("confirm-remove-member"),
      }}
    >
      <span>{t("component.modal.delete_company_member.body")}</span>
    </ConfirmModal>
  );
};
