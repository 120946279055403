import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";

import * as api from "./searchesConfigurationApi";

export const searchesConfigurationAdapter =
  createEntityAdapter<Search.Configuration>({
    sortComparer: (a, b) => a.id.localeCompare(b.id),
  });

const initialState =
  searchesConfigurationAdapter.getInitialState<Store.InitialState>({
    status: "idle",
    error: null,
  });

export const fetchTrackerSearchesConfiguration = createAsyncThunk<
  Search.Configuration[],
  Search.Data["id"][]
>("searches-configuration/fetch-by-ids", api.getTrackerSearchesConfiguration);

export const fetchSearchConfigurationById = createAsyncThunk<
  Search.Configuration,
  Search.Data["id"]
>("searches-configuration/fetch-by-id", api.getSearchConfigurationById);

export const updateSearchConfiguration = createAsyncThunk<
  Store.UpdateEntity<Search.Configuration>,
  Store.UpdateEntity<Search.Configuration>
>("searches-configuration/update-one", async (payload) => {
  await api.updateSearchConfiguration(payload);

  return payload;
});

const searchesConfiguration = createSlice({
  name: "searches-configuration",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      fetchTrackerSearchesConfiguration.fulfilled,
      searchesConfigurationAdapter.addMany,
    );

    builder.addCase(
      fetchSearchConfigurationById.fulfilled,
      searchesConfigurationAdapter.addOne,
    );

    builder.addCase(
      updateSearchConfiguration.fulfilled,
      searchesConfigurationAdapter.updateOne,
    );
  },
});

export default searchesConfiguration.reducer;
