import { useCallback, useEffect, useState } from "react";

import { useAppDispatch } from "src/store";
import { updateKeywordToolSettings } from "src/store/actions";
import { subscribeOnKeywordToolSettings } from "src/store/application/applicationApi";

export const useKeywordToolSettingsObserver = () => {
  const dispatch = useAppDispatch();

  const [isObserverSet, setIsObserverSet] = useState<boolean>(false);

  const keywordToolSettingsCallback = useCallback(
    (data: Application.KeywordsSettings): void => {
      dispatch(updateKeywordToolSettings(data));

      setIsObserverSet(true);
    },
    [dispatch],
  );

  useEffect(() => {
    let observer = () => {};

    try {
      observer = subscribeOnKeywordToolSettings(keywordToolSettingsCallback);
    } catch (err) {
      console.error(err);
    }

    return () => {
      observer();
    };
  }, [keywordToolSettingsCallback]);

  return { isObserverSet };
};
