import { useMemo } from "react";
import { useSelector } from "react-redux";

import { MarkdownChart } from "src/components";
import { selectWidgetData } from "src/store/selectors";

// Inner imports
import { WIDGET_ID } from "../constants";

export const useInsightsCharts = ({
  dashboardDateRangeId,
}: Widget.ChartProps): Widget.Chart[] => {
  const widgetData = useSelector((state: Store.RootState) =>
    selectWidgetData(state, dashboardDateRangeId, WIDGET_ID),
  );

  const value = useMemo<string>(() => {
    if (!widgetData?.data) return "";

    const { customPrompt, aiPrompt } = widgetData.data;

    return customPrompt.value || aiPrompt.value;
  }, [widgetData?.data]);

  return useMemo<Widget.Chart[]>(() => {
    const charts: Widget.Chart[] = [];

    const hasText = Boolean(value);

    if (hasText)
      charts.push({
        type: "text",
        chart: <MarkdownChart value={value} />,
      });

    return charts;
  }, [value]);
};
