import * as yup from "yup";

import { SEARCH_KEYWORDS_DATA_SOURCES } from "src/constants";

type YupKeywordsDataSourceSettingsType = yup.ObjectSchema<
  Application.KeywordsDataSourceSettings,
  Record<string, any>,
  Application.KeywordsDataSourceSettings,
  "d"
>;

const keywordsSettingsMap = SEARCH_KEYWORDS_DATA_SOURCES.reduce<
  Record<Search.KeywordsDataSource, YupKeywordsDataSourceSettingsType>
>((acc, value) => {
  acc[value] = yup
    .object({
      date: yup.string().default(""),
      dateUpdatedAt: yup.string().default(""),
    })
    .default({ date: "", dateUpdatedAt: "" });

  return acc;
}, {} as Record<Search.KeywordsDataSource, YupKeywordsDataSourceSettingsType>);

export const versionInfoSchema = yup
  .object({
    version: yup.string().required(),
    updatedAt: yup.string().required(),
  })
  .noUnknown()
  .required();

export const keywordsSettingsSchema = yup
  .object({
    latestKeywordsDateMap: yup.object(keywordsSettingsMap),
  })
  .noUnknown()
  .required();

export const hardReloadSchema = yup
  .object({
    updatedAt: yup.string().default(""),
  })
  .noUnknown()
  .required();
