import { forwardRef, HTMLProps, useMemo } from "react";
import isString from "lodash/isString";

import styles from "./DateSelectorInput.module.scss";
import { formatToDayMonthFullYearDate } from "src/utils";
import { InputWithIcon } from "../../../inputs/InputWithIcon/InputWithIcon";

type Props = HTMLProps<HTMLDivElement>;

export const DateSelectorInput = forwardRef<HTMLDivElement, Props>(
  ({ onClick, onChange, placeholder, value }, ref) => {
    const formattedValue = useMemo<string>(() => {
      if (!isString(value)) return "";

      return formatToDayMonthFullYearDate(value);
    }, [value]);

    return (
      <div
        className={styles.wrapper}
        onClick={onClick}
        onChange={onChange}
        ref={ref}
      >
        <InputWithIcon
          inputClassName={styles.input}
          value={formattedValue}
          icon="CalendarOutline"
          placeholder={placeholder}
          readOnly
        />
      </div>
    );
  },
);
