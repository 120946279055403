import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { ROUTES } from "src/constants";
import { Translation } from "src/components";
import { showToastNotification } from "src/utils";
import {
  selectTrackersLimit,
  selectUserPermissions,
  selectHasTrackerAccess,
  selectCompanyOwnerEmail,
} from "src/store/selectors";

export const useTrackerBlocker = () => {
  const { t } = useTranslation();

  const history = useHistory();

  const { isTrackersLimitExceeded, trackersLimit } =
    useSelector(selectTrackersLimit);

  const { hasTrackerAccess } = useSelector(selectHasTrackerAccess);

  const companyOwnerEmail = useSelector(selectCompanyOwnerEmail);

  const { isUserCompanyOwnerOrAdmin } = useSelector(selectUserPermissions);

  const isTrackerBlocked = useMemo<boolean>(
    () => !hasTrackerAccess || isTrackersLimitExceeded,
    [hasTrackerAccess, isTrackersLimitExceeded],
  );

  const showContactCompanyOwnerNotification = (): void => {
    const localizationKey = companyOwnerEmail
      ? "component.header.status.info.contact_company_owner"
      : "component.header.status.info.contact_support";

    return showToastNotification({
      type: "info",
      id: "subscription-plan-update",
      text: (
        <Translation
          i18nKey={localizationKey}
          values={{ email: companyOwnerEmail }}
        />
      ),
    });
  };

  const showTrackerLimitNotification = (): void => {
    if (!isUserCompanyOwnerOrAdmin)
      return showContactCompanyOwnerNotification();

    return showToastNotification({
      id: "tracker-limit",
      type: "warning",
      closeButton: {
        buttonType: "success",
        text: t("tracker.status.warning.button.upgrade"),
        onClick: () => history.push(`${ROUTES.settingsBillingPage}`),
      },
      text: (
        <Translation
          values={{ count: trackersLimit }}
          i18nKey="tracker.status.warning.tracker_limit"
        />
      ),
    });
  };

  const showSubscriptionPlanLimitNotification = (): void => {
    if (!isUserCompanyOwnerOrAdmin)
      return showContactCompanyOwnerNotification();

    return showToastNotification({
      type: "warning",
      id: "subscription-plan-limit",
      closeButton: {
        buttonType: "success",
        text: t("tracker.status.warning.button.upgrade"),
        onClick: () => history.push(`${ROUTES.settingsBillingPage}`),
      },
      text: (
        <Translation i18nKey="tracker.status.warning.tracker_unavailable" />
      ),
    });
  };

  const showTrackerBlockerNotification = (): void => {
    if (!hasTrackerAccess) return showSubscriptionPlanLimitNotification();

    if (isTrackersLimitExceeded) return showTrackerLimitNotification();
  };

  return {
    hasTrackerAccess,
    isTrackerBlocked,
    isTrackersLimitExceeded,
    showTrackerLimitNotification,
    showTrackerBlockerNotification,
    showSubscriptionPlanLimitNotification,
  };
};
