import { FC } from "react";

import * as icons from "src/assets/icons";

// Inner imports
import type { TableItemGenericProps } from "../../types";
import type { TableCellProps } from "./types";

export const getTableColumnProps = <T extends TableItemGenericProps>({
  item,
  tableColumn: { dataKey, key, icon, checkIsClickable, onClick },
}: Pick<TableCellProps<T>, "item" | "tableColumn">): {
  title: string;
  value: ValueOf<T>;
  Icon: FC | null;
  isClickable: boolean;
} => {
  const [value, titleValue] = [item[dataKey || key], item[key]];

  const title = typeof titleValue === "string" ? titleValue : "";

  const Icon = icon ? icons[icon] : null;

  const isClickable = Boolean(
    onClick && (checkIsClickable ? checkIsClickable(item) : true),
  );

  return {
    title,
    value,
    Icon,
    isClickable,
  };
};
