import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Redirect, useHistory, useParams } from "react-router-dom";

import styles from "./ShareDashboard.module.scss";
import { ROUTES } from "src/constants";
import { useAppDispatch } from "src/store";
import { triggerGtmEvent } from "src/utils";
import { selectAvailableDashboardById } from "src/store/selectors";
import { useGlobalPreloader, useQueryParamsState } from "src/hooks";
import {
  getDashboardDataById,
  parseDashboardShareLinkToken,
} from "src/store/dashboards/dashboardsApi";
import {
  fetchEventsByIds,
  fetchTrackersByIds,
  fetchDashboardById,
  fetchExternalCompanyById,
  fetchTrackersCollectionById,
  fetchDashboardDateRangesByIds,
  fetchSubscriptionPlanByCompanyId,
} from "src/store/actions";
import DashboardPage from "../../Dashboards/DashboardPage/DashboardPage";

const ShareDashboard: FC = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const history = useHistory();

  const { showGlobalPreloader, hideGlobalPreloader } = useGlobalPreloader();

  const [{ token = "" }] = useQueryParamsState();

  const { dashboardId: defaultDashboardId = "" } = useParams<UrlParams>();

  const [dashboardId, setDashboardId] =
    useState<Dashboard.Data["id"]>(defaultDashboardId);

  const [isUpdateAllowed, setIsUpdateAllowed] = useState<boolean>(false);

  const dashboard = useSelector((state: Store.RootState) =>
    selectAvailableDashboardById(state, dashboardId, true),
  );

  const initialLoadingStatus = useMemo<LoadingStatus>(
    () => (dashboard ? "succeeded" : "idle"),
    [dashboard],
  );

  const [loadingStatus, setLoadingStatus] =
    useState<LoadingStatus>(initialLoadingStatus);

  const isLoading = useMemo<boolean>(
    () => loadingStatus === "idle" || loadingStatus === "loading",
    [loadingStatus],
  );

  const fetchDashboardData = useCallback(
    (dashboardId: Dashboard.Data["id"]): Promise<void> =>
      getDashboardDataById(dashboardId)
        .then(({ eventIds, dashboardDateRangeIds, trackerIds }) =>
          dispatch(fetchDashboardById(dashboardId))
            .unwrap()
            .then(async ({ companyId }) => {
              await Promise.all([
                dispatch(fetchEventsByIds(eventIds)).unwrap(),
                dispatch(fetchTrackersByIds(trackerIds)).unwrap(),
                dispatch(fetchExternalCompanyById(companyId)).unwrap(),
                dispatch(fetchTrackersCollectionById(dashboardId)).unwrap(),
                dispatch(fetchSubscriptionPlanByCompanyId(companyId)).unwrap(),
                dispatch(
                  fetchDashboardDateRangesByIds(dashboardDateRangeIds),
                ).unwrap(),
              ]);
            })
            .then(() => {
              triggerGtmEvent("SharedDashboardView", { dashboardId });

              setLoadingStatus("succeeded");
            })
            .catch((error) => {
              console.error(error);

              setLoadingStatus("failed");
            })
            .finally(hideGlobalPreloader),
        )
        .catch((error) => {
          console.error(error);

          setLoadingStatus("failed");
        })
        .finally(hideGlobalPreloader),
    [dispatch, hideGlobalPreloader],
  );

  const fetchDashboardDataWithToken = useCallback(
    (token: string): Promise<void> =>
      parseDashboardShareLinkToken(token)
        .then(({ dashboardId, dashboardDateRangeId, isUpdateAllowed }) => {
          setDashboardId(dashboardId);

          setIsUpdateAllowed(isUpdateAllowed);

          const queryParams = new URLSearchParams({
            dateRangeId: dashboardDateRangeId,
          }).toString();

          history.push(
            `${ROUTES.shareDashboard}/${dashboardId}?${queryParams}`,
          );

          fetchDashboardData(dashboardId).catch();
        })
        .catch((error) => {
          console.error(error);

          setLoadingStatus("failed");
        }),
    [fetchDashboardData, history],
  );

  useEffect(() => {
    if (!dashboardId && !token) return;

    if (dashboard || loadingStatus !== "idle") return;

    setLoadingStatus("loading");

    showGlobalPreloader(
      t("page.explore_trend.suggested_dashboard.loader.download_dashboard"),
    );

    if (dashboardId) fetchDashboardData(dashboardId).catch();
    else if (token) fetchDashboardDataWithToken(token).catch();
  }, [
    t,
    token,
    dashboard,
    dashboardId,
    loadingStatus,
    fetchDashboardData,
    showGlobalPreloader,
    fetchDashboardDataWithToken,
  ]);

  if (isLoading) return null;

  if (
    loadingStatus === "failed" ||
    (loadingStatus === "succeeded" && !dashboard)
  )
    return <Redirect to={ROUTES.dashboardsHomePage} />;

  return (
    <div className={styles.wrapper}>
      <DashboardPage
        isReadOnly
        dashboardId={dashboardId}
        isUpdateAllowed={isUpdateAllowed}
      />
    </div>
  );
};

export default ShareDashboard;
