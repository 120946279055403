import { FC, useMemo, useState } from "react";
import cx from "classnames";

import styles from "./CustomPerspective.module.scss";
import { useModal } from "src/hooks";
import { Add, PencilOutline } from "src/assets/icons";
import { Button } from "src/components";
import { SelectTrackerPerspectiveModal } from "src/features";

type Props = {
  isDisabled?: boolean;
  selectedPerspective: Tracker.Perspective;
  suggestedPerspectives: Tracker.Perspective[];
  selectPerspectiveHandler: (value: Tracker.Perspective) => void;
};

export const CustomPerspective: FC<Props> = ({
  isDisabled = false,
  selectedPerspective,
  suggestedPerspectives,
  selectPerspectiveHandler,
}) => {
  const { setModal } = useModal();

  const [customPerspective, setCustomPerspective] =
    useState<Tracker.Perspective>("");

  const hasCustomPerspective = useMemo<boolean>(
    () => Boolean(customPerspective),
    [customPerspective],
  );

  const isSelected = useMemo<boolean>(
    () => hasCustomPerspective && customPerspective === selectedPerspective,
    [customPerspective, hasCustomPerspective, selectedPerspective],
  );

  const onSubmit = (value: Tracker.Perspective): void => {
    const isSuggestedPerspective = suggestedPerspectives.includes(value);

    if (isSuggestedPerspective) setCustomPerspective("");
    else setCustomPerspective(value);

    selectPerspectiveHandler(value);
  };

  const onClick = (): void =>
    setModal(
      "select-tracker-perspective",
      <SelectTrackerPerspectiveModal
        perspective={customPerspective}
        submitHandler={onSubmit}
      />,
    );

  return (
    <Button
      type="button"
      onClick={onClick}
      disabled={isDisabled}
      className={cx(
        styles.wrapper,
        isSelected ? styles.wrapperActive : "",
        hasCustomPerspective ? "" : styles.wrapperEmpty,
      )}
      buttonType="secondary"
      shouldPreventEnter
    >
      {customPerspective ? (
        <>
          <span className={styles.perspective}>{customPerspective}</span>
          <PencilOutline />
        </>
      ) : (
        <Add />
      )}
    </Button>
  );
};
