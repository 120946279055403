import { FC, useContext, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";

import styles from "./Share.module.scss";
import Context from "src/context";
import { ROUTES } from "src/constants";
import { ShareHeader } from "src/features";
import { Preloader } from "src/components";
import { useAppDispatch } from "src/store";
import { logOutUser } from "src/store/actions";
import { checkIsWhiteLabelUser } from "src/store/user/userApi";
import { useKeywordToolSettingsObserver } from "src/app/hooks";
import { selectUserId, selectWhiteLabel } from "src/store/selectors";
import ShareDashboard from "src/pages/Share/ShareDashboard/ShareDashboard";
import {
  useUserDataLoader,
  useWidgetsDataLoader,
  useCompanyDataLoader,
  useWhiteLabelDataLoader,
  useSubscriptionPlanLoader,
} from "../Home/hooks";

const Share: FC = () => {
  const dispatch = useAppDispatch();

  const userId = useSelector(selectUserId);

  const whiteLabel = useSelector(selectWhiteLabel);

  const { isUserAuthenticated } = useContext(Context);

  const { loadingStatus: whiteLabelLoadingStatus } = useWhiteLabelDataLoader();

  const { loadingStatus: widgetsDataLoadingStatus } = useWidgetsDataLoader();

  const { loadingStatus: userDataLoadingStatus } = useUserDataLoader();

  const { loadingStatus: companyDataLoadingStatus } = useCompanyDataLoader();

  const { loadingStatus: subscriptionPlanLoadingStatus } =
    useSubscriptionPlanLoader();

  const { isObserverSet: isKeywordToolSettingsObserverSet } =
    useKeywordToolSettingsObserver();

  useEffect(() => {
    if (!userId || !whiteLabel.id) return;

    checkIsWhiteLabelUser(whiteLabel.id, userId).then((isWhiteLabelUser) => {
      if (!isWhiteLabelUser) dispatch(logOutUser()).unwrap().catch();
    });
  }, [dispatch, userId, whiteLabel]);

  const isMainDataLoaded = useMemo<boolean>(() => {
    if (
      whiteLabelLoadingStatus !== "succeeded" ||
      widgetsDataLoadingStatus !== "succeeded"
    )
      return false;

    if (!isUserAuthenticated) return true;

    return (
      userDataLoadingStatus === "succeeded" &&
      companyDataLoadingStatus === "succeeded" &&
      subscriptionPlanLoadingStatus === "succeeded" &&
      isKeywordToolSettingsObserverSet
    );
  }, [
    isUserAuthenticated,
    userDataLoadingStatus,
    whiteLabelLoadingStatus,
    widgetsDataLoadingStatus,
    companyDataLoadingStatus,
    subscriptionPlanLoadingStatus,
    isKeywordToolSettingsObserverSet,
  ]);

  if (!isMainDataLoaded)
    return <Preloader modifier="global" className={styles.initialPreloader} />;

  return (
    <div className={styles.wrapper}>
      <ShareHeader />
      <div className={styles.content}>
        <Switch>
          <Route
            path={[ROUTES.shareDashboardPage, ROUTES.shareDashboardPageNew]}
          >
            <ShareDashboard />
          </Route>
          <Redirect to="/" />
        </Switch>
      </div>
    </div>
  );
};

export default Share;
