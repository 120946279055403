import { FC, useMemo } from "react";
import cx from "classnames";

import styles from "./TrackerChartIcon.module.scss";
import { CHART_COLORS, LIGHT_BLUE } from "src/constants";

type Props = {
  color?: string;
  className?: string;
  trackerId?: Tracker.Data["id"];
  trackerIds?: TrackersCollection.Data["trackerIds"];
};

export const TrackerChartIcon: FC<Props> = ({
  className,
  trackerId,
  trackerIds,
  color: defaultColor,
}) => {
  const color = useMemo<string>(() => {
    if (defaultColor) return defaultColor;

    if (trackerId && trackerIds) {
      const index = trackerIds.indexOf(trackerId);

      if (index < 0) return LIGHT_BLUE;

      return CHART_COLORS[index] || LIGHT_BLUE;
    }

    return LIGHT_BLUE;
  }, [defaultColor, trackerId, trackerIds]);

  return (
    <div className={cx(styles.wrapper, className)}>
      <span style={{ color, backgroundColor: color, borderColor: color }} />
    </div>
  );
};
