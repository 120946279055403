export const WIDGET_SYNC_STATUSES = [
  "INITIAL",
  "NEW",
  "RUNNING",
  "FINISHED",
  "ERROR",
  "CANCELED",
] as const;

export const WIDGET_SYNC_ID_DIVIDER: string = "_";
