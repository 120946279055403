import { FC, useMemo, useState } from "react";
import cx from "classnames";

import styles from "./Labels.module.scss";
import { Button } from "src/components";
import { ChevronRight, ChevronDown } from "src/assets/icons";
import { TrackerChartIcon, TrackerKeywordsDataSourcesIcon } from "src/features";

// Inner imports
import { ChartLabel } from "../../types";

type Props = {
  labels?: readonly ChartLabel[];
};

export const Labels: FC<Props> = ({ labels = [] }) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const [isExpandAvailable, setIsExpandAvailable] = useState<boolean>(false);

  const isExpandShown = useMemo<boolean>(
    () => isExpandAvailable || isExpanded,
    [isExpandAvailable, isExpanded],
  );

  const toggleIsExpanded = (): void => setIsExpanded((state) => !state);

  const refCallback = (element: HTMLElement | null): void => {
    const childElement = element?.children[0];

    if (element === null || !childElement) return;

    const [
      { clientHeight, scrollHeight },
      { clientHeight: childClientHeight },
    ] = [element, childElement];

    const isElementOverflown = clientHeight < scrollHeight;

    if (clientHeight === childClientHeight && isExpanded) setIsExpanded(false);

    setIsExpandAvailable(isElementOverflown);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.actions}>
        {isExpandShown && (
          <Button
            buttonStyle="transparent"
            className={styles.button}
            onClick={toggleIsExpanded}
          >
            {isExpanded ? <ChevronDown /> : <ChevronRight />}
          </Button>
        )}
      </div>
      <div
        ref={refCallback}
        className={cx(styles.labels, isExpanded ? styles.labelsExpanded : "")}
      >
        {labels.map(({ value, label, color }) => (
          <div key={value} className={styles.label}>
            <TrackerChartIcon color={color} trackerId={value} />
            <div className={styles.labelName}>{label}</div>
            <TrackerKeywordsDataSourcesIcon trackerId={value} />
          </div>
        ))}
      </div>
    </div>
  );
};
