import add from "date-fns/add";
import sub from "date-fns/sub";
import format from "date-fns/format";
import endOfMonth from "date-fns/endOfMonth";
import isSameMonth from "date-fns/isSameMonth";
import startOfMonth from "date-fns/startOfMonth";
import lastDayOfMonth from "date-fns/lastDayOfMonth";
import formatDistanceToNow from "date-fns/formatDistanceToNow";

export const formatToMonthDayYearDate = (
  date: string | number | Date,
): string => formatDate(date, "MMM dd, yy");

export const formatToMonthDayFullYearDate = (
  date: string | number | Date,
): string => formatDate(date, "MMM dd, yyyy");

export const formatToDayMonthFullYearDate = (
  date: string | number | Date,
): string => formatDate(date, "dd.MM.yyyy");

export const formatToDayMonthYearDate = (
  date: string | number | Date,
): string => formatDate(date, "dd.MM.yy");

export const formatToMonthYearDate = (date: string | number | Date): string =>
  formatDate(date, "MMM, yy");

export const formatToMonthFullYearDate = (
  date: string | number | Date,
): string => formatDate(date, "MMM, yyyy");

export const formatToDayMonthFullYearTimeDate = (
  date: string | number | Date,
): string => formatDate(date, "dd.MM.yyyy, HH:mm");

export const formatToYearMonth = (date: string | number | Date): string =>
  formatDate(date, "yyyy-MM");

export const formatToYearMonthDay = (date: string | number | Date): string =>
  formatDate(date, "yyyy-MM-dd");

export const formatToLastMonthDay = (date: string | number | Date): string => {
  try {
    return lastDayOfMonth(new Date(date)).toISOString();
  } catch (error) {
    console.error(error);

    return "";
  }
};

export const formatToUniversalTime = (
  _date: string | number | Date,
): string => {
  try {
    const date = new Date(_date);

    return date.toString();
  } catch (error) {
    console.error(error);

    return "";
  }
};

export const formatToDistanceToNow = (
  _date: string | number | Date,
): string => {
  try {
    const date = new Date(_date);

    return formatDistanceToNow(date, { addSuffix: true });
  } catch (err) {
    console.error(err);

    return "";
  }
};

export const formatStringToDate = (
  value?: string | null,
  defaultValue: Date | null = null,
): Date | null => {
  if (!value) return defaultValue;

  try {
    return new Date(value);
  } catch (error) {
    console.error(error);

    return defaultValue;
  }
};

export const cleanTimeFromDate = (date: string | number | Date): string =>
  formatDate(date, "yyyy-MM-dd");

export const checkIsSameMonth = (
  firstDate: string | number | Date,
  secondDate: string | number | Date,
): boolean => {
  try {
    const [formattedFirstDate, formattedSecondDate] = [
      new Date(firstDate),
      new Date(secondDate),
    ];

    return isSameMonth(formattedFirstDate, formattedSecondDate);
  } catch (error) {
    console.error(error);

    return false;
  }
};

export const adjustDateToUTC = (date: string | number | Date): Date | null => {
  try {
    let formattedDate = new Date(date);

    const timezoneOffset = formattedDate.getTimezoneOffset();

    if (timezoneOffset > 0)
      formattedDate = add(formattedDate, { minutes: timezoneOffset });

    if (timezoneOffset < 0)
      formattedDate = sub(formattedDate, { minutes: timezoneOffset });

    return formattedDate;
  } catch (error) {
    console.error(error);

    return null;
  }
};

export const getMonthStartDate = (
  date: string | number | Date,
): Date | null => {
  try {
    const formattedDate = new Date(date);

    const formattedStartOfMonth = startOfMonth(formattedDate);

    return adjustDateToUTC(formattedStartOfMonth);
  } catch (error) {
    console.error(error);

    return null;
  }
};

export const getMonthEndDate = (date: string | number | Date): Date | null => {
  try {
    const formattedDate = new Date(date);

    const formattedEndOfMonth = endOfMonth(formattedDate);

    return adjustDateToUTC(formattedEndOfMonth);
  } catch (error) {
    console.error(error);

    return null;
  }
};

function formatDate(_date: string | number | Date, template: string): string {
  try {
    const date = new Date(_date);

    return format(date, template);
  } catch (err) {
    console.error(err);

    return "";
  }
}
