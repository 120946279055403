import { ReactNode, ReactChild, useState } from "react";
import { useImmer } from "use-immer";

import { useAuthObserver } from "src/app/hooks";
import { ModalId } from "src/features/Modals/ConfirmModal/types";

// Inner imports
import Context from "./index";

type IProps = {
  children: ReactChild;
};

const ContextProvider = ({ children }: IProps) => {
  const [modalElements, setModalElements] = useImmer<
    Partial<Record<ModalId, ReactNode | null>>
  >({});

  const [isGlobalPreloaderShown, setIsGlobalPreloaderShown] =
    useState<boolean>(false);

  const [globalPreloaderText, setGlobalPreloaderText] = useState<string>("");

  const { isObserverSet: isAuthenticationObserverSet, isUserAuthenticated } =
    useAuthObserver();

  const _setModalElements = (id: ModalId, element: ReactNode | null) =>
    setModalElements((draft) => {
      if (element) draft[id] = element;
      else delete draft[id];
    });

  return (
    <Context.Provider
      value={{
        modalElements,
        setModalElements: _setModalElements,
        globalPreloaderText,
        setGlobalPreloaderText,
        isGlobalPreloaderShown,
        setIsGlobalPreloaderShown,
        isUserAuthenticated,
        isAuthenticationObserverSet,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default ContextProvider;
