export const EVENT_INPUT_LIMIT: number = 150;

export const EVENT_TYPES = ["single", "range"] as const;

export const EVENT_DEFAULT_TYPE: Event.Type = "single";

export const EVENT_TYPE_OPTIONS: Option<Event.Type>[] = [
  {
    label: "event.label.date_range_type_single",
    value: "single",
  },
  {
    label: "event.label.date_range_type_period",
    value: "range",
  },
];
