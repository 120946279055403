import { useCallback, useMemo, useState } from "react";
import { useImmer } from "use-immer";
import { ReferenceArea } from "recharts";
import { useSelector } from "react-redux";
import { CategoricalChartState } from "recharts/types/chart/types";

import { isNumber } from "src/utils";
import { LIGHT_BLUE } from "src/constants";
import { selectIsCompanyTrackersCollection } from "src/store/selectors";

// Inner imports
import { LineChartSelection } from "../types";

export const useLineChartSelection = ({
  minValue,
  maxValue,
  callback,
  trackersCollectionId,
}: {
  minValue?: number;
  maxValue?: number;
  callback?: (value: LineChartSelection) => void;
  trackersCollectionId: TrackersCollection.Data["id"];
}) => {
  const isCompanyTrackersCollection = useSelector((state: Store.RootState) =>
    selectIsCompanyTrackersCollection(state, trackersCollectionId),
  );

  const [selection, setSelection] = useImmer<LineChartSelection>({
    start: null,
    end: null,
  });

  const [isHovered, setIsHovered] = useState<boolean>(false);

  const isSelectionAvailable = useMemo<boolean>(
    () => isCompanyTrackersCollection,
    [isCompanyTrackersCollection],
  );

  const isSelectionActive = useMemo<boolean>(
    () => isSelectionAvailable && Boolean(selection.start || selection.end),
    [isSelectionAvailable, selection.start, selection.end],
  );

  const checkIsValueInRange = useCallback(
    (value: number): boolean => {
      if (minValue && value < minValue) return false;

      if (maxValue && value > maxValue) return false;

      return true;
    },
    [maxValue, minValue],
  );

  const onMouseUp = useCallback((): void => {
    if (!isSelectionAvailable) return;

    if (selection.start && selection.end && selection.start !== selection.end)
      callback?.(selection);

    setSelection((draft) => {
      draft.start = null;
      draft.end = null;
    });
  }, [isSelectionAvailable, callback, selection, setSelection]);

  const onMouseDown = useCallback(
    (event: CategoricalChartState): void => {
      if (!isSelectionAvailable) return;

      const start = Number(event.activeLabel);

      const isValidValue = isNumber(start) && checkIsValueInRange(start);

      if (!start || !isValidValue || !start) return;

      setSelection((draft) => {
        draft.start = start;
        draft.end = null;
      });
    },
    [isSelectionAvailable, checkIsValueInRange, setSelection],
  );

  const onMouseMove = useCallback(
    (event: CategoricalChartState): void => {
      if (!isSelectionAvailable) return;

      const end = Number(event.activeLabel);

      const isValidValue = isNumber(end) && checkIsValueInRange(end);

      setIsHovered(isValidValue);

      if (!selection.start || !isValidValue || !end) return;

      setSelection((draft) => {
        draft.end = end;
      });
    },
    [isSelectionAvailable, checkIsValueInRange, selection.start, setSelection],
  );

  const SelectionElement = useMemo<JSX.Element | null>(() => {
    if (!isSelectionAvailable || !selection.start || !selection.end)
      return null;

    return (
      <ReferenceArea
        opacity={0.6}
        fill={LIGHT_BLUE}
        x1={selection.start}
        x2={selection.end}
        pointerEvents="none"
      />
    );
  }, [isSelectionAvailable, selection.start, selection.end]);

  return {
    onMouseUp,
    isHovered,
    onMouseDown,
    onMouseMove,
    SelectionElement,
    isSelectionActive,
    isSelectionAvailable,
  };
};
