export const formatSearchKey = ({
  subject,
  locationId,
  languageId,
  description,
}: Search.CreationData): string =>
  `${subject.toLowerCase()}_${description.toLowerCase()}_${locationId}_${languageId}`;

export const formatTrackerKey = ({
  name,
  locationId,
  languageId,
}: Tracker.CreationData): string =>
  `${name.toLowerCase()}_${locationId}_${languageId}`;

export const parseTrackerKey = (
  trackerKey: string,
): Pick<Tracker.CreationData, "name" | "locationId" | "languageId"> => {
  const [name = "", locationId = "", languageId = ""] = trackerKey.split("_");

  return { name, locationId, languageId };
};

export const formatTrackerCategoryKey = ({
  subject,
  category,
}: Tracker.Category): string =>
  `${subject.toLowerCase()}_${category.toLowerCase()}`;

export const formatArticleKey = (
  { title, url, date }: Widgets.ArticlesChart.Article,
  index: number = 0,
): string => `${title}_${url}_${date}_${index}`;

export const formatDashboardWidgetTileId = (
  { trackersCollectionId }: Dashboard.Data,
  widgetId: Widget.IdType,
): string => `widget-tile_${trackersCollectionId}_${widgetId}`;

export const formatKeywordKey = (
  data: Widgets.TopKeywords.KeywordData | Widgets.TrendingKeywords.KeywordData,
  index: number,
): string => `keyword_${data.keyword}_${index}`;
