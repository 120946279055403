import { createDraftSafeSelector } from "@reduxjs/toolkit";

import { selectUser } from "../user/userSelector";

// Inner imports
import { trackersAdapter } from "./trackersSlice";

export const {
  selectAll: selectTrackers,
  selectById: selectTrackerById,
  selectEntities: selectTrackersEntities,
} = trackersAdapter.getSelectors<Store.RootState>(({ trackers }) => trackers);

export const selectTrackersStatus = createDraftSafeSelector(
  ({ trackers }: Store.RootState) => trackers,
  ({ status }) => status,
);

export const selectTrackerIds = createDraftSafeSelector(
  selectTrackersEntities,
  (entities) => Object.keys(entities),
);

export const selectCompanyTrackers = createDraftSafeSelector(
  (
    state: Store.RootState,
  ): [ReturnType<typeof selectUser>, ReturnType<typeof selectTrackers>] => [
    selectUser(state),
    selectTrackers(state),
  ],
  ([user, trackers]) => {
    const filteredTrackers = new Set<Tracker.Data>();

    for (const tracker of trackers) {
      const isCompanyTracker = tracker.companyId === user.companyId;

      if (isCompanyTracker) filteredTrackers.add(tracker);
    }

    return [...filteredTrackers];
  },
);

export const selectCompanyTrackersCount = createDraftSafeSelector(
  (state: Store.RootState): [ReturnType<typeof selectCompanyTrackers>] => [
    selectCompanyTrackers(state),
  ],
  ([trackers]) => trackers.length,
);

export const selectUserTrackers = createDraftSafeSelector(
  (
    state: Store.RootState,
  ): [ReturnType<typeof selectUser>, ReturnType<typeof selectTrackers>] => [
    selectUser(state),
    selectTrackers(state),
  ],
  ([user, trackers]) => {
    const filteredTrackers = new Set<Tracker.Data>();

    for (const tracker of trackers) {
      const isUserTracker = tracker.authorId === user.id;

      if (isUserTracker) filteredTrackers.add(tracker);
    }

    return [...filteredTrackers];
  },
);

export const selectCompanyTrackersEntities = createDraftSafeSelector(
  (
    state: Store.RootState,
  ): [ReturnType<typeof selectUser>, ReturnType<typeof selectTrackers>] => [
    selectUser(state),
    selectTrackers(state),
  ],
  ([user, trackers]) => {
    const filteredTrackers = new Map<Tracker.Data["id"], Tracker.Data>();

    for (const tracker of trackers) {
      const isCompanyTracker = tracker.companyId === user.companyId;

      if (isCompanyTracker) filteredTrackers.set(tracker.id, tracker);
    }

    return Object.fromEntries(filteredTrackers);
  },
);

export const selectCompanyTrackerNames = createDraftSafeSelector(
  selectCompanyTrackers,
  (trackers) => trackers.map(({ name }) => name),
);

export const selectTrackersWithSearchId = createDraftSafeSelector(
  (
    state: Store.RootState,
    searchId: Search.Data["id"],
  ): [Tracker.Data[], Search.Data["id"]] => [selectTrackers(state), searchId],
  ([trackers, searchId]) => {
    const trackersWithSearchId: Tracker.Data[] = [];

    for (const tracker of trackers) {
      const isSearchIdIncluded = tracker.searchIds.includes(searchId);

      if (isSearchIdIncluded) trackersWithSearchId.push(tracker);
    }

    return trackersWithSearchId;
  },
);

export const selectIsCompanyTracker = createDraftSafeSelector(
  (
    state: Store.RootState,
    trackerId: Tracker.Data["id"],
  ): [ReturnType<typeof selectUser>, ReturnType<typeof selectTrackerById>] => [
    selectUser(state),
    selectTrackerById(state, trackerId),
  ],
  ([user, tracker]) => {
    if (!user || !tracker) return false;

    return user.companyId === tracker.companyId;
  },
);

export const selectTrackersById = createDraftSafeSelector(
  (
    state: Store.RootState,
    trackerIds: Tracker.Data["id"][],
  ): [ReturnType<typeof selectTrackersEntities>, Tracker.Data["id"][]] => [
    selectTrackersEntities(state),
    trackerIds,
  ],
  ([trackers, trackerIds]) => {
    const filteredTrackers = new Set<Tracker.Data>();

    for (const trackerId of trackerIds) {
      const tracker = trackers[trackerId];

      if (tracker) filteredTrackers.add(tracker);
    }

    return [...filteredTrackers];
  },
);
