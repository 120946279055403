import { useSelector } from "react-redux";

import { useLocationState, useQueryParams } from "src/hooks";
import { selectDashboardDateRangeById } from "src/store/selectors";

export const useLineChartDashboardDateRanges = () => {
  const {
    locationState: { previousDateRangeIds = [] },
  } = useLocationState();

  const { dateRangeId: dashboardDateRangeId = "" } = useQueryParams();

  const dashboardDateRange = useSelector((state: Store.RootState) =>
    selectDashboardDateRangeById(state, dashboardDateRangeId),
  );

  return { dashboardDateRange, dashboardDateRangeId, previousDateRangeIds };
};
