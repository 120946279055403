import { FC, memo } from "react";
import cx from "classnames";

import styles from "./TableItemToggler.module.scss";
import { ChevronRight } from "src/assets/icons";

type Props = {
  isCollapsed: boolean;
  toggleCollapse: () => void;
};

export const TableItemToggler: FC<Props> = memo(
  ({ isCollapsed, toggleCollapse }) => (
    <ChevronRight
      className={cx(
        styles.itemToggler,
        isCollapsed ? styles.itemTogglerCollapsed : "",
      )}
      size={20}
      onClick={toggleCollapse}
    />
  ),
);
