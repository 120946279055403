import { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";

import { useModal } from "src/hooks";
import { ConfirmModal } from "src/features";
import { Translation } from "src/components";

type Props = {
  path: string;
  hash: string;
  query: string;
  state?: Object;
  history: RouteComponentProps["history"];
  onOkHandleClick?: Function;
  onCancelHandleClick?: Function;
};

export const ConfirmRedirectionModal: FC<Props> = ({
  path,
  hash,
  query,
  state = {},
  history,
  onOkHandleClick,
  onCancelHandleClick,
}) => {
  const { closeModal } = useModal();

  const { t } = useTranslation();

  const handleOK = useCallback(
    (currentPath: string): void => {
      history.block(() => {});

      closeModal("confirm-redirection");

      onOkHandleClick?.();

      let location = `${currentPath}${hash}`;

      if (query) location = `${location}?${query}`;

      history.push(location, state);
    },
    [history, closeModal, onOkHandleClick, hash, query, state],
  );

  const handleCancel = useCallback((): void => {
    onCancelHandleClick?.();

    closeModal("confirm-redirection");
  }, [closeModal, onCancelHandleClick]);

  return (
    <ConfirmModal
      id="confirm-redirection"
      type="error"
      acceptButton={{
        text: t("component.modal.redirect.button.submit"),
        onClick: () => handleOK(path),
      }}
      cancelButton={{
        text: t("component.modal.redirect.button.cancel"),
        onClick: () => handleCancel(),
      }}
      title={t("component.modal.redirect.title")}
    >
      <span>
        <Translation i18nKey="component.modal.redirect.body" />
      </span>
    </ConfirmModal>
  );
};
