import { useCallback, useContext } from "react";

import context from "src/context";
import { ModalId } from "src/features/Modals/ConfirmModal/types";

export const useModal = () => {
  const { setModalElements } = useContext(context);

  const setModal = useCallback(setModalElements, [setModalElements]);

  const closeModal = useCallback(
    (key: ModalId) => setModalElements(key, null),
    [setModalElements],
  );

  return { setModal, closeModal };
};
