import * as yup from "yup";
import isString from "lodash/isString";
import isBefore from "date-fns/isAfter";

export const eventSchema = yup
  .object({
    authorId: yup.string().required(),
    companyId: yup.string().required(),
    dashboardId: yup.string().required(),
    dashboardDateRangeId: yup.string().required(),

    name: yup.string().required(),
    description: yup.string().default(""),
    isActive: yup.boolean().required(),

    startDate: yup.string().required(),
    endDate: yup
      .string()
      .test((endDate, { parent: { startDate } }) => {
        if (!isString(endDate) || !isString(startDate)) return false;

        const [formattedStartDate, formatterEndDate] = [
          new Date(startDate),
          new Date(endDate),
        ];

        return !isBefore(formattedStartDate, formatterEndDate);
      })
      .required(),
    createdAt: yup.string().default(""),
    updatedAt: yup.string().default(""),
  })
  .noUnknown()
  .required();
