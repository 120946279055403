import { FC, memo, useMemo } from "react";
import { useTranslation } from "react-i18next";

import styles from "./RelatedWords.module.scss";
import { getIconByName } from "src/utils";

// Inner imports
import { Tag } from "../../types";

type Props = {
  tag: Tag;
  icon: AppIcon;
};

export const RelatedWords: FC<Props> = memo(({ tag, icon }) => {
  const { t } = useTranslation();

  const { string, relatedWords } = useMemo<Tag>(() => tag, [tag]);

  const hasRelatedWords = useMemo<boolean>(
    () => Boolean(relatedWords?.length),
    [relatedWords?.length],
  );

  if (!hasRelatedWords) return null;

  return (
    <div className={styles.wrapper}>
      <div className={styles.tagWord} title={string}>
        {getIconByName(icon)}
        <span>{string}</span>
      </div>
      <span className={styles.tagTip}>
        {t("widget.mentions.label.associated_words")}
      </span>
      <div className={styles.relatedWords}>
        {relatedWords?.map((word, index) => (
          <span
            key={`${word}-${index}`}
            className={styles.relatedWord}
            title={word}
          >
            {word}
          </span>
        ))}
      </div>
    </div>
  );
});
