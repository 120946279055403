import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import styles from "./SuggestedSearches.module.scss";
import { useUnmountEffect } from "src/hooks";
import { Button, Preloader } from "src/components";
import { LocationsDropdown, LanguagesDropdown } from "src/features";
import { CollapseAlt, ExpandAlt, Stars, Sync } from "src/assets/icons";
import { formatSearchKey, getLocationDefaultLanguageId } from "src/utils";
import { useGetSuggestedSearches } from "src/pages/Trackers/CreateTrackers/hooks";
import {
  selectLanguageById,
  selectLocationById,
  selectLocationsByKeywordsDataSource,
  selectLanguagesByKeywordsDataSource,
} from "src/store/selectors";

// Inner imports
import { SuggestedSearch } from "./components";

type Props = {
  isExpanded?: boolean;
  tracker: Tracker.CreationData | Tracker.Data;
  languageId: Language.Data["id"];
  locationId: Location.Data["id"];
  selectedSearches: Search.CreationData[];
  keywordsDataSource: Search.KeywordsDataSource;
  selectSearchHandler: (value: Search.CreationData) => void;
  unselectSearchHandler: (value: Search.CreationData) => void;
  selectLocationIdHandler: (value: Location.Data["id"]) => void;
  selectLanguageIdHandler: (value: Language.Data["id"]) => void;
};

export const SuggestedSearches: FC<Props> = ({
  tracker,
  locationId,
  languageId,
  selectedSearches,
  keywordsDataSource,
  selectSearchHandler,
  unselectSearchHandler,
  selectLanguageIdHandler,
  selectLocationIdHandler,
  isExpanded: defaultIsExpanded = true,
}) => {
  const { t } = useTranslation();

  const locations = useSelector((state: Store.RootState) =>
    selectLocationsByKeywordsDataSource(state, keywordsDataSource),
  );

  const languages = useSelector((state: Store.RootState) =>
    selectLanguagesByKeywordsDataSource(state, keywordsDataSource),
  );

  const location = useSelector((state: Store.RootState) =>
    selectLocationById(state, locationId),
  );

  const language = useSelector((state: Store.RootState) =>
    selectLanguageById(state, languageId),
  );

  const [suggestedSearches, setSuggestedSearches] = useState<
    Search.CreationData[]
  >([]);

  const [excludedSearches, setExcludedSearches] = useState<
    Search.CreationData[]
  >([]);

  const [suggestedSearchesLoadingStatus, setSuggestedSearchesLoadingStatus] =
    useState<LoadingStatus>("idle");

  const [isExpanded, setIsExpanded] = useState<boolean>(defaultIsExpanded);

  const { getSuggestedSearches, cancelGetSuggestedSearches } =
    useGetSuggestedSearches({
      updateStatusHandler: setSuggestedSearchesLoadingStatus,
      updateExcludedSearchesHandler: setExcludedSearches,
    });

  const isSuggestedSearchesLoading = useMemo<boolean>(
    () => suggestedSearchesLoadingStatus === "loading",
    [suggestedSearchesLoadingStatus],
  );

  const locationName = useMemo<string>(
    () => location?.name || "",
    [location?.name],
  );

  const languageName = useMemo<string>(
    () => language?.name || "",
    [language?.name],
  );

  const filteredSearches = useMemo<Search.CreationData[]>(() => {
    const searches = new Set<Search.CreationData>();

    for (const search of suggestedSearches) {
      const isSearchSelected = selectedSearches.some(
        (selectedSearch) =>
          formatSearchKey(search) === formatSearchKey(selectedSearch),
      );

      if (!isSearchSelected) searches.add(search);
    }

    return Array.from(searches);
  }, [selectedSearches, suggestedSearches]);

  const hasSuggestedSearchesAutoFetch = useMemo<boolean>(
    () =>
      isExpanded &&
      Boolean(tracker?.category) &&
      !filteredSearches.length &&
      suggestedSearchesLoadingStatus === "idle",
    [
      isExpanded,
      tracker?.category,
      filteredSearches.length,
      suggestedSearchesLoadingStatus,
    ],
  );

  const updateSuggestedSearches = useCallback(
    ({
      locationId: newLocationId,
      languageId: newLanguageId,
    }: {
      locationId?: Location.Data["id"];
      languageId?: Language.Data["id"];
    }): Promise<Search.CreationData[]> => {
      const isConfigurationUpdated = Boolean(newLocationId || newLanguageId);

      const newExcludedSearches = isConfigurationUpdated
        ? [...selectedSearches]
        : [...excludedSearches, ...selectedSearches, ...suggestedSearches];

      return getSuggestedSearches({
        keywordsDataSource,
        query: tracker?.name || "",
        callback: setSuggestedSearches,
        category: tracker?.category || "",
        locationId: newLocationId || locationId,
        languageId: newLanguageId || languageId,
        description: tracker?.description || "",
        excludedSearches: newExcludedSearches,
      });
    },
    [
      locationId,
      languageId,
      tracker?.name,
      excludedSearches,
      selectedSearches,
      tracker?.category,
      suggestedSearches,
      keywordsDataSource,
      tracker?.description,
      getSuggestedSearches,
    ],
  );

  const selectSearch = useCallback(
    (search: Search.CreationData): void => {
      selectSearchHandler(search);

      const updatedFilteredSearches = filteredSearches.filter(
        ({ id }) => id !== search.id,
      );

      if (!updatedFilteredSearches.length) updateSuggestedSearches({}).catch();
    },
    [filteredSearches, selectSearchHandler, updateSuggestedSearches],
  );

  const SuggestedSearches = useMemo<JSX.Element>(() => {
    switch (true) {
      case Boolean(filteredSearches.length):
        return (
          <>
            {isSuggestedSearchesLoading && (
              <div className={styles.loaderWrapper}>
                <Preloader
                  type="bar"
                  text={t(
                    "page.create_tracker.select_searches.loader.suggest_searches",
                  )}
                />
              </div>
            )}
            {filteredSearches.map((search) => (
              <SuggestedSearch
                key={search.id}
                search={search}
                selectedSearches={selectedSearches}
                selectSearchHandler={selectSearch}
                unselectSearchHandler={unselectSearchHandler}
              />
            ))}
          </>
        );
      case isSuggestedSearchesLoading:
        return (
          <Preloader
            type="bar"
            className={styles.loaderWrapper}
            text={t(
              "page.create_tracker.select_searches.loader.suggest_searches",
            )}
          />
        );
      default:
        return (
          <div className={styles.placeholder}>
            {t(
              "page.create_tracker.select_searches.placeholder.suggested_searches",
            )}
          </div>
        );
    }
  }, [
    t,
    selectSearch,
    filteredSearches,
    selectedSearches,
    unselectSearchHandler,
    isSuggestedSearchesLoading,
  ]);

  useUnmountEffect(cancelGetSuggestedSearches);

  useEffect(() => {
    if (hasSuggestedSearchesAutoFetch) updateSuggestedSearches({}).catch();
  }, [hasSuggestedSearchesAutoFetch, updateSuggestedSearches]);

  const onLocationIdChange = (value: Location.Data["id"]): void => {
    if (value === locationId) return;

    selectLocationIdHandler(value);

    const languageId = getLocationDefaultLanguageId(
      value,
      locations,
      languages,
    );

    updateSuggestedSearches({ locationId: value, languageId }).catch();
  };

  const onLanguageIdChange = (value: Language.Data["id"]): void => {
    if (value === languageId) return;

    selectLanguageIdHandler(value);

    updateSuggestedSearches({ languageId: value }).catch();
  };

  const onExpandClick = (): void => setIsExpanded((state) => !state);

  return (
    <div className={styles.wrapper}>
      <div className={styles.section}>
        <div className={styles.title}>
          <Stars />
          <span className={styles.heading}>
            {t("page.create_tracker.select_searches.label.suggested_searches")}
          </span>
        </div>
        {isExpanded && (
          <div className={styles.settings}>
            <div title={locationName}>
              <LocationsDropdown
                isDisabled={isSuggestedSearchesLoading}
                locationId={locationId}
                setLocationId={onLocationIdChange}
                keywordsDataSource={keywordsDataSource}
              />
            </div>
            <div title={languageName}>
              <LanguagesDropdown
                isDisabled={isSuggestedSearchesLoading}
                languageId={languageId}
                setLanguageId={onLanguageIdChange}
                keywordsDataSource={keywordsDataSource}
              />
            </div>
          </div>
        )}
        <Button
          onClick={onExpandClick}
          buttonSize="medium"
          buttonStyle="transparent"
          className={styles.expandButton}
        >
          {isExpanded ? <CollapseAlt /> : <ExpandAlt />}
        </Button>
      </div>
      {isExpanded && (
        <div className={styles.section}>
          <div className={styles.searches}>{SuggestedSearches}</div>
          <div className={styles.update}>
            <Button
              onClick={() => updateSuggestedSearches({})}
              disabled={isSuggestedSearchesLoading}
              buttonSize="small"
              buttonStyle="transparent"
            >
              <Sync
                className={isSuggestedSearchesLoading ? styles.loader : ""}
              />
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
