import { memo, useMemo } from "react";
import { useSelector } from "react-redux";
import { BarChart, YAxis, XAxis, Bar } from "recharts";
import Table from "rsuite/Table";
import { useTranslation } from "react-i18next";

import styles from "./LineChartCell.module.scss";
import {
  checkColorContrast,
  formatToLocaleNumber,
  formatToMonthFullYearDate,
} from "src/utils";
import { Tooltip } from "src/components";
import { selectWhiteLabel } from "src/store/selectors";
import { TOOLTIP_STYLE } from "src/components/Tooltip/constants";
import { KEYWORDS_TABLE_STYLES } from "../../constants";

// Inner imports
import type { LineChartCellProps } from "./types";
import { KEYWORDS_TABLE_BAR_CHART_STYLES } from "./constants";
import { KeywordLineChartData, keywordLineChartDataSchema } from "./schema";
import { SmallLineChart } from "./components";

const { Cell } = Table;

export const LineChartCell = memo<LineChartCellProps>(
  ({ rowData, dataKey, ...props }) => {
    const { t } = useTranslation();

    const {
      styles: { primaryColor },
    } = useSelector(selectWhiteLabel);

    const lineChartData = useMemo<KeywordLineChartData>(() => {
      const data: KeywordLineChartData = [];

      const { values = [] } = rowData || {};

      if (!values?.length) return data;

      for (const value of values) {
        try {
          const { volume, date } =
            keywordLineChartDataSchema.validateSync(value);

          const formattedDate = formatToMonthFullYearDate(date);

          data.push({
            volume,
            date: formattedDate,
          });
        } catch (err) {
          console.error(err);
        }
      }

      return data;
    }, [rowData]);

    const keyword = useMemo<string>(
      () => rowData?.label || "",
      [rowData?.label],
    );

    const barColor = useMemo<string>(() => {
      if (!TOOLTIP_STYLE.backgroundColor)
        return KEYWORDS_TABLE_BAR_CHART_STYLES.fill;

      const foregroundColors = [primaryColor];

      for (const foregroundColor of foregroundColors) {
        const isColorContrasted = checkColorContrast(
          foregroundColor,
          TOOLTIP_STYLE.backgroundColor,
        );

        if (isColorContrasted) return foregroundColor;
      }

      return KEYWORDS_TABLE_BAR_CHART_STYLES.fill;
    }, [primaryColor]);

    const tooltip = useMemo<JSX.Element>(
      () => (
        <div className={styles.tooltipWrapper}>
          <span className={styles.keyword}>{keyword}</span>
          <div className={styles.barChartWrapper}>
            <BarChart
              width={KEYWORDS_TABLE_BAR_CHART_STYLES.width}
              height={KEYWORDS_TABLE_BAR_CHART_STYLES.height}
              data={lineChartData}
            >
              <YAxis
                tickFormatter={yAxisTickFormatter}
                fontSize={KEYWORDS_TABLE_BAR_CHART_STYLES.fontSize}
                stroke={KEYWORDS_TABLE_BAR_CHART_STYLES.axisColor}
              />
              <XAxis
                dataKey="date"
                fontSize={KEYWORDS_TABLE_BAR_CHART_STYLES.fontSize}
                stroke={KEYWORDS_TABLE_BAR_CHART_STYLES.axisColor}
                interval="preserveStartEnd"
              />
              <Bar dataKey="volume" fill={barColor} isAnimationActive />
            </BarChart>
          </div>
        </div>
      ),
      [barColor, keyword, lineChartData],
    );

    if (!lineChartData.length)
      return (
        <Cell {...props} dataKey={dataKey}>
          {t("dc_keywords_table_line_chart_cell_error")}
        </Cell>
      );

    return (
      <Cell
        {...props}
        dataKey={dataKey}
        className={styles.cell}
        height={KEYWORDS_TABLE_STYLES.cellHeight}
      >
        <Tooltip content={tooltip} className={styles.wrapper}>
          <SmallLineChart data={lineChartData} color={primaryColor} />
        </Tooltip>
      </Cell>
    );
  },
);

function yAxisTickFormatter(value: number | string): string {
  if (typeof value === "string") return value;

  return formatToLocaleNumber(value);
}
