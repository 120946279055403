import { useInsightsCharts } from "../../Components/Insights/hooks";
import { useSearchShareCharts } from "../../Components/SearchShare/hooks";
import { useTopKeywordsCharts } from "../../Components/TopKeywords/hooks";
import { useVolumeShareCharts } from "../../Components/VolumeShare/hooks";
import { useTrendingKeywordsCharts } from "../../Components/TrendingKeywords/hooks";

export const WIDGET_CHART_HOOKS: Record<
  Widget.IdType,
  (props: Widget.ChartProps) => Widget.Chart[]
> = {
  "widget-search-share": useSearchShareCharts,
  "widget-top-keywords": useTopKeywordsCharts,
  "widget-trending-keywords": useTrendingKeywordsCharts,
  "widget-volume-share": useVolumeShareCharts,
  "widget-insights": useInsightsCharts,
  "widget-articles": () => [],
};
