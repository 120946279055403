import { FC, useMemo } from "react";
import cx from "classnames";

import styles from "./SuggestedSearch.module.scss";
import { Button } from "src/components";
import { MinusCircleOutline, PlusCircleOutline } from "src/assets/icons";

type Props = {
  search: Search.CreationData;
  selectedSearches: Search.CreationData[];
  selectSearchHandler: (value: Search.CreationData) => void;
  unselectSearchHandler: (value: Search.CreationData) => void;
};

export const SuggestedSearch: FC<Props> = ({
  search,
  selectedSearches,
  selectSearchHandler,
  unselectSearchHandler,
}) => {
  const selectedSearch = useMemo<Search.CreationData | undefined>(
    () =>
      selectedSearches.find(
        (selectedSearch) =>
          selectedSearch.subject === search.subject &&
          selectedSearch.description === search.description,
      ),
    [search.description, search.subject, selectedSearches],
  );

  const isSelected = useMemo<boolean>(
    () => Boolean(selectedSearch),
    [selectedSearch],
  );

  const onClick = (): void => {
    if (selectedSearch) unselectSearchHandler(selectedSearch);
    else selectSearchHandler(search);
  };

  return (
    <Button
      type="button"
      onClick={onClick}
      shouldPreventEnter
      buttonStyle="outlined"
      className={cx(styles.wrapper, isSelected ? styles.wrapperActive : "")}
    >
      <div className={styles.content}>
        <span className={styles.name}>{search.subject}</span>
        <span className={styles.description}>{search.description}</span>
      </div>
      {isSelected ? <MinusCircleOutline /> : <PlusCircleOutline />}
    </Button>
  );
};
