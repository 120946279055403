import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import compareAsc from "date-fns/compareAsc";
import { useHistory } from "react-router-dom";
import differenceInDays from "date-fns/differenceInDays";

import { useLocationState } from "src/hooks";
import { CHART_COLORS, LIGHT_BLUE } from "src/constants/colors";
import { cleanTimeFromDate, updateQueryParams } from "src/utils";
import {
  selectEvents,
  selectDefaultDashboardDateRangeByTrackersCollectionId,
} from "src/store/selectors";
import {
  ChartItem,
  LineChartEvent,
  LineChartEvent as LineChartEventType,
} from "../types";

type Props = {
  data: ChartItem[];
  previousDateRangeIds: DashboardDateRange.Data["id"][];
  dashboardDateRangeId: DashboardDateRange.Data["id"];
  trackersCollectionId: TrackersCollection.Data["id"];
};

export const useLineChartEvents = ({
  data,
  previousDateRangeIds,
  dashboardDateRangeId,
  trackersCollectionId,
}: Props) => {
  const history = useHistory();

  const events = useSelector(selectEvents);

  const { setLocationState } = useLocationState();

  const defaultDashboardDateRange = useSelector((state: Store.RootState) =>
    selectDefaultDashboardDateRangeByTrackersCollectionId(
      state,
      trackersCollectionId,
    ),
  );

  const [selectedEventId, setSelectedEventId] = useState<Event.Data["id"]>("");

  const dashboardActiveEvents = useMemo<Event.Data[]>(() => {
    const filteredEvents = new Set<Event.Data>();

    for (const event of events) {
      const isDashboardEvent = event.dashboardId === trackersCollectionId;

      if (isDashboardEvent && event.isActive) filteredEvents.add(event);
    }

    return [...filteredEvents];
  }, [trackersCollectionId, events]);

  const formattedEvents = useMemo<LineChartEvent[]>(() => {
    const formattedEvents: LineChartEvent[] = [];

    for (const [index, event] of dashboardActiveEvents.entries())
      formattedEvents.push(formatToLineChartEvent(data, event, index));

    return formattedEvents.sort((a, b) =>
      compareAsc(
        differenceInDays(a.startDate, a.endDate),
        differenceInDays(b.startDate, b.endDate),
      ),
    );
  }, [data, dashboardActiveEvents]);

  const selectedEvent = useMemo<LineChartEventType | undefined>(
    () => formattedEvents.find(({ id }) => id === selectedEventId),
    [formattedEvents, selectedEventId],
  );

  const renderedEvents = useMemo<LineChartEventType[]>(() => {
    if (!selectedEvent) return formattedEvents;

    return [selectedEvent];
  }, [formattedEvents, selectedEvent]);

  const onEventClick = (eventId: Event.Data["id"]): void => {
    const event = events.find(({ id }) => id === eventId);

    if (!event) return;

    if (event.dashboardDateRangeId !== dashboardDateRangeId) {
      const updatedPreviousDateRangeIds = [
        ...previousDateRangeIds,
        dashboardDateRangeId,
      ];

      setLocationState({ previousDateRangeIds: updatedPreviousDateRangeIds });

      return updateQueryParams(
        { dateRangeId: event.dashboardDateRangeId },
        history,
      );
    }

    const lastOpenedDashboardDateRangeId = previousDateRangeIds.pop();

    if (lastOpenedDashboardDateRangeId) {
      setLocationState({ previousDateRangeIds });

      return updateQueryParams(
        { dateRangeId: lastOpenedDashboardDateRangeId },
        history,
      );
    }

    setLocationState({ previousDateRangeIds: [] });

    if (defaultDashboardDateRange)
      return updateQueryParams(
        { dateRangeId: defaultDashboardDateRange.id },
        history,
      );
  };

  return {
    onEventClick,
    selectedEvent,
    renderedEvents,
    selectedEventId,
    setSelectedEventId,
    events: formattedEvents,
  };
};

function formatToLineChartEvent(
  data: ChartItem[],
  event: Event.Data,
  index: number,
): LineChartEvent {
  const lineChartEvent: LineChartEvent = {
    ...event,
    initialEndDate: event.endDate,
    initialStartDate: event.startDate,
    color: CHART_COLORS[index] || LIGHT_BLUE,
    endDate: new Date(cleanTimeFromDate(event.endDate)).getTime(),
    startDate: new Date(cleanTimeFromDate(event.startDate)).getTime(),
  };

  const { startDate, endDate } = lineChartEvent;

  const { minDateTime, maxDateTime } = data.reduce(
    (acc, { time }) => {
      if (time < acc.minDateTime) acc.minDateTime = time + 1;

      if (time > acc.maxDateTime) acc.maxDateTime = time;

      return acc;
    },
    { minDateTime: data[0]?.time || 0, maxDateTime: 0 },
  );

  switch (true) {
    // < ... startDate ... > endDate
    case startDate > minDateTime &&
      startDate < maxDateTime &&
      endDate > maxDateTime:
      return {
        ...lineChartEvent,
        startDate: startDate,
        endDate: maxDateTime,
      };

    // startDate < ... endDate ... >
    case startDate < minDateTime &&
      endDate < maxDateTime &&
      endDate > minDateTime:
      return {
        ...lineChartEvent,
        startDate: minDateTime,
        endDate: endDate,
      };

    // startDate < ... > endDate
    case startDate <= minDateTime && endDate >= maxDateTime:
      return {
        ...lineChartEvent,
        startDate: minDateTime,
        endDate: maxDateTime,
      };

    // startDate endDate < ... > OR < ... > startDate endDate
    case startDate < minDateTime && endDate < minDateTime:
    case startDate > maxDateTime && endDate > maxDateTime:
    default:
      return {
        ...lineChartEvent,
        startDate: startDate,
        endDate: endDate,
      };
  }
}
