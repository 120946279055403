import { FC, useMemo } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import styles from "./EventsInfo.module.scss";
import { Button, Translation } from "src/components";
import { MinusCircleFill } from "src/assets/icons";
import { formatToDayMonthFullYearDate } from "src/utils";
import {
  selectDashboardOwnerName,
  selectIsUserDashboardOwner,
  selectAvailableDashboardById,
} from "src/store/selectors";

type Props = {
  dashboardId: Dashboard.Data["id"];
  updatedEvents: Event.Data[];
  updateEventHandler: (
    id: Event.Data["id"],
    changes: Partial<Event.Data>,
  ) => void;
};

export const EventsInfo: FC<Props> = ({
  dashboardId,
  updatedEvents,
  updateEventHandler,
}) => {
  const { t } = useTranslation();

  const dashboard = useSelector((state: Store.RootState) =>
    selectAvailableDashboardById(state, dashboardId),
  );

  const isUserDashboardOwner = useSelector((state: Store.RootState) =>
    selectIsUserDashboardOwner(state, dashboardId),
  );

  const dashboardAuthorFullName = useSelector((state: Store.RootState) =>
    selectDashboardOwnerName(state, dashboardId),
  );

  const filteredEvents = useMemo<Event.Data[]>(
    () => updatedEvents.filter(({ isActive }) => isActive),
    [updatedEvents],
  );

  const dashboardName = useMemo<Dashboard.Data["name"]>(
    () => dashboard?.name || "",
    [dashboard?.name],
  );

  const dashboardAuthorText = useMemo<string>(() => {
    if (isUserDashboardOwner) return t("user.name.current_user");

    return dashboardAuthorFullName;
  }, [dashboardAuthorFullName, isUserDashboardOwner, t]);

  const visibilityLabel = useMemo<string>(() => {
    if (!dashboard?.visibility) return "";

    switch (dashboard.visibility) {
      case "company":
        return t("dashboard.label.visibility_company");
      case "public":
      default:
        return t("dashboard.label.visibility_public");
    }
  }, [dashboard?.visibility, t]);

  const createdAtLabel = useMemo<string>(() => {
    if (!dashboard?.createdAt) return "";

    let date = "";

    try {
      date = formatToDayMonthFullYearDate(dashboard.createdAt);
    } catch (error) {
      console.error(error);
    }

    return date;
  }, [dashboard?.createdAt]);

  const onToggleActiveEventClick = (event: Event.Data): void =>
    updateEventHandler(event.id, {
      isActive: !event.isActive,
    });

  return (
    <div className={styles.wrapper}>
      <div className={styles.dashboard}>
        <div className={styles.dashboardName} title={dashboardName}>
          {dashboardName}
        </div>
        <div className={styles.dashboardInfo}>
          {createdAtLabel && (
            <div>
              <Translation
                i18nKey="page.dashboard.label.dashboard_created_at"
                values={{ createdAt: createdAtLabel }}
              />
            </div>
          )}
          <div>
            <Translation
              i18nKey="page.dashboard.label.dashboard_author_name"
              values={{ ownerFullName: dashboardAuthorText }}
            />
          </div>
          {isUserDashboardOwner && (
            <div>
              <Translation
                i18nKey="page.dashboard.label.dashboard_visibility"
                values={{ privacy: visibilityLabel }}
              />
            </div>
          )}
        </div>
      </div>
      <div className={styles.eventsWrapper}>
        <div className={styles.eventsTitle}>
          {t("page.dashboard.label.dashboard_events")}
        </div>
        <div className={styles.events}>
          {filteredEvents.map((event) => (
            <Button
              key={event.id}
              className={styles.event}
              title={event.name}
              buttonSize="small"
              onClick={() => onToggleActiveEventClick(event)}
            >
              <span>{event.name}</span>
              <MinusCircleFill />
            </Button>
          ))}
        </div>
      </div>
    </div>
  );
};
