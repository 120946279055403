import { FC, memo, useMemo } from "react";
import cx from "classnames";

import styles from "./TableSortIcon.module.scss";
import { Triangle } from "src/assets/icons";

type Props = {
  sortDirection?: SortDirection;
};

export const TableSortIcon: FC<Props> = memo(({ sortDirection }) => {
  const sortDirectionClassName = useMemo<string | undefined>(() => {
    if (!sortDirection) return "";

    return sortDirection === "ASC" ? styles.sortIconAsc : styles.sortIconDesc;
  }, [sortDirection]);

  return (
    <div className={cx(styles.sortIcon, sortDirectionClassName)}>
      <Triangle />
      <Triangle />
    </div>
  );
});
