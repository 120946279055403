import * as yup from "yup";

import { SEARCH_KEYWORDS_DATA_SOURCES } from "src/constants";

const languageKeywordsDataSourcesSchema = yup
  .string<Search.KeywordsDataSource>()
  .oneOf([...SEARCH_KEYWORDS_DATA_SOURCES])
  .required();

const languageSchema = yup
  .object({
    name: yup.string().required(),

    keywordsDataSources: yup
      .array(languageKeywordsDataSourcesSchema)
      .required(),
  })
  .noUnknown()
  .required();

export default languageSchema;
