import { Dictionary } from "@reduxjs/toolkit";

import styles from "./TopKeywordsPreview.module.scss";
import { Tooltip } from "src/components";
import { formatKeywordKey } from "src/utils";
import { CHART_COLORS, LIGHT_BLUE } from "src/constants";
import { Tag } from "src/components/charts/TagCloudChart/types";
import { TrackerChartIcon, TrackerKeywordsDataSourcesIcon } from "src/features";
import { renderTrackerKeywordsDataSourcesIcon } from "src/features/TrackerKeywordsDataSourcesIcon/utils";

export const prepareTopKeywordsForTagCloud = (
  data: Widgets.TopKeywords.KeywordData[],
  trackerIds: Tracker.Data["id"][],
): Tag[] =>
  data.map((value, index) => {
    const trackerIdIndex = trackerIds.indexOf(value.trackerId);

    return {
      id: formatKeywordKey(value, index),
      value: value.volume,
      string: value.keyword,
      color: CHART_COLORS[trackerIdIndex] || LIGHT_BLUE,
    };
  });

export const renderKeywordValue = (
  value: Widgets.TopKeywords.KeywordData,
  trackers: Dictionary<Tracker.Data>,
  trackerIds: Tracker.Data["id"][],
): JSX.Element | string | null => {
  const hasMultipleTrackers = trackerIds.length > 1;

  return (
    <Tooltip content={renderKeywordValueTooltip(value, trackers, trackerIds)}>
      <div className={styles.tableCellKeyword}>
        {hasMultipleTrackers && (
          <TrackerChartIcon
            trackerIds={trackerIds}
            trackerId={value.trackerId}
          />
        )}
        <span>{value.keyword}</span>
      </div>
    </Tooltip>
  );
};

export const renderKeywordValueTooltip = (
  { trackerId }: Widgets.TopKeywords.KeywordData,
  trackers: Dictionary<Tracker.Data>,
  trackerIds: Tracker.Data["id"][],
): JSX.Element | string => {
  const tracker = trackers[trackerId];

  if (!tracker) return "";

  return (
    <div className={styles.tableCellKeywordTooltip}>
      <TrackerChartIcon trackerId={trackerId} trackerIds={trackerIds} />
      <span>{trackers[trackerId]?.name}</span>
      {renderTrackerKeywordsDataSourcesIcon(tracker)}
    </div>
  );
};

export const renderKeywordTracker = (
  { trackerId }: Widgets.TopKeywords.KeywordData,
  trackers: Dictionary<Tracker.Data>,
): JSX.Element | string | null => (
  <div className={styles.tableCellTracker}>
    <TrackerKeywordsDataSourcesIcon trackerId={trackerId} />
    <span className={styles.tableCellTrackerName}>
      {trackers[trackerId]?.name}
    </span>
  </div>
);
