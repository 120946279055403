import { FC, useMemo } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import styles from "./DashboardInfo.module.scss";
import { Translation } from "src/components";
import {
  selectDashboardById,
  selectDashboardOwnerName,
  selectIsCompanyDashboard,
  selectIsUserDashboardOwner,
} from "src/store/selectors";
import { useDashboardUpdatedAt } from "../../hooks";

type Props = {
  isReadOnly: boolean;
  trackersCollectionId: TrackersCollection.Data["id"];
  dashboardDateRangeId: DashboardDateRange.Data["id"];
};

export const DashboardInfo: FC<Props> = ({
  isReadOnly,
  trackersCollectionId,
  dashboardDateRangeId,
}) => {
  const { t } = useTranslation();

  const dashboard = useSelector((state: Store.RootState) =>
    selectDashboardById(state, trackersCollectionId),
  );

  const isUserDashboardOwner = useSelector((state: Store.RootState) =>
    selectIsUserDashboardOwner(state, trackersCollectionId),
  );

  const isCompanyDashboard = useSelector((state: Store.RootState) =>
    selectIsCompanyDashboard(state, trackersCollectionId),
  );

  const dashboardAuthorFullName = useSelector((state: Store.RootState) =>
    selectDashboardOwnerName(state, trackersCollectionId),
  );

  const { updatedAt, updatedAtDistance } = useDashboardUpdatedAt(
    trackersCollectionId,
    dashboardDateRangeId,
    isReadOnly,
  );

  const isDashboardMetaDataShown = useMemo<boolean>(
    () => isCompanyDashboard && !isReadOnly,
    [isCompanyDashboard, isReadOnly],
  );

  const dashboardAuthorText = useMemo<string>(() => {
    if (isUserDashboardOwner) return t("user.name.current_user");

    return dashboardAuthorFullName;
  }, [dashboardAuthorFullName, isUserDashboardOwner, t]);

  const privacyText = useMemo<string>(() => {
    if (!dashboard?.visibility) return "";

    switch (dashboard.visibility) {
      case "private":
        return t("dashboard.label.visibility_private");
      case "company":
        return t("dashboard.label.visibility_company");
      case "public":
      default:
        return t("dashboard.label.visibility_public");
    }
  }, [t, dashboard?.visibility]);

  return (
    <div className={styles.wrapper}>
      {isDashboardMetaDataShown && (
        <div className={styles.visibilitySection}>
          {dashboardAuthorText && (
            <div>
              <Translation
                i18nKey="page.dashboard.label.dashboard_author_name"
                values={{ ownerFullName: dashboardAuthorText }}
              />
            </div>
          )}
          {isUserDashboardOwner && (
            <div>
              <Translation
                i18nKey="page.dashboard.label.dashboard_visibility"
                values={{ privacy: privacyText }}
              />
            </div>
          )}
        </div>
      )}
      <div
        title={updatedAt}
        className={styles.updateSection}
        style={{ visibility: updatedAtDistance ? "visible" : "hidden" }}
      >
        <Translation
          i18nKey="page.dashboard.label.dashboard_last_updated_at"
          values={{ updatedAt: updatedAtDistance }}
          components={{ bold: <b /> }}
        />
      </div>
    </div>
  );
};
