export const COLLECTION_IDS = {
  app: "app",
  users: "users",
  companies: "companies",
  subscriptionPlans: "subscription-plans",
  locations: "locations",
  languages: "languages",
  localization: "localization",
  searches: "searches",
  searchesConfiguration: "searches-configuration",
  trackers: "trackers",
  dashboards: "dashboards",
  trackersCollections: "trackers-collections",
  widgetsSyncs: "widget-syncs",
  whiteLabelDomains: "white-label-domains",
  whiteLabels: "white-labels",
  events: "events",
  widgets: "widgets",
  applicationVersionInfo: "app/version-info",
  keywordsSettings: "app/keywordtool-settings",
  hardReloadInfo: "app/hard-reload-info",
  trackersWithPartialData: "trackers-with-partial-data",
  widgetInsights: "widget-insights",
  widgetArticles: "widget-articles",
  widgetVolumeShare: "widget-volume-share",
  widgetSearchShare: "widget-search-share",
  widgetTopKeywords: "widget-top-keywords",
  widgetTrendingKeywords: "widget-trending-keywords",
  dashboardDateRanges: "dashboard-date-ranges",
} as const;

const AUTH_ENDPOINTS = {
  verifyEmail: "verify-email",
  getAuthInfo: "get-user-auth-info",
  sendVerificationEmail: "send-verification-email",
};

const USER_ENDPOINTS = {
  createUser: "create-user",
  deleteUser: "delete-user",
  reportError: "report-error",
  getUserEmails: "get-user-emails",
  checkIsWhiteLabelUser: "get-user-has-access-to-white-label",
};

const SEARCH_ENDPOINTS = {
  createSearch: "create-search",
  getSearchKeywords: "get-search-keywords",
  getSuggestedSearches: "get-subject-suggestions",
  getSearchDescription: "get-subject-description",
  getSearchSelectedKeywords: "get-search-ai-selected-keywords",
};

const TRACKER_ENDPOINTS = {
  exportTrackerKeywords: "export-tracker-keywords",
  getSuggestedTrackers: "get-dashboard-tracker-suggestions",
  getTrackerDescription: "get-subject-description",
  getSuggestedCategories: "get-dashboard-input-interpretations",
  duplicateDashboardTrackers: "duplicate-dashboard-trackers",
  createTrackersWithSearches: "create-trackers-with-dashboard",
  getSuggestedTrackerPerspectives: "get-tracker-perspective-suggestions",
  duplicateTracker: "duplicate-tracker",
};

const WIDGET_ENDPOINTS = {
  getArticles: "get-articles",
  getRawArticles: "get-searches-articles",
  createWidgetSyncs: "create-widget-syncs",
};

const EXPORT_WIDGET_ENDPOINTS = {
  exportMediaShare: "export-media-share-widget",
  exportSearchShare: "export-search-share-widget",
  exportVolumeShare: "export-volume-share-widget",
  exportTopKeywords: "export-top-keywords-widget",
  exportTrendingKeywords: "export-trending-keywords-widget",
};

const DASHBOARD_ENDPOINTS = {
  getDashboardData: "get-dashboard-data",
  getSuggestedDashboards: "get-dashboards",
  triggerUpsertDashboardVector: "upsert-dashboard-vector",
  createDashboardWithTrackers: "create-trackers-with-dashboard",
};

const SUBSCRIPTION_PLAN_ENDPOINTS = {
  createStripePortalToken: "generate-stripe-portal-token",
  createStripeCheckoutToken: "generate-stripe-checkout-token",
  sendEnterpriseSubscriptionPlanRequest: "send-enterprise-plan-request-email",
};

const SHARE_DATA_ENDPOINTS = {
  encodeShareLinkData: "encode-share-link-data",
  decodeShareLinkData: "decode-share-link-data",
};

export const API_ENDPOINTS = {
  ...AUTH_ENDPOINTS,
  ...USER_ENDPOINTS,
  ...SEARCH_ENDPOINTS,
  ...WIDGET_ENDPOINTS,
  ...TRACKER_ENDPOINTS,
  ...DASHBOARD_ENDPOINTS,
  ...SHARE_DATA_ENDPOINTS,
  ...EXPORT_WIDGET_ENDPOINTS,
  ...SUBSCRIPTION_PLAN_ENDPOINTS,
};
