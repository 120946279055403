import { FC, useMemo } from "react";
import Table from "rsuite/Table";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import styles from "./SearchLinkCell.module.scss";
import { Search, ImageSearch } from "src/assets/icons";
import { Tooltip, ExternalLink, Button } from "src/components";
import { getKeywordSearchLink, triggerGtmEvent } from "src/utils";
import { selectSearchById, selectLocationById } from "src/store/selectors";
import { KEYWORDS_TABLE_STYLES } from "../../constants";

// Inner imports
import { SearchLink, SearchLinkCellProps } from "./types";

const { Cell } = Table;

export const SearchLinkCell: FC<SearchLinkCellProps> = ({
  rowData,
  dataKey,
  searchLinkType,
  searchId,
  ...props
}) => {
  const { t } = useTranslation();

  const search = useSelector((state: Store.RootState) =>
    selectSearchById(state, searchId),
  );

  const location = useSelector((state: Store.RootState) =>
    selectLocationById(state, search?.locationId || ""),
  );

  const { keyword, url } = useMemo<SearchLink>(() => {
    const rawKeyword = rowData?.value;

    if (!rawKeyword || typeof rawKeyword !== "string")
      return { keyword: "", url: "" };

    const keyword = rawKeyword.replace("_parent", "");

    return {
      url: getKeywordSearchLink({ keyword, type: searchLinkType, location }),
      keyword,
    };
  }, [searchLinkType, rowData?.value, location]);

  const tooltip = useMemo<string>(() => {
    switch (searchLinkType) {
      case "image":
        return t("component.keywords_table.tooltip.image_search_link", {
          keyword,
        });
      default:
        return t("component.keywords_table.tooltip.search_link", { keyword });
    }
  }, [keyword, searchLinkType, t]);

  const CellIcon = useMemo<JSX.Element>(() => {
    switch (searchLinkType) {
      case "image":
        return <ImageSearch />;
      default:
        return <Search />;
    }
  }, [searchLinkType]);

  const onLinkClick = (): void =>
    triggerGtmEvent("TrackerViewKeywordLink", {
      searchId,
      searchLinkType: searchLinkType,
    });

  if (!url) return null;

  return (
    <Cell
      {...props}
      dataKey={dataKey}
      align="center"
      height={KEYWORDS_TABLE_STYLES.cellHeight}
    >
      <Tooltip content={tooltip}>
        <Button
          className={styles.linkButton}
          buttonSize="small"
          buttonStyle="filled"
          onClick={onLinkClick}
        >
          <ExternalLink href={url}>{CellIcon}</ExternalLink>
        </Button>
      </Tooltip>
    </Cell>
  );
};
