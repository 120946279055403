import { useMemo } from "react";

import styles from "./Table.module.scss";
import { genericMemo } from "src/utils";
import {
  useWindowSize,
  usePagination,
  useSortedItems,
  useTableSettings,
} from "src/hooks";

// Inner imports
import type { TableItemGenericProps, TableProps } from "./types";
import {
  TablePagination,
  EmptyTablePlaceholder,
  TableCards,
  TableLayout,
} from "./components";
import { TableContextProvider } from "./context";

export const Table = genericMemo(
  <T extends TableItemGenericProps>({
    type = "",
    items: allItems,
    tableColumns,
    isTableShort = false,
    emptyTableLabel,
    getActionMenuOptions,
    defaultSort = null,
    customPreSort,
    customAfterSort,
  }: TableProps<T>) => {
    const { isMobileView } = useWindowSize();

    const { data: tableSettings } = useTableSettings(type);

    const { sortedItems, sort, setSort } = useSortedItems({
      items: allItems,
      defaultSort,
      customPreSort,
      customAfterSort,
    });

    const paginationProps = usePagination({
      items: sortedItems,
      initialPageNumber: tableSettings.page,
    });

    const items = useMemo<T[]>(
      () => paginationProps.slicedItems,
      [paginationProps],
    );

    const isTableEmpty = useMemo<boolean>(() => !allItems.length, [allItems]);

    const isCardsVisible = useMemo<boolean>(
      () => isMobileView && !isTableEmpty,
      [isMobileView, isTableEmpty],
    );

    const isTableVisible = useMemo<boolean>(
      () => !isMobileView,
      [isMobileView],
    );

    return (
      <TableContextProvider>
        <div className={styles.table}>
          {isCardsVisible && (
            <TableCards
              items={items}
              tableColumns={tableColumns}
              getActionMenuOptions={getActionMenuOptions}
            />
          )}
          {isTableVisible && (
            <TableLayout
              items={items}
              tableColumns={tableColumns}
              getActionMenuOptions={getActionMenuOptions}
              sort={sort}
              setSort={setSort}
              isTableEmpty={isTableEmpty}
            />
          )}
          {isTableEmpty && <EmptyTablePlaceholder label={emptyTableLabel} />}
          <TablePagination
            type={type}
            isTableShort={isTableShort}
            {...paginationProps}
          />
        </div>
      </TableContextProvider>
    );
  },
);
