import { getElementContrastedColor } from "src/utils";

export const handleButtonColor = ({
  element,
  disabled,
  buttonType,
  buttonStyle,
  updateHandler,
}: {
  element?: HTMLButtonElement | null;
  disabled?: boolean;
  buttonType: string;
  buttonStyle: string;
  updateHandler: (value: string) => void;
}): void => {
  if (
    !element ||
    disabled ||
    buttonStyle === "transparent" ||
    buttonType !== "primary"
  )
    return;

  const newFontColor = getElementContrastedColor({
    element,
    minRatio: 3.8,
  });

  updateHandler(newFontColor);
};

export const handleEnterClick = ({
  element,
  shouldPreventEnter,
}: {
  element?: HTMLButtonElement | null;
  shouldPreventEnter?: boolean;
}): void => {
  if (!element || !shouldPreventEnter) return;

  element.addEventListener("keydown", (event) => {
    if (event.key === "Enter") event.preventDefault();
  });
};
