import { FC, useMemo } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import differenceInDays from "date-fns/differenceInDays";

import styles from "./HeaderMessage.module.scss";
import { Rocket } from "src/assets/icons";
import { Translation } from "src/components";
import { UpdatePlanButton } from "src/features";
import {
  selectCompany,
  selectTrackersLimit,
  selectCompanyTrackers,
  selectApplicationInfo,
  selectCompanySubscriptionPlan,
} from "src/store/selectors";

// Inner imports
import { HEADER_MIN_TRACKERS_COUNT_MESSAGE } from "./constants";

export const HeaderMessage: FC = () => {
  const { t } = useTranslation();

  const company = useSelector(selectCompany);

  const trackers = useSelector(selectCompanyTrackers);

  const subscriptionPlan = useSelector(selectCompanySubscriptionPlan);

  const { sessionDate } = useSelector(selectApplicationInfo);

  const { trackersLimit } = useSelector(selectTrackersLimit);

  const subscriptionPlanExpirationDateInDays = useMemo<number>(() => {
    if (
      !subscriptionPlan ||
      subscriptionPlan.category !== "trial" ||
      company.subscriptionPlanExpirationDate === null ||
      !company.subscriptionPlanExpirationDate
    )
      return 0;

    try {
      const [formattedSessionDate, formattedSubPlanExpirationDate] = [
        new Date(sessionDate),
        new Date(company.subscriptionPlanExpirationDate),
      ];

      return differenceInDays(
        formattedSubPlanExpirationDate,
        formattedSessionDate,
      );
    } catch (error) {
      console.error(error);

      return 0;
    }
  }, [sessionDate, subscriptionPlan, company.subscriptionPlanExpirationDate]);

  const trackersLimitDifference = useMemo<number>(() => {
    if (!trackersLimit) return 0;

    return trackersLimit - trackers.length;
  }, [trackersLimit, trackers.length]);

  const isFreeSubscriptionPlanMessageShown = useMemo<boolean>(() => {
    if (!subscriptionPlan) return false;

    return subscriptionPlan?.category === "free";
  }, [subscriptionPlan]);

  const isTrackersLimitExceededMessageShown = useMemo<boolean>(() => {
    if (!subscriptionPlan || isFreeSubscriptionPlanMessageShown) return false;

    if (
      subscriptionPlan.isEnterprise ||
      subscriptionPlan.category === "free" ||
      subscriptionPlan.category === "trial"
    )
      return false;

    if (!trackers.length) return false;

    return trackersLimitDifference < 0;
  }, [
    trackers.length,
    subscriptionPlan,
    trackersLimitDifference,
    isFreeSubscriptionPlanMessageShown,
  ]);

  const isTrackersLimitMessageShown = useMemo<boolean>(() => {
    if (!subscriptionPlan || isTrackersLimitExceededMessageShown) return false;

    if (
      subscriptionPlan.isEnterprise ||
      subscriptionPlan.category === "free" ||
      subscriptionPlan.category === "trial"
    )
      return false;

    if (!trackers.length) return false;

    return trackersLimitDifference <= HEADER_MIN_TRACKERS_COUNT_MESSAGE;
  }, [
    trackers.length,
    subscriptionPlan,
    trackersLimitDifference,
    isTrackersLimitExceededMessageShown,
  ]);

  const isSubscriptionPlanExpirationMessageShown = useMemo<boolean>(() => {
    if (!subscriptionPlan || isTrackersLimitMessageShown) return false;

    if (subscriptionPlan.isEnterprise || subscriptionPlan.category !== "trial")
      return false;

    return (
      company.subscriptionPlanExpirationDate !== null &&
      Boolean(company.subscriptionPlanExpirationDate)
    );
  }, [
    subscriptionPlan,
    isTrackersLimitMessageShown,
    company.subscriptionPlanExpirationDate,
  ]);

  const isMessageShown = useMemo<boolean>(
    () =>
      isFreeSubscriptionPlanMessageShown ||
      isTrackersLimitExceededMessageShown ||
      isTrackersLimitMessageShown ||
      isSubscriptionPlanExpirationMessageShown,
    [
      isTrackersLimitMessageShown,
      isFreeSubscriptionPlanMessageShown,
      isTrackersLimitExceededMessageShown,
      isSubscriptionPlanExpirationMessageShown,
    ],
  );

  const labelKey = useMemo<string>(() => {
    switch (true) {
      case isFreeSubscriptionPlanMessageShown:
        return "component.header.label.free_subscription_plan";
      case isTrackersLimitExceededMessageShown:
        return "component.header.label.tracker_limit_exceeded";
      case isTrackersLimitMessageShown:
        return "component.header.label.tracker_limit";
      case isSubscriptionPlanExpirationMessageShown:
        return "component.header.label.subscription_plan_expiration";
      default:
        return "";
    }
  }, [
    isTrackersLimitMessageShown,
    isFreeSubscriptionPlanMessageShown,
    isTrackersLimitExceededMessageShown,
    isSubscriptionPlanExpirationMessageShown,
  ]);

  const labelCount = useMemo<number>(() => {
    switch (true) {
      case isFreeSubscriptionPlanMessageShown:
        return 0;
      case isSubscriptionPlanExpirationMessageShown:
        return subscriptionPlanExpirationDateInDays;
      case isTrackersLimitExceededMessageShown:
        return 0;
      case isTrackersLimitMessageShown:
        return trackersLimitDifference;
      default:
        return 0;
    }
  }, [
    trackersLimitDifference,
    isTrackersLimitMessageShown,
    isFreeSubscriptionPlanMessageShown,
    isTrackersLimitExceededMessageShown,
    subscriptionPlanExpirationDateInDays,
    isSubscriptionPlanExpirationMessageShown,
  ]);

  const isInvoiceCustomer = useMemo<boolean>(
    () => Boolean(company.fortnoxCustomerId),
    [company.fortnoxCustomerId],
  );

  const updateButtonLabel = useMemo<string>(
    () =>
      isInvoiceCustomer
        ? t("component.header.button.contact_us")
        : t("component.header.button.update_subscription_plan"),
    [isInvoiceCustomer, t],
  );

  if (!subscriptionPlan || !isMessageShown) return null;

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <span>
          <Translation
            i18nKey={labelKey}
            values={{ name: subscriptionPlan.name, count: labelCount }}
          />
        </span>
        <UpdatePlanButton className={styles.button}>
          <span>{updateButtonLabel}</span>
          <Rocket />
        </UpdatePlanButton>
      </div>
    </div>
  );
};
