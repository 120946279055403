import { EXTERNAL_TOOLTIP_IDS } from "src/components/ExternalTooltip/constants";

export const WIDGET_STATUSES = [
  "idle",
  "not_calculated",
  "sync_triggered",
  "calculating",
  "updating",
  "loading",
  "success",
  "data_empty",
  "fetch_failed",
  "schema_failed",
] as const;

export const WIDGET_DEFAULT_STATUS: Widget.Status = "idle";

export const WIDGET_IDS = [
  "widget-search-share",
  "widget-top-keywords",
  "widget-trending-keywords",
  "widget-volume-share",
  "widget-articles",
  "widget-insights",
] as const;

export const WIDGET_CHARTS = [
  "lineChart",
  "pieChart",
  "keywords",
  "table",
  "list",
  "text",
] as const;

export const WIDGET_IDS_WITH_FORECAST: Widget.IdType[] = [
  "widget-volume-share",
];

export const WIDGET_CHART_ICON: {
  [chart in Widget.ChartType]: { [subType: string]: AppIcon; default: AppIcon };
} = {
  list: { default: "List" },
  text: { default: "List" },
  table: { default: "List" },
  pieChart: { default: "Pie" },
  keywords: { default: "TopWords" },
  lineChart: { default: "Timeline" },
};

export const WIDGET_TITLE_EXTERNAL_TOOLTIP: Record<
  Widget.IdType,
  (typeof EXTERNAL_TOOLTIP_IDS)[number]
> = {
  "widget-articles": "intercom-widget-articles-title",
  "widget-insights": "intercom-widget-insights-title",
  "widget-top-keywords": "intercom-widget-top_keywords-title",
  "widget-volume-share": "intercom-widget-volume_share-title",
  "widget-search-share": "intercom-widget-search_share-title",
  "widget-trending-keywords": "intercom-widget-trending_keywords-title",
};

export const WIDGET_EXPORT_NAME: Record<Widget.IdType, string> = {
  "widget-articles": "articles",
  "widget-insights": "insights",
  "widget-top-keywords": "top-search-terms",
  "widget-search-share": "search-share",
  "widget-trending-keywords": "rising-search-terms",
  "widget-volume-share": "mytelescope-future-index",
};
