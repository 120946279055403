import { FC, useMemo } from "react";
import cx from "classnames";

import styles from "./TagComponent.module.scss";
import { Tooltip } from "src/components";
import { checkIfElementOverflowsParentVertical } from "src/utils";

// Inner imports
import type { Tag } from "../../types";
import { RelatedWords } from "../RelatedWords/RelatedWords";

type Props = {
  tag: Tag;
  icon: AppIcon;
  fontSize: number;
  tagStyle?: "outlined" | "filled" | "transparent";
  className?: string;
  hasHoverValue?: boolean;
  hasRelatedWordsTooltip: boolean;
  valueFormatter?: (value: number) => string;
};

export const TagComponent: FC<Props> = ({
  tag,
  icon,
  fontSize,
  tagStyle = "transparent",
  className,
  hasHoverValue = false,
  hasRelatedWordsTooltip,
  valueFormatter,
}) => {
  const { string, value } = useMemo<Tag>(() => tag, [tag]);

  const color = useMemo<string>(() => tag.color, [tag]);

  const tooltipContent = useMemo<string | JSX.Element>(() => {
    if (!hasRelatedWordsTooltip) return "";

    return <RelatedWords tag={tag} icon={icon} />;
  }, [hasRelatedWordsTooltip, icon, tag]);

  const tagContentRefCallback = (currentElement: HTMLElement | null): void => {
    const [element, parentElement] = [
      currentElement?.parentElement?.parentElement,
      currentElement?.parentElement?.parentElement?.parentElement
        ?.parentElement,
    ];

    if (
      !element ||
      !parentElement ||
      !styles.tagWrapperHidden ||
      !styles.tagWrapperVisible
    )
      return;

    const isOverflown = checkIfElementOverflowsParentVertical(
      element,
      parentElement,
    );

    if (isOverflown) {
      element.classList.add(styles.tagWrapperHidden);
      element.classList.remove(styles.tagWrapperVisible);
    } else {
      element.classList.add(styles.tagWrapperVisible);
      element.classList.remove(styles.tagWrapperHidden);
    }
  };

  return (
    <Tooltip content={tooltipContent} className={styles.wrapper}>
      <span
        key={value}
        className={cx(
          className,
          styles[tagStyle],
          hasHoverValue ? styles.hoverValue : "",
          styles.tagWrapper,
        )}
        style={{ color, backgroundColor: color, borderColor: color }}
      >
        <div
          className={styles.tagContent}
          style={{ fontSize: `${fontSize}px` }}
          ref={tagContentRefCallback}
        >
          <span className={styles.tagString}>{string}</span>
          <span className={styles.tagValue} title={value.toString()}>
            {valueFormatter?.(value) || value}
          </span>
        </div>
      </span>
    </Tooltip>
  );
};
