import { FC } from "react";

import styles from "./WidgetActionButtons.module.scss";
import { Button, Tooltip } from "src/components";
import { getIconByName } from "src/utils";

type Props = {
  buttons: Widget.ActionButton[];
};

export const WidgetActionButtons: FC<Props> = ({ buttons }) => {
  if (!buttons.length) return null;

  return (
    <div className={styles.wrapper}>
      {buttons.map(({ id, icon, onClick, tooltip = "" }) => (
        <Tooltip key={id} content={tooltip}>
          <Button
            onClick={onClick}
            className={styles.button}
            buttonSize="small"
            buttonStyle="transparent"
          >
            {getIconByName(icon)}
          </Button>
        </Tooltip>
      ))}
    </div>
  );
};
