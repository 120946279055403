import { FC, useMemo } from "react";
import isString from "lodash/isString";
import { useTranslation } from "react-i18next";
import MDEditor, { MDEditorProps } from "@uiw/react-md-editor";

import styles from "./RichTextEditor.module.scss";
import "./richTextEditor.scss";
import { limitString } from "src/utils";
import { TextOutline, Word } from "src/assets/icons";

// Inner imports
import {
  RICH_TEXT_EDITOR_COMMANDS,
  RICH_TEXT_EDITOR_EXTRA_COMMANDS,
} from "./constants";

type Props = MDEditorProps & {
  value?: string;
  wordLimit?: number;
  characterLimit?: number;
  changeValueHandler?: (value?: string) => void;
};

export const RichTextEditor: FC<Props> = ({
  value,
  wordLimit,
  characterLimit,
  changeValueHandler,
  ...props
}) => {
  const { t } = useTranslation();

  const valueWords = useMemo<string[]>(() => {
    if (!value || !isString(value)) return [];

    return value.split(" ").filter(Boolean);
  }, [value]);

  const hasCharacterLimit = useMemo<boolean>(
    () => Boolean(characterLimit && isString(value)),
    [characterLimit, value],
  );

  const hasWordLimit = useMemo<boolean>(
    () => Boolean(wordLimit && isString(value)),
    [wordLimit, value],
  );

  const characterLimitText = useMemo<string>(() => {
    if (!characterLimit || !isString(value)) return "";

    return t("component.input.label.limit", {
      length: value.length,
      limit: characterLimit,
    });
  }, [characterLimit, value, t]);

  const wordLimitText = useMemo<string>(() => {
    if (!wordLimit || !isString(value)) return "";

    return t("component.input.label.limit", {
      limit: wordLimit,
      length: valueWords.length,
    });
  }, [wordLimit, value, t, valueWords.length]);

  const onInputChange = (value: string = ""): void => {
    if (!changeValueHandler) return;

    const formattedValue = limitString(value, { characterLimit, wordLimit });

    changeValueHandler(formattedValue);
  };

  return (
    <div data-color-mode="light" className={styles.wrapper}>
      <MDEditor
        value={value}
        visibleDragbar={false}
        highlightEnable={false}
        onChange={onInputChange}
        commands={RICH_TEXT_EDITOR_COMMANDS}
        extraCommands={RICH_TEXT_EDITOR_EXTRA_COMMANDS}
        {...props}
      />
      <div className={styles.limitWrapper}>
        {hasCharacterLimit && (
          <div className={styles.limit}>
            <TextOutline />
            <span>{characterLimitText}</span>
          </div>
        )}
        {hasWordLimit && (
          <div className={styles.limit}>
            <Word />
            <span>{wordLimitText}</span>
          </div>
        )}
      </div>
    </div>
  );
};
