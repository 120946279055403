import { createDraftSafeSelector } from "@reduxjs/toolkit";

import { selectUser } from "../user/userSelector";

// Inner imports
import { eventsAdapter } from "./eventsSlice";

export const {
  selectAll: selectEvents,
  selectEntities: selectEventsEntities,
  selectTotal: selectEventsCount,
  selectById: selectEventById,
} = eventsAdapter.getSelectors<Store.RootState>(({ events }) => events);

export const selectEventsStatus = createDraftSafeSelector(
  ({ events }: Store.RootState) => events,
  (events) => events.status,
);

export const selectEventsByDashboardId = createDraftSafeSelector(
  (
    state: Store.RootState,
    dashboardId: Dashboard.Data["id"],
  ): [ReturnType<typeof selectEvents>, Dashboard.Data["id"]] => [
    selectEvents(state),
    dashboardId,
  ],
  ([events, dashboardId]) => {
    const filteredEvents = new Set<Event.Data>();

    for (const event of events) {
      const isDashboardEvent = event.dashboardId === dashboardId;

      if (isDashboardEvent) filteredEvents.add(event);
    }

    return [...filteredEvents];
  },
);

export const selectEventByDashboardDateRangeId = createDraftSafeSelector(
  (
    state: Store.RootState,
    dashboardDateRangeId: DashboardDateRange.Data["id"],
  ): [ReturnType<typeof selectEvents>, DashboardDateRange.Data["id"]] => [
    selectEvents(state),
    dashboardDateRangeId,
  ],
  ([events, dashboardDateRangeId]) =>
    events.find((event) => event.dashboardDateRangeId === dashboardDateRangeId),
);

export const selectCompanyEvents = createDraftSafeSelector(
  (
    state: Store.RootState,
  ): [ReturnType<typeof selectEvents>, ReturnType<typeof selectUser>] => [
    selectEvents(state),
    selectUser(state),
  ],
  ([events, user]) => {
    const filteredEvents = new Set<Event.Data>();

    for (const event of events) {
      const isCompanyEvent = event.companyId === user.companyId;

      if (isCompanyEvent) filteredEvents.add(event);
    }

    return [...filteredEvents];
  },
);
