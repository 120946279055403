import { FC } from "react";
import Markdown from "markdown-to-jsx";

import styles from "./MarkdownChart.module.scss";

type Props = {
  value: string;
};

export const MarkdownChart: FC<Props> = ({ value }) => (
  <div className={styles.wrapper}>
    <div className={styles.content}>
      <Markdown>{value}</Markdown>
    </div>
  </div>
);
