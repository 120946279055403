import { useCallback, useEffect, RefObject } from "react";

import { isEventTargetNodeGuard } from "src/utils";

export const useOutsideClickHandler = (
  ref: RefObject<HTMLElement>,
  callback: (e: MouseEvent) => void,
): void => {
  const handleClickOutside = useCallback(
    (e: MouseEvent): void => {
      const isClickOutside = getIsClickOutside(e, ref);

      if (isClickOutside) callback(e);
    },
    [ref, callback],
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [handleClickOutside]);
};

function getIsClickOutside(
  { target }: MouseEvent,
  ref: RefObject<HTMLElement>,
): boolean {
  if (!ref?.current) return false;

  if (!isEventTargetNodeGuard(target)) return true;

  const isElementClicked = ref.current.contains(target);

  if (isElementClicked) return false;

  const rootElement = document.getElementById("root");

  if (!rootElement) return true;

  const isElementInRoot = rootElement.contains(ref.current);

  if (!isElementInRoot) return true;

  return rootElement.contains(target);
}
