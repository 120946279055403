import { FC, useMemo, memo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import useResizeObserver from "use-resize-observer";

import styles from "./ArticlesChartArticle.module.scss";
import { Button, ExternalLink, HighlightText } from "src/components";
import { formatToDayMonthYearDate } from "src/utils";
import { ArrowUpRightFill } from "src/assets/icons";

type Props = {
  article: Widgets.ArticlesChart.Article;
  searchQuery: string;
  isExpanded: boolean;
};

export const ArticlesChartArticle: FC<Props> = memo(
  ({ article, searchQuery, isExpanded }) => {
    const { t } = useTranslation();

    const ref = useRef<HTMLElement>(null);

    const { ref: resizeCallbackRef } = useResizeObserver<HTMLElement>({ ref });

    const [shouldTruncate, setShouldTruncate] = useState<boolean>(false);

    const [isTruncated, setIsTruncated] = useState<boolean>(true);

    const { title, description, url, date } =
      useMemo<Widgets.ArticlesChart.Article>(() => article, [article]);

    const isToggleTruncateVisible = useMemo<boolean>(
      () => !isExpanded && shouldTruncate && isTruncated,
      [isExpanded, isTruncated, shouldTruncate],
    );

    const { hostname, origin } = useMemo(() => {
      const { hostname, origin } = new URL(url);

      const formattedHostname = hostname.replace("www.", "");

      return { hostname: formattedHostname, origin };
    }, [url]);

    const descriptionClassName = useMemo<string>(() => {
      if (isTruncated && !isExpanded)
        return styles.articleDescriptionTruncated || "";

      return styles.articleDescriptionFull || "";
    }, [isExpanded, isTruncated]);

    const toggleIsTruncated = (): void => setIsTruncated((state) => !state);

    const callbackRef = (element: HTMLElement | null): void => {
      const childElement = element?.children[0];

      if (!childElement || element === null || ref.current === element) return;

      const { clientHeight, scrollHeight } = childElement;

      const isChildOverflown = clientHeight < scrollHeight;

      setShouldTruncate(isChildOverflown);

      resizeCallbackRef(element);
    };

    return (
      <div className={styles.article}>
        <div className={styles.articleHead}>
          <HighlightText
            text={title}
            highlight={searchQuery}
            className={styles.articleTitle}
          />
          <ExternalLink href={url} className={styles.articleLink}>
            {t("chart.articles.label.link")}
            <ArrowUpRightFill />
          </ExternalLink>
        </div>
        <div className={styles.articleContent}>
          <div className={styles.articleDescriptionWrapper} ref={callbackRef}>
            <HighlightText
              text={description}
              highlight={searchQuery}
              className={descriptionClassName}
            />
            {isToggleTruncateVisible && (
              <Button
                onClick={toggleIsTruncated}
                className={styles.toggleTruncateButton}
                buttonStyle="transparent"
                buttonSize="small"
              >
                {t("chart.articles.label.view_more")}
              </Button>
            )}
          </div>
          <div className={styles.articleFooter}>
            <ExternalLink className={styles.articleSource} href={origin}>
              {hostname}
            </ExternalLink>
            {date && (
              <div className={styles.articleDate}>
                <span>{formatToDayMonthYearDate(date)}</span>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  },
);
