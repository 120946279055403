import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { selectSubscriptionPlanKeywordsDataSources } from "src/store/selectors";

export const useKeywordsDataSource = (
  defaultKeywordsDataSource: Search.KeywordsDataSource | null,
): [
  Search.KeywordsDataSource | null,
  Dispatch<SetStateAction<Search.KeywordsDataSource | null>>,
] => {
  const keywordsDataSources = useSelector(
    selectSubscriptionPlanKeywordsDataSources,
  );

  const [keywordsDataSource, setKeywordsDataSource] =
    useState<Search.KeywordsDataSource | null>(defaultKeywordsDataSource);

  useEffect(() => {
    if (!defaultKeywordsDataSource) return;

    const isKeywordsDataSourceAvailable = keywordsDataSources.includes(
      defaultKeywordsDataSource,
    );

    if (defaultKeywordsDataSource && isKeywordsDataSourceAvailable)
      setKeywordsDataSource(defaultKeywordsDataSource);
  }, [defaultKeywordsDataSource, keywordsDataSources]);

  return [keywordsDataSource, setKeywordsDataSource];
};
