import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";

import styles from "./SignUp.module.scss";
import { SignUpForm } from "src/features";
import { triggerGtmEvent } from "src/utils";
import { ExternalLink, Translation } from "src/components";
import {
  MY_TELESCOPE_PRIVACY_POLICY_LINK,
  MY_TELESCOPE_TERMS_AND_CONDITIONS_LINK,
} from "src/constants";

type Props = {
  companyId?: Company.Data["id"];
};

const SignUp: FC<Props> = ({ companyId }) => {
  const { t } = useTranslation();

  useEffect(() => {
    triggerGtmEvent("UserSignUpView");
  }, []);

  return (
    <div className={styles.wrapper}>
      <h1 className={styles.title}>{t("authentication.signup.title")}</h1>
      <SignUpForm companyId={companyId} />
      <div className={styles.info}>
        <div className={styles.termsAndConditions}>
          <Translation
            i18nKey="authentication.signup.label.terms_and_condition"
            components={{
              termsLink: (
                <ExternalLink href={MY_TELESCOPE_TERMS_AND_CONDITIONS_LINK} />
              ),
              privacyLink: (
                <ExternalLink href={MY_TELESCOPE_PRIVACY_POLICY_LINK} />
              ),
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default SignUp;
