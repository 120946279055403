import { FC, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import styles from "./AddSearchModal.module.scss";
import { withError } from "src/hocs";
import { Form, Input } from "src/components";
import { removeExtraSpaces } from "src/utils";
import { ConfirmModal } from "src/features/index";
import { SEARCH_NAME_INPUT_LIMIT } from "src/constants";
import { useModal, useTemporaryErrors } from "src/hooks";

const InputWithError = withError(Input);

type Props = {
  submitHandler: (value: Search.CreationData["subject"]) => void;
};

export const AddSearchModal: FC<Props> = ({ submitHandler }) => {
  const { t } = useTranslation();

  const { closeModal } = useModal();

  const { errors, setErrors } = useTemporaryErrors(3000);

  const [name, setName] = useState<Search.CreationData["subject"]>("");

  const isDisabled = useMemo<boolean>(() => !name, [name]);

  const onSubmit = (): void => {
    if (isDisabled) return;

    const errors = validate();

    if (Object.keys(errors).length) return setErrors(errors);

    const formattedName = removeExtraSpaces(name);

    submitHandler(formattedName);

    closeModal("add-search");
  };

  function validate() {
    const validationErrors: typeof errors = {};

    if (!name.trim().length)
      validationErrors.name = t(
        "component.modal.add_search.form.validation.name_required",
      );

    return validationErrors;
  }

  return (
    <ConfirmModal
      id="add-search"
      type="success"
      size="medium"
      title={t("component.modal.add_search.title")}
      cancelButton={{
        text: t("component.modal.add_search.button.cancel"),
        onClick: () => closeModal("add-search"),
      }}
      acceptButton={{
        text: t("component.modal.add_search.button.submit"),
        onClick: onSubmit,
        disabled: isDisabled,
      }}
    >
      <Form
        onSubmit={onSubmit}
        disabled={isDisabled}
        className={styles.formWrapper}
      >
        <InputWithError
          autoFocus
          value={name}
          error={errors.name}
          changeHandler={setName}
          characterLimit={SEARCH_NAME_INPUT_LIMIT}
          placeholder={t("component.modal.add_search.form.placeholder.name")}
        />
      </Form>
    </ConfirmModal>
  );
};
