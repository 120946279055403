import { HTMLProps } from "react";
import { Trans } from "react-i18next";
import type { TransProps } from "react-i18next";
import type { Namespace, ParseKeys, TOptions } from "i18next";
import { _DefaultNamespace } from "react-i18next/TransWithoutContext";

import { LOCALIZATION_COMPONENT } from "src/constants";

export const Translation = <
  Key extends ParseKeys<Ns, TOpt, KPrefix>,
  Ns extends Namespace = _DefaultNamespace,
  TOpt extends TOptions = {},
  KPrefix = undefined,
  E = HTMLProps<HTMLDivElement>,
>(
  props: TransProps<Key, Ns, TOpt, KPrefix, E>,
): JSX.Element => (
  <Trans
    {...props}
    components={{ ...LOCALIZATION_COMPONENT, ...props.components }}
  />
);
