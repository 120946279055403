import { FC, memo, useContext, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import styles from "./WidgetSettings.module.scss";
import { Loader } from "src/assets/icons";
import { useAppDispatch } from "src/store";
import { Button, Input } from "src/components";
import {
  selectWhiteLabel,
  selectWidgetInfoById,
  selectAvailableDashboardById,
} from "src/store/selectors";
import {
  compareTwoStrings,
  removeExtraSpaces,
  showToastNotification,
} from "src/utils";
import { updateDashboard } from "src/store/dashboards/dashboardsSlice";
import { DashboardPageContext } from "src/pages/Dashboards/DashboardPageWrapper/context";

// Inner imports
import { WIDGET_SETTINGS_WIDGET_NAME_LIMIT } from "./constants";

type Props = {
  dashboardId: Dashboard.Data["id"];
  widgetId: Widget.IdType;
};

export const WidgetSettings: FC<Props> = memo(({ dashboardId, widgetId }) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const { closeSidebar } = useContext(DashboardPageContext);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const dashboard = useSelector((state: Store.RootState) =>
    selectAvailableDashboardById(state, dashboardId),
  );

  const widgetInfo = useSelector((state: Store.RootState) =>
    selectWidgetInfoById(state, widgetId),
  );

  const whiteLabel = useSelector(selectWhiteLabel);

  const dashboardWidgetSettings = useMemo<Dashboard.WidgetSettings | undefined>(
    () => dashboard?.settings?.widgets?.[widgetId],
    [dashboard?.settings?.widgets, widgetId],
  );

  const defaultWidgetName = useMemo<string>(
    () =>
      t(
        whiteLabel.widgetNames[widgetId] ||
          dashboardWidgetSettings?.name ||
          widgetInfo?.placeHolderOnUi ||
          "",
      ),
    [
      t,
      widgetId,
      whiteLabel.widgetNames,
      widgetInfo?.placeHolderOnUi,
      dashboardWidgetSettings?.name,
    ],
  );

  const [widgetCustomName, setWidgetCustomName] =
    useState<string>(defaultWidgetName);

  const isDataChanged = useMemo<boolean>(
    () => !compareTwoStrings(widgetCustomName, defaultWidgetName),
    [defaultWidgetName, widgetCustomName],
  );

  const isUpdateAvailable = useMemo<boolean>(
    () => isDataChanged && !isLoading,
    [isDataChanged, isLoading],
  );

  const inputPlaceholder = useMemo<string>(
    () =>
      t(
        whiteLabel.widgetNames[widgetId] || widgetInfo?.placeHolderOnUi || "",
        "widget.configuration.settings.form.placeholder.custom_name",
      ),
    [t, whiteLabel.widgetNames, widgetId, widgetInfo?.placeHolderOnUi],
  );

  const onWidgetCustomNameChange = (value: string): void =>
    setWidgetCustomName(value);

  const saveSettings = async (): Promise<void> => {
    const formattedWidgetCustomName = removeExtraSpaces(widgetCustomName);

    setWidgetCustomName(formattedWidgetCustomName);

    if (compareTwoStrings(formattedWidgetCustomName, defaultWidgetName)) return;

    const dashboardSettings = dashboard?.settings;
    const dashboardWidgetsSettings = dashboardSettings?.widgets;

    const updatedWidgetSettings = {
      ...dashboardWidgetSettings,
      name: formattedWidgetCustomName,
    };
    const updatedWidgetsSettings = {
      ...dashboardWidgetsSettings,
      [widgetId]: updatedWidgetSettings,
    };
    const updatedSettings = {
      ...dashboardSettings,
      widgets: updatedWidgetsSettings,
    };

    try {
      setIsLoading(true);

      await dispatch(
        updateDashboard({
          id: dashboardId,
          changes: { settings: updatedSettings },
        }),
      ).unwrap();

      closeSidebar();

      showToastNotification({
        id: "widget-settings-update-success",
        type: "success",
        text: t(
          "widget.configuration.settings.status.success.settings_updated",
        ),
      });
    } catch (error) {
      showToastNotification({
        type: "error",
        text: t("common.error.server_error"),
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={styles.wrapper}>
      <span className={styles.title}>
        {t("widget.configuration.settings.title")}
      </span>
      <div>
        <span className={styles.tip}>
          {t("widget.configuration.settings.label.custom_name")}
          {/* Needed for Carl and Intercom */}
          <div>
            <div id="dashboard-widget-settings-custom-name" />
          </div>
        </span>
        <Input
          value={widgetCustomName}
          placeholder={inputPlaceholder}
          changeHandler={onWidgetCustomNameChange}
          className={styles.widgetCustomNameInput}
          characterLimit={WIDGET_SETTINGS_WIDGET_NAME_LIMIT}
        />
      </div>
      <div className={styles.buttons}>
        <Button
          className={styles.updateButton}
          buttonSize="large"
          onClick={saveSettings}
          disabled={!isUpdateAvailable}
        >
          {isLoading ? (
            <Loader className={styles.loader} />
          ) : (
            t("widget.configuration.settings.button.update_settings")
          )}
        </Button>
      </div>
    </div>
  );
});
