export const PIE_CHART_MIN_PERCENTAGE: number = 3 as const;

export const PIE_CHART_SMALL_PERCENTAGE: number = 7.5 as const;

export const PIE_CHART_SMALL_LABEL_LENGTH: number = 3 as const;

export const PIE_CHART_TOOLTIP_EXTRA_SPACE: number = 75 as const;

export const PIE_CHART_TOOLTIP_PARENT_OFFSET_TOP: number = 75 as const;

export const PIE_CHART_STYLE_PROPS = {
  animate: true,
  animationDuration: 500,
  animationEasing: "ease-out",
  lineWidth: 40,
  labelPosition: 80,
  labelStyle: {
    fill: "white",
    fontSize: 5,
    fontWeight: 400,
    pointerEvents: "none",
  },
  startAngle: -90,
} as const;
