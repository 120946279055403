import {
  useMemo,
  useState,
  useEffect,
  forwardRef,
  useCallback,
  ForwardedRef,
} from "react";
import cx from "classnames";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import styles from "./SelectedSearch.module.scss";
import { Loader } from "src/assets/icons";
import { useAppDispatch } from "src/store";
import { useModal, useScrollTo } from "src/hooks";
import { MenuDropdown, Preloader } from "src/components";
import { MenuOption } from "src/components/MenuDropdown/types";
import { getSearchKeywords } from "src/store/searches/searchesApi";
import { createSearch, fetchSearchConfigurationById } from "src/store/actions";
import {
  EditSearchModal,
  CopySearchModal,
  SearchKeywordsModal,
  SearchKeywordsDataSourceIcon,
} from "src/features";
import {
  selectLocationById,
  selectLanguageById,
  selectSearchConfigurationById,
} from "src/store/selectors";
import {
  getKeywordSearchLink,
  showToastNotification,
  formatToLocaleNumber,
  isSearchCreatedTypeGuard,
} from "src/utils";
import { useSearchStatusObserver } from "../../../../hooks";

type Props = {
  keywordsData?: Search.KeywordsData;
  search: Search.Data | Search.CreationData;
  selectSearchHandler: (search: Search.CreationData) => void;
  unselectSearchHandler: (search: Search.CreationData) => void;
  updateSearchHandler: ({
    id,
    changes,
  }: {
    id: Search.Data["id"];
    changes: Search.CreationData;
  }) => void;
  updateSearchKeywordsHandler: (
    searchId: Search.Data["id"],
    keywords: Search.Keyword[],
    status: LoadingStatus,
  ) => void;
};

export const SelectedSearch = forwardRef(
  (
    {
      search,
      keywordsData,
      updateSearchHandler,
      selectSearchHandler,
      unselectSearchHandler,
      updateSearchKeywordsHandler,
    }: Props,
    forwardedRef: ForwardedRef<HTMLDivElement>,
  ) => {
    const { t } = useTranslation();

    const dispatch = useAppDispatch();

    const [ref, setShouldScrollTo] = useScrollTo<HTMLDivElement>({
      behavior: "smooth",
    });

    const { setModal } = useModal();

    const searchId = useMemo<Search.Data["id"]>(() => search.id, [search]);

    const location = useSelector((state: Store.RootState) =>
      selectLocationById(state, search.locationId),
    );

    const language = useSelector((state: Store.RootState) =>
      selectLanguageById(state, search.languageId),
    );

    const searchConfiguration = useSelector((state: Store.RootState) =>
      selectSearchConfigurationById(state, searchId),
    );

    const [searchCreateLoadingStatus, setSearchCreateLoadingStatus] =
      useState<LoadingStatus>("idle");

    const { searchStatus } = useSearchStatusObserver(search);

    const locationName = useMemo<Location.Data["name"]>(
      () => location?.name || "",
      [location?.name],
    );

    const languageName = useMemo<Language.Data["name"]>(
      () => language?.name || "",
      [language?.name],
    );

    const isSearchCreateLoading = useMemo<boolean>(
      () => searchCreateLoadingStatus === "loading",
      [searchCreateLoadingStatus],
    );

    const keywordsStatus = useMemo<LoadingStatus>(
      () => keywordsData?.status || "idle",
      [keywordsData?.status],
    );

    const { searchLink, imageSearchLink } = useMemo(
      () => ({
        searchLink: getKeywordSearchLink({ keyword: search.subject, location }),
        imageSearchLink: getKeywordSearchLink({
          keyword: search.subject,
          location,
          type: "image",
        }),
      }),
      [search.subject, location],
    );

    const selectedKeywordsVolume = useMemo<string>(() => {
      let result = 0;

      if (!searchConfiguration?.keywords || !keywordsData?.keywords)
        return formatToLocaleNumber(result);

      for (const { string: keyword, totalVolume } of keywordsData.keywords) {
        const iKeywordSelected = searchConfiguration.keywords.includes(keyword);

        if (!iKeywordSelected) continue;

        result += totalVolume;
      }

      return formatToLocaleNumber(result);
    }, [keywordsData?.keywords, searchConfiguration?.keywords]);

    const isKeywordsLoading = useMemo<boolean>(
      () =>
        !keywordsData?.status ||
        keywordsData?.status === "idle" ||
        keywordsData?.status === "loading",
      [keywordsData?.status],
    );

    const hasKeywordsWarning = useMemo<boolean>(() => {
      if (keywordsStatus === "loading" || keywordsStatus === "idle")
        return false;

      return searchStatus === "NO_SELECTED_KEYWORDS";
    }, [keywordsStatus, searchStatus]);

    useEffect(() => {
      if (isSearchCreateLoading) setShouldScrollTo(true);
    }, [isSearchCreateLoading, setShouldScrollTo]);

    useEffect(() => {
      if (
        !searchStatus ||
        !isSearchCreatedTypeGuard(search) ||
        search.status === searchStatus
      )
        return;

      updateSearchHandler({
        id: search.id,
        changes: { ...search, status: searchStatus },
      });
    }, [search, searchStatus, updateSearchHandler]);

    useEffect(() => {
      if (
        searchCreateLoadingStatus !== "succeeded" &&
        isSearchCreatedTypeGuard(search)
      )
        setSearchCreateLoadingStatus("succeeded");
    }, [search, searchCreateLoadingStatus]);

    useEffect(() => {
      if (
        searchCreateLoadingStatus !== "idle" ||
        isSearchCreatedTypeGuard(search)
      )
        return;

      setSearchCreateLoadingStatus("loading");

      dispatch(createSearch(search))
        .unwrap()
        .then((newSearch) => {
          updateSearchKeywordsHandler(newSearch.id, [], "idle");

          updateSearchHandler({ id: search.id, changes: newSearch });

          setSearchCreateLoadingStatus("succeeded");
        })
        .catch((error) => {
          console.error(error);

          showToastNotification({
            type: "error",
            text: t("common.error.server_error"),
          });

          setSearchCreateLoadingStatus("failed");

          unselectSearchHandler(search);
        });
    }, [
      t,
      search,
      dispatch,
      updateSearchHandler,
      unselectSearchHandler,
      searchCreateLoadingStatus,
      updateSearchKeywordsHandler,
    ]);

    useEffect(() => {
      if (
        searchConfiguration ||
        searchCreateLoadingStatus !== "succeeded" ||
        !isSearchCreatedTypeGuard(search)
      )
        return;

      if (searchStatus !== "READY" && searchStatus !== "NO_SELECTED_KEYWORDS")
        return;

      dispatch(fetchSearchConfigurationById(search.id))
        .unwrap()
        .catch((error) => {
          console.error(error);

          showToastNotification({
            type: "error",
            text: t("common.error.server_error"),
          });
        });
    }, [
      t,
      search,
      dispatch,
      searchStatus,
      searchConfiguration,
      searchCreateLoadingStatus,
    ]);

    useEffect(() => {
      if (
        !searchConfiguration ||
        keywordsStatus !== "idle" ||
        !isSearchCreatedTypeGuard(search)
      )
        return;

      if (searchStatus !== "READY" && searchStatus !== "NO_SELECTED_KEYWORDS")
        return;

      updateSearchKeywordsHandler(search.id, [], "loading");

      getSearchKeywords(search.id)
        .then((keywords) =>
          updateSearchKeywordsHandler(search.id, keywords, "succeeded"),
        )
        .catch(() => {
          updateSearchKeywordsHandler(search.id, [], "failed");

          showToastNotification({
            type: "error",
            text: t("common.error.server_error"),
          });
        });
    }, [
      t,
      search,
      searchStatus,
      keywordsStatus,
      searchConfiguration,
      searchCreateLoadingStatus,
      updateSearchKeywordsHandler,
    ]);

    const showSearch = useCallback((): void => {
      window.open(searchLink, "_blank");
    }, [searchLink]);

    const showImageSearch = useCallback((): void => {
      window.open(imageSearchLink, "_blank");
    }, [imageSearchLink]);

    const showEditSearchModal = useCallback((): void => {
      if (!isSearchCreatedTypeGuard(search)) return;

      return setModal(
        "edit-search",
        <EditSearchModal search={search} submitHandler={updateSearchHandler} />,
      );
    }, [search, setModal, updateSearchHandler]);

    const showDuplicateSearchModal = useCallback((): void => {
      if (!isSearchCreatedTypeGuard(search)) return;

      return setModal(
        "copy-search",
        <CopySearchModal
          search={search}
          duplicateHandler={selectSearchHandler}
        />,
      );
    }, [selectSearchHandler, search, setModal]);

    const openSearchKeywords = useCallback((): void => {
      if (!isSearchCreatedTypeGuard(search)) return;

      return setModal(
        "search-keywords",
        <SearchKeywordsModal search={search} keywordsData={keywordsData} />,
      );
    }, [keywordsData, search, setModal]);

    const unselectSearch = useCallback(
      (): void => unselectSearchHandler(search),
      [search, unselectSearchHandler],
    );

    const options = useMemo<MenuOption[]>(
      () => [
        {
          group: 1,
          icon: "DuplicateOutline",
          onClick: showDuplicateSearchModal,
          label: t("component.selected_search.label.copy_search"),
        },
        {
          group: 1,
          icon: "PencilOutline",
          onClick: showEditSearchModal,
          label: t("component.selected_search.label.edit_search"),
        },
        {
          group: 1,
          icon: "GearOutline",
          onClick: openSearchKeywords,
          label: t("component.selected_search.label.keywords"),
        },
        {
          group: 2,
          icon: "Search",
          onClick: showSearch,
          label: t("component.selected_search.label.search_link"),
        },
        {
          group: 2,
          icon: "ImageSearch",
          onClick: showImageSearch,
          label: t("component.selected_search.label.image_search_link"),
        },
        {
          group: 3,
          type: "danger",
          icon: "TrashOutline",
          onClick: unselectSearch,
          label: t("component.selected_search.label.unselect_search"),
        },
      ],
      [
        t,
        showSearch,
        unselectSearch,
        showImageSearch,
        openSearchKeywords,
        showEditSearchModal,
        showDuplicateSearchModal,
      ],
    );

    const onClick = (): void => {
      if (!isSearchCreateLoading) openSearchKeywords();
    };

    return (
      <div
        ref={ref}
        onClick={onClick}
        className={cx(
          styles.wrapper,
          hasKeywordsWarning ? styles.wrapperWarning : "",
        )}
      >
        {isSearchCreateLoading && (
          <div className={styles.loader}>
            <Preloader
              type="bar"
              text={t("component.selected_search.loader.create_search")}
            />
          </div>
        )}
        <div className={styles.header}>
          <span
            className={cx(
              styles.heading,
              hasKeywordsWarning ? styles.headingWarning : "",
            )}
          >
            {t("component.selected_search.label.search")}
          </span>
          <div className={styles.settings}>
            <MenuDropdown
              ref={forwardedRef}
              options={options}
              className={styles.setting}
              isPositionFixed
              isEventPropagationStopped
            />
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles.title}>
            <span>{search.subject}</span>
          </div>
          <div className={styles.description}>
            <span>{search.description}</span>
          </div>
          {hasKeywordsWarning && (
            <div className={styles.warning}>
              <span>{t("component.selected_search.label.warning")}</span>
            </div>
          )}
        </div>
        <div className={styles.footer}>
          <div className={styles.footerSettings}>
            <div className={styles.keywordsDataSource}>
              <SearchKeywordsDataSourceIcon
                keywordsDataSource={search.keywordsDataSource}
              />
            </div>
            <div className={styles.location} title={locationName}>
              <span>{locationName}</span>
            </div>
            <div className={styles.language} title={languageName}>
              <span>{search.languageId.toUpperCase()}</span>
            </div>
          </div>
          <div className={styles.footerKeywords}>
            {!isSearchCreateLoading && isKeywordsLoading && (
              <Loader className={styles.loaderWrapper} />
            )}
            {!isSearchCreateLoading && !isKeywordsLoading && (
              <span>{selectedKeywordsVolume}</span>
            )}
          </div>
        </div>
      </div>
    );
  },
);
