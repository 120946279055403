import { useMemo } from "react";
import cx from "classnames";

import styles from "./TableCardHead.module.scss";
import { genericMemo } from "src/utils";

// Inner imports
import type {
  TableMenuOption,
  TableColumn,
  TableItemGenericProps,
  TableProps,
} from "../../../../types";
import {
  TableItemToggler,
  TableCellMenu,
  ClickableTableCell,
  TextTableCell,
} from "../../..";

type Props<T> = {
  item: T;
  cardHeaderProp: keyof T;
  isCollapsed: boolean;
  toggleCollapse: () => void;
  isSubTable?: boolean;
} & Pick<TableProps<T>, "tableColumns" | "getActionMenuOptions">;

export const TableCardHead = genericMemo(
  <T extends TableItemGenericProps>({
    item,
    tableColumns,
    getActionMenuOptions,
    cardHeaderProp,
    isCollapsed,
    toggleCollapse,
    isSubTable,
  }: Props<T>): JSX.Element => {
    const menuOptions = useMemo<TableMenuOption[]>(
      () => getActionMenuOptions?.(item) || [],
      [getActionMenuOptions, item],
    );

    const isCellMenuVisible = useMemo<boolean>(
      () => Boolean(menuOptions.length),
      [menuOptions.length],
    );

    const tableColumn = useMemo<TableColumn<T> | undefined>(
      () => tableColumns.find(({ key }) => key === cardHeaderProp),
      [cardHeaderProp, tableColumns],
    );

    const HeaderElement = useMemo<JSX.Element | null>(() => {
      if (!tableColumn) return null;

      const { onClick, checkIsClickable } = tableColumn;

      const isClickable =
        onClick && (checkIsClickable ? checkIsClickable(item) : true);

      if (isClickable)
        return <ClickableTableCell item={item} tableColumn={tableColumn} />;

      return <TextTableCell item={item} tableColumn={tableColumn} />;
    }, [tableColumn, item]);

    return (
      <div
        className={cx(
          styles.tableCardHead,
          isSubTable ? styles.subTableCardHead : "",
        )}
      >
        <TableItemToggler
          isCollapsed={isCollapsed}
          toggleCollapse={toggleCollapse}
        />
        <div className={styles.tableCardHeadTitle}>{HeaderElement}</div>
        <div className={styles.tableCardHeadAction}>
          {tableColumns.map(
            ({ isAction, getComponent }) => isAction && getComponent?.(item),
          )}
          {isCellMenuVisible && (
            <TableCellMenu
              options={menuOptions}
              className={styles.tableCardHeadMenu}
            />
          )}
        </div>
      </div>
    );
  },
);
